import React, { useState } from 'react'
import Header from '../common/header'
import popularBanner from '../../asset/home-image/blog-3.png'
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import Footer from '../common/footer'
import BlogVerticalCard from './BlogVerticalCard';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
// import cardImg from '../../asset/images/travel-1.png'
// import { Row, Col, } from 'react-bootstrap'
// import { API_IMG_URL } from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import blogActions from '../../redux/blog/actions';
import Masonry from 'react-masonry-css'
import InfiniteScroll from 'react-infinite-scroll-component';
import travellerActions from '../../redux/traveller/actions';

const useStyles = makeStyles((theme) =>
({
    popularBanner: {
        backgroundImage: `url(${popularBanner})`,
        backgroundRepeat: 'no-repeat',
        width: ' 100%',
        height: '100%',
        minHeight: '45vh',
        maxHeight: '45vh',
        backgroundSize: ' cover',
        backgroundPosition: ' center center',
        position: ' relative',
    },
})
)
// const blogListCard = 9;

export const BlogCountryWiseList = (props) => {
    const dispatch = useDispatch()
    const { state } = useLocation();
    const classes = useStyles();
    // const postPerPage = 20;
    // const [currentPage, setCurrentPage] = useState(0);
    const [fetchedData, setFetchedData] = useState([]);
    const { countryWiseBlog } = useSelector((state) => state.blogReducer)
    // const blogGrid = [
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Durga devi',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     },
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Surya',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     },
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Karthi',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     },
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Vicky',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     },
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Pavithran',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     }
    // ]
    useEffect(() => {
        if (state?.countryId) {
            dispatch({ type: blogActions.GET_COUNTRY_WISE_BLOG, payload: state?.countryId })
        }
    }, [state])
    useEffect(() => {
        dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }, []);
    const breakpointColumnsObj = {
        default: 3,
        991: 2,
        600: 1,
    };

    useEffect(() => {
        if (countryWiseBlog?.length > 0) {
            setFetchedData(countryWiseBlog?.slice(0, 20));
        };
    }, [countryWiseBlog]);

    const fetchMoreData = () => {
        if (countryWiseBlog?.length === fetchedData?.length) {
            return;
        } else {
            let currentLength = fetchedData?.length;
            setFetchedData([...fetchedData, ...countryWiseBlog?.slice(currentLength, currentLength + 20)])
        }
    };

    // const indexOfFirstPost = (currentPage * postPerPage) % countryWiseBlog?.length;
    // const indexOfLastPost = indexOfFirstPost + postPerPage;
    // const currentdata = countryWiseBlog?.slice(indexOfFirstPost, indexOfLastPost);

    // const handlePaginate = (e, value) => {
    //     setCurrentPage(value - 1);
    //     blogListRef.current.scrollIntoView();
    // };

    return (
        <Box className='popular_detail_sec'>
            <Header />
            <Box className={classes.popularBanner}>
                <Box className='overlay'></Box>
                <Typography className='blog__sub_banner' variant='h4'>{state?.countryName} Blog's</Typography>
            </Box>
            <div>
                <Box sx={{ flexGrow: 1 }} className='blog_list_section' >
                    <Container>

                        <Box className='recent_blog_head'>
                            <Typography variant='h3'>{state?.countryName} related blogs</Typography>
                        </Box>

                        {/* {countryWiseBlog.map((val, index) => {
                                return (
                                    <Col lg={4} md={4} sm={6}>
                                        <BlogVerticalCard key={index} val={val} />
                                    </Col>
                                )
                            })} */}
                        <InfiniteScroll
                            dataLength={fetchedData?.length}
                            next={fetchMoreData}
                            hasMore={true}
                            // loader={<h4>Loading...</h4>}
                            style={{ overflow: "hidden", marginBottom: "3rem" }}
                        >
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {fetchedData?.map((val, index) => {
                                    // {currentdata?.map((val, index) => {
                                    return (
                                        // <Col key={index} lg='4' md='4' sm='6'>
                                        <div key={index}>
                                            <BlogVerticalCard val={val} page="country_wise_blog" />
                                        </div>
                                        // </Col>

                                    );
                                })}
                            </Masonry>
                        </InfiniteScroll>
                        {/* <Grid item lg={12}>
                            <Stack spacing={2} className='blog_list_pagination'>
                                {countryWiseBlog?.length > postPerPage &&
                                    <Pagination count={Math.ceil(countryWiseBlog?.length / postPerPage)} page={currentPage + 1} onChange={handlePaginate} variant="outlined" shape="rounded" />
                                }
                            </Stack>
                        </Grid> */}
                    </Container>
                </Box>
            </div >

            <Footer />
        </Box >
    )
}

