import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import hotelActions from "../../redux/hotel/actions";
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { useNavigate } from "react-router";

const Timer = (props) => {
    const { hotelSessionDate, hotelSearchResult } = useSelector(state => state.hotelReducer);
    const { flightSessionReload } = useSelector(state => state.flightReducer);
    const dispatch = useDispatch();
    const history = useNavigate();
    let timerFunction, timer = 900;
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (hotelSessionDate) {
            var display = document.querySelector('#demo');
            const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
            // Clear any timeout/interval up to that id
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
            var minutes, seconds;

            timerFunction = setInterval(function () {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                display.textContent = minutes + ":" + seconds;

                if (--timer < 0) {
                    dispatch({ type: hotelActions.SET_NEXT_TOKEN, payload: null });
                    clearInterval(timerFunction);
                    if (hotelSessionDate) {
                        dispatch({ type: hotelActions.SET_HOTEL_SESSION_DATE, payload: null });
                        dispatch({ type: hotelActions.SET_CURRENT_PAGE, payload: 1 });
                    }
                    setShow(true);
                    display.textContent = "";
                }
            }, 1000);
        }
    }, [hotelSessionDate, hotelSearchResult]);

    React.useEffect(() => {
        return () => {
            var display = document.querySelector('#demo');
            dispatch({ type: hotelActions.SET_NEXT_TOKEN, payload: null });
            clearInterval(timerFunction);
            if (hotelSessionDate) {
                dispatch({ type: hotelActions.SET_HOTEL_SESSION_DATE, payload: null });
                dispatch({ type: hotelActions.SET_CURRENT_PAGE, payload: 1 });
            }
            setShow(true);
            if (display) {
                display.textContent = "";
            }
        }
    }, []);

    useEffect(() => {
        if (props.mode !== 'hotel') {
            dispatch({ type: hotelActions.RESET_HOTEL_STATE });
            clearInterval(timerFunction)
            setShow(false);
        }
    }, [props.mode]);

    useEffect(() => {
        if (localStorage.getItem('selectedHotelDetails')) {
            let temp = JSON.parse(localStorage.getItem('selectedHotelDetails'));
            dispatch({ type: hotelActions.RESET_HOTEL_REDUCER, payload: JSON.parse(localStorage.getItem('selectedHotelDetails')) });
            dispatch({
                type: hotelActions.GET_HOTEL_DETAILS, payload: {
                    hotelId: temp.detailPageBooking.val.hotelId,
                    productId: temp.detailPageBooking.val.productId,
                    tokenId: temp.detailPageBooking.val.tokenId,
                    sessionId: temp.hotelSessionId,
                    val: temp.detailPageBooking.val
                },
                history: history
            });
            localStorage.removeItem('selectedHotelDetails');
        }
    }, []);

    const handleModalClose = () => {
        window.location.href = '/hotel';
        dispatch({ type: hotelActions.RESET_HOTEL_STATE });
        clearInterval(timerFunction)
        setShow(false);
        if (document.querySelector('#demo')) {
            document.querySelector('#demo').textContent = "";
        }
    }

    const hideFlightModal = () => {
        window.location.href = '/flight';
    }

    return (
        <>
            <Modal show={show} onHide={handleModalClose} className='model_filter'>
                <Modal.Header closeButton>
                    <Modal.Title>Attention!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        Session Expired, we are redirecting to home page
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group">
                        <button type="button" className="btn btn-primary mr-1" onClick={handleModalClose}>Redirect</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={flightSessionReload} onHide={hideFlightModal} className='model_filter'>
                <Modal.Header closeButton>
                    <Modal.Title>Attention!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        Session Expired, refreshing the data
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group">
                        <button type="button" className="btn btn-primary mr-1" onClick={hideFlightModal}>Redirect</button>
                    </div>
                </Modal.Footer>
            </Modal>
            {hotelSessionDate ?

                <div className="session_timer">
                    <AlarmOnIcon /><p id="demo"></p>
                </div>
                :
                null
            }
        </>
    )
}

export default React.memo(Timer);