import { Apartment, PinDrop } from '@material-ui/icons'
import { Button, CardMedia, Rating } from '@mui/material'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useDispatch, useSelector } from 'react-redux'
import hotelActions from '../../redux/hotel/actions'
import commonActions from '../../redux/common/actions'
import Modal from 'react-bootstrap/Modal';
import HorizontalCard from './HorizontalCard';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { store } from '../../redux/store';

const HotelCard = (props) => {
  const { val, index } = props
  const dispatch = useDispatch()
  const history = useNavigate()
  const { hotelSessionId, hotelRoomRate, hotelSearchValues, hotelSessionDate } = useSelector((state) => state.hotelReducer)
  const { loading } = useSelector((state) => state.commonReducer)
  const temp = val;
  const value = val?.hotelRating;
  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(false);

  const OnHotelDetail = (val, index) => {
    localStorage.setItem('selectedHotelDetails', JSON.stringify({
      ...store.getState().hotelReducer,
      detailPageBooking: {
        val: val,
        index: index,
      },
      hotelSessionDate: hotelSessionDate,
      hotelSearchResult: [temp],
      initialHotelDetails: {
        hotelId: val.hotelId,
        productId: val.productId,
        tokenId: val.tokenId,
        sessionId: hotelSessionId,
        val: val
      }
    }));
    window.open('/hotel-result', '_blank');
  }

  const Onbooking = (val, index) => {
    dispatch({
      type: hotelActions.GET_HOTEL_RATE, payload: {
        sessionId: hotelSessionId,
        tokenId: val.tokenId,
        productId: val.productId,
        hotelId: val.hotelId,
      }
    })
    dispatch({ type: commonActions.SET_LOADER, payload: true });
    setShow(index)
  }

  const handleImages = (val1) => {
    dispatch({
      type: hotelActions.GET_HOTEL_IMAGES, payload: {
        hotelId: val1.hotelId,
        productId: val1.productId,
        tokenId: val1.tokenId,
        sessionId: hotelSessionId,
      }
    });
  }

  return (
    <Grid className='col-12 hotel_list_page' key={'currentPosts' + index}>
      <div className="popular_hotel_card row col-12 cursor-pointer" onClick={() => handleImages(val)}>
        <div className='nearhotel_card_img col-md-4 col-4 padd-0'>
          <CardMedia
            component="img"
            height="190"
            image={val?.thumbNailUrl}
            alt={val?.hotelName}
          />
          <div className='overlay'></div>
          <div className='hotel_review'>
            <div className='hotel_review_inner'>
              <Rating
                name="text-feedback"
                value={value}
                readOnly
                precision={0.5}
                emptyIcon={<StarBorderIcon style={{ color: '#fff', fontSize: '16' }} fontSize="inherit" />}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 col-lg-6 col-8 border-top-gray border-shadow">
          <div>
            <div className='near_plcess_details_list not-pointer'>
              <Button variant="outlined" className="type border-gray-color list-color gray-color" tabIndex={-1} startIcon={<Apartment className="list-color" />}>
                {val?.propertyType}
              </Button>
              <Button variant="outlined" className="city border-right-1 border-gray-color list-color gray-color" style={{ borderRight: 'none!important' }} tabIndex={-1} startIcon={<PinDrop className="list-color" />}>
                {val?.city}
              </Button>
            </div>
          </div>
          <div>
            <h3 className="MuiTypography-root MuiTypography-h3 pt-2">
              {val?.hotelName}
            </h3>
            <p className='daysTripContent'>
            ( 1 night  {" " + moment.duration(moment(hotelSearchValues?.checkout, "YYYY-MM-DD").diff(moment(hotelSearchValues?.checkin, "YYYY-MM-DD"))).asDays()} day)All Inclusive Price {val?.currency} {val?.total}
             </p>
          </div>
        </div>
        <div className='hotel_card_list_contentbox col-md-3 col-lg-2 col-12 border-top-gray border-right-gray border-shadow'>
          <div className='hotel_card_list_btn'>
            <h6 className="MuiTypography-root MuiTypography-h6 room_price_list">
              {val?.currency} {val?.total}
              <span className='ml-0'>
                <span className='ml-0 tax'>{" " + moment.duration(moment(hotelSearchValues?.checkout, "YYYY-MM-DD").diff(moment(hotelSearchValues?.checkin, "YYYY-MM-DD"))).asDays().toString() === 1 ? '1 night' : moment.duration(moment(hotelSearchValues?.checkout, "YYYY-MM-DD").diff(moment(hotelSearchValues?.checkin, "YYYY-MM-DD"))).asDays() + ' nights'} for {hotelSearchValues?.totalCount} guests</span>
                All Inclusive Price {val?.currency} {val?.total}</span></h6>
            <h6 className='hotel_btn' onClick={() => Onbooking(val, index)}>
              Book Now
            </h6>
            <h6 onClick={() => OnHotelDetail(val, index)} className='hotel_btn_info'>Full Info</h6>
          </div>


          <Modal
            show={!loading && show === index && hotelRoomRate?.length > 0 && !loading}
            onHide={() => setShow(false)}
            className='booking_model_pop'
            centered

          >
            <Modal.Header closeButton>
              <h3>Select Your Room</h3>
            </Modal.Header>
            <Modal.Body>
              <div onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  setToggle(!toggle);
                }
              }}>
                {hotelRoomRate?.map((item, ind) => (
                  <div key={'hotelRoomRate' + ind}>
                    <HorizontalCard item={item} val={val} index={ind} toggle={toggle} />
                  </div>
                ))}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Grid>
  )
}

export default React.memo(HotelCard);