import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container, Button, List, ListItem, makeStyles, Typography } from "@material-ui/core";
import Logo from '../../asset/images/logo.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import appstore from '../../asset/images/appstore.png'
import palaystore from '../../asset/images/playstore.png'
import footerVector from '../../asset/images/footer_vector.png'
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import commonActions from '../../redux/common/actions';



const useStyles = makeStyles((theme) => (
  {
    footer: {
      background: '#12122e',
    },
    fmenuitem: {
      color: '#abacb0',
      fontSize: ' 14px',
    },
    fmenuitems: {
      color: '#fff',
    },
    flogosec: {
      width: '211px',
    },
    fsocialicon: {
      color: '#fff !important',
      marginRight: '1rem',
    },
    fsocial: {
      margin: '1.5rem 0 1rem 0',
    },
    design: {
      display: 'flex',
      justifyContent: ' space-between',
      borderTop: '1px solid #ffffff2b',
      marginTop: '3rem',
      alignItems: 'center',
      padding: '0.5rem 0',
    },
    designname: {
      color: '#abacb0 !important',
      fontSize: '14px',
    },
    subscrib: {
      background: 'white',
      padding: '1rem 2rem',
      borderBottom: '3px solid #f6c220',
      borderRadius: '10px 10px 2px 2px',
      position: ' relative',
      top: ' -4rem',
      boxShadow: '0px 5px 27px -7px #00000029',
      alignItems: 'center',
      width: '90%',
      margin: ' auto',
      [theme.breakpoints.down("xs")]: {
        display: 'block',
        boxShadow: '0px 5px 27px -7px #00000029',
        alignItems: '-webkit-center',
      },
    },


  }
));

function Footer() {
  const dispatch = useDispatch()
  const classes = useStyles();
  // form validation rules 
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    dispatch({ type: commonActions.GET_SUBSCRIBE_FORM, payload: data.email })
    reset()
  }
  return (
    <div>
      <footer className={classes.footer}>
        <div className='footer_vector'>
          <div className='vector_image'>
          <img src={footerVector} alt='img' />
          </div>
          <Container>
            {/* <form >
              <div className={`${classes.subscrib} full-w`}>
                <div className='row'>
                  <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                    <div className='subNewsLetter'>
                      <Typography className='subscribe_con'> <b>Subscribe</b> to our free Weekly <br /> TickaTrip news letter!</Typography>
                      <div className='subscribe_form'>
                        <input placeholder='Enter Your email address' name="email" type="text" {...register('email')} className={`form-control subscribe_input ${errors.email ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-12 col-sm-12 col-12 submit_newsbtn_align'>
                    <Button className='subscribe_btn' onClick={handleSubmit(onSubmit)}>submit newsletter</Button>
                  </div>
                </div>
              </div>
            </form> */}

            <Box className='footer_content'>
              <Grid className='row'>
                <Grid className='col-lg-5 col-md-5 col-sm-12 col-12'>
                  <div>
                    <Link to='/'>
                      <img className={classes.flogosec} src={Logo} alt='' />
                    </Link>
                  </div>
                  <div className={classes.fsocial}>
                    <a target='blank' className={classes.fsocialicon} href="https://www.facebook.com/login/" underline="none"><FacebookIcon /></a>
                    <a target='blank' className={classes.fsocialicon} href="https://www.instagram.com/accounts/login/" underline="none"><InstagramIcon /></a>
                    <a target='blank' className={classes.fsocialicon} href="https://twitter.com/i/flow/login" underline="none"><TwitterIcon /></a>
                    <a target='blank' className={classes.fsocialicon} href="https://www.youtube.com/" underline="none"><YouTubeIcon /></a>
                  </div>
                  <div className="flex social-btns">
                    <a target='blank' className="app-btn blu flex vert" href="https://www.apple.com/in/app-store/">
                      <img src={appstore} alt='img' className='app_image' />
                    </a>

                    <a target='blank' className="app-btn blu flex vert" href="https://play.google.com/store/apps">
                      <img src={palaystore} alt='img' className='app_image' />
                    </a>
                  </div>


                </Grid>
                {/* <Grid className='col-lg-3 col-md-3 col-sm-12 col-12'>
                  <List className={`${classes.fmenuitems} footer-list`}>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/flight'>Book Flights</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/hotel'>Book Hotels</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/'>Partner Programs</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/'>Trending Routes</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/'>Popular Flights</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/'>Members Discount</Link>
                    </ListItem>
                  </List>
                </Grid> */}
                <Grid className='col-lg-2 col-md-2 col-sm-12 col-12'>
                  <List className={`${classes.fmenuitems} footer-list`}>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/about'>About us</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/career'>Careers</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/contact'>Contact us</Link>
                    </ListItem>
                    {/* <ListItem className={classes.fmenuitem}>
                      <Link to=''>Sitemap</Link>
                    </ListItem> */}
                  </List>
                </Grid>
                <Grid className='col-lg-2 col-md-2 col-sm-12 col-12' >
                  <List className={`${classes.fmenuitems} footer-list no-bor`}>
                    <ListItem className={classes.fmenuitem}>
                      <Link to=''>Help & Support</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/term-condition'>Terms & Conditions</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/privacy-policy'>Privacy Policy</Link>
                    </ListItem>
                    <ListItem className={classes.fmenuitem}>
                      <Link to='/faqs'>FAQ's</Link>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <div className={classes.design}>
                <p>@2022 TickaTrip</p>
                <a target='blank' className={classes.designname} href="https://www.mindmade.in/" underline="none"><span>Design : </span>Mindmade</a>
              </div>
            </Box>
          </Container>
        </div>
      </footer>
    </div>
  )
};

export default React.memo(Footer);