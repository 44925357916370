import { all } from "redux-saga/effects";
import FlightSaga from "./flight/saga";
import UserSaga from "./user/saga";
import travellerSaga from './traveller/saga'
import HotelSaga from "./hotel/saga";
import HomeSaga from "./home/saga";
import commonSaga from "./common/saga";
import blogSaga from "./blog/saga";

export default function* rootSaga() {
    yield all([
        FlightSaga(),
        UserSaga(),
        travellerSaga(),
        HotelSaga(),
        HomeSaga(),
        commonSaga(),
        blogSaga()
       
    ]);
};
