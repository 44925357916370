import actions from "./actions";

const initialState = {
  UserDetails: [],
  isAuthenticated: !!localStorage.getItem('tickatrip-token'),
  isAuthenticatedLogin: false,
  validUserMailMessage: null,
  userValidUserOtp: {
    otp: ''
  },
  userValidOtpId: null,
  userNewPassword: {
    password: '',
    confirmPassword: ''
  },
  userOtpVerify: null,
  flightBookingList: [],
  flightBookingCancelVerify: [],
  flightBookingCancelConfirm: [],
  myBookingCancelList: [],
  myBookingTicketDetails: [],
  myHotelBookings: [],
  myHotelBookingsCompleted: [],
  myHotelBookingsCancel: [],
  myHotelBookingsCancelRequest: [],
  myHotelBookingsCancelVerify: [],
  myHotelGuestBookingsCancel: [],
  supplierConfirmationNumber: '',
  myHotelGuestBookingsCancelOtp: [],
  upCommingFlightDetail: null,
  flightCompletedBooking: [],
  hotelBookingDetail: [],
  userPageSlider: [],
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_AUTHETICATION:
      return {
        ...state,
        isAuthenticated: action.payload
      }
    case actions.SET_OTP_MAIL_LOGIN_MESSAGE:
      return {
        ...state,
        validUserMailMessage: action.payload
      }
    case actions.SET_VERIFY_OTP:
      return {
        ...state,
        userValidUserOtp: action.payload
      }
    case actions.SET_VERIFY_OTP_ID:
      return {
        ...state,
        userValidOtpId: action.payload
      }
    case actions.SET_NEW_PASSWORD:
      return {
        ...state,
        userNewPassword: action.payload
      }
    case actions.SET_VERIFY_OTP_ID_PASSWORD:
      return {
        ...state,
        userOtpVerify: action.payload
      }
    case actions.SET_PROFILE_EDIT:
      return {
        ...state,
        userEditUpdate: action.payload
      }
    case actions.SET_MY_BOOKING:
      return {
        ...state,
        flightBookingList: action.payload
      }
    case actions.SET_MY_BOOKING_CANCEL_VERIFY:
      return {
        ...state,
        flightBookingCancelVerify: action.payload
      }
    case actions.SET_MY_BOOKING_CANCEL_CONFIRM:
      return {
        ...state,
        flightBookingCancelConfirm: action.payload
      }
    case actions.SET_MY_BOOKING_CANCEL_LIST:
      return {
        ...state,
        myBookingCancelList: action.payload
      }
    case actions.SET_MY_BOOKING_TICKET_DETAILS:
      return {
        ...state,
        myBookingTicketDetails: action.payload
      }
    case actions.SET_MY_HOTEL_BOOKINGS:
      return {
        ...state,
        myHotelBookings: action.payload
      }
    case actions.SET_MY_HOTEL_BOOKINGS_COMPLETED:
      return {
        ...state,
        myHotelBookingsCompleted: action.payload
      }
    case actions.SET_MY_HOTEL_BOOKINGS_CANCEL:
      return {
        ...state,
        myHotelBookingsCancel: action.payload
      }
    case actions.SET_HOTEL_BOOKINGS_CANCEL_REQUEST:
      return {
        ...state,
        myHotelBookingsCancelRequest: action.payload
      }
    case actions.SET_HOTEL_BOOKINGS_CANCEL_VERIFY:
      return {
        ...state,
        myHotelBookingsCancelVerify: action.payload
      }
    case actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_REG:
      return {
        ...state,
        myHotelGuestBookingsCancel: action.payload
      }
    case actions.SET_SCN_NUMBER:
      return {
        ...state,
        supplierConfirmationNumber: action.payload
      }
    case actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER:
      return {
        ...state,
        myHotelGuestBookingsCancelOtp: action.payload
      }
    case actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS:
      return {
        ...state,
        upCommingFlightDetail: action.payload
      }
    case actions.SET_MY_COMPLETED_BOOKING:
      return {
        ...state,
        flightCompletedBooking: action.payload
      }
    case actions.SET_MY_HOTEL_BOOKING_DETAILS:
      return {
        ...state,
        hotelBookingDetail: action.payload
      }
    case actions.SET_USER_PAGE_SLIDER:
      return {
        ...state,
        userPageSlider: action.payload
      }
    default:
      return state;
  }
};

export default userReducer;