import { useEffect } from 'react'
import { Button } from '@material-ui/core'
import React from 'react'
import './user.css'
import flightIcon from '../../asset/icons/center.svg'
import { useNavigate } from 'react-router'
import userActions from '../../redux/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import commonActions from '../../redux/common/actions'
import flightBookingNotFound from '../../asset/flightBookingNotFound.gif'
import { Link } from 'react-router-dom'

const FlightCompletedTrip = () => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const { flightCompletedBooking } = useSelector((state) => state.userReducer)

    const ViewTicket = (item, index) => {
        let temp = item.id
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: userActions.GET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: temp, history: history })
    }

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: userActions.GET_MY_COMPLETED_BOOKING, payload: '' })
    }, []);

    return (
        <div className='upcoming_trips completed_booking'>
            {flightCompletedBooking.length !== 0 ?
                <div>
                    {flightCompletedBooking?.map((item, index) =>
                        <main className='main_ticket' key={'flightCompletedBooking' + index}>
                            <section className="flight--general">
                                <div>
                                    <h2>MUC</h2>
                                    <h4>Munich</h4>
                                </div>
                                <div className='flight_center_img'>
                                    <h4><img src={flightIcon} alt='img' /></h4>
                                </div>
                                <div>
                                    <h2>BCN</h2>
                                    <h4>Barcelona</h4>
                                </div>
                            </section>
                            <section className="flight--TimeInfo">
                                <div>
                                    <h5>PNR No</h5>
                                    <p>{item.AirlinePNR}</p>
                                </div>
                                <div>
                                    <h5>Boarding</h5>
                                    <p>18:20</p>
                                </div>
                                <div>
                                    <h5>Departure</h5>
                                    <p>19:00</p>
                                </div>
                                <div>
                                    <h5>Date</h5>
                                    <p>15/10/2017</p>
                                </div>
                                <div>
                                    <h5>Traveller</h5>
                                    <p>3</p>
                                </div>
                                <div>
                                    <Button className='view_ticket' onClick={() => ViewTicket(item, index)}>View Ticket</Button>
                                </div>
                            </section>

                        </main>
                    )}
                </div>

                :
                <div className='flight_not_found'>
                    <img src={flightBookingNotFound} alt='notFound' />
                    <h3>You Don't have any Bookings</h3>
                    <Link to='/'>Go to booking</Link>
                </div>
            }
        </div>
    )
}

export default React.memo(FlightCompletedTrip);