import actions from "./actions";

const initialState = {
  saveAdultTraveller: [],
  travellerListData: [],
  travellerEdit: [],
  userProfileEdit: [],
  userProfileData: [],
};

const travellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ADULT_TRAVELLER:
      return {
        ...state,
        saveAdultTraveller: action.payload
      }
    case actions.SET_TRAVELLER_LIST:
      return {
        ...state,
        travellerListData: action.payload
      }
    case actions.SET_TRAVELLER_EDIT:
      return {
        ...state,
        travellerEdit: action.payload
      }
    case actions.SET_PROFILE_EDIT:
      return {
        ...state,
        userProfileEdit: action.payload
      }
    case actions.SET_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.payload
      }
    default:
      return state;
  }
};

export default travellerReducer;