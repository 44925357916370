import React, { useState } from 'react';
import Header from '../common/header'
// import popularBanner from '../../asset/home-image/blog-3.png'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import Footer from '../common/footer'
import BlogVerticalCard from './BlogVerticalCard';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
import { API_IMG_URL } from '../../utils/constant';
// import cardImg from '../../asset/images/travel-1.png'
import { Row, Col, } from 'react-bootstrap'
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import blogActions from '../../redux/blog/actions';
import Masonry from 'react-masonry-css'
import InfiniteScroll from 'react-infinite-scroll-component';
import travellerActions from '../../redux/traveller/actions';

export const TagWiseBlog = (props) => {
    const dispatch = useDispatch()
    const { state } = useLocation();
    const [fetchedData, setFetchedData] = useState([]);
    const blogImage = state?.blogImage

    const useStyles = makeStyles((theme) =>
    ({
        popularBanner: {
            backgroundImage: `url(${API_IMG_URL}/server/blog/${blogImage})`,
            backgroundRepeat: 'no-repeat',
            width: ' 100%',
            height: '100%',
            minHeight: '45vh',
            maxHeight: '45vh',
            backgroundSize: ' cover',
            backgroundPosition: ' center center',
            position: ' relative',
        },
    })
    )

    const classes = useStyles();
    const { TagWiseBlog } = useSelector((state) => state.blogReducer)


    useEffect(() => {
        if (state?.tagName) {
            dispatch({ type: blogActions.GET_TAG_WISE_BLOG, payload: state?.tagName })
        }
    }, [state?.tagName])

    useEffect(() => {
        dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }, []);

    const breakpointColumnsObj = {
        default: 3,
        991: 2,
        600: 1,
    };

    useEffect(() => {
        if (TagWiseBlog?.length > 0) {
            setFetchedData(TagWiseBlog?.slice(0, 20));
        };
    }, [TagWiseBlog]);

    const fetchMoreData = () => {
        if (TagWiseBlog?.length === fetchedData?.length) {
            return;
        } else {
            let currentLength = fetchedData?.length;
            setFetchedData([...fetchedData, ...TagWiseBlog?.slice(currentLength, currentLength + 20)])
        }
    };

    return (
        <Box className='popular_detail_sec'>
            <Header />
            <Box className={classes.popularBanner}>
                <Box className='overlay'></Box>
                <Typography className='blog__sub_banner' variant='h4'>{state?.blogHeading}</Typography>
            </Box>

            <Box sx={{ flexGrow: 1 }} className='blog_list_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Box className='recent_blog_head'>
                                <Typography variant='h3'>{state?.blogHeading} RELATED BLOg'S</Typography>
                            </Box>
                        </Col>
                        <Row>
                            <InfiniteScroll
                                dataLength={fetchedData?.length}
                                next={fetchMoreData}
                                hasMore={true}
                                // loader={<h4>Loading...</h4>}
                                style={{ overflow: "hidden", marginBottom: "3rem" }}
                            >
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                >
                                    {fetchedData.map((val, index) => {
                                        return (
                                            <div key={index}>
                                                {/* <Col key={index} lg='4' md='4' sm='6'> */}
                                                <BlogVerticalCard val={val} page="tag_wise_blog" />
                                                {/* </Col> */}
                                            </div>
                                        );
                                    })}
                                </Masonry>
                            </InfiniteScroll>
                        </Row>
                        {/* <Grid item lg={12}>
                            <Stack spacing={2} className='blog_list_pagination'>
                                <Pagination count={5} variant="outlined" shape="rounded" />
                            </Stack>
                        </Grid> */}
                    </Row>
                </Container>
            </Box>

            <Footer />
        </Box>

    )
}

