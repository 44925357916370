import { Box, Container, Typography } from '@material-ui/core'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
// import Footer from '../common/footer'
// import Header from '../common/header'
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import avatar from '../../asset/images/avatar.jpg'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AddImage from '../../asset/images/add.jpg'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import blogActions from '../../redux/blog/actions'
import { API_IMG_URL } from '../../utils/constant'
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
// import foundImg from '../../asset/icon-nothing-found.png'
import DeleteIcon from '@mui/icons-material/Delete';
// import BlogMoreVertIcon from './BlogMoreVertIcon'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Pagination from '@mui/material/Pagination'
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// const storyCard = 6;
// const followers = 5;

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required'),
  name: Yup.string()
    .required('Name is required'),
  lastName: Yup.string()
    .required('Last name is required'),
  dob: Yup.string()
    .required('Date of Birth is required')
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  acceptTerms: Yup.bool()
    .oneOf([true], 'Accept Ts & Cs is required')
});

export const BlogUserProfile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { state } = useLocation();
  const { followBloggerList, UserWiseBlog, BlogStoryList, BookMarkList, viewBloggerProfile, bloggersFollow } = useSelector((state) => state.blogReducer)
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [value, setValue] = useState(0);
  const [addStoryBlog, setAddStoryBlog] = useState(false)
  //for pagination
  const [currentPage, setCurrentPage] = useState(0)
  const [postPerPage, setPostPerPage] = useState(4)
  const [filteredData, setFilteredData] = useState([])
  const handleModalClose = () => setAddStoryBlog(false);
  const handleModalShow = () => setAddStoryBlog(true);

  useEffect(() => {
    if (BookMarkList?.length !== 0) {
      setFilteredData(BookMarkList)
    } else {
      setFilteredData([])
    }
  }, [BookMarkList])

  const Paginate = (event, value) => {
    setCurrentPage(value - 1)
  }

  const indexOfFirstPost = (currentPage * postPerPage) % filteredData?.length;
  const indexOfLastPost = indexOfFirstPost + postPerPage
  const currentData = filteredData?.slice(indexOfFirstPost, indexOfLastPost)

  let userID = state?.userId;
  // let userID = viewBloggerProfile?.id;
  useEffect(() => {
    if (userID) {
      // dispatch({ type: blogActions.GET_VIEW_BLOGGER_PROFILE, payload: userID });
      dispatch({ type: blogActions.GET_BLOGGER_FOLLOW_CHECK_BY_ID, payload: userID });
    };
  }, [userID]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onSubmit = (data) => {
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    return false;
  }

  const handleAddStory = (Id) => {
    dispatch({ type: blogActions.GET_ADD_BLOG_STORY, payload: Id })
    handleModalClose()
  }
  const handleDeleteStory = (BlogId) => {
    dispatch({ type: blogActions.DELETE_BLOG_STORY_LIST, payload: BlogId })
  }

  var [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleBlogDelete = (Id) => {
    dispatch({ type: blogActions.DELETE_BLOGS, payload: Id })
    // setDropdownVisible(false);
  }

  const handleBlogEdit = (val) => {
    // navigate('/create-blog', { state: { UserProfileData: val, type: 'UserProfileData' } })
  }

  const handleBloggerFollow = (e) => {
    e.preventDefault();
    dispatch({ type: blogActions.UPDATE_BLOGGERS_FOLLOW, payload: userID });
  };
  // console.log("UserWiseBlog",UserWiseBlog)

  return (
    <Box className='create_blog_section'>
      <Box className='blog_user_profile_section'>
        <Box className='blog_user_profile'>
          <Container>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={3}>
                  <Box className='blog_user_profile_card'>
                    <Box className='blog_user_profile_card_img'>
                      <img src={avatar} alt='img' />
                    </Box>
                    <Box className='blog_user_profile_content'>
                      <Typography variant='h5'>{viewBloggerProfile?.name}</Typography>
                      {/* <Typography variant='h5'>mark antony</Typography> */}
                      <Typography className='blogs_count' variant='span'>{UserWiseBlog?.length} Blogs</Typography>
                      <Typography variant='h6'>{followBloggerList?.followers?.length} Follower</Typography>
                      <FormControlLabel control={<Switch defaultChecked />} label="Public" />
                      {bloggersFollow &&
                        <button type="submit" className="btn btn-primary pro-follow-btn" onClick={handleBloggerFollow}>{bloggersFollow?.follow_check === 'Follow' ? 'Unfollow' : 'Follow'}</button>
                      }
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={9} md={9} xs={12}>
                  <Box className='blog_user_personal_details'>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'Boxider' }}>
                        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{
                          style: {
                            backgroundColor: "#172651"
                          }
                        }} aria-label="basic tabs example" className='pro-tabss'>
                          <Tab label="Personal data" {...a11yProps(0)} />
                          <Tab label="Bookmarks" {...a11yProps(1)} />
                          <Tab label="settings" {...a11yProps(2)} />
                          <Tab label="password" {...a11yProps(3)} />
                          <Tab label="Other's" {...a11yProps(4)} />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0} className='blog_personalDetails'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item lg={4} xs={12}>
                                <Box className="form-group">
                                  <label>Name</label>
                                  <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.name?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <Box className="form-group">
                                  <label>Email</label>
                                  <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.email?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <Box className="form-group">
                                  <label>Mobile</label>
                                  <input name="mobile" type="number" {...register('mobile')} className={`form-control ${errors.mobile ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.mobile?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <Box className="form-group">
                                  <label>Date of Birth</label>
                                  <input name="dob" type="date" {...register('dob')} className={`form-control ${errors.dob ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.dob?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <Box className="form-group">
                                  <label>Facebook</label>
                                  <input name="facebook" type="text" {...register('facebook')} className={`form-control ${errors.facebook ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.facebook?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <Box className="form-group">
                                  <label>Twitter</label>
                                  <input name="twitter" type="text" {...register('twitter')} className={`form-control ${errors.twitter ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.twitter?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box className="form-group">
                                  <button type="submit" className="btn btn-primary mr-1">Update profile</button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>

                        </form>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        {(filteredData?.length > 0) ?
                          <Grid container spacing={2}>
                            {currentData?.map((val, index) => {
                              return (
                                <Grid key={index} item lg={6} md={6} sm={12}>
                                  <Box className='bookMark__full_list'>
                                    <Box className='book_mar__img_tit'>
                                      <img src={`${API_IMG_URL}/server/blog/${val?.blog_image[0]}`} alt='blog_img' />
                                      <Typography
                                        onClick={() => navigate('/blog-detail', { state: { categoryId: val?.category_id, blogId: val?.id, type: 'UserStoryDet' } })}
                                        variant='h5'>{val?.heading}</Typography>
                                    </Box>
                                    <div className='more_blog_det_icon'>
                                      <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <MoreVertIcon />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <div
                                            id="myDropdown"
                                            className="three__dot_btn" >
                                            <div className='button__edit_det'>
                                              <button
                                                onClick={() => navigate('/blog-detail', { state: { categoryId: val?.category_id, blogId: val?.id, type: 'UserStoryDet' } })}
                                                className="Blog__edit__btn">View</button>
                                              <button
                                                onClick={() => dispatch({ type: blogActions.UPDATE_BLOG_BOOKMARK, payload: { blogId: val.id, categoryId: val?.category_id, countryId: val?.country_id } })}
                                                className="Blog__det__btn">Delete</button>
                                            </div>
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </Box>
                                </Grid>
                              );
                            })}
                            {(filteredData?.length > postPerPage) &&
                            <Grid item lg={12}>
                              <Stack spacing={2} className='blog_list_pagination'>
                                <Pagination count={Math.ceil(filteredData?.length / postPerPage)} page={currentPage + 1} variant="outlined" shape="rounded" onChange={Paginate} />
                              </Stack>
                            </Grid>
                             } 

                          </Grid>
                          : <p className='text-center p-5 text-primary'>No Bookmarks Found!!</p>}
                        {/* <Box className='View__More__btn'>
                          <Button>
                          View More
                          </Button>
                        </Box> */}
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        Item Three
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        Item Two
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        Item Three
                      </TabPanel>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box className='blog_yourStory_followers'>
          <Container>
            <Grid container spacing={2}>
              <Grid item lg={8} md={8} xs={12}>
                <Box className='recent_blog_head'>
                  <Typography variant='h3'>Your Story</Typography>
                </Box>
                <Box className='blog_yourStory'>
                  <Grid container spacing={2}>
                    {BlogStoryList.map((val, index) =>
                      <Grid key={index} item lg={3} md={4} sm={6} xs={6}>
                        {/* {console.log('UserWiseBlog',UserWiseBlog)} */}
                        <Box className='blog_yourStory_card'>
                          <div onClick={() => navigate('/blog-detail', { state: { categoryId: val?.category_id, blogId: val?.id, type: 'UserStoryDet' } })} >
                            <img src={`${API_IMG_URL}/server/blog/${val?.blog_image[0]}`} alt='img' />
                            {val.category_name.map((item, index) => {
                              return (
                                <Typography key={index} variant='h5'>{item?.name}</Typography>
                              );
                            })}
                          </div>
                          <div onClick={() => handleDeleteStory(val?.id)} className='story__det_icon'>
                            <DeleteIcon />
                          </div>
                        </Box>
                      </Grid>
                    )}
                    <Grid item lg={3} md={4} sm={6} xs={6}>
                      <Box onClick={() => handleModalShow()} className='blog_yourStory_card add_more'>
                        <img src={AddImage} alt='img' />
                        <Typography variant='h5'>Add</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <Box className='blog_followers'>
                  <Box className='recent_blog_head'>
                    <Typography variant='h3'>follower's</Typography>
                    <Link to='' className='viewAll_ul'>view all</Link>
                  </Box>
                  <Box className='blog_followers_lists'>
                    {followBloggerList?.followers?.map((val, index) => {
                      return (
                        <Box key={index} className='blog_followers_Datalist'>
                          <Box className='blog_followers_img_content'>
                            <Box className='blog_followers_img'>
                              <img src={`${API_IMG_URL}/server/user/${val?.profile_image}`} alt='img' />
                            </Box>
                            <Box className='blog_followers_name'>
                              <Typography variant='h6'>{val?.name}</Typography>
                              <Typography variant='span'>{val?.email}</Typography>
                            </Box>
                          </Box>
                          <Box className='blog_followers_detailsMore'>
                            <MoreVertIcon />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className='User__wise__blogs__gridd'>
          <Container>
            <Row>
              <Box className='recent_blog_head'>
                <Typography variant='h3'>Our Blog's</Typography>
              </Box>
              {UserWiseBlog.map((val, index) => {
                return (
                  <Col lg='3' md='3' sm='6' xs='12' key={index}>
                    <div>
                      {/* {console.log('val', val?.category_name)} */}
                      <div className='modal__body__cont'>
                        <div style={{ position: 'relative' }}>
                          <div className='Blog__images our_blog_img' onClick={() => navigate('/blog-detail', { state: { categoryId: val?.category_id, blogId: val?.id, type: 'UserStoryDet' } })} >
                            <img src={`${API_IMG_URL}/server/blog/${val?.blog_image[0]}`} />
                            {/* {val.category_name.map((e) => {
                              return (
                                <div>
                                  {console.log('e',e)}
                                </div>
                              );
                            })} */}
                            <h4 className='story_category___name'>{val.category_name[0]?.name}</h4>
                          </div>
                          <div className='more_blog_det_icon user__blog'>
                            <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <MoreVertIcon />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <div
                                  id="myDropdown"
                                // className="dropdown-content"
                                >
                                  <div className='button__edit_det'>
                                    <button onClick={() => navigate('/update-blog-content',{state : {id:val?.id, heading : val?.heading, description : val?.description, blogImage : val?.blog_image ,category_id : val?.category_id , place: val?.place ,countryId : val?.country_id , tags : val?.tags }})} className="Blog__edit__btn">Edit</button>
                                    <button onClick={() => handleBlogDelete(val?.id)} className="Blog__det__btn">Delete</button>
                                  </div>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className='story__blogs___title our_blog_tit'>
                          <h5>{val?.place}</h5>
                          <h3 onClick={() => navigate('/blog-detail', { state: { categoryId: val?.category_id, blogId: val?.id, type: 'UserStoryDet' } })}>{val?.heading}</h3>
                        </div>
                        <div className='story_blog_cont our_blog_cont' dangerouslySetInnerHTML={{
                          __html: val?.description,
                        }} ></div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Box>
      </Box>
      <Box>
        <Modal
          dialogClassName='Story___Modal'
          show={addStoryBlog}
          onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select Your Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {UserWiseBlog.map((val, index) => {
                return (
                  <Col lg='4' md='4' sm='6' key={index}>
                    {/* {console.log('val', val)} */}
                    <div onClick={() => handleAddStory(val?.id)}>
                      <div className='modal__body__cont'>
                        <div className='Blog__images'>
                          <img src={`${API_IMG_URL}/server/blog/${val?.blog_image}`} alt='blog' />
                          <h4 className='story_category___name'>travel</h4>
                        </div>
                        <div className='story__blogs___title'>
                          <h5>{val?.place}</h5>
                          <h3>{val?.heading}</h3>
                        </div>
                        <div className='story_blog_cont' dangerouslySetInnerHTML={{
                          __html: val?.description,
                        }} ></div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Modal.Body>
        </Modal>
      </Box>
    </Box>
  )
}
