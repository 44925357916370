import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'

const HomeSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_HOME_COUNT, getHomeCount),
        yield takeEvery(actions.GET_HOME_TESTIMONIAL, getHomeTestimonial)
    ]);
};

const getHomeCount = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/counthome`)
        );
        if (result.data.status) {
            yield put({ type: actions.SET_HOME_COUNT, payload: result.data.counthome });
        } else {
            yield ({ type: actions.SET_HOME_COUNT, payload: null })
        }
    } catch (err) {
        yield ({ type: actions.SET_HOME_COUNT, payload: null })
    }
}

const getHomeTestimonial = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/testimonialhome`)
        );
        if (result.data.status) {
            yield put({ type: actions.SET_HOME_TESTIMONIAL, payload: result.data.testimonialhome });
        } else {
            yield ({ type: actions.SET_HOME_TESTIMONIAL, payload: [] })
        }
    } catch (err) {
        yield ({ type: actions.SET_HOME_TESTIMONIAL, payload: [] })
    }
}

export default HomeSaga;