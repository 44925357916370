import { Box } from '@material-ui/core'
import React, { useState } from 'react'
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import blogBannerRecentNews1 from '../../asset/hotel/1.jpg'
// import demo1 from '../../asset/blog/demo_1.jpg'
// import demo2 from '../../asset/blog/demo_2.jpg'
// import demo3 from '../../asset/blog/demo_3.jpg'
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
// import avatarImg from '../../asset/images/avatar.jpg'
import { AvatarGroup } from '@material-ui/lab';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import LikeButton from '../common/likeBtn';
import { useDispatch, useSelector } from 'react-redux'
import blogActions from '../../redux/blog/actions';
import { API_IMG_URL } from '../../utils/constant';
import Slider from "react-slick";
import commonActions from '../../redux/common/actions';
// import travellerActions from '../../redux/traveller/actions';

export const BlogRecentNewsBanner = () => {
  // const slider = React.useRef(null);
  const dispatch = useDispatch()
  const { recentBlog } = useSelector((state) => state.blogReducer)
  const [addLike, setAddLike] = useState(false);

  var recentBlogSlider = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: true,
    autoplay: true,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    )
  };
  const updateBlogLike = (Id) => {
    if (localStorage.getItem('tickatrip-token')) {
      var temp = recentBlog.find((e) => e.id === Id)
      dispatch({ type: blogActions.UPDATE_BLOG_LIKE, payload: { blogId: temp?.id } })
      // dispatch({ type: travellerActions.GET_PROFILE_DATA });
      setAddLike(!addLike)
    } else {
      dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
    }
  }
  return (
    <div>
      <Box className='blog_recentNews_banner'>
        <Slider {...recentBlogSlider} >
          {recentBlog[0]?.blog_image?.map((val, index) => {
            return (
              <>
                <div className='sliderClasses'>
                  <Box key={index} className="item blogRecentNews">
                    <Box className='blogBannerRecentNews_tag'>
                      <LocalOfferIcon />
                    </Box>
                    <div className='blog__banner_shadow'></div>
                    <img className='blogRecentNews_img' src={`${API_IMG_URL}/server/blog/${val}`} alt='' />
                  </Box>
                </div>
              </>
            );
          })}
        </Slider>
        {/* {recentBlog?.map((val, index) => {
          return ( */}
        <>
          <Box className='recent_bennr_head'>
            <Box className='RecentNewsInner_content'>
              <Typography className='recent_bennr_head_txt'>{recentBlog[0]?.heading}</Typography>
              {/* <div className='overlay'></div> */}
              <Box className='reacent__first__grid'>
                <Box className='our__user__img__name'>
                  <Stack direction="row" spacing={2}>
                    <Avatar className='blogeSingle_img' alt="Remy Sharp" src={`${API_IMG_URL}/server/user/${recentBlog[0]?.profile_image}`} />
                    <Box className='recent_user__name_date'>
                      <Typography variant='h6'>{recentBlog[0]?.name}</Typography>
                      <Typography variant='span' className='date_txt'>{recentBlog[0]?.post_date}</Typography>
                    </Box>
                  </Stack>
                </Box>
                {recentBlog[0]?.liked_user_images?.length === 0 ?
                  <></>
                  :
                  <Box className='liked__img__count'>
                    <Box className='liked__by__count'>
                      <Box direction="row" className='recent_banne_name'>
                        <Typography variant='span'>Liked by</Typography>
                        <Typography variant='h5'>{recentBlog[0]?.like_count} {recentBlog[0]?.liked_user_images?.length >= 2 ? "peoples" : "people"}</Typography>
                      </Box>
                    </Box>
                    <Box className='liked__by__img'>
                      <AvatarGroup >
                        {recentBlog[0]?.liked_user_images.map((ind, item) => {
                          return (
                            <Avatar className='like__user__img' key={ind} alt="Remy Sharp" src={item} />
                          );
                        })}
                      </AvatarGroup>
                    </Box>
                  </Box>
                }
                <Box className='fav__click__icon'>
                  <FavoriteIcon
                    onClick={() => updateBlogLike(recentBlog[0]?.id)}
                    className={recentBlog[0]?.auth_liked_blog === true ? 'red' : 'deactivate'}
                  />
                  <ShareIcon className='recent__grid__share' />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <div className='save_Iconss'> <LikeButton /></div> */}
        </>
        {/* );
        })} */}
        {/* <div className="productGrid zoom_slider_prev_and_next">
          <span className="left_arrow_icons" onClick={() => slider?.current?.slickPrev()}>
            <MdKeyboardArrowLeft />  prev
          </span>
          <span className="right_arrow_icons" onClick={() => slider?.current?.slickNext()}>
             <MdKeyboardArrowRight />  next
          </span>
        </div> */}
      </Box>
    </div>
  )
}
