import React from 'react'
import Header from './header'
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Typography } from '@material-ui/core';
import Footer from './footer';
import { useEffect } from 'react';

export const Career = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div>
            <Header />
            <section class="footer_contact_form_sec">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-lg-8 col-sm-12 col-12">
                            <Box className='payment_form_head'>
                                <Typography variant='h6'>Tick A Trip Careers</Typography>
                            </Box>
                            <div class="left_foo_con_sec" data-aos="fade-up" data-aos-duration="1500">
                                <h4>Personal Details</h4>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="con_foo_input_wrapper">
                                            <label>Name<sub>*</sub></label>
                                            <input type="text" class="" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="con_foo_input_wrapper">
                                            <label>Email Address<sub>*</sub></label>
                                            <input type="text" class="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="con_foo_input_wrapper">
                                            <label>Phone Number<sub>*</sub></label>
                                            <input type="text" class="" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="con_foo_input_wrapper">
                                            <label>Skype ID</label>
                                            <input type="text" class="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="con_foo_input_wrapper">
                                            <label>What is your interest?<sub>*</sub></label>
                                            <select>
                                                <option value="Select Option">Select Option</option>
                                                <option value="Website Development">Website Development</option>
                                                <option value="E-commerce Development">E-commerce Development</option>
                                                <option value="Branding">Branding</option>
                                                <option value="App Development">App Development</option>
                                                <option value="Domains &amp; Hosting">Domains &amp; Hosting</option>
                                                <option value="Just having a Coffee">Just having a Coffee</option>
                                                <option value="Working at TenTwenty">Working at TenTwenty</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="con_foo_input_wrapper">
                                            <label>Where did you here about us?<sub>*</sub> </label>
                                            <select>
                                                <option value="Select Option">Select Option</option>
                                                <option value="Google">Google</option>
                                                <option value="Clutch">Clutch</option>
                                                <option value="People Per Hour">People Per Hour</option>
                                                <option value="Good Firms">Good Firms</option>
                                                <option value="Personal Reference">Personal Reference</option>
                                                <option value="Social Media">Social Media</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="con_foo_input_wrapper">
                                            <label>Your Message</label>
                                            <textarea></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="con_foo_input_wrapper">
                                            <label>Attach files</label>
                                            <input class="file_type" type="file" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <Button type="submit" className="search_btn_banner" variant="outlined" startIcon={<SendIcon />}>Search</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-12 col-12 career_offered">
                            <Box className='payment_form_head'>
                                <Typography variant='h6'>We Are Hiring For</Typography>
                            </Box>
                            <div class="right_persion_con_sec">
                                <div class="single_persion_con" data-aos="fade-up" data-aos-duration="500">
                                    <h4 class="theme_color">Digital Marketing Expert</h4>
                                    <p>Marketing</p>
                                    <ul>
                                        <li>2-3 years Work experience</li>
                                        <li>Knowledge of design software</li>
                                    </ul>
                                </div>
                                <div class="single_persion_con" data-aos="fade-up" data-aos-duration="1000">
                                    <h4 class="theme_color">Business Analyst</h4>
                                    <p>Marketing</p>
                                    <ul>
                                        <li>2-3 years Work experience</li>
                                        <li>Knowledge of design software</li>
                                    </ul>
                                </div>
                                <div class="single_persion_con" data-aos="fade-up" data-aos-duration="1000">
                                    <h4 class="theme_color">UI/UX Developer</h4>
                                    <p>Developer</p>
                                    <ul>
                                        <li>2-3 years Work experience</li>
                                        <li>Knowledge of design software</li>
                                    </ul>
                                </div>
                                <div class="single_persion_con" data-aos="fade-up" data-aos-duration="1500">
                                    <h4 class="theme_color">Fullstack Developer</h4>
                                    <p>Developer</p>
                                    <ul>
                                        <li>2-3 years Work experience</li>
                                        <li>Knowledge of design software</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
