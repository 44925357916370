
import flightDown from '../asset/icons/flight-down.svg';
import { components } from 'react-select';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img width="30" src={flightDown} />
        </components.DropdownIndicator>
    );
};

export default DropdownIndicator;