import { Box, Container } from '@material-ui/core'
import React from 'react'
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import FlightUpcomingTrip from './FlightUpcomingTrip';
import FlightCancelledTrip from './FlightCancelledTrip';
import FlightCompletedTrip from './FlightCompletedTrip';
import { useSelector } from 'react-redux';
import loaderGifImg from "../../asset/blueflightloader.gif";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    <div>{children}</div>
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export const FlightBookingData = () => {

    const { loading } = useSelector((state) => state.commonReducer);

    return (
        <Box className='create_blog_section '>
            {loading ?
                <div>
                    <div className='blur_bg'></div>
                    <div className='loaderGif'>
                        <img src={loaderGifImg} alt='' />
                    </div>
                </div>

                
                :
                null
            }
            <Box className='blog_user_profile_section user_profile_page'>
                <Box className='blog_user_profile user_profile'>
                    <Container>
                        <Box >
                            <Grid className='row'>
                                <Grid className='col-md-12 col-lg-12 col-sm-12 col-12 booking_head'>
                                    <h3>Flight Bookings</h3>
                                </Grid>
                                <Grid className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <Box className='blog_user_personal_details  user_trip_sec'>
                                        <Tabs
                                            defaultActiveKey="upcoming"
                                            id="justify-tab-example"
                                            className="user_booking_details"
                                            justify

                                        >
                                            <Tab eventKey="upcoming" title="Upcoming Trip">
                                                <FlightUpcomingTrip />
                                            </Tab>
                                            <Tab eventKey="cancel" title="Cancelled Trip">
                                                <FlightCancelledTrip />
                                            </Tab>
                                            <Tab eventKey="completed" title="Completed Trip">
                                                <FlightCompletedTrip />
                                            </Tab>
                                        </Tabs>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Box >
        </Box >
    )
}
