import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const RestrictedRoute = () => {
    return (
        <div>
            {localStorage.getItem('tickatrip-token') ? <Navigate to='/' /> : <Outlet />}
        </div>
    )
}
