import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import loginbackground from '../../asset/login/background.png'
import { useState, useEffect } from "react";
import OTPInput from "otp-input-react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import validUserOtp from '../../redux/user/actions'
import ToastAlert from '../common/ToastAlert';
import UserSlider from './UserSlider';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

const useStyles = makeStyles((theme) =>
({
  loginSlide: {
    background: `url(${loginbackground})`,
    height: '100vh',
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      height: '100%',
      width: "100%",
      display: 'relative',
      paddingBottom: '2rem'
    },
  },
  brandlogo: {
    minHeight: ' 80px',
    maxHeight: ' 80px',
    margin: ' 3rem 0',
  },
  bannertxt: {
    fontSize: ' 16px',
    color: ' #fff',
    fontWeight: ' 300',
    lineHeight: ' 30px',
    width: ' 90%',
    margin: ' auto',
  },
  welcome_grid: {
    display: 'flex',
    justifyContent: ' space-around',
    alignItems: 'center',
    height: ' 100vh',
    textAlign: 'center',
  },

  welcome_heading: {
    fontSize: '32px',
    fontWeight: ' 500',
  },
  welcome_icon: {
    width: ' 35px',
    margin: '0 0.5rem',
    padding: ' 8px',
    background: ' #eff2f9',
    boxShadow: ' 0px 0px 5px 1px #32323254',
    marginTop: ' 1.2rem',
    borderRadius: ' 5px',
  },
  welcom_social: {
    textAlign: 'center',
  },
})
)
const UserRegisterOtp = () => {
  const { validUserMailMessage } = useSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();
  const { userValidOtpId } = useSelector(
    (state) => state.userReducer
  );
  const classes = useStyles();
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch()
  const history = useNavigate();

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    dispatch({
      type: validUserOtp.GET_VERIFY_OTP, userOtp: {
        otp: otp,
        email: userValidOtpId,
      },
      history: history
    });
  }

  useEffect(() => {
    if (!validUserMailMessage) {
      navigate('/login')
    }
  }, []);

  return (
    <div>
      <ToastAlert />
      <Box >
        <Grid className='row'>
          <Grid className='col-lg-5 col-md-5 col-sm-12 col-12 user_slider'>
            <UserSlider />
          </Grid>
          <Grid className="col-lg-7 col-md-7 col-sm-12 col-12 log_in">
            <Link to='/' className='back_home dn-m'><ReplyAllIcon />Back to home</Link>
            <div className={classes.welcome_grid}>
              <div className={classes.welcome_heade}>
                <Typography className={classes.welcome_heading} variant='h1'>Enter Verification code</Typography>
                <p className='otp_num'>{validUserMailMessage}</p>
                <div className='login_form'>
                  <form className="form user_otp" onSubmit={handleSubmitOtp}>
                    <Box
                      noValidate
                      autoComplete="off"
                    >
                      <OTPInput
                        error
                        value={otp}
                        onChange={setOtp}
                        autoFocus
                        require
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                      />
                    </Box>
                    <div className='form_btns'>
                      <p>Send the code again</p>
                      <button className='login_btn' type='submit' >VERIFY</button>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default UserRegisterOtp