import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import loginbackground from '../../asset/login/background.png'
import Logo from '../../asset/white-logo.svg'
import OwlCarousel from 'react-owl-carousel';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastAlert from '../common/ToastAlert';
import { Link } from 'react-router-dom';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import userActions from '../../redux/user/actions';

const useStyles = makeStyles((theme) =>
({
  loginSlide: {
    height: '100vh',
    width: "40%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      height: '100%',
      width: "40%",
      display: 'fixed',
      paddingBottom: '2rem'
    },
  },

  brandlogo: {
    minHeight: ' 80px',
    maxHeight: ' 80px',
    margin: ' 3rem 0',
  },
  bannertxt: {
    fontSize: ' 16px',
    color: ' #fff',
    fontWeight: ' 300',
    lineHeight: ' 30px',
    width: ' 90%',
    margin: ' auto',
  },
  welcome_grid: {
    display: 'flex',
    justifyContent: ' space-around',
    alignItems: 'center',
    height: ' 100vh',
  },
  welcome_heading: {
    fontSize: '32px',
    fontWeight: ' 500',
  },
  welcome_icon: {
    width: ' 35px',
    margin: '0 0.5rem',
    padding: ' 8px',
    background: ' #eff2f9',
    boxShadow: ' 0px 0px 5px 1px #32323254',
    marginTop: ' 1.2rem',
    borderRadius: ' 5px',
  },
  welcom_social: {
    textAlign: 'center',
  },
})
);

const UserSlider = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { userPageSlider } = useSelector((state) => state.userReducer)

  useEffect(() => {
    dispatch({ type: userActions.GET_USER_PAGE_SLIDER })
  }, []);

  return (
    <div>
      <ToastAlert />
      <div className='user_login_slider' style={{ background: `url(${loginbackground})` }}>
        <Link to='/' className='back_home'><ReplyAllIcon />Back to home</Link>
        <Container>
          <div className={classes.logininner}>
            <img className={classes.brandlogo} src={Logo} alt='img'></img>
            <div className={classes.loginSlider}>
              {userPageSlider.length > 0 &&
                <OwlCarousel
                  items={1}
                  margin={8}
                  autoplay={true}
                  className="owl-theme loginSlide"
                  dots={true}
                >
                  {userPageSlider.map((val, index) => {
                    return (
                      <div className='item' key={index}><p className={classes.bannertxt}>{val.description}</p></div>
                    )
                  })}
                </OwlCarousel>
              }
            </div>
          </div>
        </Container>

      </div>
    </div>
  )
};

export default React.memo(UserSlider);