import { takeEvery, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import commonActions from '../common/actions';
import { API_URL } from '../../utils/constant'


const commonSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_POPULAR_PLACES, getPopularPlaces),
        yield takeEvery(actions.GET_POPULAR_PLACES_DETAILS, getPopularPlacesDetail),
        yield takeEvery(actions.GET_SUBSCRIBE_FORM, getSubscribtionMail),
        yield takeEvery(actions.GET_HOME_BANNER, getHomeBanner)
    ]);
};

const getPopularPlaces = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/popularplace`)
        );
        if (result.data.status) {
            yield put({ type: actions.SET_POPULAR_PLACES, payload: result.data.PopularPlaceList });
        } else {
            yield put({ type: actions.SET_POPULAR_PLACES, payload: [] });
        }
    } catch (err) {
        yield put({ type: actions.SET_POPULAR_PLACES, payload: [] });
    }
};

const getPopularPlacesDetail = function* (data) {
    const { payload } = data
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/popularplace/detail/${payload.placeId}`)
        );
        if (result.data.status) {
            yield put({ type: actions.SET_POPULAR_PLACES_DETAILS, payload: result.data });
            data.history('/popular-place-detail')
            yield put({ type: commonActions.SET_LOADER, payload: false });
        } else {
            yield put({ type: actions.SET_POPULAR_PLACES_DETAILS, payload: [] });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
    } catch (err) {
        yield put({ type: actions.SET_POPULAR_PLACES_DETAILS, payload: [] });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
};

const getSubscribtionMail = function* (data) {
    const { payload } = data
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/subscribe`)
        );
        if (result.data.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Mail send successfully", status: "" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Mail not send", status: "" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Mail not sending failed", status: "" } });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
};

const getHomeBanner = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() => axios.get(`${API_URL}/getHomePageImages`));
        if (result.data.status) {
            yield put({ type: commonActions.SET_HOME_BANNER, payload: result.data.homePageImages });
        } else {
            yield put({ type: commonActions.SET_HOME_BANNER, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_HOME_BANNER, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
}
export default commonSaga;