import { takeEvery, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import { store } from '../store';

const HotelSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_HOTEL_SEARCH, getSearchField),
        yield takeEvery(actions.GET_HOTEL_IMAGES, getHotelImages),
        yield takeEvery(actions.GET_HOTEL_DETAILS, getHotelDetails),
        yield takeEvery(actions.GET_HOTEL_FILTER, getHotelFilter),
        yield takeEvery(actions.GET_HOTEL_BOOKING, getHotelBooking),
        yield takeEvery(actions.GET_HOTEL_RATE, getHotelRate),
        yield takeEvery(actions.GET_HOTEL_STATIC_CONTENT, getHotelStaticContent),
        yield takeEvery(actions.GET_NEAREST_HOTELS, getNearestHotels),
        yield takeEvery(actions.GET_HOTEL_REVIEWS, getHotelReviews),
        yield takeEvery(actions.UPDATE_HOTEL_REVIEW, updateHotelReview),
        yield takeEvery(actions.DELETE_HOTEL_REVIEW, deleteHotelReview),
        yield takeEvery(actions.CREATE_HOTEL_REVIEW, createHotelReview),
        yield takeEvery(actions.LOAD_MORE_HOTELS, loadMoreHotels),
        yield takeEvery(actions.GET_COUPONS, getCoupons),
        yield takeEvery(actions.GET_HOTEL_CHECKOUT, HotelCheckout),
    ]);
};

// const HotelCheckout = function* (data) {
//     console.log('checkout data', data)
//     try {
//         const result = yield call(() =>
//             axios.get(
//                 `${API_URL}/checkout`, {
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//             }
//             )
//         );
//         console.log('checkout result', result.data)
//         if (result.data.status) {
//             console.log('checkout if....')
//         } else {
//             console.log('checkout else....')
//         }

//     } catch (err) {
//         console.log('checkout catch....')
//     }
// }

const HotelCheckout = function* (data) {
    console.log('data',data)
    try {
            const result = yield call(() =>
                axios.post(
                    `${API_URL}/checkout`,
                    data.payload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
                )
            );
            console.log('checkout result....',result.data)
            if (result.data.status) {
                console.log('checkout if....')

                window.location.href=`https://tickatrip.travel/server/checkoutAgain/${result.data.checkout_id}`;

                console.log('inside....',window.location.search.substring(1))

            } else {
                yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "Try Again" } });
        }
        // console.log('outside....',window.location.search.substring(1))
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true,status: "Try Again" } });
        console.log('catch outside....')
    }
};

// const HotelCheckout = function* (data) {
//     const { payload } = data;
//     console.log('payload',payload)
//     var form_data = new FormData();
//     for (var key in payload) {
//         form_data.append(key, payload[key]);
//     }
//     try {
//         const result = yield call(() =>
//             axios.post(
//                 `${API_URL}/checkout`, form_data,
//                 {
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                 }
//             )
//         );
//         console.log('v',result.data)
//         if (result.data.status) {
//             yield put({ type: commonActions.SET_LOADER, payload: false });
//             console.log('result data if....',result.data.status)
//             window.location.href=`https://tickatrip.travel/server/checkoutAgain/${result.data.checkout_id}`;
//             console.log('checkout if....')
//         } else {
//             yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors, status: "failed" } });
//         }
//     }
//     catch (err) {
//         console.log('checkout catch....')
//         yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.response.message, status: "failed" } });
//     }
// };

const loadMoreHotels = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hotelSearch/pagination`, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            )
        );

        if (result.data.status && result.data.message.hotelList > 0) {
            yield put({ type: actions.SET_HOTEL_SEARCH, payload: [...result.data.message.hotelList, ...data.payload.hotelList] });
            if (result.data.message?.nextToken) {
                yield put({ type: actions.SET_NEXT_TOKEN, payload: result.data.message.nextToken });
            } else {
                yield put({ type: actions.SET_NEXT_TOKEN, payload: null });
            }
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error Occured, kindly check the input details', status: "failed" } });
    }
}

const getCoupons = function* () {
    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hotel-coupons`, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            )
        );
        if (result?.data?.status) {
            yield put({ type: actions.SET_COUPONS, payload: result?.data?.message });
        } else {
            yield put({ type: actions.SET_COUPONS, payload: [] });
        }
    } catch (err) {
        yield put({ type: actions.SET_COUPONS, payload: [] });
    }
};

const updateHotelReview = function* (data) {
    yield put({ type: actions.SET_REVIEW_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/reviews/${data.id}`,
                JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );
        if (result.data.status) {
            yield put({ type: actions.GET_HOTEL_REVIEWS, payload: { type: 'Hotel', hotelId: data.payload.hotelId }, initial: true })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        }
        yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while updating review', status: "failed" } });
        yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
    }
};

const deleteHotelReview = function* (data) {
    yield put({ type: actions.SET_REVIEW_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/reviews/${data.payload.id}`
            )
        );
        yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
        if (result.data.status) {
            yield put({ type: actions.GET_HOTEL_REVIEWS, payload: { type: 'Hotel', hotelId: data.payload.hotelId }, initial: true })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while deleting review', status: "failed" } });
        yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
    }
};

const createHotelReview = function* (data) {
    yield put({ type: actions.SET_REVIEW_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/reviews`,
                JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );
        yield put({ type: actions.GET_HOTEL_REVIEWS, payload: { type: 'Hotel', hotelId: data.payload.hotelId }, initial: true })
        yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });

    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while creating review', status: "failed" } });
        yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
    }
};

const getHotelImages = function* (data) {
    yield put({ type: actions.HOTEL_IMAGE_LOADER, payload: true });
    const { payload } = data;
    var form_data = new FormData();
    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/hotelDetails`, form_data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
        );
        if (result.data.status) {
            yield put({ type: actions.SET_HOTEL_IMAGES, payload: result.data.message?.hotelImages });
            yield put({ type: actions.HOTEL_IMAGE_LOADER, payload: false });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.error.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
            yield put({ type: actions.HOTEL_IMAGE_LOADER, payload: false });
            yield put({ type: actions.SET_HOTEL_IMAGES, payload: [] });
        }
    }
    catch (err) {
        yield put({ type: actions.HOTEL_IMAGE_LOADER, payload: false });
        yield put({ type: actions.SET_HOTEL_IMAGES, payload: [] });
    }
};

const getHotelReviews = function* (data) {
    yield put({ type: actions.SET_REVIEW_LOADER, payload: true });
    try {
        if (data.initial) {
            const result = yield call(() =>
                axios.post(
                    `${API_URL}/hotelReviews`,
                    JSON.stringify(data.payload), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
                )
            );
            if (result.data.status) {
                yield put({ type: actions.SET_HOTEL_REVIEWS, payload: result.data.message.data, page: result.data.message.next_page_url });
            }
            yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
        } else {
            const result = yield call(() =>
                axios.post(
                    `${store.getState().hotelReducer.nextPageUrl}`,
                    JSON.stringify(data.payload), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
                )
            );
            if (result.data.status) {
                yield put({ type: actions.SET_HOTEL_REVIEWS, payload: [...store.getState().hotelReducer.reviews, ...result.data.message.data], page: result.data.message.next_page_url });
            }
            yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
        }
    } catch (err) {
        yield put({ type: actions.SET_HOTEL_REVIEWS, payload: [], page: null });
        yield put({ type: actions.SET_REVIEW_LOADER, payload: false });
    }
};

const getSearchField = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    yield put({ type: commonActions.SET_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/hotelSearch`, payload,
            )
        );
      
        if (result.data.status) {
            if (payload.hotel_name) {
                let selectedHotel = result.data.message.hotelList.find(el => el.hotelName.toUpperCase() === payload.hotel_name.toUpperCase());
                if (!selectedHotel) {
                    yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Requested hotel is unavailable, try with different date or other hotels.', status: "failed" } });
                    yield put({ type: actions.SET_HOTEL_SEARCH, payload: [] });
                    yield put({ type: actions.SET_HOTEL_SEARCH_VALUE, payload: null });
                } else {
                    let tempList = result.data.message.hotelList.filter(el => el.hotelName.toUpperCase() !== payload.hotel_name.toUpperCase());
                    tempList.unshift(selectedHotel);
                    yield put({ type: actions.SET_HOTEL_SEARCH, payload: tempList });
                    yield put({ type: actions.SET_HOTEL_SEARCH_VALUE, payload: payload });
                    yield put({ type: actions.SET_HOTEL_SESSIONID, payload: result.data.message.sessionId });
                    yield put({ type: actions.SET_NEXT_TOKEN, payload: result.data.message.nextToken });
                    yield put({ type: actions.SET_HOTEL_SESSION_DATE, payload: new Date() });
                    yield put({ type: actions.SET_CURRENT_PAGE, payload: 1 });
                    data.from('/hotel-list');
                }
            } else {
                yield put({ type: actions.SET_HOTEL_SEARCH_VALUE, payload: payload });
                yield put({ type: actions.SET_HOTEL_SEARCH, payload: result.data.message.hotelList });
                yield put({ type: actions.SET_HOTEL_SESSIONID, payload: result.data.message.sessionId });
                yield put({ type: actions.SET_HOTEL_SESSION_DATE, payload: new Date() });
                yield put({ type: actions.SET_CURRENT_PAGE, payload: 1 });
                data.from('/hotel-list');
            }
        } else {
            yield put({ type: actions.SET_HOTEL_SEARCH, payload: [] });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error Occured, kindly check the input details', status: "failed" } });
        yield put({ type: actions.SET_HOTEL_SEARCH, payload: [] });
    }

};

const getHotelDetails = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/hotelDetails`, form_data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
        );
        if (result.data.status) {
            yield put({ type: actions.SET_HOTEL_DETAILS, payload: { initialDetails: data.payload, result: result.data.message } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
            data.history('/hotel-result')
        } else {
            yield put({ type: actions.SET_HOTEL_DETAILS, payload: null });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.error.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.response.message, status: "failed" } });
        yield put({ type: actions.SET_HOTEL_DETAILS, payload: null });
    }

};


const getHotelFilter = function* (data) {
    const { payload } = data;
    var form_data = new FormData();
    yield put({ type: commonActions.SET_LOADER, payload: true });
    yield put({ type: actions.SET_CLEAR_FILTER, payload: true });
    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/hotelFilter`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );
        if (result.data.status) {
            yield put({ type: commonActions.SET_LOADER, payload: false });
            if (result.data.message.itineraries) {
                yield put({ type: actions.SET_HOTEL_SEARCH, payload: result.data.message.itineraries });
            }
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Filter Applied Your Hotel Results', status: "failed" } });
        } else {
            console.log(result.data);
            if (typeof result?.data?.message?.errors === 'object') {
                yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors[0]?.errorMessage, status: "failed" } });
            } else {
                yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors, status: "failed" } });
            }
            yield put({ type: actions.SET_HOTEL_SEARCH, payload: [] });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: actions.SET_HOTEL_FILTER, payload: null });
        yield put({ type: actions.SET_HOTEL_SEARCH, payload: [] });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message.status.error, status: "failed" } });
    }

};
const getHotelRate = function* (data) {
    console.log('hotel rate',data)
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/roomRates`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );
        if (result.data.status) {
            if (result?.data?.message?.roomRates?.perBookingRates.length === 0) {
                yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Room Rate not Available, Try Other hotels', status: "failed" } });
            }
            if (result?.data?.message?.status?.error) {
                yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message?.status?.error, status: "failed" } });
            }
            yield put({ type: actions.SET_HOTEL_RATE, payload: { result: result.data.message.roomRates.perBookingRates } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        } else if (result.data.status === false) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors, status: "failed" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }


    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: actions.SET_HOTEL_RATE, payload: [] });
    }

};
const getHotelBooking = function* (data) {
    const { payload, history } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(
                `${API_URL}/hotelbooking`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                        // "Access-Control-Allow-Origin": "*"
                    },
                }
            )
        );
        console.log('getHotelBooking', result.data)
        if (result.data.status) {
            // yield put({ type: actions.SET_HOTEL_BOOKING, payload: result.data.log });
            // yield put({ type: commonActions.SET_LOADER, payload: false });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "failed" } });
            localStorage.setItem('hotelBookingData', JSON.stringify(result.data.log));
            // window.location.href = '/hotel-booking-confirm';
            yield put({ type: actions.SET_HOTEL_BOOKING, payload: result.data.log });
            yield put({ type: commonActions.SET_LOADER, payload: false });
            history('/hotel-booking-confirm')
            // data.history('/hotel-booking-confirm');

        } else if (result.data.status === false) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "failed" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.log.errors.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: <div>{err?.message}</div>, status: "failed" } });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: actions.SET_HOTEL_BOOKING, payload: [] });
    }

};


const getHotelStaticContent = function* (data) {
    const { payload } = data;
    yield put({ type: commonActions.SET_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/hotel-static-content`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );
        yield put({ type: actions.SET_HOTEL_STATIC_CONTENT, payload: result.data.message });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: actions.SET_HOTEL_BOOKING, payload: [] });
    }

};
const getNearestHotels = function* (data) {
    const { payload } = data;
    yield put({ type: commonActions.SET_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/hotel-static-content`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );
        yield put({ type: actions.SET_NEAREST_HOTELS, payload: result.data.message });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: actions.SET_HOTEL_BOOKING, payload: [] });
    }

};

export default HotelSaga;