const hotelActions = {
    GET_HOTEL_SEARCH: 'GET_HOTEL_SEARCH_LIST',
    SET_HOTEL_SEARCH: 'SET_HOTEL_SEARCH_LIST',
    GET_HOTEL_DETAILS: 'GET_HOTEL_DETAILS_LIST',
    SET_HOTEL_DETAILS: 'SET_HOTEL_DETAILS_LIST',
    GET_HOTEL_FILTER: 'GET_HOTEL_FILTER_LIST',
    SET_HOTEL_FILTER: 'SET_HOTEL_FILTER_LIST',
    GET_HOTEL_BOOKING: 'GET_HOTEL_BOOKING_LIST',
    SET_HOTEL_BOOKING: 'SET_HOTEL_BOOKING_LIST',
    SET_HOTEL_SESSIONID: 'SET_HOTEL_SESSIONID_LIST',
    SET_NEXT_TOKEN: 'SET_NEXT_TOKEN_HOTEL',
    LOAD_MORE_HOTELS: 'LOAD_MORE_HOTELS',
    SET_HOTEL_SESSION_DATE: 'SET_HOTEL_SESSION_DATE',
    GET_HOTEL_RATE: 'GET_HOTEL_RATE_LIST',
    SET_HOTEL_RATE: 'SET_HOTEL_RATE_LIST',
    SET_HOTEL_SEARCH_VALUE: "SET_HOTEL_SEARCH_VALUE_LIST",
    GET_HOTEL_STATIC_CONTENT: 'GET_HOTEL_STATIC_CONTEN_LISTT',
    SET_HOTEL_STATIC_CONTENT: 'SET_HOTEL_STATIC_CONTENT_LIST',
    GET_NEAREST_HOTELS: 'GET NEAREST HOTELS_LIST',
    SET_NEAREST_HOTELS: 'SET NEAREST HOTELS_LIST',
    SET_HOTEL_PAYMENT: 'SET_HOTEL_PAYMENT_LIST',
    SET_TEMP_HOTEL_DETAILS: "SET_TEMP_HOTEL_DETAILS",
    RESET_HOTEL_STATE: "RESET_HOTEL_STATE",
    GET_HOTEL_REVIEWS: 'GET_HOTEL_REVIEWS',
    SET_HOTEL_REVIEWS: 'SET_HOTEL_REVIEWS',
    UPDATE_HOTEL_REVIEW: 'UPDATE_HOTEL_REVIEW',
    DELETE_HOTEL_REVIEW: 'DELETE_HOTEL_REVIEW',
    CREATE_HOTEL_REVIEW: 'CREATE_HOTEL_REVIEW',
    SET_REVIEW_LOADER: 'SET_REVIEW_LOADER',
    GET_DETAIL_PAGE_BOOKING: 'GET_DETAIL_PAGE_BOOKING',
    SET_DETAIL_PAGE_BOOKING: 'SET_DETAIL_PAGE_BOOKING',
    SET_CURRENT_PAGE: 'SET_CURRENT_HOTEL_PAGE_COUNT',
    SET_DETAIL_PAGE_BOOKING_CONDISION: 'SET_DETAIL_PAGE_BOOKING_CONDISION',
    RESET_HOTEL_REDUCER: 'RESET_HOTEL_REDUCER',
    GET_COUPONS: 'GET_HOTEL_COUPONS',
    SET_COUPONS: 'SET_HOTEL_COUPONS',
    HOTEL_IMAGE_LOADER: 'HOTEL_IMAGE_LOADER',
    GET_HOTEL_IMAGES: 'GET_HOTEL_IMAGES',
    SET_HOTEL_IMAGES: 'SET_HOTEL_IMAGES',
    SET_CLEAR_FILTER: 'SET_CLEAR_HOTEL_FILTER',
    GET_HOTEL_CHECKOUT:'GET_HOTEL_CHECKOUT',
    // SET_HOTEL_CHECKOUT:'SET_HOTEL_CHECKOUT',
};

export default hotelActions;