import React, { useEffect, useRef, useState } from 'react'
import { Editor } from "@tinymce/tinymce-react";
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import placeholderDrop from '../../asset/images/logo-gallery.png'
import { Form } from 'react-bootstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Select from 'react-select';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import blogActions from '../../redux/blog/actions'
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import trash from '../../asset/icons/trash.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { API_IMG_URL } from '../../utils/constant';
import './editorStyle.css';
import { useForm, Controller } from "react-hook-form";

import MomentTimeZone from 'moment-timezone'
const UpdateBlogEditor = (props) => {

    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log("state..", state)

    // const currDate = new Date().toLocaleDateString();
    // const currTime = new Date().toLocaleTimeString();

    const currDate = MomentTimeZone().tz('Asia/Kolkata').format('DD-MM-YYYY');
    const currTime = MomentTimeZone().tz('Asia/Kolkata').format('hh:mm A');

    
    // console.log("currDate.....", moment(currDate, 'MM/DD/YYYY'))
    const [categoryDropDown, setCategoryDropDown] = useState([]);
    var [selectedCategory, setSelectedCategory] = useState();
    var [enterPlace, setEnterPlace] = useState(state?.place);
    var [selectedTagValues, setSelectedTagValues] = useState([]);
    const [countryDropDown, setCountryDropDown] = useState([]);
    var [selectedCountry, setSelectedCountry] = useState();
    var [blogTitle, setBlogTitle] = useState(state?.heading);
    var [blogDisc, setBlogDisc] = useState();
    const editorRef = useRef(null);
    const [selectImage, setSelectImage] = useState({ file: null, url: null });
    const [titleError, setTitleError] = useState([]);
    const [imageError, setImageError] = useState("");

    const { blogCategoryList, blogCountryList } = useSelector((state) => state.blogReducer);

    const handleAddTags = (newTags) => {
        setSelectedTagValues(newTags);
        setValue("tag", newTags);
        clearErrors("tag");
    }

    const categoryList = blogCategoryList;
    const countryList = blogCountryList;
    const blogDiscription = () => {
        if (editorRef?.current) {
            setBlogDisc(blogDisc = editorRef?.current?.getContent())
            setValue("descriptionErr", editorRef?.current?.getContent())
            clearErrors("descriptionErr")
        }
    };

    useEffect(() => {
        dispatch({ type: blogActions.GET_BLOG_CATEGORY_LIST })
        dispatch({ type: blogActions.GET_BLOG_COUNTRY_LIST })
    }, [])

    useEffect(() => {
        var temp = []
        categoryList?.forEach((e) => {
            temp?.push({
                value: e?.name, label: e?.name, category_id: e?.id
            })
        })
        setCategoryDropDown(temp);

        var temp2 = []
        countryList?.forEach((e) => {
            temp2?.push({
                value: e?.name, label: e?.name, country_id: e?.id
            })
        })
        setCountryDropDown(temp2)
    }, [categoryList, countryList])

    // setting initial values
    useEffect(() => {
        let categoryValue = categoryList?.find((val) => val?.id === state?.category_id)
        if (categoryValue) {
            setSelectedCategory({ value: categoryValue?.name, label: categoryValue?.name, category_id: categoryValue?.id })
            setValue("category", { value: categoryValue?.name, label: categoryValue?.name, category_id: categoryValue?.id })
            clearErrors("category")
        }
        let countryValue = countryList?.find((val) => val?.id === state?.countryId)
        if (countryValue) {
            setSelectedCountry({ value: countryValue?.name, label: countryValue?.name, country_id: countryValue?.id })
            setValue("country", { value: countryValue?.name, label: countryValue?.name, country_id: countryValue?.id })
            clearErrors("country")
        }
    }, [categoryList, countryList])

    //edit category value
    const handleChangeCategory = (e) => {
        let categoryValue = categoryList?.find((val) => val?.id === e?.category_id)
        if (categoryValue) {
            setSelectedCategory({ value: categoryValue?.name, label: categoryValue?.name, category_id: categoryValue?.id })
        }
    }

    //edit country value
    const handleChangeCountry = (e) => {
        //  console.log("e",e)
        let countryValue = countryList?.find((val) => val?.id === e?.country_id)
        if (countryValue) {
            setSelectedCountry({ value: countryValue?.name, label: countryValue?.name, country_id: countryValue?.id })
        }
    }

    useEffect(() => {
        let tagArray = [];
        let array = state?.tags?.split(",")
        // console.log("ar",array)
        array?.map((val) => tagArray.push(val));
        setSelectedTagValues(tagArray);
        setValue("tag", tagArray);
        clearErrors("tag");
        //  let convertedTag = selectedTagValues ?.join() 
        //  console.log("convertedTag",convertedTag)        
    }, [])

    let [images, setImages] = useState([]);

    useEffect(() => {
        let imgArray = [];
        state?.blogImage?.map((val) =>
            imgArray.push({ blog_image: val, type: "old" }));
        setImages(imgArray);
    }, [])


    const handleFileUpload = (event) => {
        const files = event.target.files;
        let imageArr = [];
        for (let i = 0; i < files.length; i++) {
            imageArr.push({
                file: files[i],
                previewUrl: URL.createObjectURL(files[i]),
                type:"new"
            })
        }
        setImages(images = [...images, ...imageArr]);
        if (images?.length > 0) {
            setImageError("")
        } else {
            setImageError("Image is required")
        }
    };

    const removeImage = (index) => {
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    };

    const handlePublish = (data) => {
        // console.log("Data",data)
        if (images?.length === 0) {
            setImageError("Image is required")
        } else {
            var formData = new FormData();
            formData.append('heading', blogTitle)
            formData.append('description', blogDisc)
            formData.append('post_date', moment(currDate).format('DD-MM-YYYY'))
            formData.append('post_time', moment(currTime, 'hh:mm A').format('hh:mm'))
            formData.append('category_id', selectedCategory?.category_id)
            formData.append('place', enterPlace)
            formData.append('country_id', selectedCountry?.country_id)
            formData.append('tags', selectedTagValues?.join())
            // for new image
            if (images?.length > 0) {
                for (let i = 0; i < images?.length; i++) {
                    // console.log("img-array", images[i].file)
                    if (images[i].type === 'new') {
                        formData.append('blog_image[]', images[i].file);
                    }
                };
                //for old image
                let oldArray = images?.filter((val) => val?.type === 'old').map((val) => {
                    return val?.blog_image;
                });
                let old_blog_image = oldArray?.join()
                formData.append("old_blog_image", old_blog_image);
            };
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
        }
           dispatch({ type: blogActions.UPDATE_BLOG_DATA, payload: {body : formData ,id :state?.id ,navigate: navigate}})
    }

    const onSubmit = (data) => {
        // console.log("submit", data);
        handlePublish(data)
    }

    const handleHeading = (e) => {
        const newInput = e.target.value;
        // console.log("newValue",newValue)
        setBlogTitle(newInput);
        setValue("heading", newInput);
        clearErrors("heading");
    };

    const handlePlace = (e) => {
        const newInput = e.target.value;
        setEnterPlace(newInput);
        setValue("place", newInput);
        clearErrors("place");
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ flexGrow: 1 }} className='upload_blog_details'>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Box className='upload_blog_form'>
                                    <Box className="form-row">
                                        <Box className="form-group">
                                            <Form.Label>Blog Heading</Form.Label>
                                            <input
                                                name="heading"
                                                type="text"
                                                {...register("heading", { required: true, maxLength: 30 })}
                                                value={blogTitle}
                                                // defaultValue={state?.heading}
                                                className='form-control'
                                                onChange={handleHeading}

                                            />
                                            <div className='error'> {errors?.heading && errors?.heading.type === "required" && <p className='error'>Blog Heading is required</p>}</div>
                                            <div className='error'> {errors?.heading && errors?.heading?.type === "maxLength" && <p className='error'>Blog Heading should be 30 characters</p>}</div>
                                        </Box>
                                    </Box>
                                    <Box className="form-row">
                                        <Box className="form-group">
                                            <label >Description</label>
                                            <Editor
                                                // apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
                                                apiKey="egrtrwnyaann0ljwixpbdyfnrszxsfxir40r0hbaupb652lc"
                                                init={{
                                                    height: 350,
                                                    menubar: false
                                                }}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                // value={blogDisc}
                                                initialValue={state?.description}
                                                onEditorChange={(newValue, editor) => {
                                                    setValue("descriptionErr", newValue)
                                                    clearErrors("descriptionErr")
                                                }}
                                                {...register("descriptionErr", { required: true, minLength: 150, maxLength: 5000 })}
                                            />
                                            <div className='error'> {errors?.descriptionErr && errors?.descriptionErr.type === "required" && <p className='error'>Blog Description is required</p>}</div>
                                            <div className='error'> {errors?.descriptionErr && errors?.descriptionErr?.type === "minLength" && <p className='error'>Blog Description should be minimum 150</p>}</div>
                                            <div className='error'> {errors?.descriptionErr && errors?.descriptionErr?.type === "maxLength" && <p className='error'>Blog Description should not exceed 5000 characters</p>}</div>

                                        </Box>
                                    </Box>
                                    <Box className="form-row blog_bg_drop">
                                        <Box className="form-group">
                                            <Box className="blog_bg_drop_sec">

                                                <Box className='related_cont'>
                                                    <img className='blog_bg_drop_sec_img' src={placeholderDrop} alt='img' />
                                                    <input
                                                        name="userProfilePic"
                                                        onChange={handleFileUpload}
                                                        type="file"
                                                        multiple="multiple"
                                                        accept="image/png, image/jpeg"
                                                        className={`form-control select_blog_image_input`} />
                                                    <div className='drag__and_drop_text'>
                                                        <span>Upload a file</span><br></br>
                                                        <span className='accept__files'>Only jpeg, png accepted</span>
                                                    </div>
                                                </Box>
                                            </Box>
                                            <div className='error'> {imageError}</div>
                                            <div className='drag__and_drop_image'>
                                                {images?.map((val, index) => (
                                                    <>
                                                        <div key={index} className="image-item">
                                                            {val?.type === 'old' ? (
                                                                <>
                                                                    <img
                                                                        src={`${API_IMG_URL}/server/blog/${val?.blog_image}`}
                                                                        alt="Preview"
                                                                        className="profile-pic"
                                                                    // onClick={() => showImage(index)}
                                                                    />

                                                                    <button
                                                                        className="remove-button"
                                                                        onClick={() => removeImage(index)}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </>

                                                            ) : (
                                                                <>
                                                                    <img
                                                                        src={val?.previewUrl}
                                                                        alt="Preview"
                                                                        className="profile-pic"
                                                                    // onClick={() => showImage(index)}
                                                                    />
                                                                    <button
                                                                        className="remove-button"
                                                                        onClick={() => removeImage(index)}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                            {/* {showModal && (
                                            <div className="modal" onClick={closeModal}>
                                                <div className="modal-content">
                                                    <span className="close" onClick={closeModal}>
                                                        &times;
                                                    </span>
                                                    <img
                                                        src={selectedImage.previewUrl}
                                                        alt="Preview"
                                                        className="modal-image"
                                                    />
                                                </div>
                                            </div>
                                        )} */}


                                            <Box className='show_select_and_delete_box'>
                                                <img onClick={() => setSelectImage({ file: null, url: null })} className='deleted__icon' src={trash} alt='delete_icon' />
                                                <img className='selected__image' src={selectImage?.url} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box className='date_gategory_section'>

                                    <Box className='blog_date_time'>
                                        <Box className='blog_postDate'>
                                            <Form.Group>
                                                <Form.Label>Post Date :</Form.Label>
                                                <span className='post_date_time'> {moment(currDate, 'DD/MM/YYYY').format('DD/MM/YYYY')}</span>

                                            </Form.Group>
                                        </Box>
                                        <Box className='blog_postTime'>
                                            <Form.Group>
                                                <Form.Label>Post Time :</Form.Label>
                                                <span className='post_date_time'> {moment(currTime, 'hh:mm A').format('HH:mm')}</span>

                                            </Form.Group>
                                        </Box>
                                    </Box>
                                    <Box className='blog_catagies_select'>
                                        <Form.Group>
                                            <Form.Label>Category</Form.Label>
                                            <div className='select__drop__down'>
                                                <Select
                                                    options={categoryDropDown}
                                                    placeholder="Select Category"
                                                    {...register("category", { required: true })}
                                                    value={selectedCategory}
                                                    onChange={(e) => handleChangeCategory(e)}
                                                    isSearchable={true}
                                                // isMulti
                                                />
                                            </div>
                                            <div className='error'> {errors?.category && errors?.category.type === "required" && <p className='error'>Blog category is required</p>}</div>
                                        </Form.Group>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box className='blog_catagies_select'>
                                                <Form.Group>
                                                    <Form.Label>Place</Form.Label>
                                                    <Box className="form-row">
                                                        <Box className="form-group">
                                                            <input
                                                                name="place"
                                                                type="text"
                                                                {...register("place", { required: true })}
                                                                value={enterPlace}
                                                                // defaultValue={enterPlace}
                                                                className='form-control blog__inputs'
                                                                onChange={handlePlace}
                                                            />
                                                            <div className='error'> {errors?.place && errors?.place.type === "required" && <p className='error'>Place is required</p>}</div>
                                                        </Box>
                                                    </Box>
                                                </Form.Group>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box className='blog_catagies_select'>
                                                <Form.Group>
                                                    <Form.Label>Country</Form.Label>
                                                    <div className='select__drop__down'>
                                                        <Select
                                                            name='country'
                                                            options={countryDropDown}
                                                            placeholder="Country"
                                                            {...register("country", { required: true })}
                                                            value={selectedCountry}
                                                            onChange={(e) => handleChangeCountry(e)}
                                                            isSearchable={true}
                                                        // isMulti
                                                        />
                                                    </div>
                                                    <div className='error'> {errors?.country && errors?.country.type === "required" && <p className='error'>Country is required</p>}</div>
                                                </Form.Group>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box className='blog_tag_select '>
                                        <Form.Group>
                                            <Form.Label>Tag<small>(Press enter to add tags)</small></Form.Label>
                                            <TagsInput
                                                inputProps={{ placeholder: "Press enter to add tags" }}
                                                {...register("tag", { required: true })}
                                                value={selectedTagValues}
                                                // defaultValue = {state?.tags}
                                                onChange={handleAddTags} />
                                            <div className='error'> {errors?.tag && errors?.tag.type === "required" && <p className='error'>Enter Tags</p>}</div>
                                        </Form.Group>
                                    </Box>
                                    <Box className='create_blog_publish'>
                                        <Button type="submit" onClick={blogDiscription}>publish</Button>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box >
            </form>
        </>
    )
}

export default UpdateBlogEditor;