import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container } from '@material-ui/core'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, PinterestShareButton } from 'react-share';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PinterestIcon from '@material-ui/icons/Pinterest';


function BlogShareModal({ modal, closeModal, url, title ,media }) {
      console.log("media", media)

    const handleCopy = () => {
        navigator.clipboard.writeText(url);
    }
    return (
        <Modal show={modal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Share</Modal.Title>
            </Modal.Header>


            <div className='align-icon'>
                <div className='socialMedias_list icon-size'>
                    <FacebookShareButton url={url} quote={title}>
                        <FacebookIcon className='fb_cb' />
                    </FacebookShareButton>
                    <TwitterShareButton url={url} quote={title}>
                        <TwitterIcon className='twit_cb' />
                    </TwitterShareButton>
                    <LinkedinShareButton url={url} title={title}>
                        <LinkedInIcon className='link_cb' />
                    </LinkedinShareButton>
                    {/* <PinterestShareButton url={url} media={media} description="Check out this awesome blog!"> */}
                    <PinterestShareButton >
                        <PinterestIcon className='pin_cb' />
                    </PinterestShareButton>
                </div>
            </div>
            <hr />
            <div className='share-link'>
                <Modal.Title>Page Link</Modal.Title>
                <input className='copy-txt'
                    value={url} />
                <Button className='copy-btn' onClick={handleCopy}>Copy</Button>
            </div>

            {/* <Modal.Footer>
                <Button className='close-btn' onClick={closeModal}>Close</Button>
            </Modal.Footer> */}


        </Modal>
    );
}

export default BlogShareModal;