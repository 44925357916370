import { Box } from '@material-ui/core'
import React, { useEffect } from 'react'
import Header from '../components/common/header'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import blogBanner1 from '../asset/hotel/1.jpg'
import { Typography, Button, Container } from '@mui/material';
// import Grid from '@material-ui/core/Grid';
import { BlogRecentNewsBanner } from '../components/Blog/BlogRecentNewsBanner';
import { BlogHorizontalCard } from '../components/Blog/BlogHorizontalCard';
import BlogVerticalCard from '../components/Blog/BlogVerticalCard';
// import BlogersCard  from '../components/Blog/BlogersCard';
import OurExperience from '../components/home/OurExperience'
import Footer from '../components/common/footer'
// import cardImg from '../../asset/images/travel-1.png'
// import cardImg from '../asset/images/travel-1.png'
import { Link } from 'react-router-dom';
import BlogersCard from '../components/Blog/BlogersCard';
import { Row, Col, } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import blogActions from '../redux/blog/actions';
import { BlogDestination } from '../components/Blog/BlogDestination';
import { BlogSuggested } from '../components/Blog/BlogSuggested';
import { useNavigate } from "react-router-dom";
import commonActions from '../redux/common/actions';
import Masonry from 'react-masonry-css'
import { API_IMG_URL } from '../utils/constant';
import travellerActions from '../redux/traveller/actions';

const optionsSlider = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    loop: true,
    items: 1,
    dots: false,
    autoplay: true,
    navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
    smartSpeed: 1000,
};
// const horizontalCard = 2;
// const blogCard = 6;
// const blogGrid = [
//     {
//         cardImg: { cardImg },
//         Name: 'Durga devi',
//         totalBlog: '25 Blogs',
//         title: 'We found a paradise in koh change island thailand',
//         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
//     },
//     {
//         cardImg: { cardImg },
//         Name: 'Surya',
//         totalBlog: '25 Blogs',
//         title: 'We found a paradise in koh change island thailand',
//         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
//     },
//     {
//         cardImg: { cardImg },
//         Name: 'Karthi',
//         totalBlog: '25 Blogs',
//         title: 'We found a paradise in koh change island thailand',
//         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
//     },
//     {
//         cardImg: { cardImg },
//         Name: 'Vicky',
//         totalBlog: '25 Blogs',
//         title: 'We found a paradise in koh change island thailand',
//         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
//     },
//     {
//         cardImg: { cardImg },
//         Name: 'Pavithran',
//         totalBlog: '25 Blogs',
//         title: 'We found a paradise in koh change island thailand',
//         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
//     },
//     {
//         cardImg: { cardImg },
//         Name: 'Anand kumar',
//         totalBlog: '25 Blogs',
//         title: 'We found a paradise in koh change island thailand',
//         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
//     }
// ]
export const Blog = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { blogDetailsList, blogCategoryList } = useSelector((state) => state.blogReducer)
    const { homePageBanner } = useSelector((state) => state.commonReducer)

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true })
        dispatch({ type: commonActions.GET_HOME_BANNER, payload: [] })
        dispatch({ type: blogActions.GET_ALL_BLOG_LIST })
        dispatch({ type: blogActions.GET_BLOGGER_LIST })
        dispatch({ type: blogActions.GET_RECENT_BLOG })
        // dispatch({ type: blogActions.GET_POPULAR_BLOG_LIST })
        dispatch({ type: blogActions.GET_BLOG_DETAILS_LIST })
        dispatch({ type: blogActions.GET_DESTINATION_BLOG_LIST })
        dispatch({ type: blogActions.GET_BLOG_CATEGORY_LIST })
        // dispatch({ type: blogActions.GET_CATEGORY_WISE_BLOG })
    }, [])

    useEffect(() => {
        dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }, []);

    const breakpointColumnsObj = {
        default: 3,
        991: 2,
        600: 1,
    };

    return (
        <Box className='blog_section'>
            <Header />
            <Box className='blog_banner blog__slider_ban'>
                {homePageBanner?.length > 0 &&
                    <OwlCarousel className="owl-theme blogBanner" {...optionsSlider}>
                        {homePageBanner?.filter((val) => {
                            if (val.type === 'Blog') {
                                return val
                            } else {
                                return val;
                            }
                        })?.map((val, index) =>
                            <div key={index}>
                                <Box className="item blogBannerInner">
                                    <img src={`${API_IMG_URL}/server/homePageSlider/${val.image}`} alt='img' />
                                    <div className='overlay'></div>
                                    <Box className='blogBannerInner_content'>
                                        <Typography variant='h2'>{val?.line1}</Typography>
                                        <Typography variant='span'>{val?.line2}</Typography>
                                        <Typography variant='h5'>{val?.line3}</Typography>
                                    </Box>
                                </Box>
                                {/* <Box className="item blogBannerInner">
                                    <img src={blogBanner1} alt='img' />
                                    <div className='overlay'></div>
                                    <Box className='blogBannerInner_content'>
                                        <Typography variant='h2'>Discover - Thailand</Typography>
                                        <Typography variant='span'>10 Easy Way to be Environmentally Conscious At Thailand</Typography>
                                    </Box>
                                </Box>
                                <Box className="item blogBannerInner">
                                    <img src={blogBanner1} alt='img' />
                                    <div className='overlay'></div>
                                    <Box className='blogBannerInner_content'>
                                        <Typography variant='h2'>Discover - Thailand</Typography>
                                        <Typography variant='span'>10 Easy Way to be Environmentally Conscious At Thailand</Typography>
                                    </Box>
                                </Box>
                                <Box className="item blogBannerInner">
                                    <img src={blogBanner1} alt='img' />
                                    <div className='overlay'></div>
                                    <Box className='blogBannerInner_content'>
                                        <Typography variant='h2'>Discover - Thailand</Typography>
                                        <Typography variant='span'>10 Easy Way to be Environmentally Conscious At Thailand</Typography>
                                    </Box>
                                </Box> */}
                            </div>
                        )}
                    </OwlCarousel>
                }
            </Box>
            <Box className='recent_blog'>
                <Container>
                    <Box className='recentNews_slider_section'>
                        {localStorage.getItem('tickatrip-token') ?
                            <></>
                            :
                            <Box className='recent_blog_head'>
                                <Typography variant='h3'>recent blog's</Typography>
                                {/* <Link to='/blog-list'><Button className='book_tick blog_more'>explore more</Button></Link> */}
                            </Box>
                        }
                        <Row>
                            <Col lg={7} md={7} sm={12}>
                                <div>
                                    {localStorage.getItem('tickatrip-token') ?
                                        <Box className='recent_blog_head'>
                                            <Typography variant='h3'>Destination blog's</Typography>
                                            {/* <Link to='/blog-list'><Button className='book_tick blog_more'>explore more</Button></Link> */}
                                        </Box>
                                        :
                                        <></>
                                    }
                                </div>
                                {localStorage.getItem('tickatrip-token') ?
                                    <BlogDestination />
                                    :
                                    <BlogRecentNewsBanner />
                                }

                            </Col>
                            <Col lg={5} md={5} sm={12}>
                                <div>
                                    {localStorage.getItem('tickatrip-token') ?
                                        <Box className='recent_blog_head'>
                                            <Typography variant='h3'>Suggested blog's</Typography>
                                            {/* <Link to='/blog-list'><Button className='book_tick blog_more'>explore more</Button></Link> */}
                                        </Box>
                                        :
                                        <></>
                                    }
                                </div>
                                {localStorage.getItem('tickatrip-token') ?
                                    <BlogSuggested />
                                    :
                                    <BlogHorizontalCard />
                                }
                            </Col>
                        </Row>
                    </Box>
                </Container>
                {/* <Box className='recent_news_overlay'></Box> */}
            </Box>
            <Box className='main_before_blog'>
                <Container>
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {blogDetailsList?.slice(0, 6)?.map((val, index) => {
                            return (
                                <div key={index}>
                                    <BlogVerticalCard val={val} page="blog" />
                                </div>
                            );
                        })}
                    </Masonry>
                    {/* <Grid container spacing={3}>
                        {blogDetailsList?.map((val, index) => {
                            return (
                                <Grid className='blog__main__page__grid' key={index} item lg={4} md={4} sm={12}>
                                    <BlogVerticalCard val={val} />
                                </Grid>
                            )
                        })} */}
                    {/* <Grid lg={12} md={12} sm={12} className='al-c'> */}

                    {/* </Grid> */}
                    {/* </Grid> */}
                    <div className='blog_explore_button_cls'>
                        <Link to='/blog-list'>
                            {/* <Link to='/created-blog-list'> */}
                            <Button className='subscribe_btn al-c'>Explore More</Button>
                            {/* <Button className='subscribe_btn al-c'>Explore More</Button> */}
                        </Link>
                    </div>
                </Container>

            </Box>
            <Box className='sufferingOn_catogory'>
                <Container>
                    <div className='our__Cate'>
                        <div className='gategory__heading'>
                            <Typography variant='h3'>surfing on categories</Typography>
                        </div>
                        <div className='gategory__List'>
                            <Box className='catogories_btns'>
                                {blogCategoryList.map((val, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            type='button'
                                            style={{ color: "#000", backgroundColor: val?.color_code }}
                                            onClick={() => navigate('/category_list', { state: { categoryId: val?.id, categoryName: val?.name } })}
                                        >{val?.name}</Button>
                                    );
                                })}
                            </Box>
                        </div>
                    </div>
                </Container>
            </Box>
            <Box className='blogers_section'>
                <Container>
                    <Box className='recent_blog_head'>
                        <Typography variant='h3'>bloggers</Typography>
                        {/* <Link to='/create-blog'>
                            <Button className='book_tick blog_more'>explore more</Button>
                        </Link> */}
                    </Box>
                    <Box className='bloggers_slider'>
                        <BlogersCard />
                    </Box>
                </Container>
            </Box>
            <OurExperience />
            <Footer />
        </Box>
    )
}
