import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AvatarGroup, Box } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { API_IMG_URL } from '../../utils/constant';
import { useNavigate } from "react-router-dom";
import blogActions from '../../redux/blog/actions';
import { useDispatch,useSelector } from 'react-redux'
import commonActions from '../../redux/common/actions';
// import travellerActions from '../../redux/traveller/actions';
// import { useEffect } from 'react';
import BlogShareModal from './BlogShareModal';
import { useLocation } from 'react-router-dom';

export default function BlogVerticalCard(props) {
    const { val, storyDesign, clickedFrom, page } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [showShareButtonsPopup, setShowShareButtonsPopup] = useState(false);
    const [postBlogId, setPostBlogId] = useState();
    const [blogTitle, setBlogTitle] = useState();

    const { blogLikeStatus } = useSelector((state) => state.blogReducer)
    const updateBlogLike = () => {
        if (localStorage.getItem('tickatrip-token')) {
            dispatch({ type: blogActions.UPDATE_BLOG_LIKE, payload: { blogId: val.id, categoryId: val?.category_id, countryId: val?.country_id, clickedFrom: clickedFrom, page: page } })
            // dispatch({ type: travellerActions.GET_PROFILE_DATA });
        } else {
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
        }
    }
    const updateBlogBookmark = () => {
        if (localStorage.getItem('tickatrip-token')) {
            dispatch({ type: blogActions.UPDATE_BLOG_BOOKMARK, payload: { blogId: val.id, categoryId: val?.category_id, countryId: val?.country_id, clickedFrom: clickedFrom, page: page } })
        } else {
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
        }
    }
    // useEffect(() => {
    //     dispatch({ type: travellerActions.GET_PROFILE_DATA });
    // }, []);

    const handleModal = (e) => {
        // console.log("e",e)
        setShowShareButtonsPopup(true);
        setPostBlogId(`https://test.tickatrip.travel/blog-detail/${e?.id}`);
        setBlogTitle(e?.heading);
    }

    const closeModal = () => {
        setShowShareButtonsPopup(false);
    }


    return (
        <div>
            {/* <Link to='/blog-detail'> */}
            {/* {blogGrid.map((val, index) => {
                return ( */}
            <Card sx={{ maxWidth: 345 }} className='blog_ver_card'>
                {storyDesign === "UserStoryDet" ?
                    <></>
                    :
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: red[500] }} src={`${API_IMG_URL}/server/user/${val?.profile_image}`} aria-label="recipe" />

                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title={val?.name}
                        subheader={val?.totalBlog}
                    />
                }
                {/* <Link to='/blog-detail' > */}
                <div onClick={() => navigate(`/blog-detail/${val?.id}`, { state: { blogId: val?.id, categoryId: val?.category_id, type: "related_detail",title: val?.heading } })}>
                    <Box className='verCard_img_sec'>
                        <CardMedia
                            className='verCard_img'
                            component="img"
                            height="194"
                            image={`${API_IMG_URL}/server/blog/${val?.blog_image[0]}`}
                            alt="Paella dish"
                        />
                        {val?.category_name.map((el, ind) => {
                            return (
                                <span className='verCard_tag' key={ind}>{el?.name}</span>
                            );
                        })}
                    </Box>
                </div>
                {/* </Link> */}
                <Box className='horizontal_bager_td '>
                    <Box className='horiCard_like_share_icon '>
                        <CardActions disableSpacing>
                            <FavoriteIcon
                                onClick={updateBlogLike}
                                className={val?.auth_liked_blog === true ? 'red' : 'deactivate'}
                            />
                            <IconButton aria-label="share">
                                <ShareIcon onClick={(e) => handleModal(val)} />
                            </IconButton>
                        </CardActions>
                    </Box>
                    <div style={{ cursor: 'pointer' }}>
                        <BookmarkIcon
                            onClick={updateBlogBookmark}
                            className={val?.auth_bookmarked_blog === true ? 'green' : 'deactivate'}
                        />
                    </div>

                </Box>
                <Box className='horizontal_bager_name'>
                    {val?.liked_user_images?.length === 0 ?
                        <></>
                        :
                        <>
                            <Box direction="row" className='horiCard_like_share'>
                                <Typography variant='h5'><Typography variant='span'>Liked by</Typography> {val?.like_count} {val?.liked_user_images?.length >= 2 ? "peoples" : "people"}</Typography>
                            </Box>
                            <AvatarGroup >
                                {val?.liked_user_images?.slice(0, 3)?.map((item, index) => {
                                    return (
                                        <Avatar className='Liked__person_avatar' key={index} alt="Remy Sharp" src={`${API_IMG_URL}/server/user/${item}`} />
                                    );
                                })}
                            </AvatarGroup>
                        </>
                    }
                </Box>
                <div onClick={() => navigate(`/blog-detail/${val?.id}`, { state: { blogId: val?.id, categoryId: val?.category_id, type: "related_detail",title: val?.heading } })}>
                    <Typography variant='h2' className='verCard_title'>{val?.heading}</Typography>
                </div>
                <Typography variant="span" className='verCard_content blog__total__desic'>
                    <div className='card__descrition' dangerouslySetInnerHTML={{
                        __html: val?.description,
                    }} ></div>
                </Typography>
            </Card >
            {/* );
            })} */}
            {/* </Link> */}

            <BlogShareModal
                modal={showShareButtonsPopup}
                closeModal={closeModal}
                url={postBlogId}
                title={blogTitle}
            // blogId ={postBlogId}
            />
        </div >
    );
}
