const userActions = {
  SET_ALL_REGISTER: 'SET_ALL_REGISTER',
  SET_AUTHETICATION: 'SET_AUTHETICATION',
  USER_LOGIN: 'USER_LOGIN',
  GET_OTP_MAIL_LOGIN: 'GET_OTP_MAIL_LOGIN',
  SET_OTP_MAIL_LOGIN_MESSAGE: 'SET_OTP_MAIL_LOGIN_MESSAGE',
  SET_VERIFY_OTP: 'SET_VERIFY_OTP',
  GET_VERIFY_OTP: 'GET_VERIFY_OTP',
  SET_VERIFY_OTP_ID: 'SET_VERIFY_OTP_ID',
  GET_NEW_PASSWORD: 'GET_NEW_PASSWORD',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  GET_VERIFY_OTP_ID_PASSWORD: 'GET_VERIFY_OTP_ID_PASSWORD',
  SET_VERIFY_OTP_ID_PASSWORD: 'SET_VERIFY_OTP_ID_PASSWORD',
  GET_PROFILE_EDIT: 'GET_PROFILE_USER_EDIT',
  SET_PROFILE_EDIT: 'SET_PROFILE_USER_EDIT',
  SET_MY_BOOKING: 'SET_MY_BOOKING',
  GET_MY_BOOKING: 'GET_MY_BOOKING',
  SET_MY_COMPLETED_BOOKING: 'SET_MY_COMPLETED_BOOKING',
  GET_MY_COMPLETED_BOOKING: 'GET_MY_COMPLETED_BOOKING',
  SET_MY_BOOKING_CANCEL_LIST: 'SET_MY_BOOKING_CANCEL_LIST',
  GET_MY_BOOKING_CANCEL_LIST: 'GET_MY_BOOKING_CANCEL_LIST',
  GET_MY_BOOKING_CANCEL_VERIFY: 'GET_MY_BOOKING_CANCEL_VERIFY',
  SET_MY_BOOKING_CANCEL_VERIFY: 'SET_MY_BOOKING_CANCEL_VERIFY',
  GET_MY_BOOKING_CANCEL_CONFIRM: 'GET_MY_BOOKING_CANCEL_CONFIRM',
  SET_MY_BOOKING_CANCEL_CONFIRM: 'SET_MY_BOOKING_CANCEL_CONFIRM',
  GET_MY_BOOKING_TICKET_DETAILS: 'GET_MY_BOOKING_TICKET_DETAILS',
  SET_MY_BOOKING_TICKET_DETAILS: 'SET_MY_BOOKING_TICKET_DETAILS',
  GET_MY_HOTEL_BOOKINGS: 'GET_MY_HOTEL_BOOKINGS',
  SET_MY_HOTEL_BOOKINGS: 'SET_MY_HOTEL_BOOKINGS',
  GET_MY_HOTEL_BOOKINGS_COMPLETED: 'GET_MY_HOTEL_BOOKINGS_COMPLETED',
  SET_MY_HOTEL_BOOKINGS_COMPLETED: 'SET_MY_HOTEL_BOOKINGS_COMPLETED',
  GET_MY_HOTEL_BOOKINGS_CANCEL: 'GET_MY_HOTEL_BOOKINGS_CANCEL',
  SET_MY_HOTEL_BOOKINGS_CANCEL: 'SET_MY_HOTEL_BOOKINGS_CANCEL',
  GET_HOTEL_BOOKINGS_CANCEL_REQUEST: 'GET_HOTEL_BOOKINGS_CANCEL_REQUEST',
  SET_HOTEL_BOOKINGS_CANCEL_REQUEST: 'SET_HOTEL_BOOKINGS_CANCEL_REQUEST',
  GET_HOTEL_BOOKINGS_CANCEL_VERIFY: 'GET_HOTEL_BOOKINGS_CANCEL_VERIFY',
  SET_HOTEL_BOOKINGS_CANCEL_VERIFY: 'SET_HOTEL_BOOKINGS_CANCEL_VERIFY',
  GET_HOTEL_BOOKINGS_GUEST_CANCEL_REG: 'GET_HOTEL_BOOKINGS_GUEST_CANCEL_REG',
  SET_HOTEL_BOOKINGS_GUEST_CANCEL_REG: 'SET_HOTEL_BOOKINGS_GUEST_CANCEL_REG',
  SET_SCN_NUMBER: 'SET_SCN_NUMBER',
  GET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER: 'GET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER',
  SET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER: 'SET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER',
  SET_MY_FLIGHT_UP_BOOKING_DETAILS: 'SET_MY_FLIGHT_UP_BOOKING_DETAILS',
  GET_MY_FLIGHT_UP_BOOKING_DETAILS: 'GET_MY_FLIGHT_UP_BOOKING_DETAILS',
  GET_MY_FLIGHT_CANCEL_BOOKING_DETAILS: 'GET_MY_FLIGHT_CANCEL_BOOKING_DETAILS',
  GET_MY_FLIGHT_COMPLETED_BOOKING_DETAILS: 'GET_MY_FLIGHT_COMPLETED_BOOKING_DETAILS',
  SET_MY_HOTEL_BOOKING_DETAILS: 'SET_MY_HOTEL_BOOKING_DETAILS',
  GET_MY_HOTEL_BOOKING_DETAILS: 'GET_MY_HOTEL_BOOKING_DETAILS',
  GET_MY_HOTEL_CANCEL_BOOKING_DETAILS: 'GET_MY_HOTEL_CANCEL_BOOKING_DETAILS',
  GET_MY_HOTEL_COMPLETED_BOOKING_DETAILS: 'GET_MY_HOTEL_COMPLETED_BOOKING_DETAILS',
  SET_USER_PAGE_SLIDER: 'SET_USER_PAGE_SLIDER',
  GET_USER_PAGE_SLIDER: 'GET_USER_PAGE_SLIDER',
  LOG_OUT: 'USER_LOG_OUT',
  
};

export default userActions;