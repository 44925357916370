const blogActions =  {

GET_BLOG_CATEGORY_LIST:'GET_BLOG_CATEGORY_LIST',
SET_BLOG_CATEGORY_LIST:'SET_BLOG_CATEGORY_LIST',
GET_BLOG_COUNTRY_LIST:'GET_BLOG_COUNTRY_LIST',
SET_BLOG_COUNTRY_LIST:'SET_BLOG_COUNTRY_LIST',
GET_CREATE_BLOG_DATA:'GET_CREATE_BLOG_DATA',
SET_CREATE_BLOG_DATA:'SET_CREATE_BLOG_DATA',
GET_RECENT_BLOG:'GET_RECENT_BLOG',
SET_RECENT_BLOG:'SET_RECENT_BLOG',
GET_ALL_BLOG_LIST:'GET_ALL_BLOG_LIST',
SET_ALL_BLOG_LIST:'SET_ALL_BLOG_LIST',
GET_BLOGGER_LIST:'GET_BLOGGER_LIST',
SET_BLOGGER_LIST:'SET_BLOGGER_LIST',
GET_POPULAR_BLOG_LIST:'GET_POPULAR_BLOG_LIST',
SET_POPULAR_BLOG_LIST:'SET_POPULAR_BLOG_LIST',
GET_BLOG_DISCRIPTION:'GET_BLOG_DISCRIPTION',
SET_BLOG_DISCRIPTION:'SET_BLOG_DISCRIPTION',
GET_RELATED_BLOG_LIST:'GET_RELATED_BLOG_LIST',
SET_RELATED_BLOG_LIST:'SET_RELATED_BLOG_LIST',
GET_CREATE_BLOG_COMMENT:'GET_CREATE_BLOG_COMMENT',
SET_CREATE_BLOG_COMMENT:'SET_CREATE_BLOG_COMMENT',
GET_BLOG_DETAILS_LIST:'GET_BLOG_DETAILS_LIST',
SET_BLOG_DETAILS_LIST:'SET_BLOG_DETAILS_LIST',
UPDATE_BLOG_LIKE:'UPDATE_BLOG_LIKE',
UPDATE_BLOG_BOOKMARK:'UPDATE_BLOG_BOOKMARK',
CREATE_BLOG_RATING:'CREATE_BLOG_RATING',
DELETE_BLOG_STORY_LIST:'DELETE_BLOG_STORY_LIST',
GET_DESTINATION_BLOG_LIST:'GET_DESTINATION_BLOG_LIST',
SET_DESTINATION_BLOG_LIST:'SET_DESTINATION_BLOG_LIST',
GET_COUNTRY_WISE_BLOG:'GET_COUNTRY_WISE_BLOG',
SET_COUNTRY_WISE_BLOG:'SET_COUNTRY_WISE_BLOG',
GET_FOLLOW_BLOGGER_LIST:'GET_FOLLOW_BLOGGER_LIST',
SET_FOLLOW_BLOGGER_LIST:'SET_FOLLOW_BLOGGER_LIST',
GET_CATEGORY_WISE_BLOG:'GET_CATEGORY_WISE_BLOG',
SET_CATEGORY_WISE_BLOG:'SET_CATEGORY_WISE_BLOG',
GET_USER_WISE_BLOG:'GET_USER_WISE_BLOG',
SET_USER_WISE_BLOG:'SET_USER_WISE_BLOG',
GET_ADD_BLOG_STORY:'GET_ADD_BLOG_STORY',
SET_ADD_BLOG_STORY:'SET_ADD_BLOG_STORY',
GET_BLOG_STORY_LIST:'GET_BLOG_STORY_LIST',
SET_BLOG_STORY_LIST:'SET_BLOG_STORY_LIST',
GET_BLOG_VIEW_RATING:'GET_BLOG_VIEW_RATING',
SET_BLOG_VIEW_RATING:'SET_BLOG_VIEW_RATING',
GET_BLOG_VIEW_COMMENTS:'GET_BLOG_VIEW_COMMENTS',
SET_BLOG_VIEW_COMMENTS:'SET_BLOG_VIEW_COMMENTS',
DELETE_BLOG_COMMENTS:'DELETE_BLOG_COMMENTS',
DELETE_BLOGS:'DELETE_BLOGS',
GET_BOOK_MARK_LIST:'GET_BOOK_MARK_LIST',
SET_BOOK_MARK_LIST:'SET_BOOK_MARK_LIST',
GET_VIEW_BLOGGER_PROFILE:'GET_VIEW_BLOGGER_PROFILE',
SET_VIEW_BLOGGER_PROFILE:'SET_VIEW_BLOGGER_PROFILE',
GET_BLOGGER_FOLLOW_CHECK_BY_ID: 'GET_BLOGGER_FOLLOW_CHECK_BY_ID',
SET__BLOGGER_FOLLOW_CHECK_BY_ID: 'SET__BLOGGER_FOLLOW_CHECK_BY_ID',
UPDATE_BLOGGERS_FOLLOW: 'UPDATE_BLOGGERS_FOLLOW',

GET_TAG_WISE_BLOG : 'GET_TAG_WISE_BLOG',
SET_TAG_WISE_BLOG : 'SET_TAG_WISE_BLOG',

UPDATE_BLOG_DATA : 'UPDATE_BLOG_DATA',

}

export default blogActions;