import React, { useEffect, useState } from 'react'
import '../Hotel/payment.css'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Typography } from '@mui/material';

export const HotelBookingDetail = () => {

    const { hotelBookingDetail } = useSelector((state) => state.userReducer)

    var [roomGuestDetails, setRoomGuestDetails] = useState(JSON.parse(localStorage.getItem('items')));

    // useEffect(() => {
    //     if (hotelBookingDetail) {
    //         // setRoomGuestDetails(hotelBookingDetail)
    //         localStorage.setItem('items', JSON.stringify(hotelBookingDetail));
    //         setRoomGuestDetails(JSON.parse(localStorage.getItem('items')))
    //     }
    // }, []);
    return (
        <>
            <div className='traveller_list hotel_confirm_pas_det'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='hotel_booking_tabel'>
                                <div>
                                    <h5 className="MuiTypography-root MuiTypography-h5 travel_head">
                                        Room Booking Details
                                    </h5>
                                </div>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Booking Status</th>
                                            <td>{roomGuestDetails?.booking_status}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Booking Time</th>
                                            <td>{moment(roomGuestDetails?.booking_time).format('hh:mm')}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Supplier Confirmation No</th>
                                            <td>{roomGuestDetails?.supplier_confirmation_no}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Hotel Name</th>
                                            <td>{roomGuestDetails?.hotel_name}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Hotel Address</th>
                                            <td>{roomGuestDetails?.hotel_address}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">City</th>
                                            <td>{roomGuestDetails?.city}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Country</th>
                                            <td>{roomGuestDetails?.country}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Check In</th>
                                            <td>{roomGuestDetails?.check_in}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Check Out</th>
                                            <td>{roomGuestDetails?.check_out}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Booking Days</th>
                                            <td>{roomGuestDetails?.booking_days}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Net Price</th>
                                            <td>{roomGuestDetails?.net_price}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Fare Type</th>
                                            <td>{roomGuestDetails?.fare_type}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Customer Email</th>
                                            <td>{roomGuestDetails?.customer_email}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Customer Phone</th>
                                            <td>{roomGuestDetails?.customer_phone}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Cancellation Policy</th>
                                            <td>{roomGuestDetails?.cancellation_policy}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* {hotelBookingDetail?.room_details?.image ?
                                <div className='hotel_images'>
                                    <div className='container'>
                                        <div className='row '>
                                            <div className='col-lg-6 clo-md-6 col-sm-12'>
                                                <img src={hotelBookingDetail?.room_details?.image} alt='img' />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            } */}
                        </div>
                        <div className='col-lg-5'>
                            <div>
                                <div>
                                    <h6 className="MuiTypography-root MuiTypography-h6 travel_head">
                                        Room  Details
                                    </h6></div>
                                {roomGuestDetails?.room_details?.map((val, index) => (
                                    <div className="wrapper-traveller" key={'roomGuestDetails' + index}>
                                        <div className="right">
                                            <div className="info">
                                                <h3>Room : {index + 1}</h3>
                                                <div className='roo_typs'>
                                                    <ul>
                                                        <li><span className='bld_txt'>Name :</span>  {val?.name}</li>
                                                        <li><span className='bld_txt'>BoardType :</span>  {val?.boardType}</li>
                                                        <li><span className='bld_txt'>Description :</span>  {val?.description}</li>
                                                    </ul>
                                                </div>
                                                <div className="info_data">
                                                    <div className="data">
                                                        <p>{val.paxDetails?.name[0]} {val.paxDetails?.name.length > 1 ? <>+ {val.paxDetails?.name.length - 1}</> : <></>} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                                <div>
                                    <div><Typography variant='h6' className='travel_head'>Booking Details</Typography></div>
                                    <div className='flight_pop_total'>
                                        <div className='pop_total_card'>
                                            <div className='pop_total_card_single'>
                                                <span className='pop_total_list'>Net Price</span>
                                                <span className='pop_total_price'>{roomGuestDetails?.currency} {roomGuestDetails?.total_amount_paid}</span>
                                            </div>
                                            <div className='pop_total_card_single'>
                                                <span className='pop_total_list'>Taxes</span>
                                                <span className='pop_total_price'>{roomGuestDetails?.currency} {roomGuestDetails?.tax}</span>
                                            </div>
                                            <div className='pop_total_card_single'>
                                                <span className='pop_total_list'>Discounts & Adjustments</span>
                                                <span className='pop_total_price'>{roomGuestDetails?.currency} {roomGuestDetails?.discount_amount}</span>
                                            </div>
                                            <div className='pop_total_card_single'>
                                                <span className='pop_total_list'>Convenience Fee<br /><span className='sm_text'></span></span>
                                                <span className='pop_total_price'>{roomGuestDetails?.currency} {roomGuestDetails?.convenience_fee}</span>
                                            </div>
                                            <div className='pop_total_card_single total_price'>
                                                <span className='pop_total_list'>Total</span>
                                                <span className='pop_total_price'> {roomGuestDetails?.currency} {roomGuestDetails?.order_amount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
