import { Box } from '@material-ui/core'
import { Avatar, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import carImg from '../../asset/images/travel-2.png'
// import avatarImg from '../../asset/images/avatar.jpg'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
// import { Link } from 'react-router-dom'
// import LikeButton from '../common/likeBtn'
import { useDispatch, useSelector } from 'react-redux';
import blogActions from '../../redux/blog/actions'
import { API_IMG_URL } from '../../utils/constant'
import { useNavigate } from "react-router-dom";
import commonActions from '../../redux/common/actions'
import InfiniteScroll from 'react-infinite-scroll-component';
// import travellerActions from '../../redux/traveller/actions';
import BlogShareModal from './BlogShareModal';
import { useLocation } from 'react-router-dom';

export const BlogSuggested = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { allBlogList } = useSelector((state) => state.blogReducer);
    const [addLike, setAddLike] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [showShareButtonsPopup, setShowShareButtonsPopup] = useState(false);
    const [postBlogId, setPostBlogId] = useState();
    const [blogTitle, setBlogTitle] = useState();
    const [blogImage,setBlogImage] = useState();

    const updateBlogLike = (Id) => {
        if (localStorage.getItem('tickatrip-token')) {
            var temp = allBlogList.find((e) => e.id === Id)
            dispatch({ type: blogActions.UPDATE_BLOG_LIKE, payload: { blogId: temp?.id, page: "all_blog_list" } })
            // dispatch({ type: travellerActions.GET_PROFILE_DATA });
            setAddLike(!addLike)
        } else {
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
        }
    }
    const updateBlogBookmark = (Id) => {
        if (localStorage.getItem('tickatrip-token')) {
            var temp = allBlogList.find((e) => e.id === Id)
            dispatch({ type: blogActions.UPDATE_BLOG_BOOKMARK, payload: { blogId: temp?.id, page: "all_blog_list" } })
        } else {
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
        }
    }
    useEffect(() => {
        if (allBlogList?.length > 0) {
            setFetchedData(allBlogList?.slice(0, 20));
        };
    }, [allBlogList]);

    const fetchMoreData = () => {
        if (allBlogList?.length === fetchedData?.length) {
            return;
        } else {
            let currentLength = fetchedData?.length;
            setFetchedData([...fetchedData, ...allBlogList?.slice(currentLength, currentLength + 20)])
        }
    };
    // useEffect(() => {
    //     dispatch({ type: blogActions.GET_ALL_BLOG_LIST })
    // }, [])

    const closeModal = () => {
        setShowShareButtonsPopup(false);
    }

    const handleModal = (e) => {
         console.log("e",e)
        setShowShareButtonsPopup(true);
        setPostBlogId(`https://test.tickatrip.travel/blog-detail/${e?.id}`);
        setBlogTitle(e?.heading);
        setBlogImage(`${API_IMG_URL}/server/user/${e?.blog_image[0]}`);
    }

    //  const blogUrl = window.location.origin + location.pathname ;  

    return (
        <div className='our__Suggested__sec' id="scrollableDiv">
            <InfiniteScroll
                dataLength={fetchedData?.length}
                next={fetchMoreData}
                hasMore={true}
                scrollableTarget="scrollableDiv"
            >
                {fetchedData.map((val, index) => {
                    return (
                        <div key={index} className='Suggested_Grid'>
                            <Box
                                onClick={() => navigate(`/blog-detail/${val?.id}`, { state: { blogId: val?.id, categoryId: val?.category_id, type: "related_detail" ,title: val?.heading } })}
                                className='blogHorizontal_card_img'>
                                <img src={`${API_IMG_URL}/server/blog/${val?.blog_image[0]}`} alt='img' />
                            </Box>
                            <Box className='blogHorizontal_card_content'>
                                <Box className='horizontal_bager_name'>
                                    <Stack direction="row" spacing={2}>
                                        <Avatar alt="Remy Sharp" src={`${API_IMG_URL}/server/user/${val?.profile_image}`} />
                                        <Box className='blogHorizontal_card_name'>
                                            <Typography variant='h6'>{val?.name}</Typography>
                                            <Typography variant='span' className='date_txt'>{val?.post_date}</Typography>
                                        </Box>
                                    </Stack>
                                    <BookmarkIcon
                                        onClick={() => updateBlogBookmark(val?.id)}
                                        className={val?.auth_bookmarked_blog === true ? 'green' : 'deactivate'}
                                    />
                                </Box>
                                <div onClick={() => navigate(`/blog-detail/${val?.id}`, { state: { blogId: val?.id, categoryId: val?.category_id, type: "related_detail",title: val?.heading } })}>
                                    <Box className='horizontal_bager_td'>
                                        <Typography variant='h2'>
                                            {val?.heading}
                                        </Typography>
                                    </Box>
                                </div>
                                <Box className='recent__bager horizontal_bager_td  bt-l'>
                                    <Box direction="row" className='horiCard_like_share'>
                                        <Typography variant='h5'><Typography variant='span'>Liked by</Typography>{val?.like_count} people</Typography>
                                    </Box>
                                    <Box className='horiCard_like_share_icon recent_banne_blogers'>
                                        <FavoriteIcon
                                            onClick={() => updateBlogLike(val?.id)}
                                            className={val?.auth_liked_blog === true ? 'red' : 'deactivate'}
                                        />
                                        <ShareIcon onClick={(e) => handleModal(val)} />
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    );
                })}

            </InfiniteScroll>

            <BlogShareModal
                modal={showShareButtonsPopup}
                closeModal={closeModal}
                url={postBlogId}
                title={blogTitle}
                media ={blogImage}
            // blogId ={postBlogId}
            />
        </div>
    )
}
