import React from 'react'
import OTPInput from "otp-input-react";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userActions from '../../redux/user/actions';
import { useNavigate } from 'react-router';
import commonActions from '../../redux/common/actions';
import loaderGifImg from "../../asset/blueflightloader.gif";
import { Button } from '@material-ui/core';

export const HotelGuestCancelOtp = () => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const { supplierConfirmationNumber } = useSelector((state) => state.userReducer)
    const { loading } = useSelector(
        (state) => state.commonReducer
    )
    const [otp, setOtp] = useState("")

    const OnOtpCofirm = (e) => {
        e.preventDefault()
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({
            type: userActions.GET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER, payload: {
                OTP: otp,
                supplierConfirmationNum: supplierConfirmationNumber,
            },
            history: history
        })
    }

    return (
        <>
          {loading ?
                <div>
                    <div className='blur_bg'></div>
                    <div className='loaderGif'>
                        <img src={loaderGifImg} alt='' />
                    </div>
                </div>
                :
                null
            }
            <div className='pnr_cancel_sec hotel_scn_cancel'>
                <div className='pnr_cancel_card'>
                    <form onSubmit={OnOtpCofirm}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Enter your OTP</label>
                            <OTPInput
                                error
                                value={otp}
                                onChange={setOtp}
                                autoFocus
                                require
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                className='otp_field'
                            />
                        </div>
                        <Button disabled={otp.length!==6} type="submit">Submit</Button>
                    </form>
                </div>
            </div>
        </>
    )
}
