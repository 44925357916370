import React, { useEffect, useState } from 'react'
import './payment.css'
import VerifiedIcon from '@mui/icons-material/Verified';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import hotelActions from '../../redux/hotel/actions';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import moment from 'moment';

export const HotelBookingConfirm = () => {
  // const { hotelBokking } = useSelector((state) => state.hotelReducer);
  var [hotelBookingDetails, setHotelBookingDetails] = useState(JSON.parse(localStorage.getItem('hotelBookingData')))
  var [hotelSupplierId, setHotelSupplierId] = useState({
    supplierConfirmationNum: '',
    referenceNum: '',
  })

  const dispatch = useDispatch();

  const [roomGuestDetails, setRoomGuestDetails] = useState([])

  useEffect(() => {
    if (hotelBookingDetails) {
      setRoomGuestDetails(hotelBookingDetails?.room_details)
      // console.log('booking condition',hotelBookingDetails?.roomBookDetails?.rooms)
    }
    return () => {
      dispatch({ type: hotelActions.RESET_HOTEL_STATE });
    }
  }, [hotelBookingDetails]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search.split("?")[1]);
    if (params) {
      setHotelSupplierId(hotelSupplierId = {
        supplierConfirmationNum: params.get('supplierConfirmationNum'),
        referenceNum: params.get('referenceNum')
      })
    }
    bookingdet()
  }, [])

  const bookingdet = () => {
    axios.post(
      `${API_URL}/hotelbooking_details`, hotelSupplierId, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    }
    ).then((res) => {
      console.log('res', res.data)
      setHotelBookingDetails(hotelBookingDetails = res?.data?.message)

    }).catch(err => {

    })
  }
  console.log('hotelBookingDetailsSSS', hotelBookingDetails)
  return (
    <>
      <div className='hotel_boocking_confirm_page'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className='hotel_confirm_card'>
                <a href="#" className="card credentialing">
                  <div className="overlay"></div>
                  <div className='yor_booking_con'>
                    <div className="circle">
                      <VerifiedIcon />
                    </div>
                    <h3>Your Booking confirm</h3>
                    <p>check blow booking details</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='traveller_list hotel_confirm_pas_det'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
              <div className='hotel_booking_tabel'>
                <div><Typography variant='h6' className='travel_head'>Room Booking Details</Typography></div>
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">Booking Status</th>
                      <td>{hotelBookingDetails?.booking_status}</td>
                    </tr>
                    <tr>
                      <th scope="row">Supplier Confirmation No</th>
                      <td>{hotelBookingDetails?.supplier_confirmation_no}</td>
                    </tr>
                    <tr>
                      <th scope="row">Check In</th>
                      <td>{hotelBookingDetails?.check_in}</td>
                    </tr>
                    <tr>
                      <th scope="row">Check Out</th>
                      <td>{hotelBookingDetails?.check_out}</td>
                    </tr>
                    <tr>
                      <th scope="row">Booking Days</th>
                      <td>{hotelBookingDetails?.booking_days}</td>
                    </tr>
                    {/* <tr>
                      <th scope="row">Net Price</th>
                      <td> {hotelBookingDetails?.net_price}</td>
                    </tr> */}
                    <tr>
                      <th scope="row">Fare Type</th>
                      <td>{hotelBookingDetails?.fare_type}</td>
                    </tr>

                    <tr>
                      <th scope="row">Customer Email</th>
                      <td>{hotelBookingDetails?.customer_email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Customer Phone</th>
                      <td>{hotelBookingDetails?.customer_phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Cancellation Policy</th>
                      <td>{hotelBookingDetails?.cancellation_policy}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
              <div>
                <div><Typography variant='h6' className='travel_head'>Room Details</Typography></div>
                {roomGuestDetails?.map((val, index) => (
                  <div className="wrapper-traveller" key={'roomGuestDetails' + index}>
                    <div className="right">
                      <div className="info">
                        <h3>Room : {index + 1}</h3>
                        <div className='roo_typs'>
                          <ul>
                            <li><span className='bld_txt'>Name :</span>  {val?.name}</li>
                            <li><span className='bld_txt'>BoardType :</span>  {val?.boardType}</li>
                            <li><span className='bld_txt'>Description :</span>  {val?.description}</li>
                          </ul>
                        </div>
                        <div className="info_data">
                          <div className="data">
                            <p>{val.paxDetails?.name[0]} {val.paxDetails?.name.length > 1 ? <>+ {val.paxDetails?.name.length - 1}</> : <></>} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
              <div>
                <div><Typography variant='h6' className='travel_head'>Booking Details</Typography></div>
                <div className='flight_pop_total'>
                  <div className='pop_total_card'>
                  <div className='pop_total_card_single'>
                      <span className='pop_total_list'>Net Price</span>
                      <span className='pop_total_price'>{hotelBookingDetails?.currency} {hotelBookingDetails?.total_amount_paid}</span>
                    </div>
                    <div className='pop_total_card_single'>
                      <span className='pop_total_list'>Taxes</span>
                      <span className='pop_total_price'>{hotelBookingDetails?.currency} {hotelBookingDetails?.tax}</span>
                    </div>
                    <div className='pop_total_card_single'>
                      <span className='pop_total_list'>Discounts & Adjustments</span>
                      <span className='pop_total_price'> {hotelBookingDetails?.discount_amount}</span>
                    </div>
                    <div className='pop_total_card_single'>
                      <span className='pop_total_list'>Convenience Fee<br /><span className='sm_text'></span></span>
                      <span className='pop_total_price'>{hotelBookingDetails?.currency} {hotelBookingDetails?.convenience_fee}</span>
                    </div>
                    <div className='pop_total_card_single total_price'>
                      <span className='pop_total_list'>Total</span>
                      <span className='pop_total_price'> {hotelBookingDetails?.currency} {hotelBookingDetails?.order_amount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
