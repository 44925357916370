import React, { useEffect, useRef, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Add, ArrowRightAlt } from "@material-ui/icons";
import { Avatar, Grid, TextField } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { components } from "react-select";
import * as Yup from "yup";
import "antd/dist/antd.css";
import TravellerList from "../Traveller/TravellerList";
import { useDispatch, useSelector } from "react-redux";
import TravellerChild from "../Traveller/TravellerChild";
import TravellerInfant from "../Traveller/TravellerInfant";
import flightActions from "../../redux/flight/actions";
import loaderGifImg from "../../asset/blueflightloader.gif";

import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import AsyncSelect from "react-select/async";
import {
  API_IMG_URL,
  API_URL,
  RAZOR_KEY,
  RAZOR_KEY_SECRET,
  CURRENCY,
  TIMEOUT,
} from "../../utils/constant";
import axios from "axios";
import commonActions from "../../redux/common/actions";
function Item(props) {
  const { sx, ...other } = props;
  return <Box {...other} />;
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const style1 = {
  control: (base, state) => ({
    ...base,
    outline: state.isFocused ? "1px solid black" : "1px solid #e8ebf3",
    border: state.isFocused ? "1px solid black" : "1px solid #e8ebf3",
    padding: "3px 0px",
    ":hover": {
      outline: "1px solid black",
      border: "1px solid black",
    },
    ":active": {
      outline: "1px solid black",
      border: "1px solid black",
    },
    ":focus": {
      outline: "1px solid black",
      border: "1px solid black",
    },
    ":focus-visible": {
      outline: "1px solid black",
      border: "1px solid black",
    },
    boxShadow: "none",
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-search cursor-pointer"></i>
    </components.DropdownIndicator>
  );
};

// const validationSchema = Yup.object().shape({
//   bname: localStorage.getItem('tickatrip-token') ? Yup.string().required("Name is required") : Yup.string(),
//   fname: localStorage.getItem('tickatrip-token') ? Yup.string() : Yup.string().required("Name is required"),
//   lname: localStorage.getItem('tickatrip-token') ? Yup.string() : Yup.string().required("Name is required"),
//   bemail: Yup.string().required("Email is required").email("Email is invalid"),
//   bphone: Yup.string()
//     .min(10, "must 10 number")
//     .max(10, "must 10 number")
//     .matches(
//       /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
//       "Phone number is not valid"
//     )
//     .required("phone is required"),
//   bpostelCode: localStorage.getItem('tickatrip-token') ? Yup.string()
//     .min(6, "Enter valid postel")
//     .required("Postel Code is required") : Yup.string(),
//   nationality: localStorage.getItem('tickatrip-token') ? Yup.string() : Yup.object()
//     .required('Nationality is required'),
//   country_code: Yup.object().required('Country Code is required'),
//   gender: Yup.string().required('Gender is required'),
//   dob: Yup.string().required('dob is required'),
//   title: Yup.string().required('Title is required'),
//   passportNumber: Yup.string().required('passport Number is required'),
//   expiryDate: Yup.string().required('Expiry Date is required'),
//   issueCountry: Yup.object().required('Issue Country is required'),
// });

export const BookingModel = (props) => {
  var guestMode = props.guestMode;
  var setGuestMode = props.setGuestMode;
  const { it, item } = props;
  const dispatch = useDispatch();
  const history = useNavigate();
  const couponRef = useRef();
  const {
    tempFlightDetails,
    ticketOrderResponse,
    SearchFlight,
    getFlightCoupon,
  } = useSelector((state) => state.flightReducer);
  const { userProfileData } = useSelector((state) => state.travellerReducer);
  const {
    adultTravellerData,
    childTravellerData,
    infantTravellerData,
    bookingFareSource,
    bookingResponse,
    bookingConfirm,
    fareMethod,
    flightLoader,
  } = useSelector((state) => state.flightReducer);
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [flightRootDetails, setFlightRootDetails] = useState([]);
  // const [expanded, setExpanded] = useState("");
  var [guestMode, setGuestMode] = useState(
    !!localStorage.getItem("tickatrip-token")
  );
  const [paymenterDetails, setPaymenterDetails] = useState([]);
  const [travellerCount, setTravellerCount] = useState();

  const [addTravellerCount, setAddTravellerCount] = useState();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [countAdult, setCountAdult] = useState();
  const [countChild, setCountChild] = useState();
  const [countInfant, setCountInfant] = useState();
  const [paymentFailurError, setPaymentFailurError] = useState([]);
  const [payfailPop, setPayfailPop] = useState(false);
  const [flightTotalFinalPrice, setFlightTotalFinalPrice] = useState();
  const [AppliedCoupon, setAppliedCoupon] = useState([]);
  const [couponDiscount, setCouponDiscount] = useState();
  const [minAgeLimit, setMinAgeLimit] = useState("");
  const [maxAgeLimit, setMaxAgeLimit] = useState("");
  var [adultCopyText, setAdultCopyText] = useState([]);
  const [copyTravellerIcon, setCopyTravellerIcon] = useState(false);
  const [addContactIcon, setAddContactIcon] = useState(false);
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const handleCopyText = (data) => {
    console.log("data", data);
    setAdultCopyText(
      (adultCopyText = [
        {
          name: data.fname + " " + data.lname,
          travellerTitle: data.title,
          travellerFirstName: data.fname,
          travellerLastName: data.lname,
          travellerMail: data.bemail,
          travellerPhone: data.bphone,
          travellerGender: data.gender,
          travellerBirthday: data.dob,
          country_code: data.country_code,
          // travellerGst: data.bgstNo,
          travellerNationality: data.nationality,
          travellerPassportNumber: data.passportNumber,
          travellerExpiryDate: data.expiryDate,
          travellerIssuingCountry: data.issueCountry,
        },
      ])
    );
  };

  useEffect(() => {
    parseInt(SearchFlight?.adult_flight);
  }, [handleCopyText]);

  useEffect(() => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear() - 14;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    setMaxAgeLimit(today);
    setMinAgeLimit(null);
  }, []);

  const loadCountryOptions = async (inputValue, callback) => {
    if (inputValue.trim().length > 0) {
      var form_data = new FormData();
      form_data.append("name", inputValue.trim());
      const result = await axios.post(`${API_URL}/countries`, form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      callback(result.data.message);
    } else {
      callback(null);
    }
  };

  const OnPayment = async (data) => {
    console.log("control data", data);
    // console.log(JSON.stringify(childTravellerData));
    // console.log(JSON.stringify(childTravellerData
    //   ?.map((val) => val.travellerNationality.country_code)));
    // console.log(childTravellerData
    //   ?.map((val) => val.travellerNationality.country_code)
    //   .reduce((total, value, index) => {
    //     return index === 0 ? value : total + "<br>" + value;
    //   }));

    let bookingData = {
      name: localStorage.getItem("tickatrip-token") ? data?.bname : null,
      // name: data?.bname,
      area_code: data.area_code ? data.area_code : 758,
      isRefundable: it?.IsRefundable,
      nationality: data.nationality,
      country_code: data.country_code.country_code,
      first_name: adultTravellerData
        ?.map((val) => val.travellerFirstName)
        .reduce((total, value, index) => {
          return index === 0 ? value : total + "<br>" + value;
        }),
      last_name: adultTravellerData
        ?.map((val) => val.travellerLastName)
        .reduce((total, value, index) => {
          return index === 0 ? value : total + "<br>" + value;
        }),
      email_id: data.bemail,
      mobile_no: data.bphone,
      dob: adultTravellerData
        ?.map((val) => val.travellerBirthday + "T00:00:00")
        .reduce((total, value, index) => {
          return index === 0 ? value : total + "<br>" + value;
        }),
      gender: adultTravellerData
        ?.map((val) => val.travellerGender)
        .reduce((total, value, index) => {
          return index === 0 ? value : total + "<br>" + value;
        }),
      type: bookingFareSource.fare_type,
      IsPassportMandatory: fareMethod?.IsPassportMandatory,
      adult_flight: countAdult,
      child_flight: countChild,
      infant_flight: countInfant,
      fare_source_code: bookingFareSource.fare_source_code,
      totalFare: fareMethod?.BaseFareAmount,
      taxes: fareMethod?.TotalTaxAmount,
      couponDiscount: parseFloat(couponDiscount ?? "0").toFixed(0),
      totalAmount: flightTotalFinalPrice,
      currency: fareMethod?.CurrencyCode,
      // amount: price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 100 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 100,
      PostCode: localStorage.getItem("tickatrip-token")
        ? data.bpostelCode
        : null,
    };
    // if (!localStorage.getItem('tickatrip-token')) {
    //   delete bookingData.customerEmail
    //   delete bookingData.customerPhone
    // } else {

    // }

    bookingData["title"] = adultTravellerData
      ?.map((val) => val.travellerTitle)
      .reduce((total, value, index) => {
        return index === 0 ? value : total + "<br>" + value;
      });
    if (fareMethod?.IsPassportMandatory) {
      bookingData["issue_country"] = adultTravellerData
        ?.map((val) => val.travellerIssuingCountry.country_code)
        .reduce((total, value, index) => {
          return index === 0 ? value : total + "<br>" + value;
        });
    }
    bookingData["nationality"] = adultTravellerData
      ?.map((val) => val.travellerNationality.country_code)
      .reduce((total, value, index) => {
        return index === 0 ? value : total + "<br>" + value;
      });
    if (fareMethod?.IsPassportMandatory) {
      bookingData["passport_no"] = adultTravellerData
        ?.map((val) => val.travellerPassportNumber)
        .reduce((total, value, index) => {
          return index === 0 ? value : total + "<br>" + value;
        });
      bookingData["passport_expiry"] = adultTravellerData
        ?.map((val) => val.travellerExpiryDate)
        .reduce((total, value, index) => {
          return index === 0 ? value : total + "<br>" + value;
        });
    }
    if (countChild > 0) {
      bookingData["child_dob"] =
        childTravellerData?.length === 0
          ? ""
          : childTravellerData
              ?.map((val) => val.travellerBirthday + "T00:00:00")
              // ?.map((val) => (moment(val.travellerBirthday).format('YYYY-MM-DD')).split('+')[0])
              .reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      bookingData["child_gender"] =
        childTravellerData?.length === 0
          ? ""
          : childTravellerData
              ?.map((val) => val.travellerGender)
              ?.reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      bookingData["child_first_name"] =
        childTravellerData?.length === 0
          ? ""
          : childTravellerData
              ?.map((val) => val.travellerFirstName)
              ?.reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      bookingData["child_last_name"] =
        childTravellerData?.length === 0
          ? ""
          : childTravellerData
              ?.map((val) => val.travellerLastName)
              ?.reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });

      bookingData["child_title"] =
        childTravellerData?.length === 0
          ? ""
          : childTravellerData
              ?.map((val) => val.travellerTitle)
              ?.reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      if (fareMethod?.IsPassportMandatory) {
        // console.log(childTravellerData
        //   ?.map((val) => val?.travellerIssuingCountry?.country_code)
        //   .reduce((total, value, index) => {
        //     return index === 0 ? value : total + "<br>" + value;
        //   }));
        bookingData["child_issue_country"] =
          childTravellerData?.length === 0
            ? ""
            : childTravellerData
                ?.map((val) => val?.travellerIssuingCountry?.country_code)
                .reduce((total, value, index) => {
                  return index === 0 ? value : total + "<br>" + value;
                });
      }
      if (fareMethod?.IsPassportMandatory) {
        bookingData["child_passport_expiry_date"] =
          childTravellerData?.length === 0
            ? ""
            : childTravellerData
                ?.map((val) => val.travellerExpiryDate)
                ?.reduce((total, value, index) => {
                  return index === 0 ? value : total + "<br>" + value;
                });
        bookingData["child_passport_no"] =
          childTravellerData?.length === 0
            ? ""
            : childTravellerData
                ?.map((val) => val.travellerPassportNumber)
                ?.reduce((total, value, index) => {
                  return index === 0 ? value : total + "<br>" + value;
                });
      }
    }
    if (countInfant > 0) {
      bookingData["infant_dob"] =
        infantTravellerData?.length === 0
          ? ""
          : infantTravellerData
              ?.map((val) => val.travellerBirthday + "T00:00:00")
              // ?.map((val) => (moment(val.travellerBirthday).format('YYYY-MM-DD')).split('+')[0])
              .reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      bookingData["infant_gender"] =
        infantTravellerData?.length === 0
          ? ""
          : infantTravellerData
              ?.map((val) => val.travellerGender)
              ?.reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      bookingData["infant_first_name"] =
        infantTravellerData?.length === 0
          ? ""
          : infantTravellerData
              ?.map((val) => val.travellerFirstName)
              ?.reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      bookingData["infant_last_name"] =
        infantTravellerData?.length === 0
          ? ""
          : infantTravellerData
              ?.map((val) => val.travellerLastName)
              ?.reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      bookingData["infant_title"] =
        infantTravellerData?.length === 0
          ? ""
          : infantTravellerData
              ?.map((val) => val.travellerTitle)
              ?.reduce((total, value, index) => {
                return index === 0 ? value : total + "<br>" + value;
              });
      if (fareMethod?.IsPassportMandatory) {
        bookingData["infant_issue_country"] =
          infantTravellerData?.length === 0
            ? ""
            : infantTravellerData
                ?.map((val) => val?.travellerIssuingCountry?.country_code)
                .reduce((total, value, index) => {
                  return index === 0 ? value : total + "<br>" + value;
                });
      }
      if (fareMethod?.IsPassportMandatory) {
        bookingData["infant_passport_expiry_date"] =
          infantTravellerData?.length === 0
            ? ""
            : infantTravellerData
                ?.map((val) => val.travellerExpiryDate)
                ?.reduce((total, value, index) => {
                  return index === 0 ? value : total + "<br>" + value;
                });
        bookingData["infant_passport_no"] =
          infantTravellerData?.length === 0
            ? ""
            : infantTravellerData
                ?.map((val) => val.travellerPassportNumber)
                ?.reduce((total, value, index) => {
                  return index === 0 ? value : total + "<br>" + value;
                });
      }
    }
    dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: true });
    setPaymenterDetails(data);

    if (bookingFareSource?.fare_type === "WebFare") {
      var form_data = new FormData();
      form_data.append("fare_source_code", bookingFareSource.fare_source_code);
      await axios
        .post(`${API_URL}/revalidate`, form_data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((el) => {
          dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
          if (el?.status === 204) {
            localStorage.setItem("searchValues", JSON.stringify(SearchFlight));
            dispatch({ type: flightActions.RESET_INITIAL_STATE });
            dispatch({
              type: flightActions.FLIGHT_SESSION_RELOAD,
              payload: true,
            });
          }
          if (el?.data?.message?.IsValid) {
            if (!el.data.message?.TotalFareAmount) {
              alert("enter the amount");
            } else {
              // setCouponDiscount(parseFloat(couponTotalDiscount).toFixed(2))
              // setFlightTotalFinalPrice(parseFloat(flightTotalFinalPrice * (1 - couponDiscountPrice / 100)).toFixed(2));
              let price = el.data.message?.TotalFareAmount;
              if (!couponDiscount) {
                price = price?.toString().split("").includes(".")
                  ? Math.floor(price?.toString().split(".")[0]) * 100 +
                    parseFloat(price?.toString().split(".")[1])
                  : parseFloat(price) * 100;
              }
              if (couponDiscount) {
                price =
                  parseFloat(
                    parseFloat(price * (1 - couponDiscount / 100)).toFixed(2)
                  ) * 100;
              }
              // dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: true });
              // var options = {
              //   key: RAZOR_KEY,
              //   key_secret: RAZOR_KEY_SECRET,
              //   amount: price,
              //   // amount: parseFloat(parseFloat(el.data.message?.TotalFareAmount ? el.data.message?.TotalFareAmount : 0) * 100),
              //   currency: CURRENCY,
              //   name: paymenterDetails.bname,
              //   description: "Payment Tick A Trip",
              //   timeout: TIMEOUT,
              //   handler: function (response) {
              //     if (response.razorpay_payment_id) {
              //       console.log(response);
              //       console.log(response.razorpay_payment_id);
              //       console.log(response.razorpay_order_id);
              //       console.log(response.razorpay_signature);
              //       console.log(response.razorpay_status);
              //       bookingData['paymentTransactionId'] = response.razorpay_payment_id;
              //       bookingData['TotalFare'] = price / 100;
              //       if (!!couponDiscount) {
              //         bookingData['couponDiscount'] = couponDiscount;
              //       }
              //       dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: true });
              //       dispatch({
              //         type: flightActions.GET_BOOKIG_TRAVELLER_DATA,
              //         history: history,
              //         payload: bookingData
              //       });
              //     }
              //   },
              //   prefill: {
              //     name: paymenterDetails.bphone,
              //     email: paymenterDetails.bemail,
              //     conatct: paymenterDetails.bphone,
              //   },
              //   notes: {
              //     address: "",
              //   },
              //   theme: {
              //     color: "#0543e9",
              //   },
              // };
              dispatch({
                type: flightActions.GET_FLIGHT_BOOKING,
                history: history,
                payload: bookingData,
              });
              // dispatch({ type: flightActions.GET_FLIGHT_BOOKING, payload: bookingData });
            }
          } else {
            dispatch({
              type: commonActions.SET_COMMON_TOASTER_ALERT,
              payload: {
                open: true,
                message:
                  "Validation failed, search again or try other flights!",
                status: "failed",
              },
            });
          }
        })
        .catch((el) => {
          if (el?.status === 204) {
            localStorage.setItem("searchValues", JSON.stringify(SearchFlight));
            dispatch({ type: flightActions.RESET_INITIAL_STATE });
            dispatch({
              type: flightActions.FLIGHT_SESSION_RELOAD,
              payload: true,
            });
          }
          dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
        });
    } else {
      var form_data1 = new FormData();
      form_data1.append("fare_source_code", bookingFareSource.fare_source_code);
      await axios
        .post(`${API_URL}/revalidate`, form_data1, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((el) => {
          dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
          if (el?.status === 204) {
            localStorage.setItem("searchValues", JSON.stringify(SearchFlight));
            dispatch({ type: flightActions.RESET_INITIAL_STATE });
            dispatch({
              type: flightActions.FLIGHT_SESSION_RELOAD,
              payload: true,
            });
          }
          if (el?.data?.message?.IsValid) {
            let price = el.data.message?.TotalFareAmount;
            if (!couponDiscount) {
              price = price?.toString().split("").includes(".")
                ? Math.floor(price?.toString().split(".")[0]) * 100 +
                  parseFloat(price?.toString().split(".")[1])
                : parseFloat(price) * 100;
            }
            if (couponDiscount) {
              price =
                parseFloat(
                  parseFloat(price * (1 - couponDiscount / 100)).toFixed(2)
                ) * 100;
            }
            bookingData["TotalFare"] = price / 100;
            if (!!couponDiscount) {
              bookingData["couponDiscount"] = couponDiscount;
            }
            dispatch({
              type: flightActions.GET_FLIGHT_BOOKING,
              payload: bookingData,
            });
          } else {
            dispatch({
              type: commonActions.SET_COMMON_TOASTER_ALERT,
              payload: {
                open: true,
                message:
                  "Validation failed, search again or try other flights!",
                status: "failed",
              },
            });
          }
        })
        .catch((el) => {
          if (el?.status === 204) {
            localStorage.setItem("searchValues", JSON.stringify(SearchFlight));
            dispatch({ type: flightActions.RESET_INITIAL_STATE });
            dispatch({
              type: flightActions.FLIGHT_SESSION_RELOAD,
              payload: true,
            });
          }
          dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
        });
    }

    // if (bookingFareSource?.fare_type === "WebFare") {
    //   var form_data = new FormData();
    //   form_data.append('fare_source_code', bookingFareSource.fare_source_code);
    //   await axios.post(`${API_URL}/revalidate`,
    //     form_data, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }).then(el => {
    //     dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
    //     if (el?.status === 204) {
    //       localStorage.setItem('searchValues', JSON.stringify(SearchFlight));
    //       dispatch({ type: flightActions.RESET_INITIAL_STATE });
    //       dispatch({ type: flightActions.FLIGHT_SESSION_RELOAD, payload: true });
    //     }
    //     if (el?.data?.message?.IsValid) {
    //       if (!el.data.message?.TotalFareAmount) {
    //         alert("enter the amount");
    //       } else {
    //         // setCouponDiscount(parseFloat(couponTotalDiscount).toFixed(2))
    //         // setFlightTotalFinalPrice(parseFloat(flightTotalFinalPrice * (1 - couponDiscountPrice / 100)).toFixed(2));
    //         let price = el.data.message?.TotalFareAmount;
    //         if (!couponDiscount) {
    //           price = price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 100 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 100;
    //         }
    //         if (couponDiscount) {
    //           price = parseFloat(parseFloat(price * (1 - (couponDiscount / 100))).toFixed(2)) * 100;
    //         }
    //         // dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: true });
    //         var options = {
    //           key: RAZOR_KEY,
    //           key_secret: RAZOR_KEY_SECRET,
    //           amount: price,
    //           // amount: parseFloat(parseFloat(el.data.message?.TotalFareAmount ? el.data.message?.TotalFareAmount : 0) * 100),
    //           currency: CURRENCY,
    //           name: paymenterDetails.bname,
    //           description: "Payment Tick A Trip",
    //           timeout: TIMEOUT,
    //           handler: function (response) {
    //             if (response.razorpay_payment_id) {
    //               console.log(response);
    //               console.log(response.razorpay_payment_id);
    //               console.log(response.razorpay_order_id);
    //               console.log(response.razorpay_signature);
    //               console.log(response.razorpay_status);
    //               bookingData['paymentTransactionId'] = response.razorpay_payment_id;
    //               bookingData['TotalFare'] = price / 100;
    //               if (!!couponDiscount) {
    //                 bookingData['couponDiscount'] = couponDiscount;
    //               }
    //               dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: true });
    //               dispatch({
    //                 type: flightActions.GET_BOOKIG_TRAVELLER_DATA,
    //                 history: history,
    //                 payload: bookingData
    //               });
    //             }
    //           },
    //           prefill: {
    //             name: paymenterDetails.bphone,
    //             email: paymenterDetails.bemail,
    //             conatct: paymenterDetails.bphone,
    //           },
    //           notes: {
    //             address: "",
    //           },
    //           theme: {
    //             color: "#0543e9",
    //           },
    //         };
    //         var pay = new window.Razorpay(options);
    //         pay.on("payment.failed", function (response) {
    //           setPaymentFailurError(response);
    //         });
    //         pay.open();
    //       }
    //     } else {
    //       dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Validation failed, search again or try other flights!", status: "failed" } });
    //     }
    //   }).catch(el => {
    //     if (el?.status === 204) {
    //       localStorage.setItem('searchValues', JSON.stringify(SearchFlight));
    //       dispatch({ type: flightActions.RESET_INITIAL_STATE });
    //       dispatch({ type: flightActions.FLIGHT_SESSION_RELOAD, payload: true });
    //     }
    //     dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
    //   });
    // } else {
    //   var form_data1 = new FormData();
    //   form_data1.append('fare_source_code', bookingFareSource.fare_source_code);
    //   await axios.post(`${API_URL}/revalidate`,
    //     form_data1, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }).then(el => {
    //     dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
    //     if (el?.status === 204) {
    //       localStorage.setItem('searchValues', JSON.stringify(SearchFlight));
    //       dispatch({ type: flightActions.RESET_INITIAL_STATE });
    //       dispatch({ type: flightActions.FLIGHT_SESSION_RELOAD, payload: true });
    //     }
    //     if (el?.data?.message?.IsValid) {
    //       let price = el.data.message?.TotalFareAmount;
    //       if (!couponDiscount) {
    //         price = price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 100 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 100;
    //       }
    //       if (couponDiscount) {
    //         price = parseFloat(parseFloat(price * (1 - (couponDiscount / 100))).toFixed(2)) * 100;
    //       }
    //       bookingData['TotalFare'] = price / 100;
    //       if (!!couponDiscount) {
    //         bookingData['couponDiscount'] = couponDiscount;
    //       }
    //       dispatch({
    //         type: flightActions.GET_BOOKIG_TRAVELLER_DATA,
    //         history: history,
    //         payload: bookingData
    //       });
    //     } else {
    //       dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Validation failed, search again or try other flights!", status: "failed" } });
    //     }
    //   }).catch(el => {
    //     if (el?.status === 204) {
    //       localStorage.setItem('searchValues', JSON.stringify(SearchFlight));
    //       dispatch({ type: flightActions.RESET_INITIAL_STATE });
    //       dispatch({ type: flightActions.FLIGHT_SESSION_RELOAD, payload: true });
    //     }
    //     dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
    //   });
    // }
  };

  useEffect(() => {
    setGuestMode(!!localStorage?.getItem("tickatrip-token"));
  }, [item, fareMethod]);

  useEffect(() => {
    if (paymentFailurError?.error?.code !== undefined) {
      setPayfailPop(true);
    }
  }, [paymentFailurError]);

  useEffect(() => {
    if (bookingConfirm && fareMethod) {
      if (!fareMethod?.TotalFareAmount) {
        alert("enter the amount");
      } else {
        dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
        let price = fareMethod?.TotalFareAmount;
        if (!couponDiscount) {
          price = price?.toString().split("").includes(".")
            ? Math.floor(price?.toString().split(".")[0]) * 100 +
              parseFloat(price?.toString().split(".")[1])
            : parseFloat(price) * 100;
        }
        if (couponDiscount) {
          price =
            parseFloat(
              parseFloat(price * (1 - couponDiscount / 100)).toFixed(2)
            ) * 100;
        }
        var options = {
          // key: RAZOR_KEY,
          // key_secret: RAZOR_KEY_SECRET,
          // amount: parseFloat(parseFloat(fareMethod?.TotalFareAmount ? fareMethod?.TotalFareAmount : 0) * 100),
          amount: price,
          currency: CURRENCY,
          name: paymenterDetails.bname,
          description: "Payment Tick A Trip",
          timeout: TIMEOUT,
          handler: function (response) {
            // if (response.razorpay_payment_id) {
            //   console.log(response.razorpay_payment_id);
            //   console.log(response.razorpay_order_id);
            //   console.log(response.razorpay_signature);
            //   console.log(response.razorpay_status);
            //   dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: true });
            //   setPaymentSuccess(true);
            //   let tempData = {
            //   };
            //   let bookigResponseField = {
            //     UniqueID: bookingResponse.UniqueID,
            //     Target: bookingResponse.Target,
            //   };
            //   let fare = {
            //     TotalFare: price / 100
            //   }
            //   if (!!couponDiscount) {
            //     fare['couponDiscount'] = couponDiscount;
            //   }
            //   dispatch({
            //     type: flightActions.GET_TICKET_ORDER_DATA,
            //     payload: {
            //       ...bookigResponseField,
            //       ...tempData,
            //       ...fare,
            //       paymentTransactionId: response.razorpay_payment_id,
            //       phoneNumber: paymenterDetails.bphone,
            //       email: paymenterDetails.bemail,
            //     },
            //   });
            // }
          },
          prefill: {
            name: paymenterDetails.bname,
            email: paymenterDetails.bemail,
            conatct: paymenterDetails.bphone,
          },
          notes: {
            address: "",
          },
          theme: {
            color: "#0543e9",
          },
        };
        // var pay = new window.Razorpay(options);
        // pay.on("payment.failed", function (response) {
        //   setPaymentFailurError(response);
        // });
        // pay.open();
      }
    }
  }, [bookingResponse, bookingConfirm, fareMethod]);

  useEffect(() => {
    if (tempFlightDetails?.adult_flight === 0) {
      setCountAdult(0);
    } else {
      setCountAdult(tempFlightDetails?.adult_flight.toString());
    }

    if (tempFlightDetails?.child_flight === 0) {
      setCountChild(0);
    } else {
      setCountChild(tempFlightDetails?.child_flight);
    }

    if (tempFlightDetails?.infant_flight === 0) {
      setCountInfant(0);
    } else {
      setCountInfant(tempFlightDetails?.infant_flight);
    }
  }, [tempFlightDetails]);

  // useEffect(() => {
  //   if (errors) {
  //     setExpanded("panel1");
  //   }
  // }, [errors]);

  React.useEffect(() => {
    return () => {
      setFlightRootDetails([]);
      // setExpanded("panel3");
      setGuestMode(!!localStorage.getItem("tickatrip-token"));
      setPaymenterDetails([]);
      setTravellerCount();

      setAddTravellerCount();
      setPaymentSuccess(false);
      setCountAdult();
      setCountChild();
      setCountInfant();
      setPaymentFailurError([]);
      setPayfailPop(false);
    };
  }, []);

  useEffect(() => {
    setTravellerCount(
      adultTravellerData.length +
        childTravellerData.length +
        infantTravellerData.length
    );
    setAddTravellerCount(
      parseInt(tempFlightDetails.adult_flight) +
        parseInt(tempFlightDetails.child_flight) +
        parseInt(tempFlightDetails.infant_flight)
    );
  }, [
    tempFlightDetails,
    adultTravellerData,
    childTravellerData,
    infantTravellerData,
  ]);

  //
  useEffect(() => {
    if (paymentSuccess === true) {
      dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
      dispatch({
        type: flightActions.GET_TRIP_DETAILS,
        payload: {
          UniqueID: ticketOrderResponse.UniqueID,
          target: ticketOrderResponse.Target,
        },
        history: history,
      });
      dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
    }
  }, [bookingResponse, ticketOrderResponse]);

  useEffect(() => {
    if (travellerCount === addTravellerCount) {
      // setExpanded("panel3");
    }
  }, [travellerCount, addTravellerCount]);

  useEffect(() => {
    if (it) {
      setFlightRootDetails(it.flight_details);
    }
  }, [it]);

  useEffect(() => {
    if (userProfileData && !localStorage.getItem("tickatrip-token")) {
      reset({
        // bname: userProfileData?.name,
        bemail: userProfileData?.email,
        bphone: userProfileData?.phone,
      });
    }
  }, [userProfileData]);

  useEffect(() => {
    // if (localStorage.getItem('tickatrip-token')) {
    //   dispatch({ type: flightActions.GET_FLIGHT_COUPON, payload: [] });
    // }
    dispatch({ type: flightActions.GET_FLIGHT_COUPON, payload: [] });
  }, []);

  const ApplyCoupon = async (val) =>
    await axios
      .get(`${API_URL}/flight-coupon/${val}`)
      .then((el) => {
        dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: true });
        if (el.data.status) {
          let couponDiscountPrice = el.data.message.coupon.coupon_discount;
          let couponTotalDiscount = parseFloat(
            flightTotalFinalPrice * (couponDiscountPrice / 100)
          ).toFixed(2);

          if (flightTotalFinalPrice <= couponTotalDiscount) {
            setCouponDiscount("");
            dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
            dispatch({
              type: commonActions.SET_COMMON_TOASTER_ALERT,
              payload: {
                open: true,
                message: "Coupon not found",
                status: "failed",
              },
            });
          } else {
            setAppliedCoupon([el.data.message.coupon]);
            setCouponDiscount(parseFloat(couponTotalDiscount).toFixed(2));
            setFlightTotalFinalPrice(
              parseFloat(
                flightTotalFinalPrice * (1 - couponDiscountPrice / 100)
              ).toFixed(2)
            );
            dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
            dispatch({
              type: commonActions.SET_COMMON_TOASTER_ALERT,
              payload: {
                open: true,
                message: "Coupon code copy successfully",
                status: "failed",
              },
            });
          }
        } else {
          // setCouponDiscount('')
          dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
          // dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Coupon not found', status: "failed" } });
        }
      })
      .catch((el) => {
        setCouponDiscount("");
        dispatch({ type: flightActions.SET_FLIGHT_LOADER, payload: false });
        dispatch({
          type: commonActions.SET_COMMON_TOASTER_ALERT,
          payload: {
            open: true,
            message: "Coupon request failed",
            status: "failed",
          },
        });
      });
  useEffect(() => {
    if (fareMethod?.TotalFareAmount) {
      setFlightTotalFinalPrice(parseFloat(fareMethod?.TotalFareAmount));
    }
  }, [fareMethod]);

  const onRemoveCoupon = (e, val) => {
    e.preventDefault();
    setAppliedCoupon([]);
    setCouponDiscount("");
    setFlightTotalFinalPrice(parseFloat(fareMethod?.TotalFareAmount));
  };
  // console.log('tempFlightDetails...',tempFlightDetails)
  // console.log('fareMethod...',)
  return (
    <div className="dasdfsdgf">
      {payfailPop ? (
        <div className="dialoque_model fail_model">
          <div className="blur_bg"></div>
          <div className="modal" tabindex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Tick a Trip says your payment has been failed
                  </h5>
                </div>
                <div className="modal-body">
                  <p>
                    <span className="bld_txt">Status : </span>
                    {paymentFailurError?.error?.description}
                  </p>
                  <p>
                    <span className="bld_txt">Error : </span>
                    {paymentFailurError?.error?.code}
                  </p>
                  <p>
                    <span className="bld_txt">Payment Id : </span>
                    {paymentFailurError?.error?.metadata?.payment_id}
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setPayfailPop(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Box className="confirm_ticket_pop">
        {flightLoader ? (
          <div>
            <div className="blur_bg"></div>
            <div className="loaderGif">
              <img src={loaderGifImg} alt="" />
            </div>
          </div>

          
        ) : null}
        <Box className="comlete_head">
          {/* {!guestMode && (
            <div className="guest_or_login_check">
              <div className="blur_bg"></div>
              <div className="continue_guest">
                <ul>
                  <li>
                    <button
                      className="book_tick pop_con"
                      onClick={() => setGuestMode(!guestMode)}
                    >
                      Continue as Guest
                    </button>
                  </li>
                  <li>OR</li>
                  <li>
                    <Link className="book_login" to="/login">
                      Login
                    </Link>
                  </li>
                </ul>{" "}
              </div>
            </div>
          )} */}

          <h5>
            <Box className="select_flight">
              <div className="MuiTableCell-root MuiTableCell-sizeMedium makeStyles-column-99">
                <Avatar
                  src={API_IMG_URL + "/server/flightimage/" + it.flightUrl}
                  style={{
                    width: "50px",
                    marginRight: "0.5rem",
                    height: "50px",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                />
                <span
                  className="flightname"
                  style={{
                    color: "#3d3d3d",
                    cursor: "pointer",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  {it.flightName}
                </span>
              </div>
            </Box>
          </h5>
          {/* <ul>
            <li>{tempFlightDetails.airport_from.city}</li>
            <li>
              <ArrowRightAlt />
            </li>
            <li>{tempFlightDetails.airport_to.city}</li>
          </ul> */}
        </Box>
        <Box className="flight_pop_contentent">
          <Grid className="row">
            <Grid className="col-lg-7 col-md-7 col-sm-12 col-12">
              <Box className="flight_name_pop">
                <Box className="history-tl-container">
                  {flightRootDetails.map((val, index) => (
                    <div
                      className="flight_trip_root"
                      key={"flightRootDetails" + index}
                    >
                      <span className="totel_stops">
                        Total Stops{" "}
                        <span className="stop_qty">{val.totalStops}</span>
                      </span>
                      <div className="flight_roots">
                        {val.flights.map((flight, index) => (
                          <ul
                            className="tl"
                            key={"flightRootDetails.flights" + index}
                          >
                            <li
                              className="tl-item"
                              ng-repeat="item in retailer_history"
                            >
                              <Box className="item-detail">
                                <span className="flightTicketDate">
                                  {moment(
                                    flight.flightList.DepartureDateTime
                                  )?.format("YYYY-MM-DD")}
                                </span>
                              </Box>
                              <Box className="item-title">
                                <span className="flight_timr">
                                  {moment(flight.flightList.DepartureDateTime)
                                    ?.format("HH:mm:ss")
                                    .substring(0, 5)}
                                </span>
                                <span className="flight_when">
                                  {
                                    flight.flightList
                                      .DepartureAirportLocationCode
                                  }
                                </span>
                              </Box>
                              <Box className="item-detail">
                                {flight.departureLocation}
                              </Box>
                            </li>
                            <li className="travel_hours">
                              <span>{flight.flightList.JourneyDuration} M</span>
                            </li>
                            <li
                              className="tl-item"
                              ng-repeat="item in retailer_history"
                            >
                              <Box className="item-detail">
                                <span className="flightTicketDate">
                                  {moment(
                                    flight.flightList.ArrivalDateTime
                                  )?.format("YYYY-MM-DD")}
                                </span>
                              </Box>
                              <Box className="item-title">
                                <span className="flight_timr">
                                  {moment(flight.flightList.ArrivalDateTime)
                                    ?.format("HH:mm:ss")
                                    .substring(0, 5)}
                                </span>
                                <span className="flight_when">
                                  {flight.flightList.ArrivalAirportLocationCode}
                                </span>
                              </Box>
                              <Box className="item-detail">
                                {flight.arrivalLocation}
                              </Box>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  ))}
                </Box>
              </Box>

              <Box className="flight_pop_accourdian">
                <div className="traveller_list_drop">
                  <div className="list_dropdown_title">
                    <h4>
                      <span className="booking_step">1</span> Add your contact
                      details
                    </h4>
                    <div
                      className={
                        addContactIcon === false
                          ? "down-icons-list upIcon"
                          : "down-icons-list"
                      }
                      onClick={() => setAddContactIcon(!addContactIcon)}
                    >
                      <ExpandMoreIcon />
                    </div>
                  </div>
                  {addContactIcon === false ? (
                    <div>
                      <Box className="payment_add_conatct_address">
                        <form>
                          {localStorage.getItem("tickatrip-token") ? (
                            <Grid className="row">
                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>Name</label>
                                  <input
                                    name="bname"
                                    type="text"
                                    {...register("bname", { required: true })}
                                    className={`form-control ${
                                      errors.bname ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.bname?.message}
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>Email</label>
                                  <input
                                    name="bemail"
                                    type="text"
                                    {...register("bemail", { required: true })}
                                    className={`form-control ${
                                      errors.bemail ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.bemail?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>Phone</label>
                                  <input
                                    onKeyDown={(e) =>
                                      ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    name="bphone"
                                    type="number"
                                    min="0"
                                    max="10"
                                    {...register("bphone", { required: true })}
                                    className={`form-control ${
                                      errors.bphone ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.bphone?.message}
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>Gst Nonstop (optional)</label>
                                  <input
                                    name="bgstNo"
                                    type="text"
                                    {...register("bgstNo")}
                                    className={`form-control ${
                                      errors.bgstNo ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.bgstNo?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              {fareMethod?.RequiredFieldsToBook?.length > 0 &&
                                fareMethod?.RequiredFieldsToBook?.find(
                                  (el) => el === "ContactNumber"
                                ) && (
                                  <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <Box className="form-group">
                                      <label>Phone Code(optional)</label>
                                      <input
                                        name="area_code"
                                        type="number"
                                        {...register("area_code")}
                                        className={`form-control ${
                                          errors.area_code ? "is-invalid" : ""
                                        }`}
                                      />
                                      <Box className="invalid-feedback">
                                        {errors.area_code?.message}
                                      </Box>
                                    </Box>
                                  </Grid>
                                )}

                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>Postel Code</label>
                                  <input
                                    name="bpostelCode"
                                    {...register("bpostelCode", {
                                      required: true,
                                    })}
                                    type="text"
                                    className={`form-control ${
                                      errors.bpostelCode ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.bpostelCode?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <Box className="form-group">
                                  <label>Country Code</label>
                                  <div
                                    className={`${
                                      errors.country_code?.message &&
                                      "required-Field"
                                    }`}
                                  >
                                    <Controller
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Select Your Country Code",
                                        },
                                      }}
                                      control={control}
                                      render={({ field }) => {
                                        const handleSelectChange = (
                                          selectedOption
                                        ) => {
                                          field.onChange({
                                            dial_code:
                                              selectedOption?.dial_code,
                                            name: selectedOption?.name,
                                            country_code:
                                              selectedOption?.country_code,
                                            id: selectedOption?.id,
                                          });
                                        };
                                        return (
                                          <AsyncSelect
                                            {...field}
                                            className={`w-100 ${
                                              errors.country_code?.message &&
                                              "is-invalid"
                                            }`}
                                            inputRef={field.ref}
                                            styles={style1}
                                            defaultOptions={field.option}
                                            components={{ DropdownIndicator }}
                                            {...register("country_code")}
                                            name={"country_code"}
                                            getOptionLabel={(e) =>
                                              e.dial_code + "-" + e.name
                                            }
                                            getOptionValue={(e) =>
                                              e.dial_code + "-" + e.name
                                            }
                                            loadOptions={loadCountryOptions}
                                            onChange={handleSelectChange}
                                          />
                                        );
                                      }}
                                      name="country_code"
                                    />
                                  </div>
                                  <Box className="invalid-feedback">
                                    {errors.country_code?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              {/* <Grid item xs={6} lg={12}>
                              <Box className="form-group ">
                                <label>Address</label>
                                <TextareaAutosize
                                  minRows={3}
                                  {...register("baddress")}
                                  name="baddress"
                                  className={`form-control ${errors.baddress ? "is-invalid" : ""
                                    }`}
                                />
                                <Box className="invalid-feedback">
                                  {errors.baddress?.message}
                                </Box>
                              </Box>
                            </Grid> */}
                            </Grid>
                          ) : (
                            <Grid className="row">
                              <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <Box className="form-group">
                                  <label>Title</label>
                                  <select
                                    name="title"
                                    {...register("title", { required: true })}
                                    className={`form-control ${
                                      errors.title ? "is-invalid" : ""
                                    }`}
                                  >
                                    <option value="">Choose Title</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Mrs">Mrs</option>
                                    {/* <option value="Lord">Lord</option>
                                  <option value="Lady">Lady</option> */}
                                    <option value="Inf">Inf</option>
                                  </select>
                                  <Box className="invalid-feedback">
                                    {errors.title?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>First Name</label>
                                  <input
                                    name="fname"
                                    type="text"
                                    {...register("fname", { required: true })}
                                    className={`form-control ${
                                      errors.fname ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.fname?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>Last Name</label>
                                  <input
                                    name="lname"
                                    type="text"
                                    {...register("lname", { required: true })}
                                    className={`form-control ${
                                      errors.lname ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.lname?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>Email</label>
                                  <input
                                    name="bemail"
                                    type="text"
                                    {...register("bemail", { required: true })}
                                    className={`form-control ${
                                      errors.bemail ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.bemail?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <Box className="form-group">
                                  <label>Phone</label>
                                  <input
                                    onKeyDown={(e) =>
                                      ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    name="bphone"
                                    type="number"
                                    min="0"
                                    max="10"
                                    {...register("bphone", { required: true })}
                                    className={`form-control ${
                                      errors.bphone ? "is-invalid" : ""
                                    }`}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.bphone?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <Box className="form-group">
                                  <label>Gender</label>
                                  <select
                                    name="gender"
                                    {...register("gender", { required: true })}
                                    className={`form-control ${
                                      errors.gender ? "is-invalid" : ""
                                    }`}
                                  >
                                    <option value=""></option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                  </select>
                                  <Box className="invalid-feedback">
                                    {errors.gender?.message}
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <Box className="form-group">
                                  <label>Date of Birth</label>
                                  <input
                                    name="dob"
                                    type="date"
                                    {...register("dob", { required: true })}
                                    className={`form-control ${
                                      errors.dob ? "is-invalid" : ""
                                    }`}
                                    min={minAgeLimit}
                                    max={maxAgeLimit}
                                  />
                                  <Box className="invalid-feedback">
                                    {errors.dob?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              {fareMethod?.IsPassportMandatory && (
                                <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <Box className="form-group">
                                    <label>Passport Number</label>
                                    <input
                                      name="passportNumber"
                                      type="text"
                                      {...register("passportNumber", {
                                        required: true,
                                      })}
                                      className={`form-control ${
                                        errors.passportNumber
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <Box className="invalid-feedback">
                                      {errors.passportNumber?.message}
                                    </Box>
                                  </Box>
                                </Grid>
                              )}
                              {fareMethod?.IsPassportMandatory && (
                                <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <Box className="form-group">
                                    <label>Expiry Date</label>
                                    <input
                                      name="expiryDate"
                                      type="date"
                                      {...register("expiryDate", {
                                        required: true,
                                      })}
                                      min={moment(new Date())
                                        .add(1, "month")
                                        .format("YYYY-MM-DD")}
                                      className={`form-control ${
                                        errors.expiryDate ? "is-invalid" : ""
                                      }`}
                                    />
                                    <Box className="invalid-feedback">
                                      {errors.expiryDate?.message}
                                    </Box>
                                  </Box>
                                </Grid>
                              )}
                              <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <Box className="form-group">
                                  <label>Country Code</label>
                                  <div
                                    className={`${
                                      errors.country_code?.message &&
                                      "required-Field"
                                    }`}
                                  >
                                    <Controller
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Select Your Country Code",
                                        },
                                      }}
                                      control={control}
                                      render={({ field }) => {
                                        const handleSelectChange = (
                                          selectedOption
                                        ) => {
                                          field.onChange({
                                            dial_code:
                                              selectedOption?.dial_code,
                                            name: selectedOption?.name,
                                            country_code:
                                              selectedOption?.country_code,
                                            id: selectedOption?.id,
                                          });
                                        };
                                        return (
                                          <AsyncSelect
                                            {...field}
                                            className={`w-100 ${
                                              errors.country_code?.message &&
                                              "is-invalid"
                                            }`}
                                            inputRef={field.ref}
                                            styles={style1}
                                            defaultOptions={field.option}
                                            components={{ DropdownIndicator }}
                                            name={"country_code"}
                                            {...register("country_code")}
                                            getOptionLabel={(e) =>
                                              e.dial_code + "-" + e.name
                                            }
                                            getOptionValue={(e) =>
                                              e.dial_code + "-" + e.name
                                            }
                                            loadOptions={loadCountryOptions}
                                            onChange={handleSelectChange}
                                          />
                                        );
                                      }}
                                      name="country_code"
                                    />
                                  </div>
                                  <Box className="invalid-feedback">
                                    {errors.country_code?.message}
                                  </Box>
                                </Box>
                              </Grid>
                              {fareMethod?.IsPassportMandatory && (
                                <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <Box className="form-group">
                                    <label>Issuing Country</label>
                                    <div
                                      className={`${
                                        errors.nationality?.message &&
                                        "required-Field"
                                      }`}
                                    >
                                      <Controller
                                        rules={{
                                          required: {
                                            value: true,
                                            message:
                                              "Select Your Issue Country",
                                          },
                                        }}
                                        control={control}
                                        render={({ field }) => {
                                          const handleSelectChange = (
                                            selectedOption
                                          ) => {
                                            field.onChange({
                                              dial_code:
                                                selectedOption?.dial_code,
                                              name: selectedOption?.name,
                                              country_code:
                                                selectedOption?.country_code,
                                              id: selectedOption?.id,
                                            });
                                          };
                                          return (
                                            <AsyncSelect
                                              {...field}
                                              className={`w-100 ${
                                                errors.nationality?.message &&
                                                "is-invalid"
                                              }`}
                                              inputRef={field.ref}
                                              defaultOptions={field.option}
                                              name={"issueCountry"}
                                              styles={style1}
                                              {...register("issueCountry")}
                                              components={{ DropdownIndicator }}
                                              getOptionLabel={(e) =>
                                                e.dial_code + "-" + e.name
                                              }
                                              getOptionValue={(e) =>
                                                e.dial_code + "-" + e.name
                                              }
                                              loadOptions={loadCountryOptions}
                                              onChange={handleSelectChange}
                                            />
                                          );
                                        }}
                                        name="issueCountry"
                                      />
                                    </div>
                                    <Box className="invalid-feedback">
                                      {errors.issueCountry?.message}
                                    </Box>
                                  </Box>
                                </Grid>
                              )}
                              <Grid className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <Box className="form-group">
                                  <label>Nationality</label>
                                  <div
                                    className={`${
                                      errors.nationality?.message &&
                                      "required-Field"
                                    }`}
                                  >
                                    <Controller
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Select Your Nationality",
                                        },
                                      }}
                                      control={control}
                                      render={({ field }) => {
                                        const handleSelectChange = (
                                          selectedOption
                                        ) => {
                                          field.onChange({
                                            dial_code:
                                              selectedOption?.dial_code,
                                            name: selectedOption?.name,
                                            country_code:
                                              selectedOption?.country_code,
                                            id: selectedOption?.id,
                                          });
                                        };
                                        return (
                                          <AsyncSelect
                                            {...field}
                                            className={`w-100 ${
                                              errors.nationality?.message &&
                                              "is-invalid"
                                            }`}
                                            inputRef={field.ref}
                                            styles={style1}
                                            defaultOptions={field.option}
                                            {...register("nationality")}
                                            components={{ DropdownIndicator }}
                                            name={"nationality"}
                                            getOptionLabel={(e) => e.name}
                                            getOptionValue={(e) => e.name}
                                            loadOptions={loadCountryOptions}
                                            onChange={handleSelectChange}
                                          />
                                        );
                                      }}
                                      name="nationality"
                                    />
                                  </div>
                                  <Box className="invalid-feedback">
                                    {errors.nationality?.message}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </form>
                      </Box>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="traveller_list_drop">
                  <div className="list_dropdown_title">
                    <h4>
                      <span className="booking_step">2</span> Add traveller
                      details
                    </h4>
                    <div
                      className={
                        copyTravellerIcon === false
                          ? "down-icons-list upIcon"
                          : "down-icons-list"
                      }
                      onClick={() => setCopyTravellerIcon(!copyTravellerIcon)}
                    >
                      <ExpandMoreIcon />
                    </div>
                  </div>
                  {copyTravellerIcon === false ? (
                    <div>
                      {/* {localStorage.getItem('tickatrip-token') ?
                        <></> :
                        <Button
                          disabled={adultCopyText.length === 0 ? false : true}
                          className="copy_user_btn" onClick={handleSubmit(handleCopyText)}>
                          Copy Above Traveller Detail <Add className="plusIcons" />
                        </Button>
                      } */}
                      <ul>
                        <li>
                          <TravellerList
                            adultCopyText={adultCopyText}
                            listenDataChanges={(e) => {
                              console.log("val..", e);
                              setAdultCopyText(e);
                            }}
                            copyClick={handleSubmit(handleCopyText)}
                          />
                        </li>
                        <li>
                          <TravellerChild />
                        </li>
                        <li>
                          <TravellerInfant />
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <Accordion
                  expanded={"panel3"}
                  // onChange={handleChange("panel3")}
                >
                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    IconButtonProps={{ edge: "start" }}
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography className="booking_step">3</Typography>
                    <Typography>Payment / Guarantee option</Typography>
                  </AccordionSummary> */}
                  <AccordionDetails>
                    <div className="MuiTypography-root MuiTypography-body1">
                      <Box className="privay_check_box">
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="I agree with the terms and conditions and privacy policy of TickATrip"
                          />
                        </FormGroup>
                        <Button
                          disabled={
                            travellerCount !==
                            parseInt(
                              SearchFlight?.adult_flight
                                ? SearchFlight?.adult_flight
                                : 0
                            ) +
                              parseInt(
                                SearchFlight?.child_flight
                                  ? SearchFlight?.child_flight
                                  : 0
                              ) +
                              parseInt(
                                SearchFlight?.infant_flight
                                  ? SearchFlight?.infant_flight
                                  : 0
                              )
                          }
                          // disabled={travellerCount !== (parseInt(SearchFlight?.adult_flight ? SearchFlight?.adult_flight : 0) + parseInt(SearchFlight?.child_flight ? SearchFlight?.child_flight : 0) + parseInt(SearchFlight?.infant_flight ? SearchFlight?.infant_flight : 0))}
                          onClick={handleSubmit(OnPayment)}
                          className="book_tick pop_con bookingBtn"
                        >
                          {travellerCount !==
                          parseInt(
                            SearchFlight?.adult_flight
                              ? SearchFlight?.adult_flight
                              : 0
                          ) +
                            parseInt(
                              SearchFlight?.child_flight
                                ? SearchFlight?.child_flight
                                : 0
                            ) +
                            parseInt(
                              SearchFlight?.infant_flight
                                ? SearchFlight?.infant_flight
                                : 0
                            ) ? (
                            <span>Please add traveller to Continue</span>
                          ) : (
                            <span>
                              Continue to payment
                              {`${
                                !!localStorage?.getItem("tickatrip-token")
                                  ? ""
                                  : " As Guest"
                              }`}
                            </span>
                          )}
                        </Button>
                      </Box>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
            <Grid className="col-lg-5 col-md-5 col-sm-12 col-12">
              <Box className="flight_pop_total">
                <Box className="card">
                  <Box className="main">
                    <Box className="co-img">
                      <Box className="ticket_carrd_per">
                        <span className="per_details">adult</span>
                        <Box>:</Box>
                        <Box className="per_count">
                          {tempFlightDetails.adult_flight}
                        </Box>
                      </Box>
                      <Box className="ticket_carrd_per">
                        <span className="per_details">Kids</span>
                        <Box>:</Box>
                        <Box className="per_count">
                          {tempFlightDetails.child_flight}
                        </Box>
                      </Box>
                      <Box className="ticket_carrd_per">
                        <span className="per_details">Infant</span>
                        <Box>:</Box>
                        <Box className="per_count">
                          {tempFlightDetails.infant_flight}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { width: "100%" },
                  }}
                  noValidate
                  autoComplete="off"
                  className="coupon_input"
                >
                  {getFlightCoupon !== "Coupon Not Found" &&
                    getFlightCoupon
                      ?.filter(
                        (el) =>
                          AppliedCoupon.length === 0 ||
                          AppliedCoupon.find(
                            (vl) => el.coupon_code !== vl.coupon_code
                          )
                      )
                      .map(
                        (val) =>
                          val.coupon_code !== couponRef?.current?.value && (
                            <div class="coupon-card">
                              <h3>{val.coupon_discount}</h3>
                              <div class="coupon-row">
                                <span id="cpnCode">{val.coupon_code}</span>
                                <span
                                  id="cpnBtn"
                                  className="couonCodeCopy"
                                  onClick={() => ApplyCoupon(val.coupon_code)}
                                >
                                  Apply
                                </span>
                              </div>
                              <p>Valid Till : {val.coupon_valid_upto}</p>
                              <div class="circle1"></div>
                              <div class="circle2"></div>
                            </div>
                          )
                      )}

                  {/* {localStorage.getItem('tickatrip-token') ? */}
                  <>
                    <TextField
                      id="outlined-basic"
                      inputRef={couponRef}
                      placeholder="Add Coupon code"
                      variant="outlined"
                    />
                    <Button
                      className="coupon_apply book_tick"
                      onClick={() => ApplyCoupon(couponRef?.current?.value)}
                    >
                      Apply Coupon
                    </Button>
                  </>

                  {AppliedCoupon?.map((val) => (
                    <div className="apply_coupon_remove">
                      <p>{val.coupon_code}</p>
                      <button
                        type="button"
                        onClick={(e) => onRemoveCoupon(e, val)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </Box>
                <Box className="pop_total_card">
                  <Box className="pop_total_card_single nd-head">
                    <span className="pop_total_list pop_price_main">
                      Fare details
                    </span>
                  </Box>
                  <Box className="pop_total_card_single">
                    <span className="pop_total_list">
                      Total Fare <br />
                    </span>
                    <span className="pop_total_price">
                      {fareMethod?.CurrencyCode} :{" "}
                      {fareMethod?.BaseFareAmount.toLocaleString()}
                    </span>
                  </Box>

                  <Box className="pop_total_card_single">
                    <span className="pop_total_list">Taxes</span>
                    <span className="pop_total_price">
                      {fareMethod?.CurrencyCode} :{" "}
                      {fareMethod?.TotalTaxAmount.toLocaleString()}
                    </span>
                  </Box>

                  <Box className="pop_total_card_single">
                    <span className="pop_total_list">Coupon Discounts</span>
                    <span className="pop_total_price">
                      {fareMethod?.CurrencyCode} : - {couponDiscount}
                    </span>
                  </Box>
                  <Box className="pop_total_card_single">
                    <span className="pop_total_list">
                      Other charges
                      {/* <br />
                      <span className="sm_text">
                        Adult {tempFlightDetails.adult_flight} X 0,00 <br />{" "}
                        Kids {tempFlightDetails.child_flight} X 0,000
                        <br /> Infant {tempFlightDetails.infant_flight} X 0,000
                      </span> */}
                    </span>
                    <span className="pop_total_price">
                      {fareMethod?.CurrencyCode} : 000
                    </span>
                  </Box>
                  <Box className="pop_total_card_single total_price">
                    {/* <span className="pop_total_list">Total</span>
                    <span className="pop_total_price">
                      {fareMethod?.CurrencyCode} : {flightTotalFinalPrice}
                    </span> */}
                    <span className="pop_total_list">Total</span>
<span className="pop_total_price">
  {fareMethod?.CurrencyCode} : {flightTotalFinalPrice?.toLocaleString()}
</span>

                  </Box>
                  
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};
