import * as React from 'react';
import { Container } from '@material-ui/core'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core'
import aboutimage from '../../asset/images/about.png'
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import homeActions from '../../redux/home/actions';

const useStyles = makeStyles((theme) =>
({
  travelSupportsec: {
    paddingTop: ' 8rem',
    paddingBottom: '5rem',
    position: 'relative',
    [theme.breakpoints.down("xs")]: {
      paddingTop: ' 5rem',
      paddingBottom: '0rem',
    },
  },
})
)

function TravelSupport() {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { homeCount } = useSelector((state) => state.homeReducer);

  useEffect(() => {
    dispatch({ type: homeActions.GET_HOME_COUNT })
  }, []);

  return (
    <div>
      <div className={classes.travelSupportsec}>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <div className='travelSupportTd'>
                  <Typography variant='h2'>Travel support</Typography>
                  <Typography variant='h5'>Plan your travel with confidence</Typography>
                  <Typography variant='p'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</Typography>
                </div>
                <Box className='about-counter'>
                  <Box>
                    <Typography variant='h2'>{homeCount?.count1}</Typography>
                    <Typography variant='inherit'>Years<br />Experience</Typography>
                  </Box>
                  <Box>
                    <Typography variant='h2'>{homeCount?.count2}</Typography>
                    <Typography variant='inherit'>Destination<br />Collaboration</Typography>
                  </Box>
                  <Box>
                    <Typography variant='h2'>{homeCount?.count3}</Typography>
                    <Typography variant='inherit'>Happy<br />Customer</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <div className='travelSupportImg'>
          <img src={aboutimage} alt='img' />
        </div>
      </div>
    </div>
  )
}

export default React.memo(TravelSupport);