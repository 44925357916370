import { takeEvery, call, put, all, delay, select } from 'redux-saga/effects';
import axios from 'axios';
import blogActions from './actions';
import commonActions from '../common/actions';
// import travellerActions from '../traveller/actions';
import { API_URL } from '../../utils/constant'
// import header from '../../components/common/header';


const blogSaga = function* () {
    yield all([
        yield takeEvery(blogActions.GET_BLOG_CATEGORY_LIST, categoryBlogList),
        yield takeEvery(blogActions.GET_BLOG_COUNTRY_LIST, countryBlogList),
        yield takeEvery(blogActions.GET_CREATE_BLOG_DATA, getCreateBlog),
        yield takeEvery(blogActions.GET_RECENT_BLOG, RecentBlogList),
        yield takeEvery(blogActions.GET_ALL_BLOG_LIST, AllBlogListShowing),
        yield takeEvery(blogActions.GET_BLOGGER_LIST, getBloggerLists),
        yield takeEvery(blogActions.GET_VIEW_BLOGGER_PROFILE, getViewBloggerProfile),
        yield takeEvery(blogActions.GET_POPULAR_BLOG_LIST, getPopularList),
        yield takeEvery(blogActions.GET_BLOG_DETAILS_LIST, getBlogDetailsList),
        yield takeEvery(blogActions.GET_BLOG_DISCRIPTION, getBlogDiscription),
        yield takeEvery(blogActions.GET_RELATED_BLOG_LIST, getRelatedBlogList),
        yield takeEvery(blogActions.GET_CREATE_BLOG_COMMENT, getCreateBlogComment),
        yield takeEvery(blogActions.UPDATE_BLOG_LIKE, UpdateBlogLike),
        yield takeEvery(blogActions.UPDATE_BLOG_BOOKMARK, UpdateBlogBookMark),
        yield takeEvery(blogActions.GET_BOOK_MARK_LIST, getBookMarkList),
        yield takeEvery(blogActions.GET_DESTINATION_BLOG_LIST, getDestinationBlogList),
        yield takeEvery(blogActions.GET_COUNTRY_WISE_BLOG, getCountryWiseBlog),
        yield takeEvery(blogActions.GET_FOLLOW_BLOGGER_LIST, getFollowBloggerList),
        yield takeEvery(blogActions.GET_CATEGORY_WISE_BLOG, getCategoryWiseBlog),
        yield takeEvery(blogActions.GET_USER_WISE_BLOG, getUserWiseBlog),
        yield takeEvery(blogActions.GET_ADD_BLOG_STORY, getAddBlogStory),
        yield takeEvery(blogActions.GET_BLOG_STORY_LIST, getBlogStoryList),
        yield takeEvery(blogActions.GET_BLOG_VIEW_RATING, getBlogViewRatings),
        yield takeEvery(blogActions.DELETE_BLOG_STORY_LIST, deleteBlogStoryList),
        yield takeEvery(blogActions.CREATE_BLOG_RATING, createBlogRating),
        yield takeEvery(blogActions.GET_BLOG_VIEW_COMMENTS, getBlogViewComments),
        yield takeEvery(blogActions.DELETE_BLOG_COMMENTS, deleteBlogComments),
        yield takeEvery(blogActions.DELETE_BLOGS, BlogDelete),
        yield takeEvery(blogActions.GET_BLOGGER_FOLLOW_CHECK_BY_ID, getBloggerFollowCheckByID),
        yield takeEvery(blogActions.UPDATE_BLOGGERS_FOLLOW, updateBloggersFollow),

        yield takeEvery(blogActions.GET_TAG_WISE_BLOG, getTagWiseBlog),
        yield takeEvery(blogActions.UPDATE_BLOG_DATA, updateBlogData),
    ]);
};

const categoryBlogList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/categorylist`)
        );
        // console.log('result....', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_BLOG_CATEGORY_LIST, payload: result?.data?.CategoryLists
            });
        } else {
            yield put({ type: blogActions.SET_BLOG_CATEGORY_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const countryBlogList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/countrylist`)
        );
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_BLOG_COUNTRY_LIST, payload: result?.data?.CountryLists
            });
        } else {
            yield put({ type: blogActions.SET_BLOG_COUNTRY_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const RecentBlogList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/recentbloglist`)
        );
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_RECENT_BLOG, payload: result?.data.RecentBlogList
            });
        } else {
            yield put({ type: blogActions.SET_RECENT_BLOG, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const AllBlogListShowing = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/allBloglist`)
        );
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_ALL_BLOG_LIST, payload: result?.data?.BlogList
            });
        } else {
            yield put({ type: blogActions.SET_ALL_BLOG_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getBloggerLists = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/bloggerlists`)
        );
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_BLOGGER_LIST, payload: result?.data?.BloggersList
            });
        } else {
            yield put({ type: blogActions.SET_BLOGGER_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getViewBloggerProfile = function* (data) {
    const { payload } = data
    // console.log('payload',payload)
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/blog/viewprofile/${payload}`)
        );
        // console.log('result?.data',result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_VIEW_BLOGGER_PROFILE, payload: result?.data?.ViewProfile
            });
        } else {
            yield put({ type: blogActions.SET_VIEW_BLOGGER_PROFILE, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getPopularList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/popular_blogs`)
        );
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_POPULAR_BLOG_LIST, payload: result?.data?.PopularBlogList
            });
        } else {
            yield put({ type: blogActions.SET_POPULAR_BLOG_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getBlogDetailsList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/blogviewlist`)
        );
        // console.log('res...',result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_BLOG_DETAILS_LIST, payload: result?.data?.BlogDetailsList
            });
        } else {
            yield put({ type: blogActions.SET_BLOG_DETAILS_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getBlogDiscription = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/blog_description/${payload}`)
        );
        // console.log('result?.data...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_BLOG_DISCRIPTION, payload: result?.data?.BlogDescription
            });
        } else {
            yield put({ type: blogActions.SET_BLOG_DISCRIPTION, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getCountryWiseBlog = function* (data) {
    const { payload } = data;
    // console.log('payload...', payload)
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/country_wise_blog/${payload}`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_COUNTRY_WISE_BLOG, payload: result?.data?.CountryWiseBlog
            });
        } else {
            yield put({ type: blogActions.SET_COUNTRY_WISE_BLOG, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getFollowBloggerList = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/follow_blogger_list`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_FOLLOW_BLOGGER_LIST, payload: result?.data?.FollowersList
            });
        } else {
            yield put({ type: blogActions.SET_FOLLOW_BLOGGER_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getRelatedBlogList = function* (data) {
    const { payload } = data;
    // console.log('payload', payload)
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/related_blogs/${payload}`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_RELATED_BLOG_LIST, payload: result?.data?.RelatedBlogList
            });
        } else {
            yield put({ type: blogActions.SET_RELATED_BLOG_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getDestinationBlogList = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/destinationblogs`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            if (localStorage.getItem('tickatrip-token')) {
                yield put({
                    type: blogActions.SET_DESTINATION_BLOG_LIST, payload: result?.data?.DestinationBlogList
                });
            }
        } else {
            yield put({ type: blogActions.SET_DESTINATION_BLOG_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getCategoryWiseBlog = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/category_wise_blog/${payload}`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_CATEGORY_WISE_BLOG, payload: result?.data?.CategoryWiseBlog
            });
        } else {
            yield put({ type: blogActions.SET_CATEGORY_WISE_BLOG, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getUserWiseBlog = function* (data) {

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/user_wise_blog`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_USER_WISE_BLOG, payload: result?.data?.UserBlogList
            });
        } else {
            yield put({ type: blogActions.SET_USER_WISE_BLOG, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getBlogStoryList = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/blog_story_list`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_BLOG_STORY_LIST, payload: result?.data?.StoryList
            });
        } else {
            yield put({ type: blogActions.SET_BLOG_STORY_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const createBlogRating = function* (data) {
    const { payload } = data;
    // console.log('payload', payload)
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/create_blog_ratings/${payload?.blogId}`, payload?.data),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        // console.log('rating_result',result?.data)
        if (result?.data?.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "" } });
            yield put({ type: blogActions.GET_BLOG_VIEW_RATING, payload: payload?.blogId });
        } else {
            // yield put({ type: blogActions.SET_BLOG_COUNTRY_LIST, payload: [] });
        }
    } catch (err) {
        console.log('err', err)
    }
}
const getBlogViewRatings = function* (data) {
    const { payload } = data
    // console.log('payload...', payload)
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/blog_view_rating/${payload}`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_BLOG_VIEW_RATING, payload: parseFloat(result?.data?.BlogRating?.toString())
            });
        } else {
            yield put({ type: blogActions.SET_BLOG_VIEW_RATING, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};

const getCreateBlogComment = function* (data) {
    const { payload } = data
    var form_data = new FormData();
    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    // console.log('form_data',form_data)
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/create_blog_comment`, form_data)
        );
        //  console.log('result.data',result.data)
        if (result.data.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
            yield put({
                type: blogActions.GET_BLOG_VIEW_COMMENTS, payload: form_data.get("blog_id")
            });

        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Comment not send", status: "" } });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Comment not send", status: "" } });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
};

const getBlogViewComments = function* (data) {
    const { payload } = data
    // console.log('payload...', payload)
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/blog_view_comments/${payload}`)
        );
        // console.log('blog cmts result...', result?.data)
        if (result?.data?.status === true) {

            const blogCmts = result?.data?.BlogComments;

            if (blogCmts.length > 0) {
                yield put({
                    type: blogActions.SET_BLOG_VIEW_COMMENTS, payload: blogCmts
                });
            }

        } else {
            // console.log('result not ok')
            yield put({ type: blogActions.SET_BLOG_VIEW_COMMENTS, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};

const UpdateBlogLike = function* (data) {
    const { payload } = data;
    try {
        const { userProfileData } = yield select((state) => state.travellerReducer)
        const result = yield call(() =>
            axios.put(
                `${API_URL}/user/update_blog_like/${payload?.blogId}`,
            )
        );
        if (result.data.status) {
            // yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "success" } });
            if (userProfileData) {
                if (payload?.page === 'blog-detail') {
                    if (!payload?.clickedFrom || payload?.clickedFrom !== 'related_products') {
                        const { BlogDiscription } = yield select((state) => state.blogReducer)
                        if (BlogDiscription?.length > 0) {
                            let desc = BlogDiscription[0];
                            let descImg = [];
                            if (BlogDiscription[0]?.liked_user_images?.find(val => val === userProfileData?.profile_image)) {
                                descImg = [...BlogDiscription[0]?.liked_user_images];
                                let findInd = descImg?.findIndex(val => val === userProfileData?.profile_image);
                                if (findInd !== -1) {
                                    descImg.splice(findInd, 1);
                                }
                            } else {
                                descImg = [userProfileData?.profile_image, ...BlogDiscription[0]?.liked_user_images]
                            }
                            desc.liked_user_images = descImg;
                            desc.auth_liked_blog = !desc.auth_liked_blog;
                            desc.like_count = descImg?.length;
                            yield put({ type: blogActions.SET_BLOG_DISCRIPTION, payload: [desc] });
                        }
                    }
                } else if (payload?.page === 'related_products') {
                    const { RelatedBlogList } = yield select((state) => state.blogReducer);
                    if (RelatedBlogList?.length > 0) {
                        let likedBlog = RelatedBlogList?.find(val => val?.id === payload?.blogId);
                        let blogImg = [];
                        if (likedBlog && likedBlog?.liked_user_images?.find(val => val === userProfileData?.profile_image)) {
                            blogImg = [...likedBlog?.liked_user_images];
                            let findImgInd = blogImg?.findIndex(val => val === userProfileData?.profile_image);
                            if (findImgInd !== -1) {
                                blogImg.splice(findImgInd, 1);
                            }
                        } else {
                            blogImg = [userProfileData?.profile_image, ...likedBlog?.liked_user_images]
                        }
                        likedBlog.liked_user_images = [...blogImg];
                        likedBlog.auth_liked_blog = !likedBlog?.auth_liked_blog;
                        likedBlog.like_count = blogImg?.length;
                        let array = [...RelatedBlogList];
                        let changedInd = array.findIndex(val => val?.id === payload?.blogId);
                        if (changedInd !== -1) {
                            array[changedInd] = likedBlog;
                        }
                        yield put({ type: blogActions.SET_RELATED_BLOG_LIST, payload: array });
                    }
                } else if (payload?.page === 'blog' || payload?.page === 'all_blog_list' || payload?.page === 'blog-our-experience') {
                    const { blogDetailsList, allBlogList, popularList } = yield select((state) => state.blogReducer);
                    if (blogDetailsList?.length > 0) {
                        let likedBlog = blogDetailsList?.find(val => val?.id === payload?.blogId);
                        if (likedBlog) {
                            let blogImg = [];
                            likedBlog?.liked_user_images?.length > 0 ? blogImg = [...likedBlog?.liked_user_images] : blogImg = [];
                            if (blogImg?.length > 0 && blogImg?.find(val => val === userProfileData?.profile_image)) {
                                let findImgInd = blogImg?.findIndex(val => val === userProfileData?.profile_image);
                                if (findImgInd !== -1) {
                                    blogImg.splice(findImgInd, 1);
                                }
                            } else {
                                blogImg.push(userProfileData?.profile_image);
                            }
                            likedBlog.liked_user_images = blogImg;
                            likedBlog.auth_liked_blog = !likedBlog?.auth_liked_blog;
                            likedBlog.like_count = blogImg?.length;
                            let array = [...blogDetailsList];
                            let changedInd = array.findIndex(val => val?.id === payload?.blogId);
                            if (changedInd !== -1) {
                                array[changedInd] = likedBlog;
                            }
                            yield put({ type: blogActions.SET_BLOG_DETAILS_LIST, payload: array });
                        }
                    }
                    if (allBlogList?.length > 0) {
                        let likedBlog = allBlogList?.find(val => val?.id === payload?.blogId);
                        if (likedBlog) {
                            console.log("likedBlog", likedBlog)
                            console.log("userProfileData", userProfileData)
                            let blogImg = [];
                            if (likedBlog?.liked_user_images?.length > 0 && likedBlog?.liked_user_images?.find(val => val === userProfileData?.profile_image)) {
                                blogImg = [...likedBlog?.liked_user_images];
                                let findImgInd = blogImg?.findIndex(val => val === userProfileData?.profile_image);
                                if (findImgInd !== -1) {
                                    blogImg.splice(findImgInd, 1);
                                }
                            } else {
                                blogImg = [userProfileData?.profile_image, ...likedBlog?.liked_user_images]
                            }
                            likedBlog.liked_user_images = [...blogImg];
                            likedBlog.auth_liked_blog = !likedBlog?.auth_liked_blog;
                            likedBlog.like_count = blogImg?.length;
                            let array = [...allBlogList];
                            let changedInd = array.findIndex(val => val?.id === payload?.blogId);
                            if (changedInd !== -1) {
                                array[changedInd] = likedBlog;
                            }
                            yield put({ type: blogActions.SET_ALL_BLOG_LIST, payload: array });
                        }
                    }
                    if (popularList?.length > 0) {
                        let findListInd = popularList?.findIndex(val => val?.id === payload?.blogId);
                        let arr = [...popularList];
                        if (findListInd !== -1) {
                            arr[findListInd].auth_liked_blog = !arr[findListInd].auth_liked_blog;
                        };
                        yield put({ type: blogActions.SET_POPULAR_BLOG_LIST, payload: arr });
                    }
                } else if (payload?.page === 'country_wise_blog') {
                    const { countryWiseBlog } = yield select((state) => state.blogReducer);
                    if (countryWiseBlog?.length > 0) {
                        let likedBlog = countryWiseBlog?.find(val => val?.id === payload?.blogId);
                        let blogImg = [];
                        if (likedBlog && likedBlog?.liked_user_images?.find(val => val === userProfileData?.profile_image)) {
                            blogImg = [...likedBlog?.liked_user_images];
                            let findImgInd = blogImg?.findIndex(val => val === userProfileData?.profile_image);
                            if (findImgInd !== -1) {
                                blogImg.splice(findImgInd, 1);
                            }
                        } else {
                            blogImg = [userProfileData?.profile_image, ...likedBlog?.liked_user_images]
                        }
                        likedBlog.liked_user_images = [...blogImg];
                        likedBlog.auth_liked_blog = !likedBlog?.auth_liked_blog;
                        likedBlog.like_count = blogImg?.length;
                        let array = [...countryWiseBlog];
                        let changedInd = array.findIndex(val => val?.id === payload?.blogId);
                        if (changedInd !== -1) {
                            array[changedInd] = likedBlog;
                        }
                        yield put({ type: blogActions.SET_COUNTRY_WISE_BLOG, payload: array });
                    }
                } else if (payload?.page === 'category_wise_list') {
                    const { CategoryWiseBlog } = yield select((state) => state.blogReducer);
                    if (CategoryWiseBlog?.length > 0) {
                        let likedBlog = CategoryWiseBlog?.find(val => val?.id === payload?.blogId);
                        let blogImg = [];
                        if (likedBlog && likedBlog?.liked_user_images?.find(val => val === userProfileData?.profile_image)) {
                            blogImg = [...likedBlog?.liked_user_images];
                            let findImgInd = blogImg?.findIndex(val => val === userProfileData?.profile_image);
                            if (findImgInd !== -1) {
                                blogImg.splice(findImgInd, 1);
                            }
                        } else {
                            blogImg = [userProfileData?.profile_image, ...likedBlog?.liked_user_images]
                        }
                        likedBlog.liked_user_images = [...blogImg];
                        likedBlog.auth_liked_blog = !likedBlog?.auth_liked_blog;
                        likedBlog.like_count = blogImg?.length;
                        let array = [...CategoryWiseBlog];
                        let changedInd = array.findIndex(val => val?.id === payload?.blogId);
                        if (changedInd !== -1) {
                            array[changedInd] = likedBlog;
                        }
                        yield put({ type: blogActions.SET_CATEGORY_WISE_BLOG, payload: array });
                    }
                } else if (payload?.page === 'tag_wise_blog') {
                    const { TagWiseBlog } = yield select((state) => state.blogReducer);
                    if (TagWiseBlog?.length > 0) {
                        let likedBlog = TagWiseBlog?.find(val => val?.id === payload?.blogId);
                        let blogImg = [];
                        if (likedBlog && likedBlog?.liked_user_images?.find(val => val === userProfileData?.profile_image)) {
                            blogImg = [...likedBlog?.liked_user_images];
                            let findImgInd = blogImg?.findIndex(val => val === userProfileData?.profile_image);
                            if (findImgInd !== -1) {
                                blogImg.splice(findImgInd, 1);
                            }
                        } else {
                            blogImg = [userProfileData?.profile_image, ...likedBlog?.liked_user_images]
                        }
                        likedBlog.liked_user_images = [...blogImg];
                        likedBlog.auth_liked_blog = !likedBlog?.auth_liked_blog;
                        likedBlog.like_count = blogImg?.length;
                        let array = [...TagWiseBlog];
                        let changedInd = array.findIndex(val => val?.id === payload?.blogId);
                        if (changedInd !== -1) {
                            array[changedInd] = likedBlog;
                        }
                        yield put({ type: blogActions.SET_TAG_WISE_BLOG, payload: array });
                    }
                }
            }
            // else {
            //     if (payload?.categoryId !== undefined) {
            //         yield put({ type: blogActions.GET_CATEGORY_WISE_BLOG, payload: payload?.categoryId });
            //         yield put({ type: blogActions.GET_RELATED_BLOG_LIST, payload: payload?.categoryId });
            //     }
            //     if (payload?.countryId !== undefined) {
            //         yield put({ type: blogActions.GET_COUNTRY_WISE_BLOG, payload: payload?.countryId });
            //     }
            //     yield put({ type: blogActions.GET_POPULAR_BLOG_LIST, payload: payload?.blogId });
            //     if (!payload?.clickedFrom || payload?.clickedFrom !== 'related_products') {
            //         yield put({ type: blogActions.GET_BLOG_DISCRIPTION, payload: payload?.blogId });
            //     }
            //     yield put({ type: blogActions.GET_ALL_BLOG_LIST, payload: payload?.blogId });
            //     yield put({ type: blogActions.GET_BLOG_DETAILS_LIST, payload: payload?.blogId });
            // }
        }
    } catch (err) {
        console.log("err", err)
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while update like', status: "failed" } });
    }
};
const UpdateBlogBookMark = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/user/update_blog_bookmark/${payload?.blogId}`,
            )
        );
        if (result.data.status) {
            if (payload?.page === 'all_blog_list' || payload?.page === 'blog') {
                const { allBlogList, blogDetailsList } = yield select((state) => state.blogReducer);
                if (allBlogList?.length > 0) {
                    let blogInd = allBlogList?.findIndex(val => val?.id === payload?.blogId);
                    if (blogInd !== -1) {
                        allBlogList[blogInd].auth_bookmarked_blog = !allBlogList[blogInd].auth_bookmarked_blog;
                        yield put({ type: blogActions.SET_ALL_BLOG_LIST, payload: allBlogList });
                    }
                }
                if (blogDetailsList?.length > 0) {
                    let blogInd = blogDetailsList?.findIndex(val => val?.id === payload?.blogId);
                    if (blogInd !== -1) {
                        blogDetailsList[blogInd].auth_bookmarked_blog = !blogDetailsList[blogInd].auth_bookmarked_blog;
                        yield put({ type: blogActions.SET_BLOG_DETAILS_LIST, payload: blogDetailsList });
                    }
                }
            } else if (payload?.page === 'country_wise_blog') {
                const { countryWiseBlog } = yield select((state) => state.blogReducer);
                if (countryWiseBlog?.length > 0) {
                    let blogInd = countryWiseBlog?.findIndex(val => val?.id === payload?.blogId);
                    if (blogInd !== -1) {
                        countryWiseBlog[blogInd].auth_bookmarked_blog = !countryWiseBlog[blogInd].auth_bookmarked_blog;
                        yield put({ type: blogActions.SET_COUNTRY_WISE_BLOG, payload: countryWiseBlog });
                    }
                }
            } else if (payload?.page === 'related_products') {
                const { RelatedBlogList } = yield select((state) => state.blogReducer);
                if (RelatedBlogList?.length > 0) {
                    let blogInd = RelatedBlogList?.findIndex(val => val?.id === payload?.blogId);
                    if (blogInd !== -1) {
                        RelatedBlogList[blogInd].auth_bookmarked_blog = !RelatedBlogList[blogInd].auth_bookmarked_blog;
                        yield put({ type: blogActions.SET_RELATED_BLOG_LIST, payload: RelatedBlogList });
                    }
                }
            } else if (payload?.page === 'category_wise_list') {
                const { CategoryWiseBlog } = yield select((state) => state.blogReducer);
                if (CategoryWiseBlog?.length > 0) {
                    let blogInd = CategoryWiseBlog?.findIndex(val => val?.id === payload?.blogId);
                    if (blogInd !== -1) {
                        CategoryWiseBlog[blogInd].auth_bookmarked_blog = !CategoryWiseBlog[blogInd].auth_bookmarked_blog;
                        yield put({ type: blogActions.SET_CATEGORY_WISE_BLOG, payload: CategoryWiseBlog });
                    }
                }
            } else if (payload?.page === 'tag_wise_blog') {
                const { TagWiseBlog } = yield select((state) => state.blogReducer);
                if (TagWiseBlog?.length > 0) {
                    let blogInd = TagWiseBlog?.findIndex(val => val?.id === payload?.blogId);
                    if (blogInd !== -1) {
                        TagWiseBlog[blogInd].auth_bookmarked_blog = !TagWiseBlog[blogInd].auth_bookmarked_blog;
                        yield put({ type: blogActions.SET_TAG_WISE_BLOG, payload: TagWiseBlog });
                    }
                }
            }
            // if (payload?.page === 'all_blog_list') {
            // const { allBlogList } = yield select((state) => state.blogReducer);
            // if (allBlogList?.length > 0) {
            //     let blogInd = allBlogList?.findIndex(val => val?.id === payload?.blogId);
            //     allBlogList[blogInd].auth_bookmarked_blog = !allBlogList[blogInd].auth_bookmarked_blog;
            //     yield put({ type: blogActions.SET_ALL_BLOG_LIST, payload: allBlogList });
            // }
            // }else if(payload?.page === 'blog'){
            // const { blogDetailsList } = yield select((state) => state.blogReducer);
            // if (blogDetailsList?.length > 0) {
            //     let blogInd = blogDetailsList?.findIndex(val => val?.id === payload?.blogId);
            //     blogDetailsList[blogInd].auth_bookmarked_blog = !blogDetailsList[blogInd].auth_bookmarked_blog;
            //     blogDetailsList[blogInd].auth_liked_blog = !blogDetailsList[blogInd]?.auth_liked_blog;
            //     yield put({ type: blogActions.SET_BLOG_DETAILS_LIST, payload: blogDetailsList });
            // }
            // }
            // yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "success" } });
            // if (payload?.categoryId !== undefined) {
            //     yield put({ type: blogActions.GET_CATEGORY_WISE_BLOG, payload: payload?.categoryId });
            //     yield put({ type: blogActions.GET_RELATED_BLOG_LIST, payload: payload?.categoryId });

            // }
            // if (payload?.countryId !== undefined) {
            //     yield put({ type: blogActions.GET_COUNTRY_WISE_BLOG, payload: payload?.countryId });
            // }
            // yield put({ type: blogActions.GET_POPULAR_BLOG_LIST, payload: payload?.blogId });
            // if (!payload?.clickedFrom || payload?.clickedFrom !== 'related_products') {
            //     yield put({ type: blogActions.GET_BLOG_DISCRIPTION, payload: payload?.blogId });
            // }
            // yield put({ type: blogActions.GET_ALL_BLOG_LIST, payload: payload?.blogId });
            // yield put({ type: blogActions.GET_BLOG_DETAILS_LIST, payload: payload?.blogId });
            // yield put({ type: blogActions.GET_BOOK_MARK_LIST });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while update bookmark', status: "failed" } });
    }
};

const getBookMarkList = function* () {
    // const { payload } = data
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/bookmarkList`)
        );
        // console.log('blog cmts result...', result?.data)
        if (result?.data?.status === true) {
            yield put({
                type: blogActions.SET_BOOK_MARK_LIST, payload: result?.data?.BookmarkList
            });
        } else {
            // console.log('result not ok')
            yield put({ type: blogActions.SET_BOOK_MARK_LIST, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
};
const getCreateBlog = function* (data) {
    const { payload } = data;
    const { navigate } = payload;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/createblog`, payload?.body)
        );
        if (result?.data?.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "" } });
            navigate('/blog')
        } else {
            // yield put({ type: blogActions.SET_BLOG_COUNTRY_LIST, payload: [] });
        }
    } catch (err) {
        console.log('err', err)
    }
}

const getAddBlogStory = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/add_blog_story/${payload}`)
        );
        // console.log('result?.data',result?.data)
        if (result?.data?.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "" } });
            yield put({ type: blogActions.GET_BLOG_STORY_LIST, payload: payload });
        } else {
            // yield put({ type: blogActions.SET_BLOG_COUNTRY_LIST, payload: [] });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "" } });
        }
    } catch (err) {
        console.log('err', err)
    }
}

const deleteBlogStoryList = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/user/remove_blog_story/${payload}`
            )
        );
        // console.log('result?.data', result?.data)
        if (result.data.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
            yield put({ type: blogActions.GET_BLOG_STORY_LIST, payload: payload });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while deleting story', status: "failed" } });
    }
};
const deleteBlogComments = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/user/delete_blog_comments/${payload?.CommentId}`
            )
        );
        // console.log('result?.data', result?.data)
        if (result.data.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
            yield put({ type: blogActions.GET_BLOG_VIEW_COMMENTS, payload: payload?.blogId });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while deleting comment', status: "failed" } });
    }
};
const BlogDelete = function* (data) {
    const { payload } = data;
    // console.log('pay..', payload)
    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/user/delete_blog/${payload}`,
            )
        );
        // console.log('result.data', result.data)
        if (result.data.status) {
            yield put({ type: blogActions.GET_USER_WISE_BLOG });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "success" } });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while update like', status: "failed" } });
    }
};
const getBloggerFollowCheckByID = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/user/follow_check/${payload}`,
            )
        );
        yield put({ type: blogActions.SET__BLOGGER_FOLLOW_CHECK_BY_ID, payload: result?.data })
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while update like', status: "failed" } });
    }
};
const updateBloggersFollow = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/user/follow_bloggers/${payload}`,
            )
        );
        if (result.data.status) {
            yield put({ type: blogActions.GET_BLOGGER_FOLLOW_CHECK_BY_ID, payload: payload });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "success" } });
        }
        yield delay(3000);
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: [] });

    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Error while update like', status: "failed" } });
    }
};

const getTagWiseBlog = function* (data) {
    const { payload } = data;
    // console.log('payload...', payload)
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/tag_wise_blog/${payload}`)
        );
        // console.log('result...', result?.data)
        if (result?.data?.status) {
            yield put({
                type: blogActions.SET_TAG_WISE_BLOG, payload: result?.data?.TagWiseBlog
            });
        } else {
            yield put({ type: blogActions.SET_TAG_WISE_BLOG, payload: [] });
        }
    } catch (err) {
        console.log(err)
    }
}

const updateBlogData = function* (data) {
    const { payload } = data;
    const { navigate } = payload;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/updateblog/${payload?.id}`, payload?.body)
        );
        if (result?.data?.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "" } });
            navigate('/blog')
        }
    } catch (err) {
        console.log('err', err.response.data)
    }
}

export default blogSaga;