import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import loginbackground from '../../asset/login/background.png'
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from "react";
import { useDispatch } from "react-redux";
import userActions from '../../redux/user/actions';
import { Link, useNavigate } from "react-router-dom";
import ToastAlert from '../common/ToastAlert';
import UserSlider from './UserSlider';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

const validationSchema = Yup.object().shape({
  usermailPhone: Yup.string()
    .required('mail / phone is required'),
});

const useStyles = makeStyles((theme) =>
({
  loginSlide: {
    background: `url(${loginbackground})`,
    height: '100vh',
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      height: '100%',
      width: "100%",
      display: 'relative',
      paddingBottom: '2rem'
    },
  },

  brandlogo: {
    minHeight: ' 80px',
    maxHeight: ' 80px',
    margin: ' 3rem 0',
  },
  bannertxt: {
    fontSize: ' 16px',
    color: ' #fff',
    fontWeight: ' 300',
    lineHeight: ' 30px',
    width: ' 90%',
    margin: ' auto',
  },
  welcome_grid: {
    display: 'flex',
    justifyContent: ' space-around',
    alignItems: 'center',
    height: ' 100vh',
  },
  welcome_heading: {
    fontSize: '32px',
    fontWeight: ' 500',
  },
  welcome_icon: {
    width: ' 35px',
    margin: '0 0.5rem',
    padding: ' 8px',
    background: ' #eff2f9',
    boxShadow: ' 0px 0px 5px 1px #32323254',
    marginTop: ' 1.2rem',
    borderRadius: ' 5px',
  },
  welcom_social: {
    textAlign: 'center',
  },
})
);

const UserOtpMail = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useNavigate();

  const { register, handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [usermailPhone, setusermailPhone] = useState();

  const onSubmit = (data) => {
    dispatch({
      type: userActions.GET_OTP_MAIL_LOGIN, validUserMail: {
        email: data.usermailPhone,
      },
      history: history
    });
  }

  return (
    <div>
      <ToastAlert />
      <Box >
        <Grid className='row'>
          <Grid className='col-lg-5 col-md-5 col-sm-12 col-12 user_slider'>
            <UserSlider />
          </Grid>
          <Grid className="col-lg-7 col-md-7 col-sm-12 col-12 log_in">
            <Link to='/' className='back_home dn-m'><ReplyAllIcon />Back to home</Link>
            <div className={classes.welcome_grid}>
              <div className={classes.welcome_heade}>
                <Typography className={classes.welcome_heading} variant='h1'>Welcome to TickaTrip</Typography>
                <div className='login_form'>
                  <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <Box
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        label="Email"
                        placeholder='Enter the valid email'
                        focused
                        name={usermailPhone}
                        value={usermailPhone}
                        onChange={(e) => setusermailPhone(e.target.value)}
                        {...register('usermailPhone')}
                        sx={{
                          '& > :not(style)': { width: '38ch' },
                        }}
                      />
                      <div className='error'>{errors.usermailPhone?.message}</div>
                    </Box>
                    <br />
                    <div className='form_btns'>
                      <button className='login_btn' type='submit'>Send OTP</button>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default UserOtpMail