import { Box, Container } from '@material-ui/core'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import Footer from '../common/footer'
import Header from '../common/header'
import UpdateBlogEditor from '../Editor/UpdateBlogEditor'

export const UpdateBlogContent = (props) => {
    const dispatch = useDispatch()
    const { state } = useLocation();
      // console.log("state",state)
    return (
      <Box className='create_blog_section'>
        <Header />
        {/* <Box className='create_blog_head'>
          <Container>
             <ul>
              <li><Link className='active' to='/create-blog'>create new blog</Link></li>
              <li><Link to='/created-blog-list'>Profile</Link></li>
            </ul> 
          </Container>
        </Box> */}
        <UpdateBlogEditor  state={state} />
        <Footer />
      </Box>
    )
  }