import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Button, Container } from "@material-ui/core";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import offerImage from '../../asset/hotel/2.jpg'

const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  loop: true,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 2,
    }
  },
};

const value = 3.5;

export const HotelOfferSlider = () => {
  return (
    <div className='offer_slider'>
      <Container>
        <OwlCarousel className="owl-theme offerSlide" {...options}>
          <div className="item">
            <div className="offer_slider_inner">
              <div className="offer_slider_img">
                <img src={offerImage} alt="img" />
              </div>
              <div className="offer_slider_con">
                <h4 className="MuiTypography-root  MuiTypography-h4">
                  exclusive offer
                </h4>
                <span className="MuiTypography-root offer_p">
                  enjoy your dream vacation in switzerland
                </span>
                <div className="offer_slider_con">
                  <ul>
                    <li><p>2 Days / 3 Night <br />
                      <Rating
                        name="text-feedback"
                        value={value}
                        readOnly
                        precision={0.5}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                      />
                    </p></li>
                    <li><p>Only <br /> ₹8000</p></li>
                    <li>
                      <Button className="search_btn_banner offer_btn" variant="outlined">
                        Book
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="offer_slider_inner">
              <div className="offer_slider_img">
                <img src={offerImage} alt="img" />
              </div>
              <div className="offer_slider_con">
                <h4 className="MuiTypography-root  MuiTypography-h4">
                  exclusive offer
                </h4>
                <span className="MuiTypography-root offer_p">
                  enjoy your dream vacation in switzerland
                </span>
                <div className="offer_slider_con">
                  <ul>
                    <li><p>2 Days / 3 Night <br />
                      <Rating
                        name="text-feedback"
                        value={value}
                        readOnly
                        precision={0.5}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                      />
                    </p></li>
                    <li><p>Only <br /> ₹8000</p></li>
                    <li>
                      <Button className="search_btn_banner offer_btn" variant="outlined">
                        Book
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="offer_slider_inner">
              <div className="offer_slider_img">
                <img src={offerImage} alt="img" />
              </div>
              <div className="offer_slider_con">
                <h4 className="MuiTypography-root  MuiTypography-h4">
                  exclusive offer
                </h4>
                <span className="MuiTypography-root offer_p">
                  enjoy your dream vacation in switzerland
                </span>
                <div className="offer_slider_con">
                  <ul>
                    <li><p>2 Days / 3 Night <br />
                      <Rating
                        name="text-feedback"
                        value={value}
                        readOnly
                        precision={0.5}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                      />
                    </p></li>
                    <li><p>Only <br /> ₹8000</p></li>
                    <li>
                      <Button className="search_btn_banner offer_btn" variant="outlined">
                        Book
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


        </OwlCarousel>
      </Container>
    </div>
  )
}
