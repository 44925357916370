import React from 'react'
import { Link } from 'react-router-dom'

export const TripCancel = () => {
  return (
    <div>
      <div className='cancel_trip_page'>
         <Link to={`${localStorage.getItem('tickatrip-token') ? '/flight-booking' : '/pnr-cancel'}`}>Flight Trip Cancel</Link>
         <Link to={`${localStorage.getItem('tickatrip-token')? '/hotel-booking' : '/hotel-guest-cancel'}`}>Hotel Booking Cancel</Link>
      </div>
    </div>
  )
}
