import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Container, Typography } from '@material-ui/core';
import { Rating } from '@mui/material';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import StarIcon from '@mui/icons-material/Star';
// import trip1 from '../../asset/home-image/travel-1.png'
// import trip2 from '../../asset/home-image/travel-2.png';
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { API_IMG_URL } from '../../utils/constant';
import FavoriteIcon from '@mui/icons-material/Favorite';
import blogActions from '../../redux/blog/actions';
import { useNavigate } from "react-router-dom";
import commonActions from '../../redux/common/actions';
// import travellerActions from '../../redux/traveller/actions';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    checked: {
        color: '#ffcc00',
    },
    relative: {
        position: 'relative',
    }
}));

const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 4,
        }
    },
};

function OurExperience() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { popularList } = useSelector((state) => state.blogReducer)
    const [addLike, setAddLike] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        dispatch({ type: blogActions.GET_POPULAR_BLOG_LIST })
    },[]);
    useEffect(() => {
        if (popularList?.length > 0) {
            setList(popularList);
        };
    }, [popularList]);

    const updateBlogLike = (Id) => {
        if (localStorage.getItem('tickatrip-token')) {
            var temp = popularList.find((e) => e.id === Id)
            dispatch({ type: blogActions.UPDATE_BLOG_LIKE, payload: { blogId: temp?.id, page: "blog-our-experience" } })
            // dispatch({ type: travellerActions.GET_PROFILE_DATA });
            setAddLike(!addLike)
        } else {
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
        }
    }
    return (
        <div className={classes.relative}>
            <Container>
                <Grid container spacing={3} className='ourexperience_area'>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box className='ourexperience_area_inner'>
                            <Typography variant='h2'>Our Experience</Typography>
                            <Typography variant='h5'>Explore the beauty of the world</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box className='ourexperience_area_inner'>
                            <Typography variant='inherit'>Contrary to popular belief, Lorem Ipsum is not simply random text.
                                It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia,
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Grid className='experience_slider'>
                            <OwlCarousel className='owl-theme experienceSlide' {...options}>
                                {list.map((val, index) => {
                                    return (
                                        <div key={index} className='item_experience_slider'>
                                            <div className='popular__image__on__icons'>
                                                <div onClick={() => navigate(`/blog-detail/${val?.id}`, { state: { blogId: val?.id, categoryId: val?.category_id ,title: val?.heading} })}>
                                                    <img className='popular__image' src={`${API_IMG_URL}/server/blog/${val?.blog_image[0]}`} alt='' />
                                                </div>
                                                <div className={val?.auth_liked_blog === true ? 'popular__like_icons_active' : 'popular__like_icons'}>
                                                    <FavoriteIcon
                                                        onClick={() => updateBlogLike(val?.id)}
                                                        className={val?.auth_liked_blog === true ? 'red' : 'deactivate'}
                                                    />
                                                </div>
                                            </div>
                                            <div className='experience_slider_content'>
                                                <div onClick={() => navigate(`/blog-detail/${val?.id}`, { state: { blogId: val?.id, categoryId: val?.category_id,title: val?.heading } })}><Typography variant='h3'>{val?.heading}</Typography></div>
                                                <Box className='review_our_exp'>
                                                    <Rating
                                                        name="half-rating-read"
                                                        defaultValue={val?.avg_rating}
                                                        precision={0.5}
                                                        readOnly
                                                        sx={{
                                                            fontSize: '19px',
                                                        }}
                                                    />
                                                </Box>
                                                <Box className='blog_tag'>
                                                    <ul>
                                                        {val.tags.map((item, index) => {
                                                            return (
                                                                <li key={index}><span onClick={() => navigate('/tag_wise_blog', { state: { blogImage: val?.blog_image[0], blogHeading: val?.heading, tagName: item } })}>{item}</span></li>
                                                            );
                                                        })}
                                                    </ul>
                                                </Box>
                                            </div>
                                        </div>
                                    );
                                })}
                            </OwlCarousel>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <div className='bg_overlay'></div>
        </div>
    )
}

export default OurExperience;
// export default React.memo(OurExperience);