import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import Header from './header'
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { useState } from 'react';
import Footer from './footer';

export const PrivacyAndPolicy = () => {

    const [iSReaded, setISReaded] = useState(false)

    const AcceptTerm = (e) => {
        let tempTotalHeight = e.target.clientHeight
        let scrollingHeight = e.target.scrollHeight - e.target.scrollTop
        if (Math.ceil(tempTotalHeight) === Math.ceil(scrollingHeight)) {
            setISReaded(true)
        } else {
            setISReaded(false)
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div>
            <Header />
            <main class="wrap_term">
                <section class="container">
                    <div class="container__heading">
                        <h2>Privacy & Policy</h2>
                    </div>
                    <div class="container__content" onScroll={(e) => AcceptTerm(e)}>
                        {[...Array(20)].map((val) => (
                            <div className='term_inner_content'>
                                <h4>Privacy Policy</h4>
                                <p>Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula lacinia aliquet. Mauris ipsum. Nulla metus metus, ullamcorper vel, tincidunt sed, euismod in, nibh. Quisque volutpat condimentum velit.  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc. </p>
                            </div>
                        ))}
                    </div>
                    <div class="container__nav">
                        <small>By clicking 'Accept' you are agreeing to our Privacy & Policy.</small>
                        <Button disabled={iSReaded !== true} className="search_btn_banner" variant="outlined" startIcon={<BeenhereIcon />} >Accept</Button>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}
