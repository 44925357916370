import { Box, Container, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import PersonIcon from '@mui/icons-material/Person';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { useDispatch, useSelector } from 'react-redux';
import travellerActions from '../../redux/traveller/actions';
import SaveTraveller from '../Traveller/SaveTraveller';
import { PROFILE_URL } from '../../utils/profileConst';
import commonActions from '../../redux/common/actions';
import loaderGifImg from "../../asset/blueflightloader.gif";
import ProfileDefault from '../../asset/icons/profile_user.png';
import * as Yup from 'yup';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div sx={{ p: 2 }}>
                    <div className="MuiTypography-root cardtd">
                        <div>{children}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const validationSchema = Yup.object().shape({
    userGender: Yup.string()
        .required('Gender is required'),
    userName: Yup.string()
        .required('Username is required'),
    firstName: Yup.string()
        .required('firstName is required'),
    lastName: Yup.string()
        .required('lastName is required'),
    userDOB: Yup.string()
        .required('userDOB is required'),
    userMarriedStatus: Yup.string()
        .required('userMarriedStatus is required'),
})

const correctDate = new Date().toISOString().split("T")[0];

export const Profile = () => {

    const dispatch = useDispatch()
    const { userProfileData } = useSelector((state) => state.travellerReducer)
    const { loading } = useSelector((state) => state.commonReducer)

    const [value, setValue] = useState(0)
    const [profileImage, setProfileImage] = useState()
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { register, handleSubmit, reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        let temp = {}
        temp = {
            username: data.userName,
            first_name: data?.firstName,
            last_name: data?.lastName,
            phone: data.userMobile,
            dob: data.userDOB,
            gender: data.userGender,
            married_status: data.userMarriedStatus,
            profile_image: profileImage ? profileImage : ''
        }
        Object.keys(temp).forEach(key => {
            if (temp[key] === '') {
                delete temp[key]
            }
        })
        dispatch({
            type: travellerActions.GET_PROFILE_EDIT, payload: temp
        })
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        setOpen(false)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (userProfileData) {
            reset({
                userName: userProfileData?.username,
                firstName: userProfileData?.first_name,
                lastName: userProfileData?.last_name,
                userMail: userProfileData?.email,
                userMobile: userProfileData?.phone,
                userDOB: userProfileData?.dob,
                userGender: userProfileData?.gender,
                userMarriedStatus: userProfileData?.married_status,
                userImage: userProfileData?.profile_image,
            })
        }
    }, [userProfileData])

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }, [])

    useEffect(() => {
        dispatch({ type: travellerActions.GET_TRAVELLER_LIST });
    }, []);

    return (
        <div className='create_blog_section '>
            {loading ?
                <div>
                    <div className='blur_bg'></div>
                    <div className='loaderGif'>
                        <img src={loaderGifImg} alt='loading' />
                    </div>
                </div>
                :
                null
            }
            <div className='blog_user_profile_section user_profile_page'>
                <div className='blog_user_profile user_profile profile_pd'>
                    <Container>
                        <div sx={{ flexGrow: 1 }}>
                            <Grid className='row'>
                                <Grid className='col-lg-12 col-md-12'>
                                    <div className='blog_user_personal_details profile_tab_sec'>
                                        <div sx={{ width: '100%' }}>
                                            <div sx={{ borderBottom: 1, borderColor: 'Boxider' }}>
                                                <Tabs value={value} onChange={handleChange} TabIndicatorProps={{
                                                    style: {
                                                        backgroundColor: "#172651"
                                                    }
                                                }} aria-label="basic tabs example">
                                                    <Tab icon={<PersonIcon />} iconPosition="start" label="Profile" {...a11yProps(0)} />
                                                    <Tab icon={<PeopleOutlineIcon />} iconPosition="start" label="Add Traveller" {...a11yProps(1)} />
                                                </Tabs>
                                            </div>
                                            <TabPanel value={value} index={0} className='blog_personalDetails'>
                                                <div>
                                                    <Box className='user_profile_sec'>
                                                        <div className='row'>
                                                            <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                                                <div className='blog_user_profile_card'>
                                                                    <div className='blog_user_profile_card_img'>
                                                                        {(userProfileData?.profile_image === null || userProfileData?.profile_image === undefined) ?
                                                                            <img src={ProfileDefault} alt='profile' /> :
                                                                            <img src={userProfileData?.profile_image && `${PROFILE_URL}${userProfileData?.profile_image}`} alt={userProfileData?.username} />
                                                                        }
                                                                    </div>
                                                                    <div className='blog_user_profile_content'>
                                                                        <Typography variant='h5'>{userProfileData?.username}</Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-9 col-md-9 col-sm-12 col-12' >
                                                                <Grid className='row'>
                                                                    <Grid className='col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="form-group">
                                                                            <label>Name</label>
                                                                            <span className='user_detail_area'>{userProfileData?.username}</span>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid className='col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="form-group">
                                                                            <label>first Name</label>
                                                                            <span className='user_detail_area'>{userProfileData?.first_name}</span>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid className='col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="form-group">
                                                                            <label>last Name</label>
                                                                            <span className='user_detail_area'>{userProfileData?.last_name}</span>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid className='col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="form-group">
                                                                            <label>Email</label>
                                                                            <span className='user_detail_area'>{userProfileData?.email}</span>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid className='col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="form-group">
                                                                            <label>Mobile</label>
                                                                            <span className='user_detail_area'>{userProfileData?.phone}</span>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid className='col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="form-group">
                                                                            <label>Gender</label>
                                                                            <span className='user_detail_area'>{userProfileData?.gender}</span>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid className='col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="form-group">
                                                                            <label>Date of Birth</label>
                                                                            <span className='user_detail_area'>{userProfileData?.dob}</span>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid className='col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="form-group">
                                                                            <label>Married Status</label>
                                                                            <span className='user_detail_area'>{userProfileData?.married_status}</span>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                        <div className="form-group">
                                                                            <Button onClick={handleOpen}>Edit Profile</Button>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>


                                                    </Box>
                                                    <Modal
                                                        open={open}
                                                        onClose={handleClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <div className='profileStyleModel'>
                                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                                <div>
                                                                    <Grid className='row'>
                                                                        <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                                            <div className="form-group">
                                                                                <label>Gender</label>
                                                                                <select name="userGender" defaultValue={userProfileData?.gender ? userProfileData?.gender : null} {...register('userGender')} className={`form-control`}>
                                                                                    <option value=""></option>
                                                                                    <option value="Male">Male</option>
                                                                                    <option value="Female">Female</option>
                                                                                </select>
                                                                                <div className='error'>{errors.userGender?.message}</div>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                                            <div className="form-group">
                                                                                <label>User Name</label>
                                                                                <input name='userName' {...register('userName')} type="text" className={`form-control `} />
                                                                            </div>
                                                                            <div className='error'>{errors.userName?.message}</div>
                                                                        </Grid>
                                                                        <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                                            <div className="form-group">
                                                                                <label>First Name</label>
                                                                                <input name='firstName' {...register('firstName')} type="text" className={`form-control `} />
                                                                            </div>
                                                                            <div className='error'>{errors.firstName?.message}</div>
                                                                        </Grid>
                                                                        <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                                            <div className="form-group">
                                                                                <label>last Name</label>
                                                                                <input name='lastName' {...register('lastName')} type="text" className={`form-control `} />
                                                                            </div>
                                                                            <div className='error'>{errors.lastName?.message}</div>
                                                                        </Grid>
                                                                        <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                                            <div className="form-group">
                                                                                <label>Mobile</label>
                                                                                <input name="userMobile"  {...register('userMobile')} type="number" className={`form-control `} />
                                                                            </div>
                                                                            <div className='error'>{errors.userMobile?.message}</div>
                                                                        </Grid>
                                                                        <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                                            <div className="form-group">
                                                                                <label>Date of Birth</label>
                                                                                <input name="userDOB" {...register('userDOB')} type="date" className={`form-control`} max={correctDate} />
                                                                            </div>
                                                                            <div className='error'>{errors.userDOB?.message}</div>
                                                                        </Grid>
                                                                        <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                                            <div className="form-group">
                                                                                <label>Married Status</label>
                                                                                <select name="userMarriedStatus" defaultValue={userProfileData.married_status ? userProfileData.married_status : null} {...register('userMarriedStatus')} className={`form-control`}>
                                                                                    <option value=""></option>
                                                                                    <option value="Single">Single</option>
                                                                                    <option value="Married">Married</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className='error'>{errors.userMarriedStatus?.message}</div>
                                                                        </Grid>
                                                                        <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                                            <div className="form-group">
                                                                                <label>Profile Picture</label>
                                                                                <input name="userProfilePic" onChange={(e) => setProfileImage(e.target.files[0])} type="file" className={`form-control `} />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                            <div className="form-group">
                                                                                <Button onClick={() => setOpen(false)}>Close</Button>
                                                                                <button type="submit" className="btn btn-primary mr-1">Update Profile</button>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <ul>
                                                    <li><SaveTraveller /></li>
                                                </ul>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    )
}
