import React, { useEffect, useState } from 'react'
import { Button, debounce } from "@material-ui/core";
import Box from '@mui/material/Box';
import { Form } from 'react-bootstrap';
import { Search } from '@material-ui/icons';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import hotelActions from '../../redux/hotel/actions';
import RoomGuests from './RoomGuests';
import moment from 'moment';
import axios from "axios";
import { API_URL, CURRENCY } from "../../utils/constant";
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router';
import commonActions from '../../redux/common/actions';
import loaderGifImg from "../../asset/blueflightloader.gif";
import 'react-day-picker/dist/style.css';
import { useRef } from 'react';
import 'react-day-picker/dist/style.css';
import { DatePicker } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationBlue from '../../asset/icons/location_blue.png';
import CalenderBlue from '../../asset/icons/calender_icon.png';
import GuestsBlue from '../../asset/icons/guests_icon.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const style = {
    control: (base, state) => ({
        ...base,
        outline: state.isFocused ? "1px solid black" : "1px solid #e8ebf3",
        border: state.isFocused ? "1px solid black" : "1px solid #e8ebf3",
        padding: "7px 0px",
        ':hover': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':active': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':focus': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':focus-visible': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        // This line disable the blue border
        boxShadow: "none"
    })
};


function HotelSearch() {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { hotelSearchValues, hotelImageLoader } = useSelector(state => state.hotelReducer);
    const { loading } = useSelector((state) => state.commonReducer)
    const validationSchema = Yup.object().shape({
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, control, formState } = useForm(formOptions);
    const { errors } = formState;

    const { RangePicker } = DatePicker;
    const dateFormat = "YYYY-MM-DD";

    const searchRef = useRef();
    const [defaultCheckInDate, setDefaultCheckInDate] = useState('');
    const [fromDate, setFromDate] = useState(moment(new Date()));
    const [toDate, setToDate] = useState(moment(new Date()).add(1, "day"));
    const [openGuest, setOpenGuest] = useState(false)
    const [addRoom, setAddRoom] = useState([{
        room_no: 1,
        adult: 2,
        child: 0,
        child_age: []
    }])
    const [adultCount, setAdultCount] = useState('')
    const [childCount, setChildCount] = useState('')
    const [selectedCity, setSelectedCity] = useState({
        city: '',
        country: '',
    });
    const [errorRed, setErrorRed] = useState(false);

    function onSubmit() {
        if (selectedCity.city === '') {
            setErrorRed(true)
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please Enter the Destination', status: "failed" } });
        } else if (selectedCity.country === '') {
            setErrorRed(true)
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please Enter the Destination', status: "failed" } });
        } else if (fromDate === '') {
            setErrorRed(true)
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please Enter the Check-In Date', status: "failed" } });
        } else if (toDate === '') {
            setErrorRed(true)
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please Enter the Check-Out Date', status: "failed" } });
        } else if (addRoom.length === 0) {
            setErrorRed(true)
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please Add the Room Guest', status: "failed" } });
        } else {
            setOpenGuest(false)
            setErrorRed(false)
            dispatch({ type: commonActions.SET_LOADER, payload: true });
            let adult = adultCount ? adultCount : 0;
            let child = childCount ? childCount : 0;
            dispatch({
                type: hotelActions.GET_HOTEL_SEARCH, payload: {
                    checkin: moment(fromDate).format('YYYY-MM-DD'),
                    checkout: moment(toDate).format('YYYY-MM-DD'),
                    city_name: selectedCity.city,
                    country_name: selectedCity.country,
                    requiredCurrency: CURRENCY,
                    occupancy: addRoom,
                    hotel_name: selectedCity?.hotel_name,
                    totalCount: adult + child,
                    latitude: selectedCity?.hotel_name ? selectedCity?.latitude : undefined,
                    longitude: selectedCity?.hotel_name ? selectedCity?.longitude : undefined,
                }, from: history
            })
        }
    }

    const AddRoom = (e) => {
        let tempDate = []
        let tempG = addRoom ? addRoom : [];
        tempDate = [...tempG, {
            room_no: tempG.length + 1,
            adult: 0,
            child: 0,
            child_age: []
        }];
        setAddRoom(tempDate)
    }

    const Save = (data) => {
        setOpenGuest(false)
    }

    const Remove = (e) => {
        setAddRoom(addRoom?.filter((val, index) => index !== e))
    }


    const loadToOptions = async (inputValue, callback) => {
        if (inputValue.trim().length > 2) {
            var form_data = new FormData();
            form_data.append('name', inputValue.trim());
            const result = await axios.post(
                `${API_URL}/getHotelCitieSearch`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                },
            }
            );
            callback(result.data.message.filter(val => val.country_name !== selectedCity.from))
        } else {
            callback(null)
        }
    };

    useEffect(() => {
        let tempToday = new Date().toLocaleDateString()
        setDefaultCheckInDate(tempToday)
    }, [defaultCheckInDate]);

    useEffect(() => {
        if (addRoom) {
            let tempAdult = 0
            for (let index = 0; index < addRoom.length; index++) {
                tempAdult += addRoom[index].adult
            }
            console.log(tempAdult);
            setAdultCount(tempAdult)
            let tempChild = 0
            for (let index = 0; index < addRoom.length; index++) {
                tempChild += addRoom[index].child
            }
            setChildCount(tempChild)
        }
    }, [addRoom]);

    useEffect(() => {
        if (hotelSearchValues) {
            setSelectedCity({
                city: hotelSearchValues.city_name,
                country: hotelSearchValues.country_name,
                id: hotelSearchValues.id,
                hotel_name: hotelSearchValues.hotel_name,
                latitude: hotelSearchValues?.hotel_name ? hotelSearchValues?.latitude : undefined,
                longitude: hotelSearchValues?.hotel_name ? hotelSearchValues?.longitude : undefined,
            });
            setAddRoom(hotelSearchValues.occupancy);
            setFromDate(moment(hotelSearchValues.checkin));
            setToDate(moment(hotelSearchValues.checkout));
            searchRef?.current?.focus();
        }

    }, [hotelSearchValues]);

    return (
        <div>
            {(loading || hotelImageLoader) ?
                <div>
                    <div className='blur_bg'></div>
                    <div className='loaderGif'>
                        <img src={loaderGifImg} alt='' />
                    </div>
                </div>
                :
                null
            }
            <div>
                <div className='banner_box hotel_search'>
                    <div className='container-fluid'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box sx={{ flexGrow: 1 }}>
                                <div className="hotelSearch_grid row">
                                    <div className='col-lg-3 col-md-6 col-sm-12 margin_right mbl-padding'>
                                        <Form.Group>
                                            <label className='d-block label-heading'>Destination or Hotel Name</label>
                                            <Controller
                                                control={control}
                                                disableUnderline={false}
                                                render={({ field }) => {
                                                    const handleSelectChange = (selectedOption) => {
                                                        setSelectedCity({ ...selectedCity, id: selectedOption.id, latitude: selectedOption?.latitude, longitude: selectedOption?.longitude, hotel_name: selectedOption?.hotel_name, city: selectedOption?.city_name, country: selectedOption?.country_name })
                                                        field.onChange({ id: selectedOption.id, latitude: selectedOption?.latitude, longitude: selectedOption?.longitude, hotel_name: selectedOption?.hotel_name, country_name: selectedOption?.country_name, city_name: selectedOption?.city_name });
                                                    };

                                                    return (
                                                        <div className='d-Flex'>
                                                            <img src={LocationBlue} alt='location icons' className='location_blue' />
                                                            <AsyncSelect
                                                                {...field}
                                                                styles={style}
                                                                placeholder={'Search City...'}
                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                className={`w-100 ${errorRed ? 'redBox' : ''}`}
                                                                inputRef={field.ref}
                                                                defaultOptions={selectedCity?.city ? [{ hotel_name: selectedCity?.hotel_name, city_name: selectedCity.city, country_name: selectedCity.country }] : []}
                                                                value={selectedCity?.city ? { city_name: selectedCity?.city, country_name: selectedCity?.country, hotel_name: selectedCity?.hotel_name } : null}
                                                                name={"airport_to_code"}
                                                                getOptionLabel={(e) => e.hotel_name ? e.hotel_name + ',' + e.city_name + ',' + e?.country_name + '(Hotel)' : e.city_name + ',' + e?.country_name + '(City)'}
                                                                getOptionValue={(e) => e.id} // changes here!!!
                                                                loadOptions={debounce(loadToOptions, 400)}
                                                                onChange={handleSelectChange}
                                                            />
                                                            <KeyboardArrowDownIcon />
                                                        </div>
                                                    );
                                                }}
                                                name="destination"
                                            />

                                            <div className="invalid-feedback">{errors.destination?.message}</div>
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 margin_right mbl-padding'>
                                        <Form.Group>
                                            <label className='d-block label-heading'>Check in - out</label>
                                            <div className='dFlexStart'>
                                                <img src={CalenderBlue} alt='calender icon' className='calender_icon' />
                                                <RangePicker
                                                    suffixIcon={null}
                                                    defaultValue={[fromDate, toDate]}
                                                    format={dateFormat}
                                                    allowClear={false}
                                                    disabledDate={current => {
                                                        return current < moment(new Date()).subtract(1, "day");

                                                    }}
                                                    onChange={(value) => {
                                                        setFromDate(value[0]);
                                                        setToDate(value[1]);
                                                    }}
                                                />
                                                <KeyboardArrowDownIcon />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 margin_right mbl-padding'>
                                        <Form.Group>
                                            <label className='d-block label-heading'>Rooms and Guests</label>
                                            <div className='dFlexStartTop'>
                                                <div className='room_guest_select dFlexStartTop' onFocus={() => setOpenGuest(true)} onBlur={(e) => {
                                                    if (e.currentTarget.contains(e.relatedTarget)) {
                                                        setOpenGuest(true);
                                                    }
                                                }}>
                                                    <img src={GuestsBlue} alt='location icons' className='guests_icon' />
                                                    <div className={`room_guest cursor-pointer ${errorRed ? 'redBox' : ''}`} >
                                                        <button type="button" onClick={() => setOpenGuest(true)}>
                                                            {addRoom?.length !== 0 ?
                                                                <div className='select_guest_search'>
                                                                    {/* <h5>Room / Guests </h5> */}
                                                                    <span>{addRoom.length} Rooms / {adultCount || childCount ? adultCount + childCount : 0} Guests </span>
                                                                    <h5 className='select_type'>{adultCount} Adults, {childCount} Children </h5>
                                                                </div> : 'Select Guests'}
                                                        </button>
                                                        {/* {addRoom?.length !== 0 ?
                                                    <ul className='total_room_guest'>
                                                        <li>Adult <span>{adultCount ? adultCount : '0'}</span></li>
                                                        <li>Child <span>{childCount ? childCount : '0'}</span></li>
                                                        <li>Rooms <span>{addRoom?.length ? addRoom.length : '0'}</span></li>
                                                    </ul>
                                                    :
                                                    null
                                                } */}

                                                    </div>
                                                    {openGuest &&
                                                        <div className='guest_field'>
                                                            {addRoom?.map((e, index) =>
                                                                <div className='guest_room_list' key={'addRoom' + index}>
                                                                    <h5>Room {index + 1}</h5>
                                                                    <div className='add_guest_sec'>
                                                                        <RoomGuests
                                                                            room_no={index + 1}
                                                                            addRoom={addRoom}
                                                                            changeAddRoom={(el) => {
                                                                                console.log(el);
                                                                                setAddRoom(el);
                                                                            }}
                                                                            deleteChild={(event, e) => {
                                                                                event.preventDefault()
                                                                                let tempData = [...addRoom]
                                                                                tempData[e - 1].child = 0
                                                                                tempData[e - 1].child_age = []
                                                                                setAddRoom([...tempData])

                                                                            }}
                                                                            row={e}
                                                                        />
                                                                        <Button onClick={() => Remove(index)}><DeleteIcon /></Button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className='room_guest_btns'>
                                                                <Button onClick={AddRoom}>Add Room</Button>
                                                                <Button onClick={Save}>Done</Button>
                                                            </div>
                                                        </div>}
                                                </div>
                                                <KeyboardArrowDownIcon />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-sm-12 hotel_search_submit mbl-padding'>
                                        <Button type="submit" ref={searchRef} className="search_btn_banner" variant="outlined" startIcon={<Search />}>Search</Button>
                                    </div>
                                </div>
                            </Box>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default React.memo(HotelSearch);