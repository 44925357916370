
import actions from "./actions";

const initialState = {
  loading: false,
  response: {
    alert: false,
    message: null,
    status: null,
  },
  commonErrors: null,
  flightSerchError:null,
  commonToastError:[],
  popularPlaces:[],
  popularPlacesDetail:[],
  homePageBanner:[]
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADER:
      return {
        ...state,
        loading: action.payload
      }
    case actions.SET_NOTIFICATION:
      return {
        ...state,
        response: action.payload
      }
    case actions.SET_COMMON_ERRORS:
      return {
        ...state,
        commonErrors: action.payload
      }
      case actions.SET_FLIGHT_SEARCH_RESULT:
      return {
        ...state,
        flightSerchError: action.payload
      }
      case actions.SET_POPULAR_PLACES:
        return {
          ...state,
          popularPlaces: action.payload
        }
      case actions.SET_COMMON_TOASTER_ALERT:
        return {
          ...state,
          commonToastError: action.payload
        }
        case actions.SET_POPULAR_PLACES_DETAILS:
        return {
          ...state,
          popularPlacesDetail: action.payload
        }
        case actions.SET_HOME_BANNER:
        return {
          ...state,
          homePageBanner: action.payload
        }
    default:
      return state;
  }
};

export default commonReducer;