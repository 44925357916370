import { takeEvery, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import commonActions from '../common/actions';
import { API_URL } from '../../utils/constant'
import travellerActions from '../traveller/actions';
import { removeDuplicates } from '../../utils/validate';
import moment from 'moment';

const FlightSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_ALL_CITIES, getAllCities),
        yield takeEvery(actions.GET_ALL_FLIGHT, getAllFlight),
        yield takeEvery(actions.GET_FARE_RULES, fareRules),
        yield takeEvery(actions.GET_VALIDATE_FARE_METHOD, fareMethod),
        yield takeEvery(actions.GET_BOOKIG_TRAVELLER_DATA, travellerData),
        yield takeEvery(actions.GET_TICKET_ORDER_DATA, ticketOrderResponseData),
        yield takeEvery(actions.GET_TRIP_DETAILS, tripDetails),
        yield takeEvery(actions.GET_PNR_TICKET_CANCEL, pnrTicketCancel),
        yield takeEvery(actions.GET_PNR_TICKET_OTP_CONFIRM, pnrTicketCancelOtpVerify),
        yield takeEvery(actions.GET_FLIGHT_COUPON, getFlightCoupon),
        yield takeEvery(actions.GET_FLIGHT_BOOKING, checkoutflight),
    ]);
};
// const checkoutflight = function* (data) {
//     const { payload } = data;
//     console.log('payload',payload)
//     var form_data = new FormData();
//     for (var key in payload) {
//         form_data.append(key, payload[key]);
//     }
//     try {
//         const result = yield call(() =>
//             axios.post(
//                 `${API_URL}/checkoutflight`, form_data,
//                 {
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                 }
//             )
//         );
//         console.log('v',result.data)
//         if (result.data.status) {
//             yield put({ type: commonActions.SET_LOADER, payload: false });
//             console.log('result data if....',result.data.status)
//             // window.location.href=`https://tickatrip.travel/server/checkoutflight/${result.data.checkout_id}`;
//             console.log('checkout if....')
//         } else {
//             yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors, status: "failed" } });
//         }
//     }
//     catch (err) {
//         console.log('checkout catch....')
//         yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.response.message, status: "failed" } });
//     }
// };
const checkoutflight = function* (data) {
    const { payload, history } = data;
    yield put({ type: actions.SET_FLIGHT_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/checkoutflight`, payload)
        );
        if (result.data.status) {
            if (payload.type === "WebFare") {
                window.location.href = `https://tickatrip.travel/server/checkoutAgainFlight/${result.data.checkout_id}`;

            } else if (payload.type === "Public") {
                window.location.href = `https://tickatrip.travel/server/checkoutAgainFlight/${result.data.checkout_id}`;

            } else {
                yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Booking Failed", status: "" } });
            }
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "success" } });
        }
        yield put({ type: actions.SET_FLIGHT_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Booking Failed", status: "" } });
    }
};
const getAllCities = function* () {
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/getAirportNameSearch`)
        );
        if (result.data.success) {
            yield put({ type: actions.SET_ALL_CITIES, payload: result.data.message });
        } else {
            //error handle
            yield put({ type: actions.SET_ALL_CITIES, payload: [] });
        }
    } catch (err) {
        yield put({ type: actions.SET_ALL_CITIES, payload: [] });
    }
};

const getAllFlight = function* (data) {
    console.log("Starting getAllFlight function...");
    
    yield put({ type: commonActions.SET_LOADER, payload: true });
    console.log("Loader set to true");
    
    const { flightData } = data;
    console.log("flightData:", flightData);
    
    var form_data = new FormData();
    for (var key in flightData) {
        form_data.append(key, flightData[key]);
    }
    console.log("Form data created:", form_data);
    
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/getFlightSearch`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        ));
        console.log("API call result:", result);

        var flightNameFilter = [];
        const FlightNameList = [];
        for (let i = 0; i < result?.data.message.length; i++) {
            flightNameFilter.push(result?.data.message[i].flightName)
        }
        console.log("Flight name filter:", flightNameFilter);

        for (let i = 0; i < flightNameFilter.length; i++) {
            if (!FlightNameList.includes(flightNameFilter[i])) {
                FlightNameList.push(flightNameFilter[i])
            }
        }
        console.log("Flight name list:", FlightNameList);
        
        yield put({ type: actions.SET_FLIGHT_NAME_FILTER, payload: FlightNameList });

        if (result?.data.status === true) {
            let a = result?.data.message.map(el => {
                return {
                    ...el, flight_details: el.flight_details.map(el1 => {
                        return { FareSourceCode: el.FareSourceCode, ...el1 }
                    })
                }
            });
            console.log("Processed data:", a);

            let b = [], c = [], d = 0;
            a.forEach((el, i) => {
                let temp = "";
                temp = temp + el.flightName;
                el.flight_details.forEach(val => {
                    val.flights.forEach(el1 => {
                        temp = temp + el1.flightList.ArrivalAirportLocationCode + el1.flightList.ArrivalDateTime + el1.flightList.DepartureAirportLocationCode + el1.flightList.DepartureDateTime;
                    });
                    temp = temp + val.totalStops + val.flights.map(obj => obj.flightList.OperatingAirline.Code + obj.flightList.OperatingAirline.FlightNumber)?.join(" / ");
                });
                if (b.includes(temp)) {
                    let tempIndex;
                    b.forEach((el1, ind) => {
                        if (el1 === temp) {
                            tempIndex = ind;
                        }
                    });
                    c[tempIndex] = [...c[tempIndex], el];
                    c = [...c, c[tempIndex]];
                } else {
                    c[d] = [el];
                    b = [...b, temp];
                    d = d + 1;
                }
            });
            console.log("Processed flight list:", c);

            if (flightData.journey_type === "OneWay") {
                yield put({
                    type: actions.SET_ALL_FLIGHT, payload: c
                });
            } else {
                yield put({
                    type: actions.SET_ALL_FLIGHT, payload: a
                });
            }
            console.log("Flight data set:", flightData);
            data.history('/flight');

            yield put({ type: commonActions.SET_LOADER, payload: false });

        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors, status: "failed" } });

            yield put({ type: actions.SET_ALL_FLIGHT, payload: [] });

            yield put({ type: commonActions.SET_LOADER, payload: false });

        }
        if (data?.change) {
            yield put({ type: travellerActions.GET_PROFILE_DATA });

        }
    } catch (err) {
        if (data?.change) {
            yield put({ type: travellerActions.GET_PROFILE_DATA });

        }
        yield put({ type: actions.SET_ALL_FLIGHT, payload: [] });

        yield put({ type: commonActions.SET_LOADER, payload: false });

    }
};


const fareRules = function* (data) {           
    const { payload } = data
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/getFlightDetail`,
                JSON.stringify(payload), {
                headers: {
                    "Content-Type": "application/json",
                },
            })
        );
        if (result?.data.status === true) {
            yield put({ type: actions.SET_FARE_RULES, payload: result.data?.message[0] });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errorname, status: "failed" } });
            yield put({ type: actions.SET_FARE_RULES, payload: null });
        }
        yield put({ type: actions.SET_FARE_RULES_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_FARE_RULES_LOADER, payload: false });
        yield put({ type: actions.SET_FARE_RULES, payload: null });
    }
};

const fareMethod = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/revalidate`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result?.data.status === true) {
            yield put({ type: actions.SET_VALIDATE_FARE_METHOD, payload: result.data.message });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Validation failed, search again or try other flights!", status: "failed" } });
            yield put({ type: actions.SET_VALIDATE_FARE_METHOD, payload: null });
            yield put({ type: commonActions.SET_LOADER, payload: false });
        }
    } catch (err) {
        yield put({ type: actions.SET_VALIDATE_FARE_METHOD, payload: null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
};

const travellerData = function* (data) {
    const { payload, history } = data;
    yield put({ type: actions.SET_FLIGHT_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/booking`, payload)
        );
        if (result.data.status) {
            if (payload.type === "WebFare") {
                yield put({
                    type: actions.GET_TRIP_DETAILS,
                    payload: {
                        UniqueID: result.data?.uniqueId,
                    },
                    history: history,
                });
            } else {
                yield put({
                    type: actions.SET_BOOKIG_RESPONSE_DATA, payload: {
                        UniqueID: result.data?.uniqueId,
                    }

                });
                console.log('booking payload', result.data?.uniqueId)

                yield put({ type: actions.SET_BOOKING_CONFIRM, payload: true });
            }
        } else {
            console.log('booking Api else')
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "success" } });
        }
        yield put({ type: actions.SET_FLIGHT_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Booking Failed", status: "" } });
        yield put({ type: actions.SET_FLIGHT_LOADER, payload: false });
        yield put({ type: actions.SET_BOOKING_CONFIRM, payload: false });
        yield put({ type: actions.SET_BOOKIG_RESPONSE_DATA, payload: null });
        yield put({ type: actions.SET_BOOKIG_TRAVELLER_DATA, payload: [] });
    }
};
const ticketOrderResponseData = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/ticket_order`, payload)
        );
        if (result.data.status) {
            yield put({ type: actions.SET_TICKET_ORDER_STATUS, payload: result.data.status });
            yield put({ type: actions.SET_TICKET_ORDER_DATA, payload: { UniqueID: result.data.uniqueId } });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
            //  else if (result.data.message?.AirOrderTicketRS?.TicketOrderResult?.Errors?.Errors?.ErrorMessage) {
            //     yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.AirOrderTicketRS?.TicketOrderResult.Errors?.Errors?.ErrorMessage, status: "success" } });
            // } else if (result.data.message?.AirOrderTicketRS?.TicketOrderResult?.Errors[0]?.Errors?.ErrorMessage) {
            //     yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.AirOrderTicketRS?.TicketOrderResult.Errors[0]?.Errors?.ErrorMessage, status: "success" } });
            // } else if (result.data.message[0]) {
            //     yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message[0], status: "success" } });
            // } else {
            //     yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
            // }
        }
        yield put({ type: actions.SET_FLIGHT_LOADER, payload: false });

    } catch (err) {
        yield put({ type: actions.SET_FLIGHT_LOADER, payload: false });
        yield put({ type: actions.SET_TICKET_ORDER_DATA, payload: [] });
    }
};

const tripDetails = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/trip_details`,
                form_data, {
                headers: {
                    "User-Token": localStorage.getItem('tickatrip-token'),
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            // yield put({ type: userActions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: result.data.bookings })
            // data.history('/trip-detail');
            window.location.href = '/trip-detail';
            localStorage.setItem('flightBookingData', JSON.stringify(result.data.bookings));
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        }
    } catch (err) {
        yield ({ type: actions.SET_TRIP_DETAILS, payload: [] })
    }
}

const pnrTicketCancel = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/guest/flight/requestcancellation`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_PNR_TICKET_CANCEL, payload: result.data })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
            data.history('/pnr-otp');
        } else {
            yield ({ type: actions.SET_PNR_TICKET_CANCEL, payload: [] });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err?.response?.message, status: "failed" } });
        yield ({ type: actions.SET_PNR_TICKET_CANCEL, payload: [] })
    }
}

const pnrTicketCancelOtpVerify = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/guest/flight/cancelbooking`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_PNR_TICKET_OTP_CONFIRM, payload: result.data.message })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        } else {
            yield ({ type: actions.SET_PNR_TICKET_OTP_CONFIRM, payload: [] });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.response.message, status: "failed" } });
        yield ({ type: actions.SET_PNR_TICKET_OTP_CONFIRM, payload: [] })
    }
}
const getFlightCoupon = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/flight-coupons`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        yield put({ type: actions.SET_FLIGHT_COUPON, payload: result.data.message })
    } catch (err) {
        yield ({ type: actions.SET_FLIGHT_COUPON, payload: [] })
    }
}

export default FlightSaga;