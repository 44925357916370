import { Button } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import React, {
    useEffect,
    useState
} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { components } from "react-select";
import AsyncSelect from 'react-select/async';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import flightActions from '../../redux/flight/actions';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import moment from 'moment';



const style1 = {
    control: (base, state) => ({
        ...base,
        outline: state.isFocused ? "1px solid black" : "1px solid #e8ebf3",
        border: state.isFocused ? "1px solid black" : "1px solid #e8ebf3",
        padding: "3px 0px",
        ':hover': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':active': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':focus': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':focus-visible': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        // This line disable the blue border
        boxShadow: "none"
    })
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fas fa-search cursor-pointer"></i>
        </components.DropdownIndicator>
    );
};

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Title is required'),
    first_name: Yup.string().matches(
        /^[A-Za-z\\s]*$/,
        "first name should not contain spaces or numbers"
    ).required('First Name is required'),
    last_name: Yup.string().matches(
        /^[A-Za-z\\s]*$/,
        "Last name should not contain spaces or numbers"
    ).required('Last name is required'),
    gender: Yup.string()
        .required('Gender is required'),
    dob: Yup.string()
        .required('dob is required'),
    issueCountry: Yup.object()
        .required('Country is required')
});
const validationSchema1 = Yup.object().shape({
    first_name: Yup.string().matches(
        /^[A-Za-z\\s]*$/,
        "first name should not contain spaces or numbers"
    ).required('First Name is required'),
    last_name: Yup.string().matches(
        /^[A-Za-z\\s]*$/,
        "Last name should not contain spaces or numbers"
    ).required('Last name is required')
});

const TravellerInfant = (props) => {

    const dispatch = useDispatch();
    const { fareMethod, SearchFlight } = useSelector((state) => state.flightReducer)
    const { register, handleSubmit, reset, formState: { errors }, control } = useForm({ resolver: yupResolver(fareMethod?.IsPassportMandatory ? validationSchema : validationSchema1) });
    const { travellerListData } = useSelector((state) => state.travellerReducer)

    const [existingTraveller, setExistingTraveller] = useState([])
    const [openTravellerModel, setOpenTravellerModel] = useState("");
    const [minAgeLimit, setMinAgeLimit] = useState('')
    const [maxAgeLimit, setMaxAgeLimit] = useState('')

    const OnAddTraveller = (data) => {
        if (openTravellerModel?.type === "Edit") {
            let temp = []
            temp = existingTraveller
            temp[openTravellerModel?.Id] = {
                id: existingTraveller[openTravellerModel?.Id]?.id,
                travellerTitle: data.title,
                travellerFirstName: data.first_name,
                travellerLastName: data.last_name,
                travellerGender: data.gender,
                travellerMail: data.email,
                travellerPhone: data.phone,
                travellerBirthday: data.dob,
                travellerPassportNumber: data.passportNumber,
                travellerIssuingCountry: data.issueCountry,
                travellerExpiryDate: data.expiryDate,
            }
            setExistingTraveller(temp)
            setOpenTravellerModel({ type: "", Id: "" })
        } else {
            setExistingTraveller([...existingTraveller, {
                id: existingTraveller?.length + 1,
                travellerTitle: data.title,
                travellerFirstName: data.first_name,
                travellerLastName: data.last_name,
                travellerGender: data.gender,
                travellerMail: data.email,
                travellerPhone: data.phone,
                travellerBirthday: data.dob,
                travellerPassportNumber: data.passportNumber,
                travellerIssuingCountry: data.issueCountry,
                travellerExpiryDate: data.expiryDate,
            }]);
            setOpenTravellerModel("");
        }
        reset()
    }

    const handleTrvellerOpen = () => {
        setOpenTravellerModel({ type: "Add", Id: "" })
    }

    const OnDeleteTraveller = (val, index) => (
        setExistingTraveller(existingTraveller.filter((temp, inx) => index !== inx))
    )

    const OnEditTraveller = (data, index) => {
        setOpenTravellerModel({ type: "Edit", Id: index })
        reset({
            title: data.travellerTitle,
            first_name: data.travellerFirstName,
            last_name: data.travellerLastName,
            gender: data.travellerGender,
            email: data.travellerMail,
            phone: data.travellerPhone,
            dob: data.travellerBirthday,
            passportNumber: data.travellerPassportNumber,
            issueCountry: data.travellerIssuingCountry,
            expiryDate: data.travellerExpiryDate,
        })
    }

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.trim().length >= 1) {
            var form_data = new FormData();
            form_data.append('name', inputValue.trim());
            const result = await axios.post(
                `${API_URL}/countries`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );
            callback(result.data.message)
        } else {
            callback(null)

        }
    }

    useEffect(() => {
        if (existingTraveller.length !== 0) {
            dispatch({ type: flightActions.SET_INFANT_TRAVELLER_DATA, payload: existingTraveller })
        }
    }, [existingTraveller]);

    useEffect(() => {
        if (existingTraveller.length !== 0) {
            return dispatch({ type: flightActions.SET_INFANT_TRAVELLER_DATA, payload: existingTraveller })
        }
    }, [existingTraveller]);

    useEffect(() => {
        if (travellerListData?.length > 0) {
            let temp = travellerListData.filter(el => el.type === "Infant").filter((el, index) => index < parseInt(SearchFlight?.infant_flight)).map((data, index) => {
                return {
                    id: index + 1,
                    travellerTitle: data.title,
                    travellerFirstName: data.first_name,
                    travellerLastName: data.last_name,
                    travellerGender: data.gender,
                    travellerType: data.type,
                    travellerMail: data.email,
                    travellerPhone: data.phone,
                    travellerBirthday: data.dob,
                    travellerPassportNumber: data.passport,
                    travellerIssuingCountry: data.issue_country,
                    travellerExpiryDate: data.expire_date,
                }
            });
            setExistingTraveller(temp);
        }
    }, [travellerListData]);

    useEffect(() => {
        if (openTravellerModel?.type !== '') {
            var todayI = new Date();
            var ddI = todayI.getDate();
            var mmI = todayI.getMonth() + 1;
            var yyyyI = todayI.getFullYear() - 2;
            if (ddI < 10) {
                ddI = '0' + ddI
            }
            if (mmI < 10) {
                mmI = '0' + mmI
            }
            todayI = yyyyI + '-' + mmI + '-' + ddI;
            setMinAgeLimit(todayI)
            setMaxAgeLimit(new Date().toISOString().split("T")[0])
        }
    }, []);

    return (
        <Box>
            <Box className='addTraveller_btn'>
                <Button disabled={(existingTraveller?.length) === parseInt(SearchFlight?.infant_flight ? SearchFlight?.infant_flight : 0)} onClick={handleTrvellerOpen}>Add Infant Traveller<Add /></Button>
            </Box>
            {existingTraveller?.map((val, index) => {
                return (
                    <div key={'travellarInfantList' + index}>
                        <Box className="wrapper-traveller">
                            <Box className="right">
                                <Box className="info">
                                    <h3>{val.travellerName}</h3>
                                    <Box className="info_data">
                                        <Box className="data">
                                            <h4>First Name</h4>
                                            <p>{val.travellerFirstName}</p>
                                        </Box>
                                        <Box className="data">
                                            <h4>Last Name</h4>
                                            <p>{val.travellerLastName}</p>
                                        </Box>
                                        <Box className="data">
                                            <h4>Gender</h4>
                                            <p>{val.travellerGender}</p>
                                        </Box>
                                        <Box className="data">
                                            <Box className="social_media">
                                                <ul>
                                                    <li><Edit onClick={() => OnEditTraveller(val, index)} /></li><br />
                                                    <li><Delete onClick={() => OnDeleteTraveller(val, index)} /></li>
                                                </ul>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                );
            }
            )}

            <Box>
                <Modal
                    open={!!openTravellerModel?.type}
                    onClose={() => {
                        setOpenTravellerModel(false);
                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth() + 1;
                        var yyyy = today.getFullYear() - 14;
                        if (dd < 10) {
                            dd = '0' + dd
                        }
                        if (mm < 10) {
                            mm = '0' + mm
                        }
                        today = yyyy + '-' + mm + '-' + dd;
                        setMaxAgeLimit(today);
                        setMinAgeLimit(null);
                        reset({
                            title: "",
                            first_name: "",
                            last_name: "",
                            gender: "",
                            type: "Adult",
                            email: "",
                            phone: "",
                            dob: "",
                            passportNumber: "",
                            issueCountry: "",
                            expiryDate: ""
                        });
                    }} aria-labelledby="modal-modal-gender"
                    aria-describedby="modal-modal-description"
                    className='traveller_model'
                >
                    <Box className='travellerAddPop'>
                        <Box className="card-body">
                            <form onSubmit={handleSubmit(OnAddTraveller)}>
                                <Grid container spacing={2}>
                                    <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <Box className="form-group">
                                            <label>Title</label>
                                            <select name="title" {...register('title', { required: true })} className={`form-control ${errors.title ? 'is-invalid' : ''}`}>
                                                <option value=""></option>
                                                <option value="Master">Master</option>
                                                <option value="Miss">Miss</option>
                                            </select>
                                            <Box className="invalid-feedback">{errors.title?.message}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <Box className="form-group ">
                                            <label>First Name</label>
                                            <input name="first_name" type="text" {...register('first_name')} className={`form-control ${errors.first_name ? 'is-invalid' : ''}`} />
                                            <Box className="invalid-feedback">{errors.first_name?.message}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <Box className="form-group ">
                                            <label>Last Name</label>
                                            <input name="last_name" type="text" {...register('last_name')} className={`form-control ${errors.last_name ? 'is-invalid' : ''}`} />
                                            <Box className="invalid-feedback">{errors.last_name?.message}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <Box className="form-group">
                                            <label>Gender</label>
                                            <select name="gender" {...register('gender', { required: true })} className={`form-control ${errors.gender ? 'is-invalid' : ''}`}>
                                                <option value=""></option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </select>
                                            <Box className="invalid-feedback">{errors.gender?.message}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <Box className="form-group">
                                            <label>Date of Birth</label>
                                            <input name="dob" type="date" {...register('dob', { required: true })} className={`form-control ${errors.dob ? 'is-invalid' : ''}`} min={minAgeLimit} max={maxAgeLimit} />
                                            <Box className="invalid-feedback">{errors.dob?.message}</Box>
                                        </Box>
                                    </Grid>
                                    {fareMethod?.IsPassportMandatory &&
                                        <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <Box className="form-group">
                                                <label>Passport Number</label>
                                                <input name="passportNumber" type="text" {...register('passportNumber', { required: true })} className={`form-control ${errors.passportNumber ? 'is-invalid' : ''}`} />
                                                <Box className="invalid-feedback">{errors.passportNumber?.message}</Box>
                                            </Box>
                                        </Grid>
                                    }
                                    {fareMethod?.IsPassportMandatory &&
                                        <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <Box className="form-group">
                                                <label>Issuing Country</label>
                                                <Controller
                                                    control={control}
                                                    render={({ field }) => {
                                                        const handleSelectChange = (selectedOption) => {
                                                            field.onChange({ dial_code: selectedOption?.dial_code, name: selectedOption?.name, country_code: selectedOption?.country_code, id: selectedOption?.id });
                                                        };
                                                        return (
                                                            <AsyncSelect
                                                                {...field}
                                                                className="w-100"
                                                                inputRef={field.ref}
                                                                defaultOptions={field.option}
                                                                name={"issueCountry"}
                                                                styles={style1}
                                                                components={{ DropdownIndicator }}
                                                                getOptionLabel={(e) => e.name}
                                                                getOptionValue={(e) => e.name}
                                                                loadOptions={loadOptions}
                                                                onChange={handleSelectChange}
                                                            />
                                                        );
                                                    }}
                                                    name="issueCountry"
                                                />
                                                <Box className="invalid-feedback">{errors.issueCountry?.message}</Box>
                                            </Box>
                                        </Grid>
                                    }
                                    {fareMethod?.IsPassportMandatory &&
                                        <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <Box className="form-group">
                                                <label>Expiry Date</label>
                                                <input name="expiryDate" type="date" {...register('expiryDate', { required: true })} min={moment(new Date()).add(1, "month").format('YYYY-MM-DD')} className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`} />
                                                <Box className="invalid-feedback">{errors.expiryDate?.message}</Box>
                                            </Box>
                                        </Grid>
                                    }
                                    {/* </div>
                                            :
                                            null
                                        } */}

                                    <Grid item lg={12}>
                                        <Box className="form-group addEdit_pop_btn">
                                            <button type="button" onClick={() => setOpenTravellerModel(false)} className="btn btn-secondary">Close</button>
                                            <button type="submit" className="btn btn-primary mr-1">{openTravellerModel?.type === "Edit" ? "Update" : "Add"}</button>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </form>
                        </Box>
                    </Box>
                </Modal>
            </Box >
        </Box >
    )
}

export default React.memo(TravellerInfant);
