import blogActions from "./actions";

const initialState = {
  blogCategoryList: [],
  blogCountryList: [],
  createBlogData: [],
  recentBlog: [],
  allBlogList: [],
  BlogggerList: [],
  popularList: [],
  blogDetailsList: [],
  BlogDiscription: [],
  RelatedBlogList: [],
  createBlogComment: [],
  destinationBlogList: [],
  countryWiseBlog: [],
  followBloggerList: [],
  CategoryWiseBlog: [],
  UserWiseBlog: [],
  AddBlogStory: [],
  BlogStoryList: [],
  blogViewRating: [],
  blogViewComments: [],
  BookMarkList: [],
  viewBloggerProfile: [],
  bloggersFollow: null,
  TagWiseBlog:[]
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case blogActions.SET_BLOG_CATEGORY_LIST:
      return {
        ...state,
        blogCategoryList: action.payload
      }
    case blogActions.SET_BLOG_COUNTRY_LIST:
      return {
        ...state,
        blogCountryList: action.payload
      }
    case blogActions.SET_CREATE_BLOG_DATA:
      return {
        ...state,
        createBlogData: action.payload
      }
    case blogActions.SET_RECENT_BLOG:
      return {
        ...state,
        recentBlog: action.payload
      }
    case blogActions.SET_ALL_BLOG_LIST:
      return {
        ...state,
        allBlogList: action.payload
      }
    case blogActions.SET_BLOGGER_LIST:
      return {
        ...state,
        BlogggerList: action.payload
      }
    case blogActions.SET_POPULAR_BLOG_LIST:
      return {
        ...state,
        popularList: action.payload
      }
    case blogActions.SET_BLOG_DETAILS_LIST:
      return {
        ...state,
        blogDetailsList: action.payload
      }
    case blogActions.SET_BLOG_DISCRIPTION:
      return {
        ...state,
        BlogDiscription: action.payload
      }
    case blogActions.SET_RELATED_BLOG_LIST:
      return {
        ...state,
        RelatedBlogList: action.payload
      }
    case blogActions.SET_CREATE_BLOG_COMMENT:
      return {
        ...state,
        createBlogComment: action.payload
      }
    case blogActions.SET_DESTINATION_BLOG_LIST:
      return {
        ...state,
        destinationBlogList: action.payload
      }
    case blogActions.SET_COUNTRY_WISE_BLOG:
      return {
        ...state,
        countryWiseBlog: action.payload
      }
    case blogActions.SET_FOLLOW_BLOGGER_LIST:
      return {
        ...state,
        followBloggerList: action.payload
      }
    case blogActions.SET_CATEGORY_WISE_BLOG:
      return {
        ...state,
        CategoryWiseBlog: action.payload
      }
    case blogActions.SET_USER_WISE_BLOG:
      return {
        ...state,
        UserWiseBlog: action.payload
      }
    case blogActions.SET_ADD_BLOG_STORY:
      return {
        ...state,
        AddBlogStory: action.payload
      }
    case blogActions.SET_BLOG_STORY_LIST:
      return {
        ...state,
        BlogStoryList: action.payload
      }
    case blogActions.SET_BLOG_VIEW_RATING:
      return {
        ...state,
        blogViewRating: action.payload
      }
    case blogActions.SET_BLOG_VIEW_COMMENTS:
      return {
        ...state,
        blogViewComments: action.payload
      }
    case blogActions.SET_BOOK_MARK_LIST:
      return {
        ...state,
        BookMarkList: action.payload
      }
    case blogActions.SET_VIEW_BLOGGER_PROFILE:
      return {
        ...state,
        viewBloggerProfile: action.payload
      }
    case blogActions.SET__BLOGGER_FOLLOW_CHECK_BY_ID:
      return {
        ...state,
        bloggersFollow: action.payload
      }
    case blogActions.SET_TAG_WISE_BLOG:
        return{
          ...state,
          TagWiseBlog : action.payload
        }

    default:
      return state;
  }
};

export default blogReducer;
