import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import './user.css'
import flightIcon from '../../asset/icons/center.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import userActions from '../../redux/user/actions'
import Typography from '@mui/material/Typography';
import Modal from 'react-bootstrap/Modal';
import OTPInput from "otp-input-react";
import commonActions from '../../redux/common/actions'
import flightBookingNotFound from '../../asset/flightBookingNotFound.gif'
import moment from 'moment'

const FlightUpcomingTrip = () => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const { flightBookingList } = useSelector((state) => state.userReducer)

    const [show, setShow] = useState(false);
    const [otp, setOtp] = useState("");
    const handleClose = () => setShow(false);

    const handleOpen = (item, index) => {
        setShow(index);
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({
            type: userActions.GET_MY_BOOKING_CANCEL_VERIFY, payload: {
                // user_id: item.user_id,
                ticket_order_unique_id: item.ticket_order_unique_id,
                // OTP: otp,
                // user_password: '',
            }
        })
    }

    const confirmOtp = (e, item) => {
        console.log('item...', item)
        e.preventDefault()
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({
            type: userActions.GET_MY_BOOKING_CANCEL_CONFIRM, payload: {
                user_id: item.user_id,
                UniqueID: item.ticket_order_unique_id,
                OTP: otp,
                // user_password: '',
            }
        })
        setOtp("");
        dispatch({ type: userActions.GET_MY_BOOKING, payload: '' });
        setShow(false)
    }

    const ViewTicket = (item, index) => {
        let temp = item.id
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: userActions.GET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: temp, history: history })
    }

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: userActions.GET_MY_BOOKING, payload: '' })
    }, [])

    return (
        <div className='upcoming_trips'>
            {flightBookingList.length !== 0 ?
                <div>
                    {flightBookingList.map((item, index) =>
                        <main className='main_ticket' key={'flightBookingList' + index}>
                            <section className="flight--general">
                                <div>
                                    <h2>{item.DepartureAirportLocationCode}</h2>
                                </div>
                                <div className='flight_center_img'>
                                    <h4><img src={flightIcon} alt='img' /></h4>
                                </div>
                                <div>
                                    <h2>{item.ArrivalAirportLocationCode}</h2>
                                </div>
                            </section>
                            <section className="flight--TimeInfo">
                                <div>
                                    <h5>PNR No</h5>
                                    <p>{item.AirlinePNR}</p>
                                </div>
                                <div>
                                    <h5>Departure</h5>
                                    <p>{moment(item.DepartureDateTime).format("HH:mm")}</p>
                                </div>
                                <div>
                                    <h5>Arrival</h5>
                                    <p>{moment(item.ArrivalDateTime).format("HH:mm")}</p>
                                </div>
                                <div>
                                    <h5>Date</h5>
                                    <p>{moment(item.ArrivalDateTime).format("DD/MM/YYYY")}</p>
                                </div>
                                <div>
                                    <h5>Traveller</h5>
                                    <p>{item.TotalMembers}</p>
                                </div>
                                <div>
                                    <Button onClick={() => ViewTicket(item, index)} className='view_ticket_flight'>View Ticket</Button>
                                    <Button onClick={() => handleOpen(item, index)} className='view_ticket red'>Cancel Ticket</Button>
                                    <Modal show={show === index} onHide={handleClose} centered>
                                        <div className='cancelPop flight_cancel_otp_model'>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                <form onSubmit={(e) => confirmOtp(e, item)}>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Enter Your OTP</label>
                                                        <OTPInput
                                                            error
                                                            value={otp}
                                                            onChange={setOtp}
                                                            autoFocus
                                                            require
                                                            OTPLength={6}
                                                            otpType="number"
                                                            disabled={false}
                                                            className='otp_field'
                                                        />
                                                    </div>
                                                    <button disabled={otp.length !== 6} type="submit" className="btn btn-primary">Confirm Cancel</button>
                                                </form>
                                            </Typography>
                                        </div>
                                    </Modal>
                                </div>
                            </section>

                        </main>).reverse()}
                </div>

                :
                <div className='flight_not_found'>
                    <img src={flightBookingNotFound} alt='notFound' />
                    <h3>You Don't have any Bookings</h3>
                    <Link to='/'>Go to booking</Link>
                </div>
            }
        </div>
    )
}

export default React.memo(FlightUpcomingTrip);