import actions from "./actions";

const initialState = {
  homeCount: null,
  homeTestimonial:[]
};
const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_HOME_COUNT:
      return {
        ...state,
        homeCount: action.payload
      }
      case actions.SET_HOME_TESTIMONIAL:
      return {
        ...state,
        homeTestimonial: action.payload
      }
    default:
      return state;
  }
};

export default homeReducer;