import React from 'react'
import { useState } from 'react'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import flightActions from '../../redux/flight/actions'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'

export const PnrCancel = () => {

    const dispatch = useDispatch()
    const { pnr } = useSelector((state) => state.flightReducer)
    const history = useNavigate()

    const [btnDis, setBtnDis] = useState(false)

    const OnSendOtp = (e) => {
        e.preventDefault()
        dispatch({
            type: flightActions.GET_PNR_TICKET_CANCEL, payload: {
                AirlinePNR: pnr
            },
            history: history
        },)
    }

    useEffect(() => {
        if (pnr === '') {
            setBtnDis(true)
        } else {
            setBtnDis(false)
        }
    }, [pnr])

    return (
        <>
            <div className='pnr_cancel_sec'>
                <div className='pnr_cancel_card'>
                    <form onSubmit={(e) => OnSendOtp(e)}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">PNR Number</label>
                            <input type="text" className="form-control" placeholder="Enter your PNR" name='pnrNo' value={pnr} required='required' onChange={(e) => dispatch({ type: flightActions.SET_PNR, payload: e.target.value })} />
                            <small id="emailHelp" className="form-text text-muted">Enter the ticket PNR no to send otp your register mail i'd</small>
                        </div>
                        <Button disabled={btnDis} type="submit">Send OTP</Button>
                    </form>
                </div>
            </div>
        </>
    )
}
