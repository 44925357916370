import React, { useState, useEffect } from "react";
import FlightsTrip from "./FlightsTrip";
import { Box, Container, Grid } from "@material-ui/core";
import FlightFilter from "./FlightFilter";
import FlightList1 from "./FlightList1";
import { useDispatch, useSelector } from "react-redux";
import flightActions from "../../redux/flight/actions";
import commonActions from "../../redux/common/actions";
// import hotelActions from '../../redux/hotel/actions';
import travellerActions from "../../redux/traveller/actions";
import { useNavigate } from "react-router";
import flightBannerImg from "../../asset/images/flight-bg.avif";

function Content(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { flightList, SearchFlight, resultData } = useSelector(
    (state) => state.flightReducer
  );
 

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(8);
  const [selectedFlightList, setSelectedFlightList] = useState([]);

  const handleCabinChange = (name) => {
    let datas = {
      airport_from_code: SearchFlight.airport_from_code,
      airport_to_code: SearchFlight.airport_to_code,
      return_date:
        SearchFlight.journey_type === "RoundTrip"
          ? SearchFlight.return_date
          : undefined,
      departure_date: SearchFlight.departure_date,
      adult_flight: SearchFlight.adult_flight,
      child_flight: SearchFlight.child_flight,
      infant_flight: SearchFlight.infant_flight,
      class: name,
      journey_type: SearchFlight.journey_type,
    };
    dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({ type: flightActions.SET_SEARCH_FLIGHT, payload: datas });
    console.log("datas",datas)
    dispatch({
      type: flightActions.GET_ALL_FLIGHT,
      flightData: datas,
      history: history,
    });
  };

  // const showPosition = (position) => {
  //     let lat = position.coords.latitude;
  //     let lon = position.coords.longitude;
  //     return { lat, lon };
  // }

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (resultData.length > 0) {
      if (resultData.length > 7) {
        setSelectedFlightList(
          [...resultData].slice((page - 1) * 8, (page - 1) * 8 + 8)
        );
        setCount(Math.ceil([...resultData].length / 8));
      } else {
        setSelectedFlightList([...resultData].slice(0, resultData.length));
        setCount(1);
      }
    } else {
      setSelectedFlightList([]);
      setCount(1);
    }
  }, [resultData, page]);

  useEffect(() => {
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: flightList });
  }, [flightList]);

  useEffect(() => {
    if (localStorage.getItem("searchValues")) {
      let temp1 = JSON.parse(localStorage.getItem("searchValues"));
      localStorage.removeItem("searchValues");
      let datas = {
        journey_type: temp1.journey_type,
        airport_from_code: temp1.airport_from_code,
        airport_to_code: temp1.airport_to_code,
        airport_from: temp1.airport_from,
        airport_to: temp1.airport_to,
        departure_date: temp1.departure_date,
        return_date:
          temp1.journey_type === "Return" ? temp1.return_date : undefined,
        adult_flight: temp1.adult_flight,
        child_flight: temp1.child_flight,
        class: temp1.class,
        infant_flight: temp1.infant_flight,
        target: temp1.target,
        dep_date: temp1.dep_date,
        req_date: temp1.req_date,
      };

      
      dispatch({ type: flightActions.SET_SEARCH_FLIGHT, payload: datas });
      dispatch({
        type: flightActions.GET_ALL_FLIGHT,
        flightData: datas,
        history: history,
        change: true,
      });
    } else {
      dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: travellerActions.GET_TRAVELLER_LIST });
  }, []);
  return (
    <div>
      <div className="sub_banner">
        <div className="Sub_page_banner_overlay"></div>
        {window.location.pathname !== "/" ? (
          <div>
            <div
              className="hotelBanner"
              style={{ backgroundImage: `url(${flightBannerImg})` }}
            >
              {/* <h1 className="text-white">Flight List</h1> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="trip_sec flight_list_section home_hotel_search">
        <div className="flight_list_search">
          <FlightsTrip mode={props.mode} />
        </div>
      </div>
      {props.mode === "Update" && (
        <Container>
          <div className="flight_grid_list">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
               
                  <FlightFilter
                    handleCabinChange={handleCabinChange}
                    SearchFlight={SearchFlight}
                  />
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
               
                  <FlightList1
                    selectedFlightList={selectedFlightList}
                    count={count}
                    handlePaginationChange={handlePaginationChange}
                    page={page}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </Container>
      )}
    </div>
  );
}

export default React.memo(Content);

