import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Blogimage1 from '../../asset/images/blog-1.png';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { PinDrop, } from '@material-ui/icons';
import { Link } from 'react-router-dom'

export default function BlogCountryCard() {

  return (
    <div>
      <Link to='/blog-detail'>
        <Card sx={{ maxWidth: '100%' }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              image={Blogimage1}
              alt="green iguana"
            />
            <CardContent className='cardcon'>
              <Typography className='cardtd' variant="h5">
                Lizard
              </Typography>
              <Stack direction="row" spacing={2} className='cardbtns' >
                <Button variant="outlined" startIcon={<PinDrop />}>
                  20 Places
                </Button>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </div>
  )
}
