import React, { useEffect } from 'react'
import OurExperience from '../components/home/OurExperience'
import PopularPlace from '../components/home/PopularPlace'
import Testimonial from '../components/home/Testimonial'
import TravelSupport from '../components/home/TravelSupport'
import MenuTab from '../components/home/MenuTab';
import { useDispatch } from 'react-redux';
import blogActions from '../redux/blog/actions';

export default function Home() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: blogActions.GET_POPULAR_BLOG_LIST })
  }, []);

  return (
    <>
      <div className="banner_overlay"></div>
      <div className='home_flight_search home_hotel_search '>
        <MenuTab />
      </div>
      {/* <PopularPlace />
      <TravelSupport />
      <OurExperience />
      <Testimonial /> */}
    </>
  )
}
