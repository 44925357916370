import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Container, Typography } from '@material-ui/core';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import quote from '../../asset/icons/quotes.png';
import testimonialImg from '../../asset/images/testimonialImg.png';
import messageImg from '../../asset/images/icons/te-3.svg';
import mapBg from '../../asset/images/map.png'
import { useDispatch, useSelector } from 'react-redux';
import homeActions from '../../redux/home/actions';
import { API_IMG_URL } from '../../utils/constant';

const useStyles = makeStyles((theme) => ({
    checked: {
        color: '#ffcc00',
    },
    testimonial: {
        position: 'relative',
        backgroundImage: `url(${mapBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: ' center bottom',
        paddingBottom: '5rem',
    }
}));

const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    loop: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 2,
        }
    },
};

function Testimonial() {

    const dispatch = useDispatch()
    const classes = useStyles();
    const { homeTestimonial } = useSelector((state) => state.homeReducer)

    useEffect(() => {
        dispatch({ type: homeActions.GET_HOME_TESTIMONIAL })
    }, [])

    return (
        <div className={classes.testimonial}>
            <Container>
                <Grid className='row' >
                    <Grid className='col-md-8 col-lg-8 col-sm-12 col-12'>
                        <Grid className='experience_slider testimonial_slider'>
                            <Box className='ourexperience_area_inner'>
                                <img className='messageImg' src={messageImg} alt='' />
                                <Typography variant='h2'>Testimonial</Typography>
                                <Typography variant='h5'>Our Customer Feedback</Typography>
                            </Box>
                            {homeTestimonial.length > 0 &&
                                <OwlCarousel className='owl-theme testimonialSlider' {...options}>
                                    {homeTestimonial?.map((val, index) => (
                                        <div className='item_testimonial_slider' key={index}>
                                            <img className='testimonial_img' src={`${API_IMG_URL}/server/testimonial/${val.image}`} alt='' />
                                            <img className='quote_img' src={quote} alt='' />
                                            <div className='testimonial_slider_content'>
                                                <Typography variant='inherit'>{val.description}</Typography>
                                                <Typography variant='h5' className='user_name'>{val.name}</Typography>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            }
                        </Grid>
                    </Grid>

                    <Grid className='col-md-4 col-lg-4 col-sm-12 col-12'>
                        <Box className='testimonial_main_img'>
                            <img src={testimonialImg} alt='' />
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default React.memo(Testimonial);