import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { TableCell } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "@mui/material/Modal";
import Pagination from "@mui/material/Pagination";
import "antd/dist/antd.css";
import { useDispatch, useSelector } from "react-redux";
import flightIcon from "../../asset/icons/center.svg";
import { BookingModel } from "./BookingModel";
import actions from "../../redux/flight/actions";
import commonActions from "../../redux/common/actions";
import { API_IMG_URL } from "../../utils/constant";
import loaderGifImg from "../../asset/blueflightloader.gif";
import flightActions from "../../redux/flight/actions";
import { Link } from "react-router-dom";
import foundImg from "../../asset/icon-nothing-found.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  height: "85vh",
  borderRadius: "1rem",
};
const InnerList = {
  margin: 10,
  responsiveClass: true,
  nav: false,
  loop: false,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    850: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};
function Item(props) {
  const { sx, ...other } = props;
  return <Box {...other} />;
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  flightname: {
    color: "#000",
    fontSize: "15px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "25%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.Boxider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function FlightList1(props) {
  // console.log('prps',props.guestMode)
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    resultData,
    fareRulesFlight,
    fareRulesLoader,
    flightLoader,
    SearchFlight,
  } = useSelector((state) => state.flightReducer);
  const { flightList } = useSelector((state) => state.flightReducer);

  var [guestMode, setGuestMode] = useState(
    !!localStorage.getItem("tickatrip-token")
  );
  const [details, setDetails] = useState("");
  const [value, setValue] = useState("1");
  const [dropdown, setDropdown] = useState([]);
  const [open, setOpen] = useState("");

  const handleClose = () => {
    setOpen("");
    dispatch({ type: flightActions.SET_VALIDATE_FARE_METHOD, payload: [] });
    dispatch({ type: flightActions.SET_BOOKING_CONFIRM, payload: false });
    dispatch({ type: flightActions.SET_BOOKIG_RESPONSE_DATA, payload: null });
  };

  const handleFlightDetail = (item, index) => {
    setDetails(item);
    setValue("1");
    dispatch({ type: actions.SET_FARE_RULES, payload: null });
    dispatch({
      type: actions.GET_FARE_RULES,
      payload: {
        fareSourceCode: item?.FareSourceCode,
      },
    });
    dispatch({ type: actions.SET_FARE_RULES_LOADER, payload: true });
  };

  const handleOpen = (it, index) => {
    dispatch({ type: commonActions.SET_LOADER, payload: true });
    setOpen(it);
    dispatch({
      type: actions.GET_VALIDATE_FARE_METHOD,
      payload: {
        fare_source_code: it?.FareSourceCode,
      },
    });
    dispatch({ type: actions.SET_FARE_RULES, payload: null });
    dispatch({
      type: actions.SET_BOOKIN_FARE_SOURCE_METHOD,
      payload: {
        fare_source_code: it?.FareSourceCode,
        fare_type: it.FareType,
      },
    });
  };

  function timeConvert(n) {
    var num = n;
    var hours = Math.floor(num / 60) > 0 ? Math.floor(num / 60) + "H " : "";
    var rminutes =
      n - Math.floor(num / 60) * 60 > 0
        ? n - Math.floor(num / 60) * 60 + "M"
        : "";
    return hours + rminutes;
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleDropdown = (index) => {
    if (dropdown.includes(index)) {
      setDropdown(dropdown.filter((el) => el !== index));
    } else {
      setDropdown([...dropdown, index]);
    }
  };

  // console.log("Flight List tick a trip:", flightList);
  // console.log(
  //   "Selected Flight List tick a trip :",
  //   props?.selectedFlightList[1]
  // );
console.log("inside filter result page",props?.selectedFlightList)
  return (
    <>
      <Box className="flight_payment_pop">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Box
              sx={style}
              className={`${flightLoader && "model_overFlow_box"}`}
            >
              <BookingModel
                it={open}
                guestMode={guestMode}
                setGuestMode={setGuestMode}
              />
            </Box>
            <Box className="comlete_head">
              {!guestMode && (
                <div className="guest_or_login_check">
                  <div className="blur_bg"></div>
                  <div className="continue_guest">
                    <ul>
                      <li>
                        <button
                          className="book_tick pop_con"
                          onClick={() => setGuestMode(!guestMode)}
                        >
                          Continue as Guest
                        </button>
                      </li>
                      <li>OR</li>
                      <li>
                        <Link className="book_login" to="/login">
                          Login
                        </Link>
                      </li>
                    </ul>{" "}
                  </div>
                </div>
              )}
            </Box>
          </div>
        </Modal>
      </Box>
      <Box className="flight_payment_pop">
        <Modal
          open={details}
          onClose={() => setDetails("")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <TabList className="tb_bnl" onChange={handleChangeTab}>
                  <Tab
                    label={
                      <span className={classes.tabLabel}>Flight Details</span>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <span className={classes.tabLabel}>Refund Rules</span>
                    }
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel className={classes.tabinner} value="1">
                {details.flight_details?.map((item1, ind) => (
                  <div key={"details.flight_details" + ind}>
                    {item1.flights?.map((inst, i) => {
                      return (
                        <div key={"item1.flights" + i}>
                          <Box className={classes.root}>
                            <Box className="flight_root">
                              <Box>
                                <Box className="ticket_tab_con_head flight_root_details">
                                  <TableCell className={`${classes.column}`}>
                                    <div className=" flight_image_grid">
                                      <img
                                        className="floght_single_logo"
                                        src={
                                          API_IMG_URL +
                                          "/server/flightimage/" +
                                          details?.flightUrl
                                        }
                                        style={{
                                          width: "60px",
                                          marginRight: "0.5rem",
                                          height: "60px",
                                          display: "inline-block",
                                          verticalAlign: "middle",
                                          borderRadius: "50%",
                                        }}
                                        alt={details?.flightName}
                                      />
                                      <span className="flight_number">
                                        {inst.flightList.OperatingAirline.Code +
                                          inst.flightList.OperatingAirline
                                            .FlightNumber}
                                      </span>
                                    </div>
                                  </TableCell>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                      p: 0,
                                      m: 0,
                                      bgcolor: "background.paper",
                                      borderRadius: 1,
                                    }}
                                    className="destinamtion_flight"
                                  >
                                    <Item className="flight_desk">
                                      <span className="flight_from">
                                        {inst.departureLocation}(
                                        {
                                          inst.flightList
                                            .DepartureAirportLocationCode
                                        }
                                        )
                                      </span>
                                      <br />
                                      <span className="flight_timr">
                                        {moment(
                                          inst.flightList.DepartureDateTime
                                        )
                                          ?.format("hh:mm:ss")
                                          .substring(0, 5)}
                                      </span>
                                      <br />
                                      <span className="flight_when">
                                        {moment(
                                          inst.flightList.DepartureDateTime
                                        )
                                          ?.format("hh:mm:ss")
                                          .substring(9, 11)
                                          ?.toUpperCase()}
                                      </span>
                                    </Item>
                                    <Item className="flight_desk desk_ce flightTimingList">
                                      <span className="flight_hrs">
                                        {timeConvert(
                                          inst.flightList.JourneyDuration
                                        )}
                                      </span>
                                      <span className="flight_timr flight_icon">
                                        <img src={flightIcon} alt="img" />
                                      </span>
                                    </Item>
                                    <Item className="flight_desk">
                                      <span className="flight_from">
                                        {inst.arrivalLocation}(
                                        {
                                          inst.flightList
                                            .ArrivalAirportLocationCode
                                        }
                                        )
                                      </span>
                                      <br />
                                      <span className="flight_timr">
                                        {moment(inst.flightList.ArrivalDateTime)
                                          ?.format("hh:mm:ss")
                                          .substring(0, 5)}
                                      </span>
                                      <br />
                                      <span className="flight_when">
                                        {moment(inst.flightList.ArrivalDateTime)
                                          ?.format("hh:mm:ss")
                                          .substring(9, 11)
                                          ?.toUpperCase()}
                                      </span>
                                    </Item>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {item1.flights[i + 1] && (
                            <Box className="connecting_waiting_time">
                              {timeConvert(
                                moment(
                                  item1.flights[i + 1].flightList
                                    .DepartureDateTime
                                ).diff(
                                  item1.flights[i].flightList.ArrivalDateTime,
                                  "minutes"
                                )
                              )}
                            </Box>
                          )}
                        </div>
                      );
                    })}
                    <hr></hr>
                  </div>
                ))}
              </TabPanel>
              <TabPanel className={classes.tabinner} value="2">
                <Box className="flight_dropdown_details">
                  <Box className="trip_price_slider trip_rules">
                    {fareRulesLoader ? (
                      <div>
                        <div className="blur_bg"></div>
                        <div className="loaderGif">
                          <img src={loaderGifImg} alt="" />
                        </div>
                      </div>
                    ) : fareRulesFlight?.startsWith("https") ? (
                      <>
                        <div>
                          <p>Kindly visit the following link for rules:</p>

                          <b>
                            <a
                              href={fareRulesFlight}
                              dangerouslySetInnerHTML={{
                                __html: fareRulesFlight,
                              }}
                            ></a>
                          </b>
                        </div>
                      </>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: fareRulesFlight,
                        }}
                      ></div>
                    )}
                  </Box>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Modal>
      </Box>

      {flightList?.length !== 0 ? (
        <div>
          {flightList?.length > 0 && (
            <>
              <Box className="flight_list_sec">
                {props?.selectedFlightList?.length !== 0 ? (
                  <div>
                   
                    {SearchFlight?.journey_type === "OneWay"
                      ? props?.selectedFlightList?.map((it, index) => {
                          return (
                            <div
                              className={
                                dropdown?.includes(it[0]?.FareSourceCode)
                                  ? "p-r border-green-line"
                                  : "p-r"
                              }
                              key={"selectedFlightList" + index}
                            >
                      
                              {it[0]?.flight_details?.map((item1, ind) => {
                              
                                return (
                                  <div key={"flight_details" + ind}>
                                    <>
                                   
                                   
                                      <Box
                                        className={classes.root}
                                        key={"ticket_tab_con_head" + index}
                                      >
                                        <Box className="ticket_tab_con_head">
                                          <div className="MuiTableCell-root MuiTableCell-sizeMedium makeStyles-column-29">
                                            <div className=" flight_image_grid">
                                              <img
                                                className="floght_single_logo"
                                                src={
                                                  API_IMG_URL +
                                                  "/server/flightimage/" +
                                                  it[0].flightUrl
                                                }
                                                style={{
                                                  width: "60px",
                                                  marginRight: "0.5rem",
                                                  height: "60px",
                                                  display: "inline-block",
                                                  verticalAlign: "middle",
                                                  borderRadius: "50%",
                                                }}
                                                alt="FlightImage1"
                                              />
                                              <span
                                                className="flightname"
                                                style={{
                                                  color: "#3d3d3d",
                                                  cursor: "pointer",
                                                  display: "inline-block",
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                {it[0].flightName}
                                              </span>
                                            </div>

                                            <span className="flight_number">
                                              {item1.flights
                                                .map(
                                                  (obj) =>
                                                    obj.flightList
                                                      .OperatingAirline.Code +
                                                    obj.flightList
                                                      .OperatingAirline
                                                      .FlightNumber
                                                )
                                                ?.join(" / ")}
                                            </span>
                                          </div>

                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                              p: 0,
                                              m: 0,
                                              bgcolor: "background.paper",
                                              borderRadius: 1,
                                            }}
                                            className="destinamtion_flight"
                                          >
                                            <Item className="flight_desk">
                                              {/* <span className="flight_from">
                                                {
                                                  item1.flights[0]
                                                    .departureLocation
                                                }
                                                (
                                                {
                                                  item1.flights[0].flightList
                                                    .DepartureAirportLocationCode
                                                }
                                                )
                                              </span> */}
                                              <span
                                                className="flight_from"
                                                onClick={() =>
                                                  console.log(
                                                    "Departure Location:",
                                                    item1.flights[0]
                                                      .departureLocation,
                                                    "Airport Code:",
                                                    item1.flights[0].flightList
                                                      .DepartureAirportLocationCode
                                                  )
                                                }
                                              >
                                                {
                                                  item1.flights[0]
                                                    .departureLocation
                                                }
                                                (
                                                {
                                                  item1.flights[0].flightList
                                                    .DepartureAirportLocationCode
                                                }
                                                )
                                              </span>

                                              <br />
                                              <span className="flight_timr">
                                                {moment(
                                                  item1.flights[0].flightList
                                                    .DepartureDateTime
                                                )
                                                  ?.format("HH:mm:ss")
                                                  .substring(0, 5)}
                                              </span>
                                              <br />
                                              <span className="flight_when">
                                                {moment(
                                                  item1.flights[0].flightList
                                                    .DepartureDateTime
                                                )
                                                  ?.format("HH:mm:ss")
                                                  .substring(9, 11)
                                                  ?.toUpperCase()}
                                              </span>
                                            </Item>
                                            <Item className="flight_desk desk_ce flightTimingList">
                                              <span className="flight_hrs">
                                                {timeConvert(
                                                  item1.flights.reduce(
                                                    (total, val) =>
                                                      (total =
                                                        total +
                                                        parseFloat(
                                                          val.flightList
                                                            .JourneyDuration
                                                            ? parseFloat(
                                                                val.flightList
                                                                  .JourneyDuration
                                                              )
                                                            : 0
                                                        )),
                                                    0
                                                  )
                                                )}
                                              </span>
                                              <span className="flight_timr flight_icon">
                                                <img
                                                  src={flightIcon}
                                                  alt="img"
                                                />
                                              </span>
                                              <span className="stop">
                                                {item1.totalStops} Stop
                                              </span>
                                            </Item>
                                            <Item className="flight_desk">
                                              {/* <span className="flight_from">
                                                {
                                                  item1.flights[
                                                    item1.flights.length - 1
                                                  ].arrivalLocation
                                                }
                                                (
                                                {
                                                  item1.flights[
                                                    item1.flights.length - 1
                                                  ].flightList
                                                    .ArrivalAirportLocationCode
                                                }
                                                )
                                              </span> */}
                                              <span
                                                className="flight_from"
                                                onClick={() =>
                                                  console.log(
                                                    "Arrival Location:",
                                                    item1.flights[
                                                      item1.flights.length - 1
                                                    ].arrivalLocation,
                                                    "Airport Code:",
                                                    item1.flights[
                                                      item1.flights.length - 1
                                                    ].flightList
                                                      .ArrivalAirportLocationCode
                                                  )
                                                }
                                              >
                                                {
                                                  item1.flights[
                                                    item1.flights.length - 1
                                                  ].arrivalLocation
                                                }
                                                (
                                                {
                                                  item1.flights[
                                                    item1.flights.length - 1
                                                  ].flightList
                                                    .ArrivalAirportLocationCode
                                                }
                                                )
                                              </span>

                                              <br />
                                              <span className="flight_timr">
                                                {moment(
                                                  item1.flights[
                                                    item1.flights.length - 1
                                                  ].flightList.ArrivalDateTime
                                                )
                                                  ?.format("HH:mm:ss")
                                                  .substring(0, 5)}
                                              </span>
                                              <br />
                                              <span className="flight_when">
                                                {moment(
                                                  item1.flights[
                                                    item1.flights.length - 1
                                                  ].flightList.ArrivalDateTime
                                                )
                                                  ?.format("HH:mm:ss")
                                                  .substring(9, 11)
                                                  ?.toUpperCase()}
                                              </span>
                                            </Item>
                                          </Box>
                                          <Box className="flight_view_details book_btm">
                                            <div className="flight_booking_price_list">
                                              {/* <Button className="book_tick_price">
                                                                                            <span className="rs">{it[0].CurrencyCode}: </span>
                                                                                            {it[0].totalFare}{" "}
                                                                                        </Button> */}
                                              <Button className="book_tick_price">
                                                <span className="rs">
                                                  {it[0]?.CurrencyCode} :
                                                </span>
                                                {it[0]?.totalFare.toLocaleString()}{" "}
                                              </Button>

                                              <Button
                                                onClick={() =>
                                                  handleDropdown(
                                                    it[0]?.FareSourceCode
                                                  )
                                                }
                                                className="book_tick open_flight_list"
                                              >
                                                {dropdown?.includes(
                                                  it[0]?.FareSourceCode
                                                )
                                                  ? "Hide Details"
                                                  : "View Details"}
                                              </Button>
                                            </div>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </>
                                  </div>
                                );
                              })}

                              <div
                                className={
                                  dropdown?.includes(it[0]?.FareSourceCode)
                                    ? "booking_list_detailss"
                                    : "booking_list_detailss hide-style"
                                }
                              >
                                {dropdown?.includes(it[0]?.FareSourceCode) ? (
                                  <div
                                    className={
                                      it.length > 3
                                        ? "owlCarousel_booking_slider"
                                        : "owlCarousel_booking_slider arrow_dis_none"
                                    }
                                  >
                                    <OwlCarousel
                                      className="owl-theme"
                                      {...InnerList}
                                    >
                                      {it.map((it1) =>
                                        it1.flight_details?.map(
                                          (item1, ind) => {
                                            return (
                                              <div className="flight-inner_gird_box">
                                                <div className="list_flight_inner_gird_slider">
                                                  <div className="list_flight_inner_img_and_price">
                                                    <div className="list_flight_inner_img">
                                                      <img
                                                        className="floght_single_logo"
                                                        src={
                                                          API_IMG_URL +
                                                          "/server/flightimage/" +
                                                          it1.flightUrl
                                                        }
                                                        style={{
                                                          width: "40px",
                                                          marginRight: "0.5rem",
                                                          height: "40px",
                                                          display:
                                                            "inline-block",
                                                          verticalAlign:
                                                            "middle",
                                                          borderRadius: "50px",
                                                          border:
                                                            "1px solid #cfcfcf",
                                                        }}
                                                        alt="FlightImage1"
                                                      />
                                                    </div>
                                                    <div className="flight-name-flx">
                                                      <span
                                                        className="flightname_innner_list"
                                                        style={{
                                                          color: "#3d3d3d",
                                                          cursor: "pointer",
                                                          display:
                                                            "inline-block",
                                                          verticalAlign:
                                                            "middle",
                                                        }}
                                                      >
                                                        {it1.flightName}
                                                      </span>
                                                      <span className="flight_number">
                                                        {item1.flights
                                                          .map(
                                                            (obj) =>
                                                              obj.flightList
                                                                .OperatingAirline
                                                                .Code +
                                                              obj.flightList
                                                                .OperatingAirline
                                                                .FlightNumber
                                                          )
                                                          ?.join(" / ")}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="list_item_price_list">
                                                    <div className="inner_grid_price">
                                                      <div className="book_tick_price">
                                                        <span className="rs">
                                                          {it1.CurrencyCode} :{" "}
                                                        </span>
                                                        {it1.totalFare.toLocaleString()}{" "}
                                                      </div>
                                                    </div>
                                                    <div className="inner_grid_stops">
                                                      <span className="flight_hrs before_line">
                                                        {timeConvert(
                                                          item1.flights.reduce(
                                                            (total, val) =>
                                                              (total =
                                                                total +
                                                                parseFloat(
                                                                  val.flightList
                                                                    .JourneyDuration
                                                                    ? parseFloat(
                                                                        val
                                                                          .flightList
                                                                          .JourneyDuration
                                                                      )
                                                                    : 0
                                                                )),
                                                            0
                                                          )
                                                        )}
                                                      </span>
                                                      <span className="stop">
                                                        {item1.totalStops} Stop
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="border_bottom_line"></div>
                                                <div className="boiz_list">
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                      p: 0,
                                                      m: 0,
                                                      bgcolor:
                                                        "background.paper",
                                                      borderRadius: 1,
                                                    }}
                                                    className="flight_icons_grid_item"
                                                  >
                                                    <Item className="airport_names">
                                                      <span className="airport_inner_names">
                                                        {
                                                          item1.flights[0]
                                                            .departureLocation
                                                        }
                                                        (
                                                        {
                                                          item1.flights[0]
                                                            .flightList
                                                            .DepartureAirportLocationCode
                                                        }
                                                        )
                                                      </span>
                                                      <br />
                                                      <span className="flight_timr">
                                                        {moment(
                                                          item1.flights[0]
                                                            .flightList
                                                            .DepartureDateTime
                                                        )
                                                          ?.format("HH:mm:ss")
                                                          .substring(0, 5)}
                                                      </span>
                                                      <br />
                                                      <span className="flight_when">
                                                        {moment(
                                                          item1.flights[0]
                                                            .flightList
                                                            .DepartureDateTime
                                                        )
                                                          ?.format("HH:mm:ss")
                                                          .substring(9, 11)
                                                          ?.toUpperCase()}
                                                      </span>
                                                    </Item>

                                                    <Item className="">
                                                      <span className="">
                                                        <img
                                                          src={flightIcon}
                                                          alt="img"
                                                        />
                                                      </span>
                                                    </Item>
                                                    <Item className="airport_names right_text">
                                                      <span className="airport_inner_names">
                                                        {
                                                          item1.flights[
                                                            item1.flights
                                                              .length - 1
                                                          ].arrivalLocation
                                                        }
                                                        (
                                                        {
                                                          item1.flights[
                                                            item1.flights
                                                              .length - 1
                                                          ].flightList
                                                            .ArrivalAirportLocationCode
                                                        }
                                                        )
                                                      </span>
                                                      <br />
                                                      <span className="flight_timr">
                                                        {moment(
                                                          item1.flights[
                                                            item1.flights
                                                              .length - 1
                                                          ].flightList
                                                            .ArrivalDateTime
                                                        )
                                                          ?.format("HH:mm:ss")
                                                          .substring(0, 5)}
                                                      </span>
                                                      <br />
                                                      <span className="flight_when">
                                                        {moment(
                                                          item1.flights[
                                                            item1.flights
                                                              .length - 1
                                                          ].flightList
                                                            .ArrivalDateTime
                                                        )
                                                          ?.format("HH:mm:ss")
                                                          .substring(9, 11)
                                                          ?.toUpperCase()}
                                                      </span>
                                                    </Item>
                                                  </Box>
                                                </div>
                                                <div className="border_bottom_line"></div>
                                                <div className="flight_fare_grid">
                                                  <div className="view_fare_inner_det">
                                                    <Button
                                                      onClick={() =>
                                                        handleFlightDetail(
                                                          it1,
                                                          index
                                                        )
                                                      }
                                                      className="view_flight_class"
                                                    >
                                                      {SearchFlight?.class
                                                        ? SearchFlight?.class +
                                                          " Class"
                                                        : "Economy Class"}
                                                    </Button>
                                                    <Button
                                                      onClick={() =>
                                                        handleFlightDetail(
                                                          it1,
                                                          index
                                                        )
                                                      }
                                                      className="view_flight_detail_btn"
                                                    >
                                                      Fare Details/Rules
                                                    </Button>
                                                    <div>
                                                      <h6 className="refundable-non MuiButton-label">
                                                        {it1?.IsRefundable
                                                          ? "partially refundable"
                                                          : "Non Refundable"}
                                                      </h6>
                                                    </div>
                                                  </div>

                                                  <div className="booking_flight_list_btn">
                                                    <Button
                                                      onClick={() =>
                                                        handleOpen(it1, index)
                                                      }
                                                      className="book_tick"
                                                    >
                                                      Book Now
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      )}
                                    </OwlCarousel>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          );
                        })
                      : props?.selectedFlightList?.map((it, index) => {
                          return (
                            <div
                              className="p-r"
                              key={"selectedFlightList" + index}
                            >
                              {it.flight_details?.map((item1, ind) => {
                                return ind === 0 ? (
                                  <div key={"flight_details" + ind}>
                                    <>
                                      <Box
                                        className={classes.root}
                                        key={"ticket_tab_con_head" + index}
                                      >
                                        <Box>
                                          <Box className="ticket_tab_con_head">
                                            <div className="MuiTableCell-root MuiTableCell-sizeMedium makeStyles-column-29">
                                              <div className=" flight_image_grid">
                                                <img
                                                  className="floght_single_logo"
                                                  src={
                                                    API_IMG_URL +
                                                    "/server/flightimage/" +
                                                    it.flightUrl
                                                  }
                                                  style={{
                                                    width: "60px",
                                                    marginRight: "0.5rem",
                                                    height: "60px",
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    borderRadius: "50%",
                                                  }}
                                                  alt="FlightImage1"
                                                />
                                                <span
                                                  className="flightname"
                                                  style={{
                                                    color: "#3d3d3d",
                                                    cursor: "pointer",
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                  }}
                                                >
                                                  {it.flightName}
                                                </span>
                                              </div>
                                              <span className="flight_number">
                                                {item1.flights
                                                  .map(
                                                    (obj) =>
                                                      obj.flightList
                                                        .OperatingAirline.Code +
                                                      obj.flightList
                                                        .OperatingAirline
                                                        .FlightNumber
                                                  )
                                                  ?.join(" / ")}
                                              </span>
                                            </div>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                p: 0,
                                                m: 0,
                                                bgcolor: "background.paper",
                                                borderRadius: 1,
                                              }}
                                              className="destinamtion_flight"
                                            >
                                              <Item className="flight_desk">
                                                <span className="flight_from">
                                                  {
                                                    item1.flights[0]
                                                      .departureLocation
                                                  }
                                                  (
                                                  {
                                                    item1.flights[0].flightList
                                                      .DepartureAirportLocationCode
                                                  }
                                                  )
                                                </span>
                                                <br />
                                                <span className="flight_timr">
                                                  {moment(
                                                    item1.flights[0].flightList
                                                      .DepartureDateTime
                                                  )
                                                    ?.format("HH:mm:ss")
                                                    .substring(0, 5)}
                                                </span>
                                                <br />
                                                <span className="flight_when">
                                                  {moment(
                                                    item1.flights[0].flightList
                                                      .DepartureDateTime
                                                  )
                                                    ?.format("HH:mm:ss")
                                                    .substring(9, 11)
                                                    ?.toUpperCase()}
                                                </span>
                                              </Item>
                                              <Item className="flight_desk desk_ce flightTimingList">
                                                <span className="flight_hrs">
                                                  {timeConvert(
                                                    item1.flights.reduce(
                                                      (total, val) =>
                                                        (total =
                                                          total +
                                                          parseFloat(
                                                            val.flightList
                                                              .JourneyDuration
                                                              ? parseFloat(
                                                                  val.flightList
                                                                    .JourneyDuration
                                                                )
                                                              : 0
                                                          )),
                                                      0
                                                    )
                                                  )}
                                                </span>
                                                <span className="flight_timr flight_icon">
                                                  <img
                                                    src={flightIcon}
                                                    alt="img"
                                                  />
                                                </span>
                                                <span className="stop">
                                                  {item1.totalStops} Stop
                                                </span>
                                              </Item>
                                              <Item className="flight_desk">
                                                <span className="flight_from">
                                                  {
                                                    item1.flights[
                                                      item1.flights.length - 1
                                                    ].arrivalLocation
                                                  }
                                                  (
                                                  {
                                                    item1.flights[
                                                      item1.flights.length - 1
                                                    ].flightList
                                                      .ArrivalAirportLocationCode
                                                  }
                                                  )
                                                </span>
                                                <br />
                                                <span className="flight_timr">
                                                  {moment(
                                                    item1.flights[
                                                      item1.flights.length - 1
                                                    ].flightList.ArrivalDateTime
                                                  )
                                                    ?.format("HH:mm:ss")
                                                    .substring(0, 5)}
                                                </span>
                                                <br />
                                                <span className="flight_when">
                                                  {moment(
                                                    item1.flights[
                                                      item1.flights.length - 1
                                                    ].flightList.ArrivalDateTime
                                                  )
                                                    ?.format("HH:mm:ss")
                                                    .substring(9, 11)
                                                    ?.toUpperCase()}
                                                </span>
                                              </Item>
                                            </Box>
                                            <Box className="flight_view_details book_btm">
                                              <div className="mobile_view">
                                                <Button className="book_tick_price">
                                                  <span className="rs">
                                                    {it.CurrencyCode} :{" "}
                                                  </span>
                                                  {it.totalFare}{" "}
                                                </Button>

                                                <Button
                                                  onClick={() =>
                                                    handleOpen(it, index)
                                                  }
                                                  className="book_tick"
                                                >
                                                  Book Now
                                                </Button>
                                              </div>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <div className="flight_view_details flight_dropdown_details book_btm trip_price_slider ">
                                        <Button
                                          onClick={() =>
                                            handleFlightDetail(it, index)
                                          }
                                          className="view_flight_class"
                                        >
                                          {SearchFlight?.class
                                            ? SearchFlight?.class + " Class"
                                            : "Economy Class"}
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleFlightDetail(it, index)
                                          }
                                          className="view_flight_detail_btn"
                                        >
                                          Fare Details/Rules
                                        </Button>
                                      </div>
                                    </>
                                  </div>
                                ) : (
                                  <></>
                                );
                              })}
                            </div>
                          );
                        })}
                    <Pagination
                      count={props.count}
                      defaultPage={1}
                      page={resultData.length < 8 ? 1 : props.page}
                      onChange={props.handlePaginationChange}
                    />
                  </div>
                ) : (
                  <div className="no_result_found">
                    <img src={foundImg} alt="not found" />
                  </div>
                )}
              </Box>
            </>
          )}
        </div>
      ) : (
        <div className="no_result_found">
          <img src={foundImg} alt="not found" />
        </div>
      )}
    </>
  );
}

export default React.memo(FlightList1);
