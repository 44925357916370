import React, { useEffect, useState } from 'react'
import Header from '../common/header'
import popularBanner from '../../asset/home-image/blog-3.png'
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import Footer from '../common/footer'
import BlogVerticalCard from './BlogVerticalCard';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
// import cardImg from '../../asset/images/travel-1.png'
import { Row, Col, } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import blogActions from '../../redux/blog/actions';
import Masonry from 'react-masonry-css'
import InfiniteScroll from 'react-infinite-scroll-component';
import travellerActions from '../../redux/traveller/actions';

const useStyles = makeStyles((theme) =>
({
    popularBanner: {
        backgroundImage: `url(${popularBanner})`,
        backgroundRepeat: 'no-repeat',
        width: ' 100%',
        height: '100%',
        minHeight: '45vh',
        maxHeight: '45vh',
        backgroundSize: ' cover',
        backgroundPosition: ' center center',
        position: ' relative',
    },
})
)
// const blogListCard = 9;

export const BlogList = () => {
    const dispatch = useDispatch()
    const [fetchedData, setFetchedData] = useState([]);
    const { allBlogList } = useSelector((state) => state.blogReducer)

    useEffect(() => {
        dispatch({ type: blogActions.GET_ALL_BLOG_LIST })
    }, [])
    useEffect(() => {
        dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }, []);
    const classes = useStyles();
    // const blogGrid = [
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Durga devi',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     },
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Surya',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     },
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Karthi',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     },
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Vicky',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     },
    //     {
    //         cardImg: { cardImg },
    //         Name: 'Pavithran',
    //         totalBlog: '25 Blogs',
    //         title: 'We found a paradise in koh change island thailand',
    //         disc: ' This impressive paella is a perfect party dish and a fun meal to cook together with your guests'
    //     }
    // ]
    const breakpointColumnsObj = {
        default: 3,
        991: 2,
        600: 1,
    };
    useEffect(() => {
        if (allBlogList?.length > 0) {
            setFetchedData(allBlogList?.slice(0, 20));
        };
    }, [allBlogList]);

    const fetchMoreData = () => {
        if (allBlogList?.length === fetchedData?.length) {
            return;
        } else {
            let currentLength = fetchedData?.length;
            setFetchedData([...fetchedData, ...allBlogList?.slice(currentLength, currentLength + 20)])
        }
    };
    return (
        <Box className='popular_detail_sec'>
            <Header />
            <Box className={classes.popularBanner}>
                <Box className='overlay'></Box>
                {/* <Typography variant='h4'>Thailand</Typography> */}
            </Box>

            <Box sx={{ flexGrow: 1 }} className='blog_list_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Box className='recent_blog_head'>
                                <Typography variant='h3'>All blogs</Typography>
                            </Box>
                        </Col>
                        <Row>
                            {/* {allBlogList.map((val, index) => {
                                return (
                                    <Col lg={4} md={4} sm={6}>
                                        <BlogVerticalCard key={index} val={val} />
                                    </Col>
                                )
                            })} */}
                            <InfiniteScroll
                                dataLength={fetchedData?.length}
                                next={fetchMoreData}
                                hasMore={true}
                                // loader={<h4>Loading...</h4>}
                                style={{ overflow: "hidden", marginBottom: "3rem" }}
                            >
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                >
                                    {fetchedData.map((val, index) => {
                                        return (
                                            // <Col key={index} lg='4' md='4' sm='6'>
                                            <BlogVerticalCard val={val} page="all_blog_list" />
                                            // </Col>

                                        );
                                    })}
                                </Masonry>
                            </InfiniteScroll>
                        </Row>
                        {/* <Grid item lg={12}>
                            <Stack spacing={2} className='blog_list_pagination'>
                                <Pagination count={5} variant="outlined" shape="rounded" />
                            </Stack>
                        </Grid> */}
                    </Row>
                </Container>
            </Box>


            <Footer />
        </Box>
    )
}

