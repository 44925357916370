import React from 'react'
import Content from '../components/flight/Content';

function Flights() {

   return (
      <Content mode='Update' />
   )
}

export default Flights