import { Box, Container, Typography } from '@material-ui/core'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Footer from '../common/footer'
import Header from '../common/header'
// import Grid from '@mui/material/Grid';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
// import avatar from '../../asset/images/avatar.jpg'
import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
// import AddImage from '../../asset/images/add.jpg'
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import blogActions from '../../redux/blog/actions'
// import { API_IMG_URL } from '../../utils/constant'
// import Modal from 'react-bootstrap/Modal';
// import { Row, Col } from 'react-bootstrap'
// import { useNavigate } from "react-router-dom";
// import foundImg from '../../asset/icon-nothing-found.png'
// import DeleteIcon from '@mui/icons-material/Delete';
// import BlogMoreVertIcon from './BlogMoreVertIcon'
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import { BlogUserProfile } from './BlogUserProfile'
// import { BloggersProfile } from './BloggersProfile'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// const storyCard = 6;
// const followers = 5;

// const validationSchema = Yup.object().shape({
//   title: Yup.string()
//     .required('Title is required'),
//   name: Yup.string()
//     .required('Name is required'),
//   lastName: Yup.string()
//     .required('Last name is required'),
//   dob: Yup.string()
//     .required('Date of Birth is required')
//     .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
//   email: Yup.string()
//     .required('Email is required')
//     .email('Email is invalid'),
//   password: Yup.string()
//     .min(6, 'Password must be at least 6 characters')
//     .required('Password is required'),
//   confirmPassword: Yup.string()
//     .oneOf([Yup.ref('password'), null], 'Passwords must match')
//     .required('Confirm Password is required'),
//   acceptTerms: Yup.bool()
//     .oneOf([true], 'Accept Ts & Cs is required')
// });

export const CreatedBlogList = () => {
  const dispatch = useDispatch()
  // const navigate = useNavigate();
  const { state } = useLocation();
  // const { followBloggerList, UserWiseBlog, BlogStoryList, BookMarkList, viewBloggerProfile } = useSelector((state) => state.blogReducer)
  // const formOptions = { resolver: yupResolver(validationSchema) };
  // const { register, handleSubmit, formState } = useForm(formOptions);
  // const { errors } = formState;
  // const [value, setValue] = useState(0);
  // const [addStoryBlog, setAddStoryBlog] = useState(false)
  // console.log('viewBloggerProfile', viewBloggerProfile )
  // const handleModalClose = () => setAddStoryBlog(false);
  // const handleModalShow = () => setAddStoryBlog(true);


  useEffect(() => {
    dispatch({ type: blogActions.GET_FOLLOW_BLOGGER_LIST })
    dispatch({ type: blogActions.GET_USER_WISE_BLOG })
    dispatch({ type: blogActions.GET_BLOG_STORY_LIST })
    dispatch({ type: blogActions.GET_BOOK_MARK_LIST })
  }, [])

  useEffect(() => {
    if (state !== null) {
      if (state.type === "UserBloggersDet") {
        dispatch({ type: blogActions.GET_VIEW_BLOGGER_PROFILE, payload: state?.userId })
      }
    }
  }, [state])

  return (
    <Box className='create_blog_section'>
      <Header />
      <Box className='create_blog_head'>
        <Container>
          <ul>
            <li><Link to='/create-blog'>create new blog</Link></li>
            <li><Link className='active' to='/created-blog-list'>Profile</Link></li>
          </ul>
        </Container>
      </Box>
      {state !== null ?
        <>
          {/* {(state.type === "UserBloggersDet") ?
            <BloggersProfile />
            : */}
            <BlogUserProfile />
          {/* } */}
        </>
        :
        <BlogUserProfile />
      }

      <Footer />
    </Box>
  )
}
