 const commonActions =  {
    SET_LOADER: 'SET_LOADER',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    SET_COMMON_ERRORS: 'SET_COMMON_ERRORS',
    SET_FLIGHT_SEARCH_RESULT:'FLIGHT_SEARCH_RESULT',
    SET_COMMON_TOASTER_ALERT:'SET_COMMON_TOASTER_ALERT',
    GET_POPULAR_PLACES: 'GET_POPULAR_PLACES',
    SET_POPULAR_PLACES: 'SET_POPULAR_PLACES',
    GET_POPULAR_PLACES_DETAILS: 'GET_POPULAR_PLACES_DETAILS',
    SET_POPULAR_PLACES_DETAILS: 'SET_POPULAR_PLACES_DETAILS',
    GET_SUBSCRIBE_FORM: 'GET_SUBSCRIBE_FORM',
    GET_HOME_BANNER:'GET_HOME_BANNER',
    SET_HOME_BANNER:'SET_HOME_BANNER'
}

export default commonActions;