import React from 'react'
import Header from '../common/header'
import { Button, Card, CardActionArea, CardContent, CardMedia, Container, makeStyles, Typography } from "@material-ui/core";
import HotelSearch from '../Hotel/HotelSearch';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Footer from '../common/footer'
import { useDispatch, useSelector } from 'react-redux';
import { API_IMG_URL } from '../../utils/constant';
import { Stack } from '@mui/material';
import { Apartment, PinDrop } from '@material-ui/icons'
import { useEffect } from 'react';
import commonActions from '../../redux/common/actions';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) =>
({
  popularBanner: {
    backgroundRepeat: 'no-repeat',
    width: ' 100%',
    height: '100%',
    minHeight: '68vh',
    maxHeight: ' 68vh',
    backgroundSize: ' cover',
    backgroundPosition: ' center center',
    position: ' relative',
  },
})
)

export const PopularPlaceDetail = () => {
  const classes = useStyles();
  const history = useNavigate()
  const dispatch = useDispatch()
  const { popularPlacesDetail, popularPlaces } = useSelector((state) => state.commonReducer);

  const OnDetails = (val) => {
    dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({
      type: commonActions.GET_POPULAR_PLACES_DETAILS, payload: {
        placeId: val.id
      },
      history: history
    })
  }

  useEffect(() => {
    if (popularPlacesDetail) {
      dispatch({ type: commonActions.GET_POPULAR_PLACES, payload: {} })
    }
  }, []);

  return (
    <div className='popular_detail_sec popular_detail_page'>
      <Header />
      <div className={classes.popularBanner} style={{ backgroundImage: `url(${`${API_IMG_URL}/server/popularplace/${popularPlacesDetail.place_image}`})` }}>
        <div className='overlay_popular'></div>
        <Typography variant='h4'>{popularPlacesDetail.place_name}</Typography>
      </div>
      <div className='popular_search'>
        <Container>
          <HotelSearch />
        </Container>
      </div>
      <div className='popular_detail_con'>
        <Box >
          <Container>
            <Grid className='row'>
              <Grid className='col-lg-8 col-md-8 col-sm-12 col-12'>
                <div className='PopularPlaceContent_inner'>
                  <div className='popular_quote'>
                    <Typography variant='h5'>
                      {popularPlacesDetail.title}
                    </Typography>
                  </div>
                  <div className='place_details'>
                    <Typography variant='p' className='place_details_content'>
                      {popularPlacesDetail.description}
                    </Typography>
                    <div className='popular_inner_imgs'>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid className='row'>
                          {popularPlacesDetail?.GalleryList.map((i) => (
                            <Grid className='col-lg-4 col-md-4 col-sm-6 col-12'>
                              <img src={`${API_IMG_URL}/server/popularplacegallery/${i.gallery_image}`} alt='images' />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className='col-lg-4 col-md-4 col-sm-12 col-12'>
                <div className='popular_place'>
                  <div className='popular_quote'>
                    <Typography variant='h5'>
                      Other Places
                    </Typography>
                  </div>
                  <div className='pop_place_card '>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {popularPlaces.slice(0, 3).map((val) => (
                          <Grid item xs={12} md={12} sm={12}>
                            <Card sx={{ maxWidth: '100%' }} className='popular_other_places_card' onClick={() => OnDetails(val)}>
                              <div className='overlay_popular'></div>
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="190"
                                  image={`${API_IMG_URL}/server/popularplace/${val.place_image}`}
                                  alt="green iguana"
                                />
                                <CardContent className='cardcon pop_card'>
                                  <h5 className="MuiTypography-root MuiTypography-h5 cardtd">
                                    {val.place_name}
                                  </h5>
                                  <Stack direction="row" spacing={2} className='cardbtns' >
                                    <Button variant="outlined" startIcon={<Apartment />}>
                                      {val.entry1}
                                    </Button>
                                    <Button variant="outlined" startIcon={<PinDrop />}>
                                      {val.entry2}
                                    </Button>
                                  </Stack>
                                </CardContent>
                                <div className='overlay'></div>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        ))}


                      </Grid>
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
      <div className='nearest_hotel_slider'>
        <Container>
        </Container>
      </div>
      <Footer />
    </div>
  )
}
