import * as React from "react";
import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Container } from "@mui/material";
import { debounce } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import flightActions from "../../redux/flight/actions";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import commonActions from "../../redux/common/actions";
import Close from "@mui/icons-material/Close";
import Tune from "@mui/icons-material/Tune";
import { Modal } from "react-bootstrap";
import moment from "moment";

function FlightFilter(props) {
  const dispatch = useDispatch();
  const {
    filterData,
    flightList,
    flightSessionId,
    flightFilterResult,
    resultData,
    flightNameFilter,
    
  } = useSelector((state) => state.flightReducer);
  const [show, setShow] = useState(false);
  var [flightCabin, setFlightCabin] = useState("");
  var [minMaxSlider, setMinMaxSlider] = useState({ MinItem: "", MaxItem: "" });
  const [mobileFilter, setMobileFilter] = useState(false);
  const [departureTimeRange, setDepartureTimeRange] = useState([]);
  const [arrivalTimeRange, setArrivalTimeRange] = useState([]);
  const [selectedDepartureOptions, setSelectedDepartureOptions] = useState([]);
  const [selectedArrivalOptions, setSelectedArrivalOptions] = useState([]);
  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");  
    console.log("filterData",filterData)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    axios
      .post(
        `${API_URL}/filter`,
        { filter_type: "flight" },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setMinMaxSlider(
          (minMaxSlider = {
            MinItem: res.data.filter.min,
            MaxItem: res.data.filter.max,
          })
        );
      })
      .catch((err) => {
        setMinMaxSlider({ MinItem: "", MaxItem: "" });
      });
  }, []);

  const flightDetails = (name, status) => {
    let temp = filterData.flightComp;
    if (status) {
      temp = [...temp, name];
    } else {
      temp = filterData.flightComp.filter((el) => el !== name);
    }

    let secondTemp = [],
      temp4 = [];
    let fTemp = temp.map((el) => el.toUpperCase());

    flightList.forEach((el) => {
      if (fTemp.includes("ALL")) {
        temp4.push(el);
      } else if (el.find((a) => fTemp.includes(a.flightName.toUpperCase()))) {
        temp4.push(el);
      }
    });

    let temp3 = filterData.flightStop.map((el) => el.toUpperCase());
    temp4.forEach((el) => {
      if (
        temp3.includes("NONSTOP") &&
        el.find((val) => val.flight_details.every((y) => y.totalStops === 0))
      ) {
        secondTemp.push(el);
      } else if (
        temp3.includes("ONESTOP") &&
        el.find((val) => val.flight_details.every((y) => y.totalStops === 1))
      ) {
        secondTemp.push(el);
      } else if (
        temp3.includes("TWOSTOP") &&
        el.find((val) => val.flight_details.every((y) => y.totalStops === 2))
      ) {
        secondTemp.push(el);
      } else if (temp3.includes("ANY")) {
        secondTemp.push(el);
      }
    });

    // dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp });
    dispatch({
      type: flightActions.SET_FILTER_FLIGHT,
      payload: { ...filterData, flightComp: temp },
    });
  };

  const flightStopDetails = (name, e) => {
    let temp2 = filterData.flightStop;
    if (e.target.checked) {
      e.target.checked = true;
      temp2 = [...temp2, name];
    } else {
      e.target.checked = false;
      temp2 = temp2.filter((el) => el !== name);
    }

    let temp1 = [],
      secondTemp = [];
    let fTemp = filterData.flightComp.map((el) => el.toUpperCase());
    flightList.forEach((el) => {
      if (el.find((a) => fTemp.includes(a.flightName.toUpperCase()))) {
        temp1.push(el);
      } else if (fTemp.includes("ALL")) {
        temp1.push(el);
      }
    });

    let temp3 = temp2.map((el) => el.toUpperCase());
    temp1.forEach((el) => {
      if (
        temp3.includes("NONSTOP") &&
        el.find((a) => a.flight_details.every((val) => val.totalStops === 0))
      ) {
        secondTemp.push(el);
      } else if (
        temp3.includes("ONESTOP") &&
        el.find((a) => a.flight_details.every((val) => val.totalStops === 1))
      ) {
        secondTemp.push(el);
      } else if (
        temp3.includes("TWOSTOP") &&
        el.find((a) => a.flight_details.every((val) => val.totalStops === 2))
      ) {
        secondTemp.push(el);
      } else if (temp3.includes("ANY")) {
        secondTemp.push(el);
      }
    });

    // dispatch({ type: commonActions.SET_LOADER, payload: true });

    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp });
    dispatch({
      type: flightActions.SET_FILTER_FLIGHT,
      payload: { ...filterData, flightStop: temp2 },
    });
  };

  const flightCabinDetails = (e) => {
    let temp = e.target.value;

    setFlightCabin(temp);
    props.handleCabinChange(temp);
  };

  const flightPriceDetails = (e, type) => {
    if (type === "price") {
      dispatch({
        type: flightActions.SET_FLIGHT_FILTER,
        payload: {
          // sessionId: flightSessionId,
          maxResult: 100000,
          filters: {
            ...flightFilterResult?.filters,
            price: {
              min: e[0],
              max: e[1],
            },
          },
        },
      });
      // dispatch({
      //   type: flightActions.GET_FLIGHT_FILTER, payload: {
      //     // sessionId: flightSessionId,
      //     maxResult: 100000,
      //     filters: {
      //       ...flightFilterResult?.filters,
      //       price: {
      //         min: e[0],
      //         max: e[1]
      //       }
      //     }
      //   }
      // })
    }

    let temp = [],
      secondTemp = [];
    const startPrice = e?.[0] ? e[0] : filterData.flightPrice[0];
    const endPrice = e?.[1] ? e[1] : filterData.flightPrice[1];

    flightList.forEach((el) => {
      if (
        el.find(
          (a) =>
            a.totalFare >= parseInt(`${startPrice}`) &&
            a.totalFare <= parseInt(`${endPrice}`)
        )
      ) {
        temp.push(el);
      } else if (filterData.flightComp.includes("ALL")) {
        temp.push(el);
      }
    });

    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: temp });
    temp.forEach((el) => {
      if (
        filterData.flightStop.includes("Nonstop") &&
        el.find((a) => a.flight_details.every((val) => val.totalStops === 0))
      ) {
        secondTemp.push(el);
      } else if (
        filterData.flightStop.includes("OneStop") &&
        el.find((a) => a.flight_details.every((val) => val.totalStops === 1))
      ) {
        secondTemp.push(el);
      } else if (
        filterData.flightStop.includes("TwoStop") &&
        el.find((a) => a.flight_details.every((val) => val.totalStops === 2))
      ) {
        secondTemp.push(el);
      } else if (filterData.flightStop.includes("Any")) {
        secondTemp.push(el);
      }
    });
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp });
    if (e?.target?.value) {
      dispatch({
        type: flightActions.SET_FILTER_FLIGHT,
        payload: { ...filterData, flightPrice: e },
      });
    }
  };

  const ResetFilter = () => {
    flightPriceDetails([500, 20000], "price");
    setSelectedDepartureOptions([]);
    setSelectedArrivalOptions([]);
  };

  useEffect(() => {
    setFlightCabin(props?.SearchFlight?.class);
  }, [props]);

  const handleDepartureChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedDepartureOptions([...selectedDepartureOptions, value]);
    } else {
      setSelectedDepartureOptions(
        selectedDepartureOptions.filter((option) => option !== value)
      );
    }
  };
  
  const handleArrivalChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedArrivalOptions([...selectedArrivalOptions, value]);
    } else {
      setSelectedArrivalOptions(
        selectedArrivalOptions.filter((option) => option !== value)
      );
    }
  };

  
  

  useEffect(() => {
    flightDepartureDetails(selectedDepartureOptions);
  }, [selectedDepartureOptions]);

  useEffect(() => {
    flightArrivalDetails(selectedArrivalOptions);
  }, [selectedArrivalOptions]);

  const filterFlights = (flights, selectedOptions, isDeparture) => {
    const filteredFlights = [];

    flights.forEach((flight) => {
      if (
        flight.flight_details &&
        flight.flight_details.length > 0 &&
        flight.flight_details[0].flights &&
        flight.flight_details[0].flights.length > 0
      ) {
        const flightDetail = flight.flight_details[0].flights[0];
        const time = isDeparture
          ? moment.utc(flightDetail.departureTime)
          : moment.utc(flightDetail.arrivalTime);

        const hour = time.hours();

        if (selectedOptions.includes("6AM-12PM")) {
          const after6AM = hour >= 6;
          const before12PM = hour < 12;
          if (after6AM && before12PM) {
            filteredFlights.push([flight]);
          }
        }

        if (selectedOptions.includes("12PM-6PM")) {
          const after12PM = hour >= 12;
          const before6PM = hour < 18;
          if (after12PM && before6PM) {
            filteredFlights.push([flight]);
          }
        }

        if (selectedOptions.includes("AFTER6PM")) {
          const after6PM = hour >= 18;
          if (after6PM) {
            filteredFlights.push([flight]);
          }
        }
      }
    });

    return filteredFlights;
  };

  const flightDepartureDetails = async (selectedOptions) => {
    const filteredFlights = filterFlights(
      resultData.flat(),
      selectedOptions,
      true
    );

    dispatch({
      type: flightActions.SET_RESULT_FLIGHT,
      payload: filteredFlights,
    });
    dispatch({
      type: flightActions.SET_FILTER_FLIGHT,
      payload: { ...filterData, flightTimeFilter: selectedOptions },
    });
  };

  const flightArrivalDetails = async (selectedOptions) => {
    const filteredFlights = filterFlights(
      resultData.flat(),
      selectedOptions,
      false
    );

    dispatch({
      type: flightActions.SET_RESULT_FLIGHT,
      payload: filteredFlights,
    });
    dispatch({
      type: flightActions.SET_FILTER_FLIGHT,
      payload: { ...filterData, flightTimeFilter: selectedOptions },
    });
  };

 
  
  return (
    <div>
      <Container>
        <div className="filter_total_price">
          <div className="dis_none">
            <Box className="border-top-gray py-3">
              <div className="pb-20 d-flex justify-content-between">
                <span className="refine-results">Refine Results</span>
                <button
                  className="MuiButtonBase-root MuiButton-root MuiButton-text"
                  tabindex="0"
                  type="button"
                  title="Clear Filter"
                >
                  <span className="MuiButton-label">
                    <span className="delete-color" onClick={ResetFilter}>
                      Clear
                    </span>
                  </span>
                  <span className="MuiTouchRipple-root"></span>
                </button>
              </div>
              <Typography className="tab_td">Price</Typography>
              <div className="mr-1">
                <Slider
                  aria-label="Always visible"
                  onChange={(e) => (
                    flightPriceDetails(e.target.value, "price"), 400
                  )}
                  min={parseInt(minMaxSlider.MinItem)}
                  max={parseInt(minMaxSlider.MaxItem)}
                  step={20}
                  value={[
                    flightFilterResult?.filters?.price?.min
                      ? flightFilterResult?.filters?.price?.min
                      : 500,
                    flightFilterResult?.filters?.price?.max
                      ? flightFilterResult?.filters?.price?.max
                      : 20000,
                  ]}
                  valueLabelDisplay="auto"
                />
                <div className="dis_flex">
                  <span className="minmax_title">
                    Min: {parseInt(minMaxSlider.MinItem)}
                  </span>
                  <span className="minmax_title">
                    Max: {parseInt(minMaxSlider.MaxItem)}{" "}
                  </span>
                </div>
              </div>
            </Box>
            <div className="accordian_inner">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="tab_td">Airlines</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked={true} />}
                      label="All"
                      value="All"
                      onChange={(e) => flightDetails("All", e.target.checked)}
                    />

                    <div className="flitername_list">
                      {flightNameFilter.map((val, index) => {
                        return (
                          <FormControlLabel
                            control={<Checkbox />}
                            label={val}
                            value={val}
                            onChange={(e) =>
                              flightDetails(val, e.target.checked)
                            }
                          />
                        );
                      })}
                    </div>
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="tab_td">Cabin</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      // value={flightCabin || ''}
                      onChange={flightCabinDetails}
                    >
                      <FormControlLabel
                        value="Business"
                        control={<Radio />}
                        label="Business Class"
                      />
                      <FormControlLabel
                        value="Economy"
                        control={<Radio />}
                        label="Economy Class"
                      />
                      <FormControlLabel
                        value="Premium"
                        control={<Radio />}
                        label="Premium Class"
                      />
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className="tab_td">Stops</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked={true} />}
                      value={"Any"}
                      label="Any"
                      onChange={(e) => flightStopDetails("Any", e)}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      value={"Nonstop"}
                      label="Non-stop"
                      onChange={(e) => flightStopDetails("Nonstop", e)}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      value={"OneStop"}
                      label="1 Stop"
                      onChange={(e) => flightStopDetails("OneStop", e)}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      value={"TwoStop"}
                      label="2 Stop"
                      onChange={(e) => flightStopDetails("TwoStop", e)}
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="departure-panel-content"
                  id="departure-panel-header"
                >
                  <Typography className="tab_td">Departure Timings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {["6AM-12PM", "12PM-6PM", "AFTER6PM"].map((option) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDepartureOptions.includes(option)}
                            onChange={handleDepartureChange}
                          />
                        }
                        label={option}
                        value={option}
                      />
                    ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="arrival-panel-content"
                  id="arrival-panel-header"
                >
                  <Typography className="tab_td">Arrival Timings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {["6AM-12PM", "12PM-6PM", "AFTER6PM"].map((option) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedArrivalOptions.includes(option)}
                            onChange={handleArrivalChange}
                          />
                        }
                        label={option}
                        value={option}
                      />
                    ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="mobile_flight_filter">
            <div className="mobileFilter_open">
              <h4>Flight List</h4>
              <div className="open_close_icons" onClick={handleShow}>
                <Tune />
              </div>
            </div>
            <Modal
              className="modalOfFlightFilter"
              show={show}
              onHide={handleClose}
            >
              <div className="mobile_flight_filter_modal">
                <div className="onclose_btn" onClick={handleClose}>
                  <Close />
                </div>
                <Box className="border-top-gray py-3">
                  <div className="pb-20 d-flex justify-content-between">
                    <span className="refine-results">Refine Results</span>
                    <button
                      className="MuiButtonBase-root MuiButton-root MuiButton-text"
                      tabindex="0"
                      type="button"
                      title="Clear Filter"
                    >
                      <span className="MuiButton-label">
                        <span className="delete-color" onClick={ResetFilter}>
                          Clear
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                  </div>
                  <Typography className="tab_td">Price</Typography>
                  <div className="mr-1">
                    <Slider
                      aria-label="Always visible"
                      onChange={(e) => (
                        flightPriceDetails(e.target.value, "price"), 400
                      )}
                      min={parseInt(minMaxSlider.MinItem)}
                      max={parseInt(minMaxSlider.MaxItem)}
                      step={20}
                      // marks={hotelPrice}
                      value={[
                        flightFilterResult?.filters?.price?.min
                          ? flightFilterResult?.filters?.price?.min
                          : 500,
                        flightFilterResult?.filters?.price?.max
                          ? flightFilterResult?.filters?.price?.max
                          : 20000,
                      ]}
                      valueLabelDisplay="auto"
                    />
                    <div className="dis_flex">
                      <span className="minmax_title">
                        Min: {parseInt(minMaxSlider.MinItem)}
                      </span>
                      <span className="minmax_title">
                        Max: {parseInt(minMaxSlider.MaxItem)}{" "}
                      </span>
                    </div>
                  </div>
                </Box>
                <div className="accordian_inner">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="tab_td">Airlines</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked={true} />}
                          label="All"
                          value="All"
                          onChange={(e) =>
                            flightDetails("All", e.target.checked)
                          }
                        />
                        <div className="flitername_list">
                          {flightNameFilter.map((val, index) => {
                            return (
                              <FormControlLabel
                                control={<Checkbox />}
                                label={val}
                                value={val}
                                onChange={(e) =>
                                  flightDetails(val, e.target.checked)
                                }
                              />
                            );
                          })}
                        </div>
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="tab_td">Cabin</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          // value={flightCabin || ''}
                          onChange={flightCabinDetails}
                        >
                          <FormControlLabel
                            value="Business"
                            control={<Radio />}
                            label="Business Class"
                          />
                          <FormControlLabel
                            value="Economy"
                            control={<Radio />}
                            label="Economy Class"
                          />
                          <FormControlLabel
                            value="Premium"
                            control={<Radio />}
                            label="Premium Class"
                          />
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className="tab_td">Stops</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked={true} />}
                          value={"Any"}
                          label="Any"
                          onChange={(e) => flightStopDetails("Any", e)}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          value={"Nonstop"}
                          label="Non-stop"
                          onChange={(e) => flightStopDetails("Nonstop", e)}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          value={"OneStop"}
                          label="1 Stop"
                          onChange={(e) => flightStopDetails("OneStop", e)}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          value={"TwoStop"}
                          label="2 Stop"
                          onChange={(e) => flightStopDetails("TwoStop", e)}
                        />
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="departure-panel-content"
                      id="departure-panel-header"
                    >
                      <Typography className="tab_td">
                        Departure Timings
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {["6AM-12PM", "12PM-6PM", "AFTER6PM"].map((option) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDepartureOptions.includes(
                                  option
                                )}
                                onChange={handleDepartureChange}
                              />
                            }
                            label={option}
                            value={option}
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="arrival-panel-content"
                      id="arrival-panel-header"
                    >
                      <Typography className="tab_td">
                        Arrival Timings
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {["6AM-12PM", "12PM-6PM", "AFTER6PM"].map((option) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedArrivalOptions.includes(
                                  option
                                )}
                                onChange={handleArrivalChange}
                              />
                            }
                            label={option}
                            value={option}
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default React.memo(FlightFilter);
