import React from "react";
import Footer from "./footer";
import Header from "./header";

const Wrapper = (props) => {
    return (
        <div className='popular_detail_sec'>
            <Header mode={props.mode} />
            {props.children}
            <Footer />
        </div>
    );

};

export default React.memo(Wrapper);