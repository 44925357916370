import { Box } from '@material-ui/core'
import { Avatar, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
// import carImg from '../../asset/images/travel-2.png'
// import avatarImg from '../../asset/images/avatar.jpg'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom'
// import LikeButton from '../common/likeBtn'
import { useDispatch, useSelector } from 'react-redux'
import blogActions from '../../redux/blog/actions';
import { API_IMG_URL } from '../../utils/constant';
import commonActions from '../../redux/common/actions'
// import travellerActions from '../../redux/traveller/actions'

export const BlogHorizontalCard = () => {
  const dispatch = useDispatch()
  const { recentBlog } = useSelector((state) => state.blogReducer)
  const [addLike, setAddLike] = useState(false);

  const updateBlogLike = (Id) => {
    if (localStorage.getItem('tickatrip-token')) {
      var temp = recentBlog.find((e) => e.id === Id)
      dispatch({ type: blogActions.UPDATE_BLOG_LIKE, payload: { blogId: temp?.id } })
      //  dispatch({ type: travellerActions.GET_PROFILE_DATA });
      setAddLike(!addLike)
    } else {
      dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
    }
  }
  const updateBlogBookmark = (Id) => {
    if (localStorage.getItem('tickatrip-token')) {
      var temp = recentBlog.find((e) => e.id === Id)
      dispatch({ type: blogActions.UPDATE_BLOG_BOOKMARK, payload: { blogId: temp?.id } })
    } else {
      dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
    }
  }
  // console.log('recentBlog', recentBlog)
  return (
    <div className='horiz_card'>
      {/* <Link to='/blog-detail'> */}
      <Box className='blogHorizontal_card'>
        <Box className='blogHorizontal_card_img'>
          <img src={`${API_IMG_URL}/server/blog/${recentBlog[1]?.blog_image[0]}`} alt='img' />
        </Box>
        <Box className='blogHorizontal_card_content'>
          <Box className='horizontal_bager_name'>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={`${API_IMG_URL}/server/user/${recentBlog[1]?.profile_image}`} />
              <Box className='blogHorizontal_card_name'>
                <Typography variant='h6'>{recentBlog[1]?.name}</Typography>
                <Typography variant='span' className='date_txt'>{recentBlog[1]?.post_date}</Typography>
              </Box>
            </Stack>
            <BookmarkIcon
              onClick={() => updateBlogBookmark(recentBlog[1]?.id)}
              className={recentBlog[1]?.auth_bookmarked_blog === true ? 'green' : 'deactivate'}
            />
          </Box>
          <Link to='/blog-detail'>
            <Box className='horizontal_bager_td'>
              <Typography variant='h2'>
                {recentBlog[1]?.heading}
              </Typography>
            </Box>
          </Link>
          <Box className='recent__bager horizontal_bager_td  bt-l'>
            {recentBlog[1]?.liked_user_images?.length === 0 ?
              <></>
              :
              <Box direction="row" className='horiCard_like_share'>
                <Typography variant='h5'><Typography variant='span'>Liked by</Typography> {recentBlog[1]?.like_count} {recentBlog[1]?.liked_user_images?.length >= 2 ? "peoples" : "people"}</Typography>
              </Box>
            }
            <Box className='horiCard_like_share_icon recent_banne_blogers'>
              <FavoriteIcon
                onClick={() => updateBlogLike(recentBlog[1]?.id)}
                className={recentBlog[1]?.auth_liked_blog === true ? 'red' : 'deactivate'}
              />
              <ShareIcon />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='blogHorizontal_card'>
        <Box className='blogHorizontal_card_img'>
          <img src={`${API_IMG_URL}/server/blog/${recentBlog[2]?.blog_image[0]}`} alt='img' />
        </Box>
        <Box className='blogHorizontal_card_content'>
          <Box className='horizontal_bager_name'>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={`${API_IMG_URL}/server/user/${recentBlog[2]?.profile_image}`} />
              <Box className='blogHorizontal_card_name'>
                <Typography variant='h6'>{recentBlog[2]?.name}</Typography>
                <Typography variant='span' className='date_txt'>{recentBlog[2]?.post_date}</Typography>
              </Box>
            </Stack>
            <BookmarkIcon
              onClick={() => updateBlogBookmark(recentBlog[2]?.id)}
              className={recentBlog[2]?.auth_bookmarked_blog === true ? 'green' : 'deactivate'}
            />
          </Box>
          <Link to='/blog-detail'>
            <Box className='horizontal_bager_td'>
              <Typography variant='h2'>
                {recentBlog[2]?.heading}
              </Typography>
            </Box>
          </Link>
          <Box className='recent__bager horizontal_bager_td  bt-l'>
            {recentBlog[2]?.liked_user_images?.length === 0 ?
              <></>
              :
              <Box direction="row" className='horiCard_like_share'>
                <Typography variant='h5'><Typography variant='span'>Liked by</Typography> {recentBlog[2]?.like_count} {recentBlog[2]?.liked_user_images?.length >= 2 ? "peoples" : "people"}</Typography>
              </Box>
            }
            <Box className='horiCard_like_share_icon recent_banne_blogers'>
              <FavoriteIcon
                onClick={() => updateBlogLike(recentBlog[2]?.id)}
                className={recentBlog[2]?.auth_liked_blog === true ? 'red' : 'deactivate'}
              />
              <ShareIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </Link> */}
    </div>
  )
}
