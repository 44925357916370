import { Avatar, Box, Button, Container, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import Header from '../common/header'
import Grid from '@mui/material/Grid';
import { AvatarGroup, Rating, Stack } from '@mui/material';
// import avatarImg from '../../asset/images/avatar.jpg'
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import blogImage from '../../asset/images/blog-1.png'
// import cardImg from '../../asset/images/travel-1.png'
// import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PinterestIcon from '@material-ui/icons/Pinterest';
// import { useForm } from "react-hook-form";
import BlogVerticalCard from './BlogVerticalCard';
import Footer from '../common/footer';
// import LikeButton from '../common/likeBtn';
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import blogActions from '../../redux/blog/actions';
import { API_IMG_URL } from '../../utils/constant';
import { useState } from 'react';
import commonActions from '../../redux/common/actions';
import travellerActions from '../../redux/traveller/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import BlogShareModal from './BlogShareModal';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, PinterestShareButton } from 'react-share';

const optionsBloggers = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    autoplay: false,
    navText: ["<<", ">>"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,
        }
    },
};

const optionsRelatedBlog = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    loop: false,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,
        }
    },
};

export const BlogDetail = () => {
    const { state } = useLocation();
    //  console.log("state",state)
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();
    const url = `https://test.tickatrip.travel/blog-detail/${id}`
    // console.log("id->",id)

    const { BlogDiscription, RelatedBlogList, UserWiseBlog, blogViewRating, blogViewComments } = useSelector((state) => state.blogReducer)
    //  console.log("BlogDiscription",BlogDiscription)
    const { userProfileData } = useSelector((state) => state.travellerReducer)
    var [commentCont, setCommentCont] = useState('');
    const [errorMsg, setErrorMsg] = useState(false)
    const [addLike, setAddLike] = useState(false);
    const [showShareButtonsPopup, setShowShareButtonsPopup] = useState(false);
    const [postBlogId, setPostBlogId] = useState();
    const [blogTitle, setBlogTitle] = useState();

    useEffect(() => {
        if (state?.blogId || id) {
            dispatch({ type: blogActions.GET_BLOG_DISCRIPTION, payload: (id ?? state?.blogId) });
            dispatch({ type: blogActions.GET_BLOG_VIEW_RATING, payload: (id ?? state?.blogId) });
            dispatch({ type: blogActions.GET_BLOG_VIEW_COMMENTS, payload: (id ?? state?.blogId) });
        }
    }, [state?.blogId, id])

    useEffect(() => {
        if (state?.categoryId) {
            dispatch({ type: blogActions.GET_RELATED_BLOG_LIST, payload: state?.categoryId })
            dispatch({ type: blogActions.GET_USER_WISE_BLOG })
        }
    }, [state?.categoryId])

    useEffect(() => {
        dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }, []);

    const handleCommentSubmit = () => {
        if (commentCont?.length === 0) {
            setErrorMsg(true)
        } else {
            let temp = {}
            temp = {
                blog_id: (id ?? state?.blogId),
                comments: commentCont
            }
            Object.keys(temp).forEach(key => {
                if (temp[key] === '') {
                    delete temp[key]
                }
            })
            dispatch({ type: blogActions.GET_CREATE_BLOG_COMMENT, payload: temp })
            setErrorMsg(false)
            setCommentCont(commentCont = '')
        }
    }
    const updateBlogLike = (val) => {
        if (localStorage.getItem('tickatrip-token')) {
            dispatch({ type: blogActions.UPDATE_BLOG_LIKE, payload: { blogId: val.id, categoryId: val?.category_id, page: "blog-detail" } })
            // dispatch({ type: travellerActions.GET_PROFILE_DATA });
            setAddLike(!addLike)
        } else {
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Please SignIn or Register', status: "faild" } });
        }
    }
    const handleSubmitRating = (newValue) => {
        const data = {
            "ratings": newValue
        }
        dispatch({ type: blogActions.CREATE_BLOG_RATING, payload: { blogId: (id ?? state?.blogId), data } })
    }
    const handleDeleteComments = (CommentId) => {
        dispatch({ type: blogActions.DELETE_BLOG_COMMENTS, payload: { CommentId: CommentId, blogId: (id ?? state?.blogId) } })
    }

    const closeModal = () => {
        setShowShareButtonsPopup(false);
    }

    const handleModal = (e) => {
        // console.log("e",e)
        setShowShareButtonsPopup(true);
        setPostBlogId(`https://test.tickatrip.travel/blog-detail/${e?.id}`);
        setBlogTitle(e?.heading);
    }

    return (
        <>
            <Box>
                <Header />
                {BlogDiscription.map((val, index) => {
                    return (
                        <Box key={index} className='blog_detail_section'>
                            <Box className='blog_detail_banner'>
                                <Container>
                                    <Box className='blog_detail_banner_inner'>
                                        <Box className='blog_detail_heading'>
                                            <Typography variant='h2'>
                                                {val?.heading}
                                            </Typography>
                                            <Box className='catogories_btns'>
                                                {val?.category_name.map((item, ind) => {
                                                    return (
                                                        <h3 type='button' className='catogories_btn_2' key={ind}>{item?.name}</h3>
                                                    );
                                                })}

                                                {/* <Button type='button' className='catogories_btn_2'>Heritage</Button>
                                            <Button type='button' className='catogories_btn_3'>Holiday</Button> */}
                                            </Box>
                                        </Box>
                                        <Box className='blog_user_detail'>
                                            <Box className='horizontal_bager_td bt-l det-lik'>
                                                <Stack direction="row" spacing={2} className='user_profile_avatar'>

                                                    <Avatar alt="Remy Sharp" src={`${API_IMG_URL}/server/user/${val?.profile_image}`} />

                                                    <Box className='blogHorizontal_card_name'>

                                                        <Typography variant='h6'>{val?.name}</Typography>

                                                        <Typography variant='span' className='date_txt'>{val?.post_date}</Typography>
                                                    </Box>
                                                </Stack>

                                                <Box className='blog_details_userDetails'>
                                                    <Box className='liked__dis'>
                                                        {val?.liked_user_images?.length === 0 ?
                                                            <></>
                                                            :
                                                            <>
                                                                <Box direction="row" className='horiCard_like_share'>
                                                                    <Typography variant='h5'><Typography variant='span'>Liked by</Typography> {val?.like_count} {val?.liked_user_images?.length >= 2 ? "peoples" : "people"}</Typography>
                                                                </Box>
                                                                <AvatarGroup >
                                                                    {val?.liked_user_images.map((item, ind) => {
                                                                        return (
                                                                            <Avatar key={ind} alt="Remy Sharp" src={`${API_IMG_URL}/server/user/${item}`} />
                                                                        );
                                                                    })}
                                                                </AvatarGroup>
                                                            </>
                                                        }
                                                    </Box>
                                                    <Box className='horiCard_like_share_icon '>
                                                        <FavoriteIcon
                                                            onClick={() => updateBlogLike(val)}
                                                            className={val?.auth_liked_blog === true ? 'red' : 'deactivate'}
                                                        />
                                                        <ShareIcon onClick={(e) => handleModal(val)} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Container>
                            </Box>
                            <Box className='blog_details_content_section'>
                                <Container>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box className='blog_details_content_slider'>
                                                <OwlCarousel className="owl-theme bloggerSlider blogDetailSlider" {...optionsBloggers}>
                                                    {val?.blog_image.map((e, ind) => {
                                                        return (
                                                            <Box key={ind} className="item blgDetail_banner_inner">
                                                                <img src={`${API_IMG_URL}/server/blog/${e}`} alt='img' />
                                                            </Box>
                                                        );
                                                    })}
                                                </OwlCarousel>
                                                <Box className='blogDetailSlider_content'>
                                                    <div className='Blog__Details_Desic' dangerouslySetInnerHTML={{
                                                        __html: val?.description,
                                                    }} ></div>
                                                    {/* <Typography variant='span'>
                                                    Lorem Ipsum is simply  when an unknown printer took a galley of
                                                    type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                                    industry. Lorem Ipsum has been the industry's standard dummy text
                                                    ever since the 1500s, when an unknown printer took a galley of
                                                    type and scrambled it to make a type specimen book. It has
                                                    survived not only five centuries,
                                                </Typography>
                                                <Typography variant='span'>
                                                    Lorem Ipsum is simply  when an unknown printer took a galley of
                                                    type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                                    industry. Lorem Ipsum has been the industry's standard dummy text
                                                    ever since the 1500s, when an unknown printer took a galley of
                                                    type and scrambled it to make a type specimen book. It has
                                                    survived not only five centuries,
                                                </Typography>
                                                <Typography variant='h5'>Why integrate Side projects?</Typography>
                                                <Typography variant='span'>
                                                    Lorem Ipsum is simply  when an unknown printer took a galley of
                                                    type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                                    industry. Lorem Ipsum has been the industry's standard dummy text
                                                    ever since the 1500s, when an unknown printer took a galley of
                                                    type and scrambled it to make a type specimen book. It has
                                                    survived not only five centuries,
                                                </Typography>
                                                <Box className='blogDetailSlider_quote'>
                                                    <FormatQuoteIcon />
                                                    <Typography variant='span'>
                                                        Lorem Ipsum is simply  when an unknown printer took a galley of
                                                        type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                                        industry. Lorem Ipsum has been the industry's standard dummy text
                                                        ever since the 1500s,
                                                    </Typography>
                                                </Box>
                                                <Box className='blogDetailSlider_imageContent'>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={8}>
                                                            <Box className='blogDetailSlider_conten'>
                                                                <Typography variant='h5'>Why integrate Side projects?</Typography>
                                                                <Typography variant='span'>
                                                                    Lorem Ipsum is simply  when an unknown printer took a galley of
                                                                    type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                                                    industry. Lorem Ipsum has been the industry's standard dummy text
                                                                    ever since the 1500s, when an unknown printer took a galley of
                                                                    type and scrambled it to make a type specimen book. It has
                                                                    survived not only five centuries,
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Box className='blogDetailSlider_image'>
                                                                <img src={blogImage} alt='img' />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>


                                                </Box> */}
                                                    <Box className='blogDetailSlider_social'>
                                                        <Box className='socialMedias_list'>
                                                            <Typography variant='h6'>Share : </Typography>
                                                            <FacebookShareButton url={url} quote={state?.title} >
                                                                <FacebookIcon className='fb_cb' />
                                                            </FacebookShareButton>
                                                            <TwitterShareButton url={url} quote={state?.title}>
                                                                <TwitterIcon className='twit_cb' />
                                                            </TwitterShareButton>
                                                            <LinkedinShareButton url={url} title={state?.title}>
                                                                <LinkedInIcon className='link_cb' />
                                                            </LinkedinShareButton>
                                                            {/* <PinterestShareButton url={url} title={state?.title}> */}
                                                            <PinterestShareButton >
                                                                <PinterestIcon className='pin_cb' />
                                                            </PinterestShareButton>
                                                        </Box>
                                                        <Stack direction="row" spacing={2} className='user_profile_avatar'>
                                                            <Avatar alt="Remy Sharp" src={`${API_IMG_URL}/server/user/${val?.profile_image}`} />
                                                            <Box className='blogHorizontal_card_name'>
                                                                <Typography variant='h6'>{val?.name}</Typography>
                                                                <Typography variant='span' className='date_txt'>250 Blogs</Typography>
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        </Box>
                    );
                })}
                <Container>

                    <div className='blogger_comments'>
                        <Box className='blogDetails_commend_form'>
                            {localStorage.getItem('tickatrip-token') &&
                                <>
                                    <div>
                                        <Rating
                                            name="half-rating"
                                            defaultValue={3.5}
                                            value={blogViewRating}
                                            onChange={(event, newValue) => {
                                                handleSubmitRating(newValue)
                                            }}
                                            precision={0.5} />

                                    </div>
                                    <form>
                                        <textarea
                                            name="commends"
                                            placeholder='Your comments'
                                            type="text"
                                            rows="6"
                                            value={commentCont}
                                            id="CommentsOrAdditionalInformation"
                                            // {...register('requirements')}
                                            onChange={(e) => setCommentCont(e?.target?.value)}
                                            className='form-control'
                                        >
                                        </textarea>
                                        {errorMsg === true ?
                                            <span className='error comment_err'>Comment Box is empty</span>
                                            :
                                            <></>
                                        }
                                        <Button onClick={handleCommentSubmit} className='book_tick pop_con'>POST COMMENTS</Button>
                                    </form>
                                    <div className='comment__list'>
                                        {BlogDiscription.map((item) => {
                                            return (
                                                <>
                                                    {blogViewComments.map((val, index) => {
                                                        return (
                                                            <Box key={index} className='room_review_inner commnet__inner__list'>
                                                                {/* {console.log('val', val)} */}
                                                                <img src={`${API_IMG_URL}/server/user/${val?.profile_image}`} alt='img' />
                                                                <div className='room_review_inner_con'>
                                                                    <Box className='review_user_name'>

                                                                        <h6>{val?.name}</h6>
                                                                        {/* <span>{moment(el.updated_at).format('YYYY-MM-DD')}</span> */}
                                                                        {/* <span>{moment(new Date(el?.updated_at)).fromNow()}</span> */}
                                                                    </Box>
                                                                    <div className='reiew_lists'>
                                                                        <p>{val?.comments}</p>
                                                                    </div>
                                                                </div>
                                                                {(val?.user_id === userProfileData?.id) &&
                                                                    <div className='comment__delete__icon' onClick={() => handleDeleteComments(val?.id)}>
                                                                        <DeleteIcon />
                                                                    </div>
                                                                }
                                                            </Box>
                                                        );
                                                    })}
                                                </>
                                            );
                                        })}
                                    </div>
                                </>
                            }
                        </Box>
                    </div>
                </Container>

                {/* {state?.type === "related_detail" ?
                    <Box className='blogDetail_relatedBlogs'>
                        <Container>
                            <Box className='recent_blog_head'>
                                <Typography variant='h3'>Related blog's</Typography>
                                <Button className='book_tick blog_more'>View All</Button>
                            </Box>
                            <Box className='relatedBlogs_slider'>
                                <OwlCarousel className="owl-theme relatedBlogs" {...optionsRelatedBlog}>
                                    {RelatedBlogList.filter((e) => e?.id !== state?.blogId)?.map((val, index) => {
                                        return (
                                            <Box className="item bloggerInner">
                                                <BlogVerticalCard key={index} val={val} clickedFrom="related_products" />
                                            </Box>
                                        );
                                    })}
                                </OwlCarousel>
                                {/* <LikeButton /> 
                            </Box>
                        </Container>
                    </Box>
                    :
                    <></> */}

            {state?.type === "related_detail" ?
                    <Box className='blogDetail_relatedBlogs'>
                        <Container>
                            <Box className='recent_blog_head'>
                                <Typography variant='h3'>Related blog's</Typography>
                                <Button className='book_tick blog_more'>View All</Button>
                            </Box>
                            <Box className='relatedBlogs_slider'>
                                <OwlCarousel className="owl-theme relatedBlogs" {...optionsRelatedBlog}>
                                    {RelatedBlogList.filter((e) => e?.id !== (id ?? state?.blogId))?.map((val, index) => {
                                        return (
                                            <Box className="item bloggerInner" key={index}>
                                                <BlogVerticalCard val={val} clickedFrom="related_products" page="related_products" />
                                            </Box>
                                        );
                                    })}
                                </OwlCarousel>
                                {/* <LikeButton /> */}
                            </Box>
                        </Container>
                    </Box>
                    :
                    <></>

                }
                {state?.type === "UserStoryDet" ?
                    <Box className='blogDetail_relatedBlogs'>
                        <Container>
                            <Box className='recent_blog_head'>
                                <Typography variant='h3'>Story blog's</Typography>
                            </Box>
                            <Box className='relatedBlogs_slider'>
                                <OwlCarousel className="owl-theme relatedBlogs" {...optionsRelatedBlog}>
                                    {UserWiseBlog.filter((e) => e?.id !== (id ?? state?.blogId)).map((val, index) => {
                                        return (
                                            <Box className="item bloggerInner" key={index}>
                                                <BlogVerticalCard key={index} val={val} storyDesign={state?.type} />
                                            </Box>
                                        );
                                    })}
                                </OwlCarousel>
                                {/* <LikeButton /> */}
                            </Box>
                        </Container>
                    </Box>
                    :
                    <></>
                }
                <Footer />
            </Box >

            <BlogShareModal
                modal={showShareButtonsPopup}
                closeModal={closeModal}
                url={postBlogId}
                title={blogTitle}
            // blogId ={postBlogId}
            />
        </>
    )
}
