import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Container, Typography } from '@material-ui/core';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import StarIcon from '@mui/icons-material/Star';
// import trip1 from '../../asset/home-image/travel-1.png'
// import trip2 from '../../asset/home-image/travel-2.png';
import { Link } from 'react-router-dom'
// import avatarImg from '../../asset/images/avatar.jpg'
import { useSelector } from 'react-redux';
import { API_IMG_URL } from '../../utils/constant';
import { useNavigate } from "react-router-dom";
import { Row, Col, } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
  checked: {
    color: '#ffcc00',
  },
  relative: {
    position: 'relative',
  }
}));

const optionsBloggersCard = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  loop: true,
  dots: false,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    700: {
      items: 4,
    },
    1000: {
      items: 6,
    }
  },
};

function BlogersCard() {
  const navigate = useNavigate();
  const [fetchedData, setFetchedData] = useState([]);
  const { BlogggerList } = useSelector((state) => state.blogReducer)

  useEffect(() => {
    setFetchedData(BlogggerList);
  },[BlogggerList]);

  const classes = useStyles();
  return (
    <div className={classes.relative}>
      <Container>
        <Grid item xs={12} sm={12} lg={12}>
          <Grid className='Bloger_card_slider'>
            {fetchedData?.length >= 6 ?
              <OwlCarousel className='owl-theme experienceSlide' {...optionsBloggersCard}>
                {fetchedData?.map((val, index) => {
                  return (
                    <div key={index}>
                      {localStorage.getItem('tickatrip-token') ?
                        <div>
                          
                          <div
                            onClick={() => navigate('/created-blog-list', { state: { userId: val?.user_id, type: 'UserBloggersDet' } })}
                            className='bloggerCard'
                          >
                            <img src={`${API_IMG_URL}/server/user/${val?.profile_image}`} alt='img' />
                            <Typography variant='h4'>{val?.name}</Typography>
                            <Typography variant='span'>{val?.blog_user_count} Blogs</Typography>
                          </div>
                        </div>
                        :
                        <Link to='#'>
                          <div className='bloggerCard'>
                            <img src={`${API_IMG_URL}/server/user/${val?.profile_image}`} alt='img' />
                            <Typography variant='h4'>{val?.name}</Typography>
                            <Typography variant='span'>{val?.blog_user_count} Blogs</Typography>
                          </div>
                        </Link>
                      }
                    </div>
                  );
                })}
              </OwlCarousel>
              :
              <>
                <Row>
                  {fetchedData?.map((val, index) => {
                    return (
                      <Col key={index} lg={3} md={3} sm={12}>
                        <div
                          onClick={() => navigate('/created-blog-list', { state: { userId: val?.user_id, type: 'UserBloggersDet' } })}
                          className='bloggerCard'
                        >
                          <img src={`${API_IMG_URL}/server/user/${val?.profile_image}`} alt='img' />
                          <Typography variant='h4'>{val?.name}</Typography>
                          <Typography variant='span'>{val?.blog_user_count} Blogs</Typography>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </>
            }
          </Grid>
        </Grid>
      </Container>
      <div className='bg_overlay'></div>
    </div >
  )
}
export default BlogersCard;