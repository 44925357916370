import { Button } from '@material-ui/core'
import React from 'react'
import './user.css'
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import HotelIcon from '@mui/icons-material/Hotel';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import userActions from '../../redux/user/actions';
import commonActions from '../../redux/common/actions';
import { useNavigate } from 'react-router';
import notFoundImage from '../../asset/hotel_booking_not.gif'
import { Link } from 'react-router-dom';

const HotelCompletedBooking = () => {

    const history = useNavigate()
    const dispatch = useDispatch();
    const { myHotelBookingsCompleted } = useSelector((state) => state.userReducer)

    const ViewTicket = (val, index) => {
        let temp = {
            supplierConfirmationNum: val.supplierConfirmationNum,
            referenceNum: val.referenceNum,
        }
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: userActions.GET_MY_HOTEL_CANCEL_BOOKING_DETAILS, payload: temp, history: history })
    }

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: userActions.GET_MY_HOTEL_BOOKINGS_COMPLETED, payload: '' })
    }, [])

    return (
        <div className='upcoming_trips hotel_trip completed_booking'>
            {myHotelBookingsCompleted.length !== 0 ?
                <div>
                    {myHotelBookingsCompleted.map((val, index) =>
                        <main className='main_ticket' key={index}>
                            <section className="flight--general">
                                <div>
                                    <h4><HotelIcon className='hotel_loc' />{val.hotel_name}</h4>
                                </div>
                                <div>
                                    <h4><FmdGoodIcon className='hotel_loc' />{val.hotel_city}</h4>
                                </div>
                            </section>
                            <section className="flight--TimeInfo">
                                <div>
                                    <h5>Supplier <br />Confirmation No</h5>
                                    <p>{val.supplierConfirmationNum}</p>
                                </div>
                                <div>
                                    <h5>Check In</h5>
                                    <p>{val.checkIn}</p>
                                </div>
                                <div>
                                    <h5>Check Out</h5>
                                    <p>{val.checkOut}</p>
                                </div>
                                <div>
                                    <h5>No Days</h5>
                                    <p>{val.days}</p>
                                </div>
                                <div>
                                    <h5>Booking</h5>
                                    <p>{val.status}</p>
                                </div>
                                <div>
                                    <Button className='view_ticket' onClick={() => ViewTicket(val, index)}>View Booking</Button>
                                </div>
                            </section>

                        </main>
                    ).reverse()}
                </div>
                :
                <div className='hotel_not_found'>
                    <h3>You Don't have any Completed Bookings</h3>
                    <img src={notFoundImage} alt='notFound' />
                    <Link to='/'>Go to booking</Link>
                </div>
            }

        </div>
    )
}

export default React.memo(HotelCompletedBooking);