import { Box, Container } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../common/footer'
import Header from '../common/header'
import CreateBlogEditor from '../Editor/CreateBlogEditor'

export const CreateBlog = () => {
  return (
    <Box className='create_blog_section'>
      <Header />
      <Box className='create_blog_head'>
        <Container>
          <ul>
            <li><Link className='active' to='/create-blog'>create new blog</Link></li>
            <li><Link to='/created-blog-list'>Profile</Link></li>
          </ul>
        </Container>
      </Box>
      <CreateBlogEditor />
      <Footer />
    </Box>
  )
}
