import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormGroup, makeStyles, Typography } from "@material-ui/core";
import loginbackground from "../../asset/login/background.png";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel } from "@mui/material";
import micimg from "../../asset/icons/mike.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import googleIcon from "../../asset/icons/google.png";
import facebookIcon from "../../asset/icons/facebook.png";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/user/actions";
import commonActions from "../../redux/common/actions";
import { Link, useNavigate } from "react-router-dom";
import UserSlider from "./UserSlider";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../utils/constant";



const useremail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(
      /^[A-Za-z\\s]*$/,
      "first name should not contain spaces or numbers"
    )
    .required("First Name is required"),
  last_name: yup
    .string()
    .matches(/^[A-Za-z\\s]*$/, "Last name should not contain spaces or numbers")
    .required("Last name is required"),
  username: yup.string().required("User Name is required"),
  gender: yup.string().required("Gender is required"),
  maritalstatus: yup.string().required("Marital status is required"),
  postelcode: yup.string().required("Postel code is required"),
  mobileNumber: yup
    .string()
    .required("required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "to short")
    .max(10, "to long"),
  useremail: yup
    .string()
    .required("email is required")
    .matches(useremail, "email is not valid"),
  userdob: yup.string().required("dob is required"),
  userpasseword: yup
    .string()
    .required("Please Enter your password")
    .min(8, "please enter min 8"),
  confirmuserpasseword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("userpasseword")], "Passwords do not match"),
});

const maritalStatusData = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
];

const genderData = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const useStyles = makeStyles((theme) => ({
  loginSlide: {
    background: `url(${loginbackground})`,
    height: "100vh",
    width: "42%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      display: "relative",
      paddingBottom: "2rem",
    },
  },
  login_bg: {
    background: `url(${loginbackground})`,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  brandlogo: {
    minHeight: " 80px",
    maxHeight: " 80px",
    margin: " 3rem 0",
  },
  bannertxt: {
    fontSize: " 16px",
    color: " #fff",
    fontWeight: " 300",
    lineHeight: " 30px",
    width: " 90%",
    margin: " auto",
  },
  welcome_grid: {
    alignItems: "center",
    padding: "3rem 3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "5rem 0rem 2rem 0",
      width: "90%",
      margin: "auto",
    },
  },
  welcome_heading: {
    fontSize: "32px",
    fontWeight: " 500",
    textAlign: "center",
  },
  welcome_icon: {
    width: " 35px",
    margin: "0 0.5rem",
    padding: " 8px",
    background: " #eff2f9",
    boxShadow: " 0px 0px 5px 1px #32323254",
    marginTop: " 1.2rem",
    borderRadius: " 5px",
  },
  welcom_social: {
    textAlign: "center",
  },
}));

const correctDate = new Date().toISOString().split("T")[0];

function UserSignUp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const navigate = useNavigate();

  const { commonErrors } = useSelector((state) => state.commonReducer);

  const {commonToastError} = useSelector((state) => state.commonReducer);


  const [state, setState] = useState({
    googleLoginUrl: null,
    facebookURL: null,
  });
  const [showinfo, setshowinfo] = useState(false);
  const [regiterPolicy, setRegiterPolicy] = useState({
    promotion: false,
    termsandcondition: false,
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const { errors } = formState;

  const RegisterPolicyDetails = (name, status) => {
    setRegiterPolicy({
      promotion: name === "promotion" ? status : regiterPolicy.promotion,
      termsandcondition:
        name === "termsandcondition" ? status : regiterPolicy.termsandcondition,
    });
  };

  const onSubmit = (data) => {

    dispatch({
      type: commonActions.SET_COMMON_ERRORS,
      payload: null,
    });
    dispatch({
      type: actions.SET_ALL_REGISTER,
      userData: {
        first_name: data.first_name,
        last_name: data.last_name,
        username: data.username,
        mobilenumber: data.mobileNumber,
        email: data.useremail,
        dob: data.userdob,
        password: data.userpasseword,
        confirmuserpasseword: data.confirmuserpasseword,
        country: data.usercountry,
        currency: data.usercurrency,
        maritalstatus: data.maritalstatus,
        gender: data.gender,
        aboutme: data.aboutme,
        occupation: data.occupation,
        favouritedest: data.fovouritedestination,
        fovouritefood: data.fovouritefood,
        flyernumber: data.flyingnumber,
        passportnumber: data.passportnumber,
        issuecountry: data.issuyingcountry,
        postalcode: data.postelcode,
        expirydate: data.expirydate,
        pan: data.userpan,
      },
      history: history,
    });

  };

  const handleGoogle = async () => {
    let temp = {
      googleLoginUrl: null,
      facebookURL: null,
    };
    await axios
      .get(`${API_URL}/auth/google/url`)
      .then((response) => {
        temp = {
          googleLoginUrl: response.data.url,
          facebookURL: temp.facebookURL,
        };
      })
      .catch((error) => {});
    await axios
      .get(`${API_URL}/auth/facebook/url`)
      .then((response) => {
        temp = {
          facebookURL: response.data.url,
          googleLoginUrl: temp.googleLoginUrl,
        };
        setState(temp);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleGoogle();
  }, []);

  return (
    <div>
      <Box>
        <Grid className="row">
          <Grid className="col-lg-5 col-md-5 col-sm-12 col-12 user_slider">
            <UserSlider />
          </Grid>
          <Grid className="col-lg-7 col-md-7 col-sm-12 col-12 sign_up">
            <Link to="/" className="back_home dn-m">
              <ReplyAllIcon />
              Back to home
            </Link>
            <div className={classes.welcome_grid}>
              <div className={classes.welcome_heade}>
                <Typography className={classes.welcome_heading} variant="h1">
                  Sign up to TickaTrip
                </Typography>
                <div className={classes.welcom_social}>
                  {/* <a href={state.googleLoginUrl}><img className={classes.welcome_icon} src={googleIcon} alt="" /></a>
                  <a href={state.facebookURL}><img className={classes.welcome_icon} src={facebookIcon} alt="" /></a> */}
                </div>
                <div className="login_form">
                  <form
                    className="form"
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Box>
                      <Grid className="row">
                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12 nor-sel">
                          <h6 className="gen-marital">Gender</h6>
                          <select
                            name="gender"
                            defaultValue=""
                            {...register("gender")}
                            className="form-select"
                            aria-label="Gender Selection"
                          >
                            <option value="" disabled>
                              select Gender
                            </option>
                            {genderData.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <div className="error">{errors.gender?.message}</div>
                        </Grid>

                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12 nor-sel">
                          <h6 className="gen-marital">Marital Status</h6>
                          <select
                            name="maritalstatus"
                            defaultValue=""
                            {...register("maritalstatus")}
                            className="form-select"
                            aria-label="Marital Status Selection"
                          >
                            <option value="" disabled>
                              select Marital Status
                            </option>
                            {maritalStatusData.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <div className="error">
                            {errors.maritalstatus?.message}
                          </div>
                        </Grid>

                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <TextField
                            label="First Name*"
                            placeholder="Enter the first name"
                            focused
                            autoComplete="off"
                            style={{ width: "100%" }}
                            name="first_name"
                            {...register("first_name")}
                            pattern="[A-Za-z\\s]*"
                          />
                          <div className="error">
                            {errors.first_name?.message}
                          </div>
                        </Grid>
                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <TextField
                            label="Last Name*"
                            placeholder="Enter the last name"
                            focused
                            autoComplete="off"
                            style={{ width: "100%" }}
                            name="last_name"
                            {...register("last_name")}
                            pattern="[A-Za-z\\s]*"
                          />
                          <div className="error">
                            {errors.last_name?.message}
                          </div>
                        </Grid>
                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <TextField
                            label="User name*"
                            type={"text"}
                            placeholder="Enter the user name"
                            focused
                            autoComplete="off"
                            style={{ width: "100%" }}
                            name="username"
                            {...register("username")}
                          />
                          <div className="error">
                            {errors.username?.message}
                          </div>
                          <div className="error">
                            {commonErrors?.username &&
                              commonErrors?.username[0]}
                          </div>
                        </Grid>
                        <Grid
                          className="col-lg-6 col-md-6 col-sm-12 col-12"
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            label="Mobile Number"
                            type={"text"}
                            placeholder="Enter the mobile number"
                            focused
                            autoComplete="off"
                            inputProps={{ maxLength: 10 }}
                            style={{ width: "100%" }}
                            name="mobileNumber"
                            {...register("mobileNumber")}
                          />
                          <div className="error">
                            {errors.mobileNumber?.message}
                          </div>
                          <div className="error">
                            {commonErrors?.mobilenumber &&
                              commonErrors?.mobilenumber[0]}
                          </div>
                        </Grid>
                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <TextField
                            label="Email"
                            type={"text"}
                            placeholder="Enter the email address"
                            focused
                            autoComplete="off"
                            style={{ width: "100%" }}
                            name="useremail"
                            {...register("useremail")}
                          />
                          <div className="error">
                            {errors.useremail?.message}
                          </div>
                          <div className="error">
                            {commonErrors?.email && commonErrors?.email[0]}
                          </div>
                        </Grid>
                        <Grid
                          className="col-lg-6 col-md-6 col-sm-12 col-12"
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            label="DOB"
                            placeholder=""
                            type={"date"}
                            inputProps={{
                              max: correctDate,
                            }}
                            focused
                            autoComplete="off"
                            style={{ width: "100%" }}
                            name="userdob"
                            {...register("userdob")}
                          />
                          <div className="error">{errors.userdob?.message}</div>
                        </Grid>
                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <TextField
                            label="Password"
                            type={"password"}
                            placeholder="Enter the Password"
                            focused
                            autoComplete="off"
                            style={{ width: "100%" }}
                            name="userpasseword"
                            {...register("userpasseword")}
                          />
                          <div className="error">
                            {errors.userpasseword?.message}
                          </div>
                        </Grid>
                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <TextField
                            label="Confirm Password"
                            type={"password"}
                            placeholder="Enter the Password"
                            focused
                            autoComplete="off"
                            style={{ width: "100%" }}
                            name="confirmuserpasseword"
                            {...register("confirmuserpasseword")}
                          />
                          <div className="error">
                            {errors.confirmuserpasseword?.message}
                          </div>
                        </Grid>
                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <TextField
                            label="Postal Code"
                            type={"text"}
                            placeholder="Enter the postal code"
                            focused
                            autoComplete="off"
                            style={{ width: "100%" }}
                            name="postelcode"
                            {...register("postelcode")}
                          />
                          <div className="error">
                            {errors.postelcode?.message}
                          </div>
                        </Grid>
                        <Grid className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <div onClick={() => setshowinfo(!showinfo)}>
                            <TextField
                              label="Others (Optional)"
                              placeholder="Fill the information"
                              focused
                              autoComplete="off"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </Grid>
                        {showinfo ? (
                          <div>
                            <div className="register_details_show">
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                  <Grid item lg={12} sm={12} md={12} xs={12}>
                                    <div className="annauncement_form">
                                      <div className="mic_log">
                                        <img src={micimg} alt="" />
                                      </div>
                                      <div className="annount_log">
                                        <span className="MuiTypography-root">
                                          Complete Your Profile and Enjoy 5000
                                          As Joining Bonus
                                        </span>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    md={4}
                                    xs={12}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <TextField
                                      label="Frequent flyer number*"
                                      placeholder="Enter your Frequent flyer number"
                                      focused
                                      autoComplete="off"
                                      style={{ width: "100%" }}
                                      name="flyingnumber"
                                      {...register("flyingnumber")}
                                    />
                                  </Grid>
                                  <Grid item lg={6} sm={6} md={4} xs={12}>
                                    <TextField
                                      label="Passport number"
                                      type={"text"}
                                      placeholder="Enter passport number"
                                      focused
                                      autoComplete="off"
                                      style={{ width: "100%" }}
                                      name="passportnumber"
                                      {...register("passportnumber")}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    md={4}
                                    xs={12}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <TextField
                                      label="Issuing country*"
                                      placeholder="Enter the issuing country"
                                      focused
                                      autoComplete="off"
                                      style={{ width: "100%" }}
                                      name="issuyingcountry"
                                      {...register("issuyingcountry")}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    md={4}
                                    xs={12}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <TextField
                                      label="Expiry date"
                                      placeholder="Enter the Expiry date"
                                      focused
                                      autoComplete="off"
                                      style={{ width: "100%" }}
                                      name="expirydate"
                                      {...register("expirydate")}
                                    />
                                  </Grid>
                                  <Grid item lg={6} sm={6} md={4} xs={12}>
                                    <TextField
                                      label="PAN"
                                      type={"text"}
                                      placeholder="Enter the PAN number"
                                      focused
                                      autoComplete="off"
                                      style={{ width: "100%" }}
                                      name="userpan"
                                      {...register("userpan")}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </div>
                          </div>
                        ) : null}
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-12">
                            <FormGroup className="form_check_signup">
                              <FormControlLabel
                                name="termsandcondition"
                                control={
                                  <Checkbox
                                    value="termsandcondition"
                                    onChange={(e) =>
                                      RegisterPolicyDetails(
                                        "termsandcondition",
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label="I have read and accepted the Terms of Use"
                              />
                            </FormGroup>
                            <FormGroup className="form_check_signup">
                              <FormControlLabel
                                name="promotion"
                                control={
                                  <Checkbox
                                    value="promotion"
                                    onChange={(e) =>
                                      RegisterPolicyDetails(
                                        "promotion",
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label="The Tickatrip may send me promotional offers including emails about product and services"
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </Grid>
                    </Box>
                    <button
                      className="login_btn"
                      type="submit"
                      disabled={
                        !regiterPolicy.promotion ||
                        !regiterPolicy.termsandcondition
                      }
                    >
                      SIGN UP
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default UserSignUp;
