import React from 'react';
import { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import { useSelector, useDispatch } from 'react-redux';
import commonActions from '../../redux/common/actions';

function ToastAlert() {

    const dispatch = useDispatch();
    const { commonToastError } = useSelector((state) => state.commonReducer)

    useEffect(() => {
        if (commonToastError.open) {
            const timer = setTimeout(() => {
                dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: false, message: "", status: "" } })
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [commonToastError]);

    return (commonToastError?.open ?
        <div className="toaster_card" style={{ zIndex: 99999999999999999 }}>
            <Toast show={commonToastError?.open} className={`${commonToastError.status === "failed" ? 'tost_red' : 'toast_norml'}`}>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Tick a Trip Says</strong>
                </Toast.Header>
                <Toast.Body>{commonToastError?.message}</Toast.Body>
            </Toast>
        </div> :
        <></>
    );
}

export default React.memo(ToastAlert);