import React from 'react'
import Header from '../common/header'

export const BookingReview = () => {
  return (
    <div>
      <Header />
    </div>
  )
}
