import React, { useState } from 'react'
import { Button, Container } from "@material-ui/core";
import HotelSearch from '../Hotel/HotelSearch';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography, Slider } from '@mui/material';
import HotelCard from './HotelCard';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import hotelActions from '../../redux/hotel/actions';
import { Checkbox, Col, Row } from 'antd';
import TuneIcon from '@mui/icons-material/Tune';
import { useEffect } from 'react';
import moment from 'moment';
import foundImg from '../../asset/icon-nothing-found.png'
import Pagination from '@mui/material/Pagination';
import commonActions from '../../redux/common/actions';
import { API_URL, CURRENCY } from '../../utils/constant';
import hotelBannerImg from '../../asset/hotel/2.jpg';
import GridIcon from '../../asset/icons/grid.svg';
import GridBlueIcon from '../../asset/icons/grid_blue_icon.png';
import GridGrayIcon from '../../asset/icons/grid_gray.png';
import ListBlueIcon from '../../asset/icons/list_blue.png';
import ListGrayIcon from '../../asset/icons/list_gray.png';
import HotelGrid from './HotelGrid';
import { debounce } from "@material-ui/core";
import _ from "lodash";
import Carousel from 'react-bootstrap/Carousel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import axios from 'axios';

const postPerPage = 9;

export const HotelList = () => {

  const { hotelSearchResult, hotelSearchValues, hotelSessionId, currentPage, nextToken, hotelFilterResult, hotelImages } = useSelector((state) => state.hotelReducer)
  const dispatch = useDispatch();
  const history = useNavigate();
  const { register, handleSubmit, } = useForm();
  const [show, setShow] = useState(false);
  var [facilityShow, setFacilityShow] = useState(false);
  const [sortShow, setSortShow] = useState(false);
  const [locality, setlocality] = useState(undefined)
  const [fecility, setFecility] = useState(undefined)
  const [ratingHotel, setRatingHotel] = useState()
  const [advicerRating, setAdvicerRating] = useState()
  const [ratingHotelFinal, setRatingHotelFinal] = useState(undefined)
  const [advicerRatingFinal, setAdvicerRatingFinal] = useState(undefined)
  const [pageCount, setPageCount] = useState(1);
  const [listType, setListType] = useState('grid');
  var [minMaxSlider, setMinMaxSlider] = useState({ MinItem: '', MaxItem: '' })

  let lastPageIndex = currentPage * postPerPage;
  let firstPageIndex = lastPageIndex - postPerPage;
  let currentPosts = hotelSearchResult.slice(firstPageIndex, lastPageIndex);

  // const hotelPrice = [
  //   {
  //     value: 0,
  //     // label: 'Min',
  //   },
  //   {
  //     value: 100000,
  //     // label: 'Max',
  //   },
  // ];

  const ratingList = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  const tripRating = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  const handleList = (list) => setListType(list);
  const handleClose = () => setShow(false);
  const handleSortingClose = () => setSortShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // axios.post(
    //   `${API_URL}/filter`, { 'filter_type': 'hotel' }, {
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'multipart/form-data',
    //   },
    // }
    // ).then((res) => {
    //   setMinMaxSlider(minMaxSlider = { MinItem: res.data.filter.min, MaxItem: res.data.filter.max })
    // }).catch(err => {
    // })
    asyncCall()
  }, []);

  async function asyncCall() {
    await axios.post(
      `${API_URL}/filter`, { 'filter_type': 'hotel' }, {
      headers: {
        accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
      },
    }
    ).then((res) => {
      console.log('minmax',res.data)
      setMinMaxSlider({ MinItem: res.data.filter.min, MaxItem: res.data.filter.max })
    }).catch(err => {
      setMinMaxSlider({MinItem: '', MaxItem: ''})
    })
  }

  function onSubmit(data) {
    let filterData2 = {

      filters: {
        price: {
          min: parseInt(data.minAmount),
          max: parseInt(data.maxAmount),
        },
        rating: ratingHotelFinal !== undefined ? ratingHotelFinal?.map((val) => val)?.reduce((total, val, index) => { return index === 0 ? val : total + ',' + val }) : '',
        tripadvisorRating: advicerRatingFinal !== undefined ? advicerRatingFinal?.map((val) => val)?.reduce((total, val, index) => { return index === 0 ? val : total + ',' + val }) : '',
        faretype: data?.faretype,
        propertyType: data?.propertyType,
        facility: fecility !== undefined ? fecility?.map((val) => val)?.reduce((total, val, index) => { return index === 0 ? val : total + ',' + val }) : '',
        sorting: data?.shorting,
        locality: locality !== undefined ? locality?.map((val) => val)?.reduce((total, val, index) => { return index === 0 ? val : total + ',' + val }) : '',
      }
    }

    if (parseInt(minMaxSlider.MinItem) <= parseInt(data.minAmount) && parseInt(minMaxSlider.MaxItem) >= parseInt(data.maxAmount)) {
      Object.keys(filterData2.filters).forEach(key => {
        if (filterData2.filters[key] === '') {
          delete filterData2.filters[key]
        }
      });

      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          ...filterData2
        }
      });
      setShow(false);
      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          ...filterData2
        }
      })
    } else {
      if (parseInt(minMaxSlider.MinItem) >= parseInt(data.minAmount) && parseInt(minMaxSlider.MaxItem) <= parseInt(data.maxAmount)) {
        dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Filter price range not match", status: "failed" } });
      } else if (parseInt(minMaxSlider.MinItem) >= parseInt(data.minAmount)) {
        dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: `Enter the Value Above ${parseInt(minMaxSlider.MinItem)}`, status: "failed" } });
      } else if (parseInt(minMaxSlider.MaxItem) <= parseInt(data.maxAmount)) {
        // console.log('max Item',parseInt(minMaxSlider.MaxItem))
        // console.log('max Amount',parseInt(data.maxAmount))
        dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: `Enter the Value Below ${parseInt(minMaxSlider.MaxItem)}`, status: "failed" } });
      }
    }
  };

  const onChange = (checkedValues) => {
    setlocality(checkedValues)
  };

  const onChangeFecility = (checkedValues) => {
    setFecility(checkedValues)
  }

  const ClearFilter = () => {
    dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({
      type: hotelActions.GET_HOTEL_SEARCH, payload: {
        checkin: moment(hotelSearchValues?.checkin).format('YYYY-MM-DD'),
        checkout: moment(hotelSearchValues?.checkout).format('YYYY-MM-DD'),
        city_name: hotelSearchValues?.city_name,
        country_name: hotelSearchValues?.country_name,
        requiredCurrency: CURRENCY,
        occupancy: hotelSearchValues?.occupancy,
      }
    })
  }

  const OnPageChange = (e, p) => {
    dispatch({ type: hotelActions.SET_CURRENT_PAGE, payload: p });
  }

  const loadMore = () => {
    dispatch({
      type: hotelActions.LOAD_MORE_HOTELS, payload: {
        sessionId: hotelSessionId,
        nextToken: nextToken,
        hotelList: hotelSearchResult
      }
    })
  }

  const changeFilter = (e, type, subType) => {
    if (type === "price") {
      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            price: {
              min: e.target.value[0],
              max: e.target.value[1]
            }
          }
        }
      });
      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            price: {
              min: e.target.value[0],
              max: e.target.value[1]
            }
          }
        }
      })
    }
    if (type === "sorting") {
      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            sorting: e.target.value
          }
        }
      });
      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            sorting: e.target.value
          }
        }
      });
      setSortShow(false);
    }
    if (type === "rating") {
      let a = hotelFilterResult?.filters?.rating ? hotelFilterResult?.filters?.rating : [];
      if (e.target.checked) {
        if (a.length == 0) {
          a = [subType]
        } else {
          a = [...hotelFilterResult?.filters?.rating, subType]
        }
      } else {
        a = a.filter(el => el !== subType);
      }
      let b = "";
      a?.forEach((el, ind) => {
        if (a.length - 1 === ind) {
          b = b + el;
        } else {
          b = b + el + ",";
        }
      });

      let c = "";
      hotelFilterResult?.filters?.tripadvisorrating?.forEach((el, ind) => {
        c = c + el + ",";
      });
      console.log({
        ...hotelFilterResult?.filters,
        rating: a
      });
      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            rating: a
          }
        }
      });


      let d = {
        ...hotelFilterResult?.filters,
        rating: b.length > 0 ? (b[b.length - 1] === ",") ? b.substring(0, b.length - 1) : b : "",
        tripadvisorRating: c.length > 0 ? (c[c.length - 1] === ",") ? c.substring(0, c.length - 1) : b : "",

      };
      if (b === "") {
        delete d['rating'];
      }
      if (c === "") {
        delete d['tripadvisorRating'];
      }

      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: d
        }
      })
    }
    if (type === "tripadvisorRating") {
      console.log(subType);
      let a = hotelFilterResult?.filters?.tripadvisorRating ? hotelFilterResult?.filters?.tripadvisorRating : [];
      if (e.target.checked) {
        if (a.length == 0) {
          a = [subType]
        } else {
          a = [...hotelFilterResult?.filters?.tripadvisorRating, subType]
        }
      } else {
        a = a.filter(el => el !== subType);
      }
      let b = "";
      a?.forEach((el, ind) => {
        if (a.length - 1 === ind) {
          b = b + el;
        } else {
          b = b + el + ",";
        }
      });
      let c = "";
      hotelFilterResult?.filters?.rating?.forEach((el, ind) => {
        c = c + el + ",";
      })
      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            tripadvisorRating: a
          }
        }
      });
      let d = {
        ...hotelFilterResult?.filters,
        tripadvisorRating: b.length > 0 ? (b[b.length - 1] === ",") ? b.substring(0, b.length - 1) : b : "",
        rating: c.length > 0 ? (c[c.length - 1] === ",") ? c.substring(0, c.length - 1) : b : "",

      };
      if (b === "") {
        delete d['tripadvisorRating'];
      }
      if (c === "") {
        delete d['rating'];
      }
      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: d
        }
      })
    }
    if (type === "facilty") {
      let faciltyTemp = [];
      if (hotelFilterResult?.filters?.facility?.includes('Internet access')) {
        faciltyTemp.push('Internet access')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Room Service')) {
        faciltyTemp.push('Room Service')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Laundry Service')) {
        faciltyTemp.push('Laundry Service')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Air conditioning')) {
        faciltyTemp.push('Air conditioning')
      }

      if (hotelFilterResult?.filters?.facility?.includes('24-hour reception')) {
        faciltyTemp.push('24-hour reception')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Lift')) {
        faciltyTemp.push('Lift')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Cafe')) {
        faciltyTemp.push('Cafe')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Shops')) {
        faciltyTemp.push('Shops')
      }

      if (hotelFilterResult?.filters?.facility?.includes('Nightclub')) {
        faciltyTemp.push('Nightclub')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Theatre')) {
        faciltyTemp.push('Theatre')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Casino')) {
        faciltyTemp.push('Casino')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Games room')) {
        faciltyTemp.push('Games room')
      }

      if (hotelFilterResult?.filters?.facility?.includes('Restaurant')) {
        faciltyTemp.push('Restaurant')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Conference Room')) {
        faciltyTemp.push('Conference Room')
      }
      if (hotelFilterResult?.filters?.facility?.includes('WLAN access')) {
        faciltyTemp.push('WLAN access')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Car Park')) {
        faciltyTemp.push('Car Park')
      }

      if (hotelFilterResult?.filters?.facility?.includes('TV Room')) {
        faciltyTemp.push('TV Room')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Housekeeping')) {
        faciltyTemp.push('Housekeeping')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Currency Exchange')) {
        faciltyTemp.push('Currency Exchange')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Wheelchair')) {
        faciltyTemp.push('Wheelchair')
      }

      if (hotelFilterResult?.filters?.facility?.includes('Shower')) {
        faciltyTemp.push('Shower')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Bar')) {
        faciltyTemp.push('Bar')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Medical Assistance')) {
        faciltyTemp.push('Medical Assistance')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Wi-Fi')) {
        faciltyTemp.push('Wi-Fi')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Bath')) {
        faciltyTemp.push('Bath')
      }
      if (hotelFilterResult?.filters?.facility?.includes('24-hour check-in')) {
        faciltyTemp.push('24-hour check-in')
      }
      if (hotelFilterResult?.filters?.facility?.includes('Pub')) {
        faciltyTemp.push('Pub')
      }

      // '24-hour reception',
      //  'Lift', 'Cafe', 'Shops', 


      //  'Nightclub', 'Theatre', 'Casino', 'Games room', 

      //  'Restaurant', 'Conference Room', 'WLAN access', 'Car Park', 

      //  'TV Room', 'Housekeeping', 'Currency Exchange', 'Wheelchair', 

      //  'Shower', 'Bar', 'Medical Assistance', 'Wi-Fi', 'Bath',

      //  '24-hour check-in', 'Pub',



      if (e.target.checked) {
        if (!hotelFilterResult?.filters?.facility?.includes(subType)) {
          faciltyTemp.push(subType)
        }
        faciltyTemp = [...faciltyTemp];
      } else {
        faciltyTemp.filter(el => el !== subType)
      }
      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            facility: faciltyTemp?.length ? faciltyTemp?.map((val) => val)?.reduce((total, val, index) => { return index === 0 ? val : total + ',' + val }) : '',
          }
        }
      });
      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            facility: faciltyTemp?.length ? faciltyTemp?.map((val) => val)?.reduce((total, val, index) => { return index === 0 ? val : total + ',' + val }) : '',
          }
        }
      })
    }
    if (type === "locality") {
      let localTemp = [];
      if (hotelFilterResult?.filters?.facility?.includes('locality')) {
        localTemp.push('locality')
      }
      if (hotelFilterResult?.filters?.facility?.includes('region')) {
        localTemp.push('region')
      }
      if (e.target.checked) {
        localTemp.push(subType);
        localTemp = [...localTemp];
      } else {
        localTemp.filter(el => el !== subType)
      }
      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            locality: localTemp?.length ? localTemp?.map((val) => val)?.reduce((total, val, index) => { return index === 0 ? val : total + ',' + val }) : '',
          }
        }
      });
      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            locality: localTemp?.length ? localTemp?.map((val) => val)?.reduce((total, val, index) => { return index === 0 ? val : total + ',' + val }) : '',
          }
        }
      })
    }
    if (type === "faretype") {
      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            faretype: subType
          }
        }
      });
      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            faretype: subType
          }
        }
      })
    }
    if (type === "propertyType") {
      dispatch({
        type: hotelActions.SET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            propertyType: subType
          }
        }
      });
      dispatch({
        type: hotelActions.GET_HOTEL_FILTER, payload: {
          sessionId: hotelSessionId,
          maxResult: 100000,
          filters: {
            ...hotelFilterResult?.filters,
            propertyType: subType
          }
        }
      })
    }
  }

  const removeFilter = () => {
    dispatch({ type: hotelActions.SET_CLEAR_FILTER, payload: false });
    dispatch({ type: hotelActions.SET_HOTEL_FILTER, payload: null });
    dispatch({
      type: hotelActions.GET_HOTEL_SEARCH, payload: {
        ...hotelSearchValues
      }, from: history
    })
  }

  useEffect(() => {
    if (ratingHotel) {
      let tempdata = []
      for (let i = 1; i <= ratingHotel; i++) {
        tempdata.push(i)
      }
      setRatingHotelFinal(tempdata)
    }

    if (advicerRating) {
      let tempdata = []
      for (let i = 1; i <= advicerRating; i++) {
        tempdata.push(i)
      }
      setAdvicerRatingFinal(tempdata)
    }

  }, [ratingHotel, advicerRating])

  useEffect(() => {
    if (hotelSearchResult.length) {
      setPageCount(Math.ceil(hotelSearchResult.length / 9))
    }
  }, [pageCount]);

  return (
    <>
      <div className='popular_search hotel_list_search'>
        <div className='hotelBanner' style={{ backgroundImage: `url(${hotelBannerImg})` }}>
          <Container>
            <h1 className="text-white banner_title">Hotel List</h1>
          </Container>
        </div>
        <HotelSearch />
        <Modal show={hotelImages.length > 0} dialogClassName="dialog-modal-slider" onHide={() => dispatch({ type: hotelActions.SET_HOTEL_IMAGES, payload: [] })}>
          <Modal.Body>
            <Carousel>
              {hotelImages.map(el =>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={el.url}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h1 className='text-white'>{el.caption}</h1>
                  </Carousel.Caption>
                </Carousel.Item>)}

            </Carousel>
          </Modal.Body>
        </Modal>
      </div>
      <div className='popular_detail_con hotel_list'>
        <Box sx={{ flexGrow: 1 }}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box >
                  <Grid className='row justify-content-center'>
                    <Grid className="d-none d-xl-block col-lg-3 col-md-3 col-sm-12 col-12">
                      <div className='pb-20 d-flex justify-content-between'>
                        <span className="refine-results">Refine Results</span>

                        <Button title="Clear Filter" onClick={removeFilter}>
                          <span className='delete-color'>Clear</span>
                        </Button>
                      </div>
                      <div className='filter_total_price'>
                        <Box className="border-top-gray py-3">
                          <Typography className='tab_td'>Price</Typography>
                          <div className='mr-1'>
                            <Slider
                              aria-label="Always visible"
                              onChange={(e) => _.debounce(changeFilter(e, 'price'), 400)}
                              min={parseInt(minMaxSlider.MinItem)}
                              max={parseInt(minMaxSlider.MaxItem)}
                              step={20}
                              // marks={hotelPrice}
                              defaultValue={[hotelFilterResult?.filters?.min ? hotelFilterResult?.filters?.min : 500,
                                 hotelFilterResult?.filters?.max ? hotelFilterResult?.filters?.max : 20000]}
                              valueLabelDisplay='auto'
                            />
                            <div className='dis_flex'>
                              <span className='minmax_title'>Min: {parseInt(minMaxSlider.MinItem)}</span>
                              <span className='minmax_title'>Max: {parseInt(minMaxSlider.MaxItem)} </span>
                            </div>
                          </div>
                        </Box>
                      </div>
                      <div className='filter_total_price'>
                        {/* <Box className="border-top-gray py-3">
                          <Typography className='tab_td'>Rating</Typography>
                          <Slider
                            aria-label="Always visible"
                            min={0.5}
                            max={5}
                            onChange={(e) => _.debounce(changeFilter(e, 'price'), 400)}
                            step={0.5}
                            marks={ratingList}
                            defaultValue={hotelFilterResult?.filters?.max > 500 ? hotelFilterResult?.filters?.max : 500}
                            valueLabelDisplay="auto"
                          />
                        </Box> */}
                        <Box className="border-top-gray py-3">
                          <Typography className='tab_td'>Rating</Typography>
                          <div>
                            <p className='d-inline-block'>
                              <span className='d-block'>
                                <input type="checkbox" name="hotel-rating1" defaultChecked={hotelFilterResult?.filters?.rating?.includes('5')} onChange={(e) => changeFilter(e, 'rating', '5')} className='mr-1' id="rating-5" />
                                <label for="rating-5">
                                  5
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" name="hotel-rating2" defaultChecked={hotelFilterResult?.filters?.rating?.includes('4')} onChange={(e) => changeFilter(e, 'rating', '4')} className='mr-1' id="rating-2" />
                                <label for="rating-2">
                                  4
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" name="hotel-rating3" defaultChecked={hotelFilterResult?.filters?.rating?.includes('3')} onChange={(e) => changeFilter(e, 'rating', '3')} className='mr-1' id="rating-3" />
                                <label for="rating-3">
                                  3
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" name="hotel-rating4" defaultChecked={hotelFilterResult?.filters?.rating?.includes('2')} onChange={(e) => changeFilter(e, 'rating', '2')} className='mr-1' id="rating-2" />
                                <label for="rating-2">
                                  less than 3
                                </label>
                              </span>
                            </p>
                          </div>
                          {/* <div className='mr-1'>
                            <Slider
                              aria-label="Always visible"
                              min={0}
                              max={5}
                              defaultValue={hotelFilterResult?.filters?.rating}
                              onChange={(e) => _.debounce(changeFilter(e, 'rating'), 400)}
                              step={0.5}
                              marks={ratingList}

                              valueLabelDisplay="auto"
                            />
                          </div> */}
                        </Box>
                      </div>
                      <div className='filter_total_price'>
                        <Box className="border-top-gray py-3">
                          <Typography className='tab_td'>Trip Advisor Rating</Typography>
                          <div>
                            <p className='d-inline-block'>
                              <span className='d-block'>
                                <input type="checkbox" name="hotel-trip-rating1" defaultChecked={hotelFilterResult?.filters?.tripadvisorRating === "5"} onChange={(e) => changeFilter(e, 'tripadvisorRating', '5')} className='mr-1' id="trip-rating-5" />
                                <label for="trip-rating-5">
                                  5
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" name="hotel-trip-rating2" defaultChecked={hotelFilterResult?.filters?.tripadvisorRating?.includes('4,4.5')} onChange={(e) => changeFilter(e, 'tripadvisorRating', '4,4.5')} className='mr-1' id="trip-rating-2" />
                                <label for="trip-rating-2">
                                  4
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" name="hotel-trip-rating3" defaultChecked={hotelFilterResult?.filters?.tripadvisorRating?.includes('3,3.5')} onChange={(e) => changeFilter(e, 'tripadvisorRating', '3,3.5')} className='mr-1' id="trip-rating-3" />
                                <label for="trip-rating-3">
                                  3
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" name="hotel-trip-rating4" defaultChecked={hotelFilterResult?.filters?.tripadvisorRating?.includes('1,1.5,2,2.5')} onChange={(e) => changeFilter(e, 'tripadvisorRating', '1,1.5,2,2.5')} className='mr-1' id="trip-rating-2" />
                                <label for="trip-rating-2">
                                  less than 3
                                </label>
                              </span>
                            </p>
                          </div>
                        </Box>
                      </div>
                      <div className="border-top-gray py-3">
                        <div className='py-1 mdb-5 '>
                          <span className="filter-heading">Faciliy</span>
                        </div>
                        <div>
                          <p className='d-inline-block'>
                            <span className='d-block'>
                              <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Internet access')} onChange={(e) => changeFilter(e, 'facilty', 'Internet access')} className='mr-1' id="Faciliy-Internet" />
                              <label for="Faciliy-Internet">
                                Internet access
                              </label>
                            </span>
                            <span className='d-block'>
                              <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Room Service')} onChange={(e) => changeFilter(e, 'facilty', 'Room Service')} className='mr-1' id="Faciliy-conditioning" />
                              <label for="Faciliy-conditioning">
                                Room Service
                              </label>
                            </span>
                            <span className='d-block'>
                              <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Laundry Service')} onChange={(e) => changeFilter(e, 'facilty', 'Laundry Service')} className='mr-1' id="Faciliy-Room" />
                              <label for="Faciliy-Room">
                                Laundry Service
                              </label>
                            </span>
                            <span className='d-block'>
                              <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Air conditioning')} onChange={(e) => changeFilter(e, 'facilty', 'Air conditioning')} className='mr-1' id="Faciliy-Laundry" />
                              <label for="Faciliy-Laundry">
                                Air conditioning
                              </label>
                            </span>
                            <p className={(facilityShow) ? '' : 'd-none'}>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Pub')} onChange={(e) => changeFilter(e, 'facilty', 'Pub')} className='mr-1' id="Faciliy-Pub" />
                                <label for="Faciliy-Pub">
                                  Pub
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Bath')} onChange={(e) => changeFilter(e, 'facilty', 'Bath')} className='mr-1' id="Faciliy-Bath" />
                                <label for="Faciliy-Bath">
                                  Bath
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes(' 24-hour check-in')} onChange={(e) => changeFilter(e, 'facilty', ' 24-hour check-in')} className='mr-1' id="Faciliy- 24-hour check-in" />
                                <label for="Faciliy-check-in">
                                  24-hour check-in
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Wi-Fi')} onChange={(e) => changeFilter(e, 'facilty', 'Wi-Fi')} className='mr-1' id="Faciliy-Wi-Fi" />
                                <label for="Faciliy-Wi-Fi">
                                  Wi-Fi
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Medical Assistance')} onChange={(e) => changeFilter(e, 'facilty', 'Medical Assistance')} className='mr-1' id="Faciliy-Medical" />
                                <label for="Faciliy-Medical">
                                  Medical Assistance
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Bar')} onChange={(e) => changeFilter(e, 'facilty', 'Bar')} className='mr-1' id="Faciliy-Bar" />
                                <label for="Faciliy-Bar">
                                  Bar
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Shower')} onChange={(e) => changeFilter(e, 'facilty', 'Shower')} className='mr-1' id="Faciliy-Shower" />
                                <label for="Faciliy-Shower">
                                  Shower
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Wheelchair')} onChange={(e) => changeFilter(e, 'facilty', 'Wheelchair')} className='mr-1' id="Faciliy-Wheelchair" />
                                <label for="Faciliy-Wheelchair">
                                  Wheelchair
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Currency Exchange')} onChange={(e) => changeFilter(e, 'facilty', 'Currency Exchange')} className='mr-1' id="Faciliy-Currency" />
                                <label for="Faciliy-Currency">
                                  Currency Exchange
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Housekeeping')} onChange={(e) => changeFilter(e, 'facilty', 'Housekeeping')} className='mr-1' id="Faciliy-Housekeeping" />
                                <label for="Faciliy-Housekeeping">
                                  Housekeeping
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes(' TV Room')} onChange={(e) => changeFilter(e, 'facilty', ' TV Room')} className='mr-1' id="Faciliy-TV-Room" />
                                <label for="Faciliy-TV-Room">
                                  TV Room
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Car Park')} onChange={(e) => changeFilter(e, 'facilty', 'Car Park')} className='mr-1' id="Faciliy-Parking" />
                                <label for="Faciliy-Parking">
                                  Car Park
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes(' WLAN acces')} onChange={(e) => changeFilter(e, 'facilty', ' WLAN acces')} className='mr-1' id="Faciliy-WLAN" />
                                <label for="Faciliy-WLAN">
                                  WLAN acces
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Conference Room')} onChange={(e) => changeFilter(e, 'facilty', 'Conference Room')} className='mr-1' id="Faciliy-Conference" />
                                <label for="Faciliy-Conference">
                                  Conference Room
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Restaurant')} onChange={(e) => changeFilter(e, 'facilty', 'Restaurant')} className='mr-1' id="Faciliy-Restaurant" />
                                <label for="Faciliy-Restaurant">
                                  Restaurant
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes(' Games room')} onChange={(e) => changeFilter(e, 'facilty', ' Games room')} className='mr-1' id="Faciliy-Games" />
                                <label for="Faciliy-Games">
                                  Games room
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Casino')} onChange={(e) => changeFilter(e, 'facilty', 'Casino')} className='mr-1' id="Faciliy-Casino" />
                                <label for="Faciliy-Casino">
                                  Casino
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Theatre')} onChange={(e) => changeFilter(e, 'facilty', 'Theatre')} className='mr-1' id="Faciliy-Theatre" />
                                <label for="Faciliy-Theatre">
                                  Theatre
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Nightclub')} onChange={(e) => changeFilter(e, 'facilty', 'Nightclub')} className='mr-1' id="Faciliy-Nightclub" />
                                <label for="Faciliy-Nightclub">
                                  Nightclub
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Shops')} onChange={(e) => changeFilter(e, 'facilty', 'Shops')} className='mr-1' id="Faciliy-Shops" />
                                <label for="Faciliy-Shops">
                                  Shops
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Cafe')} onChange={(e) => changeFilter(e, 'facilty', 'Cafe')} className='mr-1' id="Faciliy-Cafe" />
                                <label for="Faciliy-Cafe">
                                  Cafe
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('Lift')} onChange={(e) => changeFilter(e, 'facilty', 'Lift')} className='mr-1' id="Faciliy-Lift" />
                                <label for="Faciliy-Lift">
                                  Lift
                                </label>
                              </span>
                              <span className='d-block'>
                                <input type="checkbox" defaultChecked={hotelFilterResult?.filters?.facility?.includes('24-hour reception')} onChange={(e) => changeFilter(e, 'facilty', '24-hour reception')} className='mr-1' id="Faciliy-reception" />
                                <label for="Faciliy-reception">
                                  24-hour reception
                                </label>
                              </span>

                            </p>
                            <button className='facilityBtn' onClick={() => setFacilityShow(!facilityShow)}>
                              {(facilityShow) ? 'Close' : 'See more'}
                            </button>
                          </p>
                        </div>
                      </div>
                      {/* <div className="border-top-gray py-3">
                        <div className='mdb-5'>
                          <span className="filter-heading">Locality</span>
                        </div>
                        <div>
                          <p>
                            <span className='d-block'>
                              <input type="checkbox" id="Locality-Locality" onChange={(e) => changeFilter(e, 'locality', 'locality')} defaultChecked={hotelFilterResult?.filters?.facility?.includes('locality')} className='mr-1' />
                              <label for="Locality-Locality">
                                Locality
                              </label>
                            </span>
                            <span className='d-block'>
                              <input type="checkbox" id="Locality-Region" onChange={(e) => changeFilter(e, 'locality', 'region')} defaultChecked={hotelFilterResult?.filters?.facility?.includes('region')} className='mr-1' />
                              <label for="Locality-Region">
                                Region
                              </label>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="border-top-gray py-3">
                        <div className='mdb-5'>
                          <span className="filter-heading">faretype</span>
                        </div>
                        <div>
                          <p>
                            <span className='d-block'>
                              <input type="radio" onChange={(e) => changeFilter(e, 'faretype', 'Refundable')} defaultChecked={hotelFilterResult?.filters?.faretype == 'Refundable'} name="refundable-type" className='mr-1' id="refundable" />
                              <label for="refundable">
                                Refundable
                              </label>
                            </span>
                            <span className='d-block'>
                              <input type="radio" onChange={(e) => changeFilter(e, 'faretype', 'Non-Refundable')} defaultChecked={hotelFilterResult?.filters?.faretype == 'Non-Refundable'} name="refundable-type" className='mr-1' id="non-refundable" />
                              <label for="non-refundable">
                                Non Refundable
                              </label>
                            </span>
                          </p>
                        </div>
                      </div> */}
                      <div className="border-top-gray py-3">
                        <div className="mdb-5">
                          <span className="filter-heading">Property Type</span>
                        </div>
                        <div>
                          <p>
                            <span className='d-block'>
                              <input type="radio" name="hotel-type" onChange={(e) => changeFilter(e, 'propertyType', 'HOTELS')} defaultChecked={hotelFilterResult?.filters?.propertyType == 'HOTELS'} className='mr-1' id="property-hotel" />
                              <label for="property-hotel">
                                Hotels
                              </label>
                            </span>
                            <span className='d-block'>
                              <input type="radio" name="hotel-type" onChange={(e) => changeFilter(e, 'propertyType', 'RESORTS')} defaultChecked={hotelFilterResult?.filters?.propertyType == 'RESORTS'} className='mr-1' id="property-resorts" />
                              <label for="property-resorts">
                                Resorts
                              </label>
                            </span>
                            <span className='d-block'>
                              <input type="radio" name="hotel-type" onChange={(e) => changeFilter(e, 'propertyType', 'APARTMENTS')} defaultChecked={hotelFilterResult?.filters?.propertyType == 'APARTMENTS'} className='mr-1' id="property-apartments" />
                              <label for="property-apartments">
                                Apartments
                              </label>
                            </span>
                          </p>
                        </div>
                      </div>
                    </Grid>

                    <Grid className="d-xl-block col-lg-9 col-md-9 col-sm-12 col-12">
                      <div className='hotel_list_header'>
                        <div className='total_hotel'><span className='list-color'>{hotelSearchResult.length} Hotels</span></div>
                        <div className='hotel_sort_by'>
                          <div className='grid_shape'>
                            <ul>
                              <li>
                                <Button className='list-color d-none d-xl-block p-0 sortBy_btn' onClick={() => setSortShow(true)}>
                                  Sort By <KeyboardArrowDownIcon />
                                </Button>
                              </li>
                              <li className='sort_by list-color d-xl-none'></li>
                              <li className='add_guest_sec'>
                                <Button className="list-color d-xl-none p-0" startIcon={<TuneIcon className='list-color' />} onClick={handleShow}>
                                </Button>
                                <Button className="list-color p-0" onClick={() => handleList('grid')}>
                                  {(listType === 'grid') ?
                                    <img width="20" src={GridBlueIcon} /> :
                                    <img width="20" src={GridGrayIcon} />
                                  }
                                </Button>
                                <Button className='list-color p-0' onClick={() => handleList('list')}>
                                  {(listType === 'list') ?
                                    <img width="20" src={ListBlueIcon} /> :
                                    <img width="20" src={ListGrayIcon} />
                                  }
                                </Button>

                              </li>
                              <Modal show={show} onHide={handleClose} className='model_filter'>
                                <Modal.Header closeButton>
                                  <Modal.Title>Hotel Filter</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Form>
                                    <div className='row'>
                                      <div className='col-lg-12 col-md-12'>
                                        <h5>Price</h5>
                                      </div>
                                      <div className='col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Min {minMaxSlider.MinItem} </Form.Label>
                                          <Form.Control
                                            type="number"
                                            placeholder="Enter Min Amount"
                                            autoFocus
                                            name="minAmount"
                                            defaultValue='500'
                                            {...register('minAmount')}
                                          />
                                        </Form.Group>
                                      </div>
                                      <div className='col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Max {minMaxSlider.MaxItem} </Form.Label>
                                          <Form.Control
                                            type="number"
                                            placeholder="Enter Max Amount"
                                            autoFocus
                                            defaultValue='100000'
                                            name="maxAmount"
                                            {...register('maxAmount')}
                                          />
                                        </Form.Group>
                                      </div>
                                      <div className='col-lg-12 col-md-12'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Sorting</Form.Label>
                                          <select name='shorting' {...register('shorting')} className="form-select" aria-label="Default select example">
                                            <option value="price-low-high">Price low high</option>
                                            <option value="price-high-low">Price high low</option>
                                            <option value="rating-low-high">Rating low high</option>
                                            <option value="rating-high-low">Rating high low</option>
                                            <option value="alpha-A-Z">Alpha A Z</option>
                                            <option value="alpha-Z-A">Alpha Z-A</option>
                                            <option value="distance-low-high">Distance low high</option>
                                            <option value="distance-high-low">Distance high low</option>
                                          </select>
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <div className='row'>
                                      <div className='col-lg-12 col-md-12'>
                                        <h5>Rating</h5>
                                      </div>
                                      <div className='col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Rating</Form.Label>
                                          <select name={ratingHotel} value={ratingHotel} onChange={(e) => setRatingHotel(e.target.value)} className="form-select" aria-label="Default select example">
                                            <option value="">Select</option>
                                            <option value="5">5</option>
                                            <option value="4">4</option>
                                            <option value="3">3</option>
                                            <option value="1,2,3">less than 3</option>
                                          </select>
                                        </Form.Group>
                                      </div>
                                      <div className='col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Trip Advisor Rating</Form.Label>
                                          <select name={advicerRating} value={advicerRating} onChange={(e) => setAdvicerRating(e.target.value)} className="form-select" aria-label="Default select example">
                                            <option value="">Select</option>
                                            <option value="5">5</option>
                                            <option value="4,4.5">4</option>
                                            <option value="3,3.5">3</option>
                                            <option value="1,1.5,2,2.5">less than 3</option>
                                          </select>
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <div className='row'>
                                      {/* <div className='col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Fare Type</Form.Label>
                                          <select name='faretype' {...register('faretype')} className="form-select" aria-label="Default select example">
                                            <option value="">Select</option>
                                            <option value="Refundable">Refundable</option>
                                            <option value="Non-Refundable">Non-Refundable</option>
                                          </select>
                                        </Form.Group>
                                      </div> */}
                                      <div className='col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Property Type</Form.Label>
                                          <select name='propertyType' {...register('propertyType')} className="form-select" aria-label="Default select example">
                                            <option value="">Select</option>
                                            <option value="HOTELS">HOTELS</option>
                                            <option value="RESORTS">RESORTS</option>
                                            <option value="APARTMENTS">APARTMENTS</option>
                                          </select>
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <div className='row'>
                                      <div className='col-lg-12 col-md-12'>
                                        <h5>Facility & Locality</h5>
                                      </div>
                                      <div className='col-lg-12 col-md-12'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Facility</Form.Label>
                                          <Checkbox.Group style={{ width: '100%' }} onChange={onChangeFecility}>
                                            <Row>
                                              <Col span={8}>
                                                <Checkbox value="Internet access">Internet access</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Room Service">Room Service</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Laundry Service">Laundry Service</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Air conditioning">Air conditioning</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Pub">Pub</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Bath">Bath</Checkbox>
                                              </Col>
                                            </Row>
                                            <Row className={(facilityShow) ? '' : 'd-none'}>
                                              <Col span={8}>
                                                <Checkbox value="24-hour check-in">24-hour check-in</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Wi-Fi">Wi-Fi</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Medical Assistance">Medical Assistance</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Bar">Bar</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Shower">Shower</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Wheelchair">Wheelchair</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Currency Exchange">Currency Exchange</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Housekeeping">Housekeeping</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="TV Room">TV Room</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Car Park">Car Park</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value=" WLAN acces"> WLAN acces</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Conference Room">Conference Room</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Restaurant">Restaurant</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Games room">Games room</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Casino">Casino</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Theatre">Theatre</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Nightclub">Nightclub</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Shops">Shops</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Cafe">Cafe</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="Lift">Lift</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="24-hour reception">24-hour reception</Checkbox>
                                              </Col>
                                            </Row>
                                            <Button className='facilityBtn' onClick={() => setFacilityShow(!facilityShow)}>
                                              {(facilityShow) ? 'Close' : 'See more'}
                                            </Button>
                                          </Checkbox.Group>
                                        </Form.Group>

                                      </div>
                                      {/* <div className='col-lg-12 col-md-12'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Locality</Form.Label>
                                          <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                                            <Row>
                                              <Col span={8}>
                                                <Checkbox value="locality">locality</Checkbox>
                                              </Col>
                                              <Col span={8}>
                                                <Checkbox value="region">region</Checkbox>
                                              </Col>
                                            </Row>
                                          </Checkbox.Group>
                                        </Form.Group>
                                      </div> */}
                                    </div>
                                  </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                  <div className="form-group">
                                    <button className="btn btn-primary mr-1" onClick={ClearFilter} >Reset Filter</button>
                                    <button className="btn btn-primary mr-1" onClick={handleClose} >Close</button>
                                    <button type="submit" className="btn btn-primary mr-1" onClick={handleSubmit(onSubmit)}>Filter</button>
                                  </div>
                                </Modal.Footer>
                              </Modal>
                              <Modal show={sortShow} onHide={handleSortingClose} className='model_filter'>
                                <Modal.Header closeButton>
                                  <Modal.Title>Sort By Price</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Form>
                                    <div className='row'>
                                      <div className='col-lg-12 col-md-12'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <select onChange={(e) => changeFilter(e, 'sorting')} defaultValue={hotelFilterResult?.filters?.sorting} name='sorting' className="form-select" aria-label="Default select example">
                                            <option value="price-low-high">Price low high</option>
                                            <option value="price-high-low">Price high low</option>
                                            <option value="rating-low-high">Rating low high</option>
                                            <option value="rating-high-low">Rating high low</option>
                                            <option value="alpha-A-Z">Alpha A Z</option>
                                            <option value="alpha-Z-A">Alpha Z-A</option>
                                            <option value="distance-low-high">Distance low high</option>
                                            <option value="distance-high-low">Distance high low</option>
                                          </select>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                  <div>
                                    <button className="btn btn-primary mr-1" onClick={handleSortingClose} >Close</button>
                                  </div>
                                </Modal.Footer>
                              </Modal>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <Box>
                        <Grid className='row'>
                          {
                            currentPosts?.length !== 0 ?
                              currentPosts.map((val, index) => {
                                return (
                                  <>
                                    {listType === "grid" ?


                                      <Grid className='col-lg-4 col-md-6 col-sm-6 col-12' key={'currentPosts' + index}>
                                        <HotelGrid val={val} index={index} key={index} />
                                      </Grid>
                                      : <HotelCard val={val} index={index} key={index} />}
                                  </>
                                )
                              }) :
                              <div className='no_result_found'>
                                <img src={foundImg} alt='not found' />
                              </div>
                          }
                        </Grid>
                      </Box>
                      <div className='hotel_pagination'>
                        {hotelSearchResult?.length > 10 &&
                          <>
                            <Pagination count={Math.ceil(hotelSearchResult.length / 10)} color="primary" page={currentPage} onChange={OnPageChange} />
                            {nextToken && <p onClick={loadMore}>load more</p>}
                          </>
                        }
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div >
    </>
  )
}

