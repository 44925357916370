import React, { useEffect, useRef, useState } from 'react'
import { Editor } from "@tinymce/tinymce-react";
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import placeholderDrop from '../../asset/images/logo-gallery.png'
import { Form } from 'react-bootstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Select from 'react-select';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import blogActions from '../../redux/blog/actions'
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import trash from '../../asset/icons/trash.png'
import { useLocation, useNavigate } from 'react-router-dom';
import './editorStyle.css';
import { useForm, Controller } from "react-hook-form";
import MomentTimeZone from 'moment-timezone'

const CreateBlogEditor = () => {

  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log('state', state?.UserProfileData)
  const { blogCategoryList, blogCountryList } = useSelector((state) => state.blogReducer)
  const [categoryDropDown, setCategoryDropDown] = useState([]);
  var [selectedCategory, setSelectedCategory] = useState(-1);
  const [countryDropDown, setCountryDropDown] = useState([]);
  var [selectedCountry, setSelectedCountry] = useState(-1);
  var [selectedTagValues, setSelectedTagValues] = useState([]);
  var [blogTitle, setBlogTitle] = useState('');
  var [enterPlace, setEnterPlace] = useState('');
  var [blogDisc, setBlogDisc] = useState('');
  const editorRef = useRef(null);
  const [selectImage, setSelectImage] = useState([]);
  const [imageError, setImageError] = useState("");

  const handleAddTags = (newTags) => {
    setSelectedTagValues(newTags);
    setValue("tag", newTags);
    clearErrors("tag");
  }

  const categoryList = blogCategoryList
  const countryList = blogCountryList
  const blogDiscription = () => {
    if (editorRef?.current) {
      setBlogDisc(blogDisc = editorRef?.current?.getContent())
    }
  };

  useEffect(() => {
    var temp = []
    categoryList?.forEach((e) => {
      temp?.push({
        value: e?.name, label: e?.name, category_id: e?.id
      })
    })
    setCategoryDropDown(temp)

    var temp2 = []
    countryList?.forEach((e) => {
      temp2?.push({
        value: e?.name, label: e?.name, country_id: e?.id
      })
    })
    setCountryDropDown(temp2)
  }, [categoryList, countryList])

  useEffect(() => {
    dispatch({ type: blogActions.GET_BLOG_CATEGORY_LIST })
    dispatch({ type: blogActions.GET_BLOG_COUNTRY_LIST })
  }, [])

  // const currDate = new Date().toLocaleDateString();
  const currDate = MomentTimeZone().tz('Asia/Kolkata').format('DD-MM-YYYY');
  // const currTime = new Date().toLocaleTimeString();
  const currTime = MomentTimeZone().tz('Asia/Kolkata').format('hh:mm A');

  const handlePublish = (data) => {
    // console.log(data)
    if (images?.length === 0) {
      setImageError("Image is required")
    } else {
      setImageError("");
      var formData = new FormData();
      formData.append('heading', data?.heading)
      formData.append('description', data?.description)
      formData.append('post_date', moment(currDate,'DD-MM-YYYY').format('DD-MM-YYYY'))
      formData.append('post_time', moment(currTime, 'hh:mm A').format('hh:mm'))
      formData.append('category_id', data?.category)
      formData.append('place', data?.place)
      formData.append('country_id', data?.country)
      formData.append('tags', selectedTagValues.toString().replace('[', "").replace(']', ""))
      // formData.append('blog_image', selectImage?.file)
      if (images?.length > 0) {
        for (let i = 0; i < images?.length; i++) {
          // console.log("img-array", images[i].file)
          formData.append('blog_image[]', images[i].file);
        };
      };
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }
      dispatch({ type: blogActions.GET_CREATE_BLOG_DATA, payload: { body: formData, navigate: navigate } })
    }
  }

  let [images, setImages] = useState([]);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    let imageArr = [];
    for (let i = 0; i < files.length; i++) {
      imageArr.push({
        file: files[i],
        previewUrl: URL.createObjectURL(files[i]),
      })
    }
    setImages(images = [...images, ...imageArr]);
    if (images?.length > 0) {
      setImageError("")
    } else {
      setImageError("Image is required")
    }
  };

  const removeImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const onSubmit = (data) => {
    //  console.log(data);
    handlePublish(data)
  }

  const handleCategory = (e) => {
    setSelectedCategory(e)
    setValue("category", e?.category_id)
    clearErrors("category")
  }

  const handleCountry = (e) => {
    setSelectedCountry(e)
    setValue("country", e?.country_id);
    clearErrors("country")
  }
  // console.log("setSelectedCategory",selectedCategory)



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ flexGrow: 1 }} className='upload_blog_details'>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box className='upload_blog_form'>
                <Box className="form-row">
                  <Box className="form-group">
                    <label>Blog Heading</label>
                    <input
                      name="heading"
                      id="heading"
                      type="text"
                      // value={blogTitle}
                      className='form-control'
                      onChange={((e) => setBlogTitle(e?.target?.value))}
                      {...register("heading", { required: true, maxLength: 30 })}
                    />
                    <div className='error'> {errors?.heading && errors?.heading.type === "required" && <p className='error'>Blog Heading is required</p>}</div>
                    <div className='error'> {errors?.heading && errors?.heading?.type === "maxLength" && <p className='error'>Blog Heading should be 30 characters</p>}</div>

                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-group">
                    <Form.Label>Description</Form.Label>
                    <Editor
                      // apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"                          
                      apiKey="egrtrwnyaann0ljwixpbdyfnrszxsfxir40r0hbaupb652lc"
                      init={{
                        height: 350,
                        menubar: false
                      }}
                      onInit={(evt, editor) => (editorRef.current = editor)}

                      onEditorChange={(newValue, editor) => {
                        setValue("description", newValue)
                        clearErrors("description")

                      }}
                      {...register("description", { required: true, minLength: 150, maxLength: 5000 })}
                    //  value={blogDisc}
                    />
                    <div className='error'> {errors?.description && errors?.description.type === "required" && <p className='error'>Blog Description is required</p>}</div>
                    <div className='error'> {errors?.description && errors?.description?.type === "minLength" && <p className='error'>Blog Description should be minimum 150</p>}</div>
                    <div className='error'> {errors?.description && errors?.description?.type === "maxLength" && <p className='error'>Blog Description should not exceed 5000 characters</p>}</div>

                  </Box>
                </Box>
                <Box className="form-row blog_bg_drop">
                  <Box className="form-group">
                    <Box className="blog_bg_drop_sec">

                      <Box className='related_cont'>
                        <img className='blog_bg_drop_sec_img' src={placeholderDrop} alt='img' />
                        <input
                          name="blogImage"
                          onChange={handleFileUpload}
                          type="file"
                          multiple="multiple"
                          accept="image/png, image/jpeg"
                          className={`form-control select_blog_image_input`}
                        />
                        <div className='drag__and_drop_text'>
                          <span>Upload a file</span><br></br>
                          <span className='accept__files'>Only jpeg, png accepted</span>
                        </div>
                      </Box>
                    </Box>
                    <p className='error'> {imageError}</p>
                    <div className='drag__and_drop_image'>
                      {images.map((image, index) => (
                        <div key={index} className="image-item">
                          <img
                            src={image.previewUrl}
                            alt="Preview"
                            className="profile-pic"
                          // onClick={() => showImage(index)}
                          />
                          <button
                            className="remove-button"
                            onClick={() => removeImage(index)}
                          >
                            X
                          </button>
                        </div>
                      ))}
                    </div>

                    <Box className='show_select_and_delete_box'>
                      <img onClick={() => setSelectImage({ file: null, url: null })} className='deleted__icon' src={trash} alt='delete_icon' />
                      <img className='selected__image' src={selectImage?.url} />
                    </Box>
                  </Box>
                </Box>
                {/* <div className='error'>{errors.image?.message}</div> */}
                {/* <Box className='set-background_sec'>
                  {[...Array(selectImage)].map((e, i) =>
                  <Box className='setUs_background'>
                    <img src={placeholderDrop} alt='img' />
                    <Box className='setUs_background_text'>
                      <Typography variant='h5'>abcd image name.jpg</Typography>
                      <Link to='/'>Set as background</Link>
                    </Box>
                  </Box>
                  )}
                </Box> */}
              </Box>

            </Grid>
            <Grid item xs={12} md={4}>
              <Box className='date_gategory_section'>
                <Box className='blog_preview_btn'>
                  <Button><VisibilityIcon />Preview</Button>
                </Box>
                <Box className='blog_date_time'>
                  <Box className='blog_postDate'>
                    <Form.Group>
                      <Form.Label>Post Date :</Form.Label>
                      <span className='post_date_time'> {moment(currDate, 'DD/MM/YYYY').format('DD/MM/YYYY')}</span>
                    </Form.Group>
                  </Box>
                  <Box className='blog_postTime'>
                    <Form.Group>
                      <Form.Label>Time :</Form.Label>
                      <span className='post_date_time'> {moment(currTime, 'hh:mm A').format('HH:mm')}</span>
                      {/* <TimePicker onChange={this.handleTimeChange} value={this.state.time} start="00:00" end="23:59" step={30} /> */}
                    </Form.Group>
                  </Box>
                </Box>
                <Box className='blog_catagies_select'>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <div className='select__drop__down'>
                      <Select
                        options={categoryDropDown}
                        placeholder="Select Category"
                        {...register("category", { required: true })}
                        value={selectedCategory}
                        onChange={((e) => handleCategory(e))}
                        isSearchable={true}
                      // isMulti                     
                      />
                    </div>
                    <div className='error'> {errors?.category && errors?.category.type === "required" && <p className='error'>Blog category is required</p>}</div>
                  </Form.Group>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className='blog_catagies_select'>
                      <Form.Group>
                        <Form.Label>Place</Form.Label>
                        <Box className="form-row">
                          <Box className="form-group">
                            <input
                              name="place"
                              id="place"
                              type="text"
                              // value={enterPlace}
                              className='form-control blog__inputs'
                              onChange={((e) => setEnterPlace(e?.target?.value))}
                              {...register("place", { required: true })}
                            />
                            <div className='error'> {errors?.place && errors?.place.type === "required" && <p className='error'>Place is required</p>}</div>
                          </Box>
                        </Box>
                      </Form.Group>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className='blog_catagies_select'>
                      <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <div className='select__drop__down'>
                          <Select
                            name='country'
                            options={countryDropDown}
                            placeholder="Country"
                            {...register("country", { required: true })}
                            value={selectedCountry}
                            onChange={((e) => handleCountry(e))}
                            isSearchable={true}
                          // isMulti
                          />
                        </div>
                        <div className='error'> {errors?.country && errors?.country.type === "required" && <p className='error'>Country is required</p>}</div>
                      </Form.Group>
                    </Box>
                  </Grid>
                </Grid>

                <Box className='blog_tag_select '>
                  <Form.Group>
                    <Form.Label>Tag <small>(Press enter to add tags)</small></Form.Label>
                    <TagsInput
                      inputProps={{ placeholder: "Press enter to add tags" }}
                      {...register("tag", { required: true })}
                      value={selectedTagValues}
                      onChange={handleAddTags}
                    />
                    <div className='error'> {errors?.tag && errors?.tag.type === "required" && <p className='error'>Enter Tags</p>}</div>
                  </Form.Group>
                </Box>

                <Box className='create_blog_publish'>
                  <Button type="submit" onSubmit={blogDiscription}>publish</Button>
                </Box>

              </Box>

            </Grid>
          </Grid>
        </Container>
      </Box>

    </form>
  );
}


export default CreateBlogEditor;
