import React, { useEffect } from 'react'
import Header from './header'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Footer from './footer';
import { Button } from '@material-ui/core';
import SendIcon from '@mui/icons-material/Send';

export const ContactUs = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div>
            <Header />
            <section class="contact" id="contact">
                <div class="container">
                    <div class="heading text-center">
                        <h2>Contact
                            <span> Us </span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            <br />incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-5 col-lg-5 col-sm-12 col-12">
                            <div class="title">
                                <h3>Contact detail</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                            </div>
                            <div class="content">
                                <div class="info">
                                    <PhoneIphoneIcon />
                                    <h4 class="d-inline-block">PHONE :
                                        <br />
                                        <span>+12457836913 , +12457836913</span></h4>
                                </div>
                                <div class="info">
                                    <EmailIcon />
                                    <h4 class="d-inline-block">EMAIL :
                                        <br />
                                        <span>example@info.com</span></h4>
                                </div>
                                <div class="info">
                                    <LocationOnIcon />
                                    <h4 class="d-inline-block">ADDRESS :<br />
                                        <span>6743 last street , Abcd, Xyz</span></h4>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-7 col-lg-7 col-sm-12 col-12 contact_frm">

                            <form>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control" placeholder="Name" />
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="email" class="form-control" placeholder="Email" />
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" placeholder="Subject" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" rows="5" id="comment" placeholder="Message"></textarea>
                                </div>
                                <Button type="submit" className="search_btn_banner" variant="outlined" startIcon={<SendIcon />}>Search</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
