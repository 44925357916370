import { Button, Typography } from '@material-ui/core'
import { Apartment, PinDrop } from '@material-ui/icons'
import { CardMedia, Rating } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import cardimg from '../../asset/hotel/2.jpg'
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useDispatch } from 'react-redux'
import hotelActions from '../../redux/hotel/actions'
import { useRef } from 'react'
import { useEffect } from 'react'

const HorizontalCard = (props) => {
  const { item, val } = props;
  const history = useNavigate();
  const bookNow = useRef([]);
  const dispatch = useDispatch();

  const value = val.hotelRating;

  const handleBooking = (val, item) => {
    history('/hotel-payment');
    dispatch({ type: hotelActions.SET_TEMP_HOTEL_DETAILS, payload: { val: val, item: item } })
  }

  useEffect(() => {
    bookNow?.current[0]?.focus();
  }, []);

  return (
    <div>
      <div className="popular_hotel_card horizontalCardMain" >
        {/* <div className='nearhotel_card_img horizontalCard'>
          <CardMedia
            component="img"
            height="190"
            image={cardimg}
            alt="green iguana"
          />
          <div className='overlay'></div>
          <div className='hotel_review'>
            <div className='hotel_review_inner'>
              <Rating
                name="text-feedback"
                value={value}
                readOnly
                precision={0.5}
                emptyIcon={<StarBorderIcon style={{ color: '#fff', fontSize: '18' }} fontSize="inherit" />}
              />
            </div>
          </div>
        </div> */}
        <div className='hori_card_con'>
          <div className='near_plcess_details'>
            <Button variant="outlined" className="list-color" tabIndex={-1} startIcon={<Apartment className="list-color" />}>
              {val.propertyType}
            </Button>
            <Button variant="outlined" className="list-color" tabIndex={-1} startIcon={<PinDrop className="list-color" />}>
              {val.city}
            </Button>
          </div>
          <div className='hotel_card_contentbox'>
            <h3 className="MuiTypography-root MuiTypography-h3">
              {val.hotelName}
            </h3>
            <div className='hotel_review_star'>
              <Rating
                name="text-feedback"
                value={value}
                readOnly
                precision={0.5}
                emptyIcon={<StarBorderIcon style={{ color: '#fff', fontSize: '18' }} fontSize="inherit" />}
              />
            </div>
            <p className='hotel_card_con'>
              {item.description}
            </p>
            <div className='row card_fec'>
              <div className='col-lg-6'>
                <ul className='fecility_room'>
                  {item.facilities.sort().map((item, ind) => (
                    <li key={'facilities' + ind}>{item}</li>
                  ))}
                </ul>
            </div>
              <div className='col-lg-6'>
                <ul className='fecility_room'>
                  <li>{item.boardType}</li>
                  <li>{item.fareType}</li>
                  <li>{item.inventoryType}</li>
                  <li>{item.roomType}</li>
                  <li>{item.cancellationPolicy} *The cancellation policy is setup by hotels, not by tickatrip *</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='hori_view_btn'>
          <h6 className='MuiTypography-root'>
            {item.currency} : {item.netPrice} <span className='tax'> All Inclusive Price {item.netPrice}</span>
          </h6>
          {/* <Link className='hotel_btn' to='/hotel-payment' state={{ val: val, item: item }}>Book Now</Link> */}
          <button className='book_tick pop_con' ref={el => bookNow.current[props.index] = el} onClick={() => handleBooking(val, item)}>Book Now</button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(HorizontalCard);