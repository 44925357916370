const flightActions = {
  GET_ALL_FLIGHT: 'GET_ALL_FLIGHT',
  SET_ALL_FLIGHT: 'SET_ALL_FLIGHT',
  GET_ALL_CITIES: 'GET_ALL_CITIES',
  SET_ALL_CITIES: 'SET_ALL_CITIES',
  UPDATE_FLIGHT_LIST_DETAILS: 'UPDATE_FLIGHT_LIST_DETAILS',
  GET_ALL_FROM: 'SET_ALL_FROM',
  SET_ALL_FILTER: 'SET_ALL_FILTER',
  GET_FROM_CITIES: 'GET_FROM_CITIES',
  SET_FROM_CITIES: 'SET_FROM_CITIES',
  GET_TO_CITIES: 'GET_TO_CITIES',
  SET_TO_CITIES: 'SET_TO_CITIES',
  SET_SEARCH_FLIGHT: 'SET_SEARCH_FLIGHT',
  GET_FARE_RULES: 'GET_FARE_RULES',
  SET_FARE_RULES_LOADER: 'SET_FARE_RULES_LOADER',
  SET_FARE_RULES: 'SET_FARE_RULES',
  GET_VALIDATE_FARE_METHOD: 'GET_VALIDATE_FARE_METHOD',
  SET_VALIDATE_FARE_METHOD: 'SET_VALIDATE_FARE_METHOD',
  GET_ADULT_TRAVELLER_DATA: 'GET_ADULT_TRAVELLER_DATA',
  SET_ADULT_TRAVELLER_DATA: 'SET_ADULT_TRAVELLER_DATA',
  GET_CHILD_TRAVELLER_DATA: 'GET_CHILD_TRAVELLER_DATA',
  SET_CHILD_TRAVELLER_DATA: 'SET_CHILD_TRAVELLER_DATA',
  GET_INFANT_TRAVELLER_DATA: 'GET_INFANT_TRAVELLER_DATA',
  SET_INFANT_TRAVELLER_DATA: 'SET_INFANT_TRAVELLER_DATA',
  GET_BOOKIG_TRAVELLER_DATA: 'GET_BOOKIG_TRAVELLER_DATA',
  SET_BOOKIG_TRAVELLER_DATA: 'SET_BOOKIG_TRAVELLER_DATA',
  SET_BOOKIN_FARE_SOURCE_METHOD: 'SET_BOOKIN_FARE_SOURCE_METHOD',
  GET_BOOKIG_RESPONSE_DATA: 'GET_BOOKIG_RESPONSE_DATA',
  SET_BOOKIG_RESPONSE_DATA: 'SET_BOOKIG_RESPONSE_DATA',
  GET_TICKET_ORDER_DATA: 'GET_TICKET_ORDER_DATA',
  SET_TICKET_ORDER_DATA: 'SET_TICKET_ORDER_DATA',
  SET_TICKET_ORDER_STATUS: 'SET_TICKET_ORDER_STATUS',
  SET_BOOKING_CONFIRM: 'SET_BOOKING_CONFIRM',
  SET_TRIP_DETAILS: 'SET_TRIP_DETAILS',
  GET_TRIP_DETAILS: 'GET_TRIP_DETAILS',
  GET_PNR_TICKET_CANCEL: 'GET_PNR_TICKET_CANCEL',
  SET_PNR_TICKET_CANCEL: 'SET_PNR_TICKET_CANCEL',
  GET_PNR_TICKET_OTP_CONFIRM: 'GET_PNR_TICKET_OTP_CONFIRM',
  SET_PNR_TICKET_OTP_CONFIRM: 'SET_PNR_TICKET_OTP_CONFIRM',
  SET_PNR: 'SET_PNR',
  SET_FILTER_FLIGHT: 'SET_FILTER_FLIGHT',
  SET_RESULT_FLIGHT: 'SET_RESULT_FLIGHT',
  SET_FLIGHT_LOADER: 'SET_FLIGHT_LOADER',
  SET_FLIGHT_IS_REFUNDABLE: 'SET_FLIGHT_IS_REFUNDABLE',
  FLIGHT_SESSION_RELOAD: 'FLIGHT_SESSION_RELOAD',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
  GET_FLIGHT_COUPON: 'GET_FLIGHT_COUPON',
  SET_FLIGHT_COUPON: 'SET_FLIGHT_COUPON',
  SET_FLIGHT_FILTER:'SET_FLIGHT_FILTER',
  GET_FLIGHT_FILTER:'GET_FLIGHT_FILTER',
  GET_FLIGHT_BOOKING:'GET_FLIGHT_BOOKING',
  GET_FLIGHT_NAME_FILTER:'GET_FLIGHT_NAME_FILTER',
  SET_FLIGHT_NAME_FILTER:'SET_FLIGHT_NAME_FILTER',
};

export default flightActions;