import React from 'react'
import { makeStyles, Typography } from "@material-ui/core";
import homebanner from "../asset/images/homebanner.png";
import HotelSearch from '../components/Hotel/HotelSearch';
import { HotelOfferSlider } from '../components/Hotel/HotelOfferSlider';
import PopularPlace from '../components/home/PopularPlace';
import TravelSupport from '../components/home/TravelSupport';
import OurExperience from '../components/home/OurExperience';
import Testimonial from '../components/home/Testimonial';

const useStyles = makeStyles((theme) =>
({
    homebanner: {
        backgroundImage: `url(${homebanner})`,
        padding: ' 3rem 0',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    bannerTxtSmtop: {
        color: "#fff",
        fontSize: '2rem ',
        [theme.breakpoints.down("xs")]: {
            fontSize: '18px ',
        },
    },
    bannerTxtcen: {
        color: "#fff",
        padding: "1rem 0",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: '40px',
        },
    },
    radiobtns: {
        display: "flex",
    },
    bannerTxtSmbot: {
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
            fontSize: '20px ',
        },
    },
    bannerTxtInner: {
        padding: "0rem 0 0 0",
        [theme.breakpoints.down("xs")]: {
            padding: "0rem 0 0 0",
        },
    },
    tabinner: {
        background: "#fff",
        borderRadius: "5px",
        padding: "2rem 3rem !important",
    },
    tabLabel: {
        color: "#fff",
    },
    bannercon: {
        marginBottom: '5rem',
        [theme.breakpoints.down("xs")]: {
            marginLeft: '1rem',
        },
    }
})
)

export default function Hotel() {
    const classes = useStyles();
    return (
        <>
            <div>
                <div className={classes.homebanner}>
                    <div className={`container ${classes.bannerTxtInner}`}>
                        <div className='hotel_banner_box'>
                            <div className={classes.bannerTxtInner}>
                                <div className='bannercon_hotel'>
                                    <Typography className={classes.bannerTxtSmtop} variant="h3">
                                        It's time to
                                    </Typography>
                                    <Typography className={classes.bannerTxtcen} variant="h1">
                                        Discover
                                    </Typography>
                                    <Typography className={classes.bannerTxtSmbot} variant="h5">
                                        Find and book a great experience.
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className='hotel-search_form'>
                            <HotelSearch />
                        </div>
                    </div>
                </div>
                <div className='home_offer_slider'>
                    <HotelOfferSlider />
                </div>
                <PopularPlace />
                <TravelSupport />
                <OurExperience />
                <Testimonial />
            </div>
        </>

    )
}
