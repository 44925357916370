import { Box } from '@material-ui/core'
import React, { useEffect } from 'react'
import blogBannerRecentNews1 from '../../asset/hotel/1.jpg'
import foundImg from '../../asset/icon-nothing-found.png'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import blogActions from '../../redux/blog/actions';
// import { API_IMG_URL } from '../../utils/constant';
// import { useNavigate } from "react-router-dom";
import { Typography } from '@mui/material';
// import { Link } from 'react-router-dom'
import BlogVerticalCard from './BlogVerticalCard'
import { useLocation } from "react-router-dom";
import { useState } from 'react'
import Masonry from 'react-masonry-css'
import InfiniteScroll from 'react-infinite-scroll-component';
import travellerActions from '../../redux/traveller/actions'

export const CategoryWiseListPage = () => {
    const { state } = useLocation();
    // const slider = React.useRef(null);
    const dispatch = useDispatch()
    const [fetchedData, setFetchedData] = useState([]);
    const { CategoryWiseBlog } = useSelector((state) => state.blogReducer)

    useEffect(() => {
        dispatch({ type: blogActions.GET_CATEGORY_WISE_BLOG, payload: state?.categoryId })
    }, [state?.categoryId])
    useEffect(() => {
        dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }, []);
    // var recentBlogSlider = {
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     infinite: true,
    //     arrows: false,
    //     dots: true,
    //     autoplay: true,
    //     appendDots: dots => <ul>{dots}</ul>,
    //     customPaging: i => (
    //         <div className="ft-slick__dots--custom">
    //             <div className="loading" />
    //         </div>
    //     )
    // };
    const breakpointColumnsObj = {
        default: 3,
        991: 2,
        600: 1,
    };
    useEffect(() => {
        if (CategoryWiseBlog?.length > 0) {
            setFetchedData(CategoryWiseBlog?.slice(0, 20));
        };
    }, [CategoryWiseBlog]);

    const fetchMoreData = () => {
        if (CategoryWiseBlog?.length === fetchedData?.length) {
            return;
        } else {
            let currentLength = fetchedData?.length;
            setFetchedData([...fetchedData, ...CategoryWiseBlog?.slice(currentLength, currentLength + 20)])
        }
    };
    return (
        <div>
            <div className='hotelBanner' style={{ backgroundImage: `url(${blogBannerRecentNews1})` }}>
                <Container>
                    <h1 className="text-white banner_title">Category List</h1>
                </Container>
            </div>
            <div className='Category__wise__list__page'>
                <Container>
                    <Box className='recent_blog_head'>
                        <Typography variant='h3'>{state.categoryName} blog's</Typography>
                        {/* <Link to='/blog-list'><Button className='book_tick blog_more'>explore more</Button></Link> */}
                    </Box>
                    {CategoryWiseBlog?.length === 0 ?
                        <div className='cate__found__img'>
                            <img src={foundImg} alt='blog' />
                        </div>
                        :
                        <Box className='Blog__Destination__section'>
                            {/* <Row>
                                {CategoryWiseBlog.map((val, index) => {
                                    return (
                                        <Col key={index} lg='4' md='4' sm='6'>
                                            <BlogVerticalCard val={val} />
                                        </Col>

                                    );
                                })}
                            </Row> */}
                            <InfiniteScroll
                                dataLength={fetchedData?.length}
                                next={fetchMoreData}
                                hasMore={true}
                                style={{ overflow: "hidden" }}
                            >
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                >
                                    {fetchedData.map((val, index) => {
                                        return (
                                            // <Col key={index} lg='4' md='4' sm='6'>
                                            <BlogVerticalCard val={val} page="category_wise_list" />
                                            // </Col>

                                        );
                                    })}
                                </Masonry>
                            </InfiniteScroll>
                        </Box>
                    }
                </Container>
            </div>
        </div>
    )
}
