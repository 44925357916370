import * as React from 'react';
import { useEffect } from 'react';
import { Container } from '@material-ui/core'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Apartment, PinDrop, } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { API_IMG_URL } from '../../utils/constant';
import commonActions from '../../redux/common/actions';
import { useNavigate } from 'react-router';

function PopularPlace() {
  const history = useNavigate()
  const dispatch = useDispatch();
  const { popularPlaces } = useSelector(state => state.commonReducer);

  const OnDetails = (el) => {
    dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({
      type: commonActions.GET_POPULAR_PLACES_DETAILS, payload: {
        placeId: el.id
      },
      history: history
    })
  }

  useEffect(() => {
    dispatch({ type: commonActions.GET_POPULAR_PLACES });
  }, []);

  return (
    <>
      {popularPlaces.length > 0 &&
        <>
          <div className='popular_sec'>
            <Container>
              <div className='popularItemTd'>
                <h2 className="popular-heading">
                  MOST POPULAR PLACES
                </h2>
                <p className="popular-description">
                  Find and book your ideal hotel and get the lowest prices
                </p>
              </div>
              <Box>
                <Grid className='row'>
                  {popularPlaces.map((el, index) => {
                    return (
                      <Grid className='col-lg-4 col-md-4 col-sm-6 col-12' key={'popularPlaces' + index}>
                        <Card className='popular_card cardShadow' onClick={() => OnDetails(el, index)}>
                          <CardMedia
                            component="img"
                            image={`${API_IMG_URL}/server/popularplace/${el.place_image}`}
                            alt="green iguana"
                            className='popularPlace_img'
                          />
                          <div className='popularBlog'>
                            <h6>Blog</h6>
                          </div>
                          <CardContent className='cardcon'>
                            <h5 className="cardtd">
                              {el.place_name}
                            </h5>
                            <Stack direction="row" spacing={2} className='cardbtns' >
                              <Button className='placebtn' variant="outlined" startIcon={<Apartment />}>
                                {el.entry2}
                              </Button>
                              <Button className='placebtn' variant="outlined" startIcon={<PinDrop />}>
                                {el.entry1}
                              </Button>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
              <Button className="popular_flot" variant="outlined">
                Find More</Button>
            </Container>
            <div className="bg_over"></div>
          </div>
        </>
      }
    </>
  )
}

export default React.memo(PopularPlace);
