import React, { useEffect } from 'react'
import Header from './header'
import aboutImg from '../../asset/home-image/t-1.jpg'
import { Box, Typography } from '@material-ui/core'
import Footer from './footer'

export const AboutUs = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div>
            <Header />
            <section class="about-section">
                <div class="container">
                    <div class="row clearfix">

                        <div class="content-column col-md-6 col-lg-6 col-sm-12 col-12">
                            <div class="inner-column">
                                <div class="sec-title">
                                    <Box className='payment_form_head'>
                                        <Typography variant='h6'>About Us</Typography>
                                    </Box>
                                    <h2>We Are The Leader In <br /> The Interiores</h2>
                                </div>
                                <div class="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</div>
                                <div class="email">Request Quote: <span class="theme_color">info.tickatrip@gmail.com</span></div>
                                <a href="about.html" class="theme-btn btn-style-three">Read More</a>
                            </div>
                        </div>

                        <div class="image-column col-md-6 col-lg-6 col-sm-12 col-12">
                            <div class="inner-column " data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div class="image">
                                    <img src={aboutImg} alt="about img" />
                                    <div class="overlay-box">
                                        <div class="year-box"><span class="number">5</span>Years <br /> Experience <br /> Working</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
