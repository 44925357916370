import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import loginbackground from '../../asset/login/background.png'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import '../common/common.css'
import { useDispatch, useSelector } from 'react-redux';
import userNewPassword from '../../redux/user/actions'
import ToastAlert from '../common/ToastAlert';
import UserSlider from './UserSlider';
import { useEffect } from 'react';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

const useStyles = makeStyles((theme) =>
({
  loginSlide: {
    background: `url(${loginbackground})`,
    height: '100vh',
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      height: '100%',
      width: "100%",
      display: 'relative',
      paddingBottom: '2rem'
    },
  },
  brandlogo: {
    minHeight: ' 80px',
    maxHeight: ' 80px',
    margin: ' 3rem 0',
  },
  bannertxt: {
    fontSize: ' 16px',
    color: ' #fff',
    fontWeight: ' 300',
    lineHeight: ' 30px',
    width: ' 90%',
    margin: ' auto',
  },
  welcome_grid: {
    display: 'flex',
    justifyContent: ' space-around',
    alignItems: 'center',
    height: ' 100vh',
    textAlign: 'center',
  },

  welcome_heading: {
    fontSize: '22px',
    fontWeight: ' 500',
    marginBottom: '1.2rem'
  },
  welcome_icon: {
    width: ' 35px',
    margin: '0 0.5rem',
    padding: ' 8px',
    background: ' #eff2f9',
    boxShadow: ' 0px 0px 5px 1px #32323254',
    marginTop: ' 1.2rem',
    borderRadius: ' 5px',
  },
  welcom_social: {
    textAlign: 'center',
  },
})
);

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const UserForgetPassword = () => {

  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const { userOtpVerify } = useSelector(
    (state) => state.userReducer
  )

  const onSubmit = (data) => {
    dispatch({
      type: userNewPassword.GET_NEW_PASSWORD, userConfimPassword: {
        email: userOtpVerify,
        password: data.password,
        password_confirmation: data.confirmPassword,
      },
      history: history
    })
  }

  useEffect(() => {
    if (!userOtpVerify) {
      history('/login');
    }
  }, []);

  return (
    <Box>
      <ToastAlert />
      <Box >
        <Grid className='row'>
          <Grid className='col-lg-5 col-md-5 col-sm-12 col-12 user_slider'>
            <UserSlider />
          </Grid>
          <Grid className="col-lg-7 col-md-7 col-sm-12 col-12 log_in">
            <Link to='/' className='back_home dn-m'><ReplyAllIcon />Back to home</Link>
            <Box className={classes.welcome_grid}>
              <Box className={classes.welcome_heade}>
                <Typography className={classes.welcome_heading} variant='h1'>Please Enter Your New Password</Typography>
                <Box className='login_form'>
                  <Box className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} className='login_form reset_password'>
                      <Box className="form-row">
                        <Box className="form-group col">
                          <label>Password</label>
                          <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                          <Box className="invalid-feedback">{errors.password?.message}</Box>
                        </Box>
                        <Box className="form-group col">
                          <label>Confirm Password</label>
                          <input name="confirmPassword" type="password" {...register('confirmPassword')} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />
                          <Box className="invalid-feedback">{errors.confirmPassword?.message}</Box>
                        </Box>
                      </Box>

                      <Box className="form-group">
                        <button type="submit" className="login_btn">Set Password</button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>

            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default UserForgetPassword;