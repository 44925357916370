import App from '../../App';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Success from '../../asset/GifFiles/success.json';
import Filed from '../../asset/GifFiles/filed.json';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';


export const BookingResponse = () => {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        let params = new URLSearchParams(window.location.search.split("?")[1]);
        if (params) {
            if (params.get("status") === false || params.get("status") === "false") {
                setStatus({
                    status: false,
                    message: params.get('message')
                })
            } else {
                setStatus({
                    status: true,
                    message: params.get('message')
                })
            }
        }

    }, [])
    return (
        <>
            {
                !status?.message ?
                        <div className='response_loader'>
                            <div className="loading">
                            <div className="loading__letter">L</div>
                            <div className="loading__letter">o</div>
                            <div className="loading__letter">a</div>
                            <div className="loading__letter">d</div>
                            <div className="loading__letter">i</div>
                            <div className="loading__letter">n</div>
                            <div className="loading__letter">g</div>
                            <div className="loading__letter">.</div>
                            <div className="loading__letter">.</div>
                            <div className="loading__letter">.</div>
                        </div>
                        </div>
                    :
                    status?.status ?
                        <div className='BookingPages'>
                            <Player
                                autoplay
                                loop
                                src={Success}
                                style={{ height: '350px', width: '400px' }}
                            >
                            </Player>
                            <div className='successfull_message'>
                                <h3>{status?.message}</h3>
                                <div className='goback_btn'>
                                    <a href='/hotel-booking'> Continue</a>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='BookingPages'>
                            <Player
                                autoplay
                                loop
                                src={Filed}
                                style={{ height: '350px', width: '400px' }}
                            >
                            </Player>
                            <div className='successfull_message'>
                                <h3>{status?.message}</h3>
                                <div className='goback_btn'>
                                    <a href='/' style={{ background: "#b40725" }}> Go Back</a>
                                </div>
                            </div>
                        </div>
            }
        </>
    );
}