import { Box } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// import blogBannerRecentNews1 from '../../asset/hotel/1.jpg'
// import demo1 from '../../asset/blog/demo_1.jpg'
// import demo2 from '../../asset/blog/demo_2.jpg'
// import demo3 from '../../asset/blog/demo_3.jpg'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
// import blogActions from '../../redux/blog/actions';
import { API_IMG_URL } from '../../utils/constant';
import { useNavigate } from "react-router-dom";

export const BlogDestination = () => {
    // const slider = React.useRef(null);
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const [fetchedData, setFetchedData] = useState([]);
    const { destinationBlogList } = useSelector((state) => state.blogReducer)

    useEffect(() => {
        setFetchedData(destinationBlogList);
    }, [destinationBlogList]);

    // var recentBlogSlider = {
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     infinite: true,
    //     arrows: false,
    //     dots: true,
    //     autoplay: true,
    //     appendDots: dots => <ul>{dots}</ul>,
    //     customPaging: i => (
    //         <div className="ft-slick__dots--custom">
    //             <div className="loading" />
    //         </div>
    //     )
    // };
    return (
        <div>
            <Box className='Blog__Destination__section'>
                <Row>
                    {fetchedData?.map((val, index) => {
                        return (
                            <Col key={index} lg='6'>
                                <div className='Blog_destination_grid'>
                                    <div onClick={() => navigate('/country_wise_list', { state: { countryId: val?.country_id, countryName: val?.name } })}>
                                        <img src={`${API_IMG_URL}/server/blog/${val?.blog_image[0]}`} alt='blog' />
                                    </div>
                                    <div className='place__name'>
                                        <div onClick={() => navigate('/country_wise_list', { state: { countryId: val?.country_id, countryName: val?.name } })}>
                                            <h2>Country - {val?.name}</h2>
                                        </div>
                                        <h5><LocationOnOutlinedIcon /> {val?.blog_place_count} {val?.blog_place_count >= 2 ? "Places" : "Place"}</h5>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Box>
        </div>
    )
}
