import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import loginbackground from '../../asset/login/background.png'
import successimg from '../../asset/login/1.png'
import ToastAlert from '../common/ToastAlert';
import UserSlider from './UserSlider';
import { useNavigate } from 'react-router';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
({
  loginSlide: {
    background: `url(${loginbackground})`,
    height: '100vh',
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      height: '100%',
      width: "100%",
      display: 'relative',
      paddingBottom: '2rem'
    },
  },
  brandlogo: {
    minHeight: ' 80px',
    maxHeight: ' 80px',
    margin: ' 3rem 0',
  },
  bannertxt: {
    fontSize: ' 16px',
    color: ' #fff',
    fontWeight: ' 300',
    lineHeight: ' 30px',
    width: ' 90%',
    margin: ' auto',
  },
  welcome_grid: {
    display: 'flex',
    justifyContent: ' space-around',
    alignItems: 'center',
    height: ' 100vh',
    textAlign: 'center',
  },

  welcome_heading: {
    fontSize: '32px',
    fontWeight: ' 500',
  },
  welcome_icon: {
    width: ' 35px',
    margin: '0 0.5rem',
    padding: ' 8px',
    background: ' #eff2f9',
    boxShadow: ' 0px 0px 5px 1px #32323254',
    marginTop: ' 1.2rem',
    borderRadius: ' 5px',
  },
  welcom_social: {
    textAlign: 'center',
  },
})
)

const UserRegisterSuccess = () => {

  const classes = useStyles();
  const history = useNavigate();

  return (
    <div>
      <ToastAlert />
      <Box >
        <Grid className='row'>
          <Grid className='col-lg-5 col-md-5 col-sm-12 col-12 user_slider'>
            <UserSlider />
          </Grid>
          <Grid className="col-lg-7 col-md-7 col-sm-12 col-12 log_in">
            <Link to='/' className='back_home dn-m'><ReplyAllIcon />Back to home</Link>
            <div className={classes.welcome_grid}>
              <div className={classes.welcome_heade}>
                <Typography className={classes.welcome_heading} variant='h1'>Hi {localStorage.getItem("username")},</Typography>
                <div className='login_form'>
                  <form className="form" onSubmit={() => history('/')}>
                    <Box
                      noValidate
                      autoComplete="off"
                    >
                      <Box>
                        <img className='success_img' src={successimg} alt="" />
                      </Box>
                    </Box>
                    <div className='form_btns'>
                      <button className='login_btn' type='submit'>Proceed</button>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default UserRegisterSuccess