import React from 'react'
import OTPInput from "otp-input-react";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import flightActions from '../../redux/flight/actions';

export const PnrCancelOtp = () => {
    
    const dispatch = useDispatch()
    const { pnr } = useSelector((state) => state.flightReducer)

    const [otp, setOtp] = useState()

    const OnOtpCofirm = (e) => {
        e.preventDefault();
        dispatch({
            type: flightActions.GET_PNR_TICKET_OTP_CONFIRM, payload: {
                OTP: otp,
                AirlinePNR: pnr
            }
        })
    }

    return (
        <>
            <div className='pnr_cancel_sec'>
                <div className='pnr_cancel_card'>
                    <form onSubmit={OnOtpCofirm}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Enter your OTP</label>
                            <OTPInput
                                error
                                value={otp}
                                onChange={setOtp}
                                autoFocus
                                require
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                className='otp_field'
                            />
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </>
    )
}
