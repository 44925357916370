import React from 'react'
import '../flight/ticket.css'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const FlightTickerDetails = () => {

  const { upCommingFlightDetail } = useSelector((state) => state.userReducer);

  const timeConvert = (n) => {
    var num = n;
    var hours = Math.floor(num / 60) > 0 ? Math.floor(num / 60) + "H " : "";
    var rminutes =
      n - Math.floor(num / 60) * 60 > 0
        ? n - Math.floor(num / 60) * 60 + "M"
        : "";
    return hours + rminutes;
  }
console.log('upCommingFlightDetail',upCommingFlightDetail)
  return (
    <Box>
      <Box className='ticket-bg'>
        <Box className="ticket">
          <Box className="ticket-header">
            <Box className="ticket-departure">
              <h1 className="city-abbr">{upCommingFlightDetail?.bookingDetails?.DepartureAirportLocationCode}</h1><span className="city-name">{upCommingFlightDetail?.bookingDetails?.DepartureAirportLocation}</span>
            </Box>
            <Box className="ticket-destination">
              <h1 className="city-abbr">{upCommingFlightDetail?.bookingDetails?.ArrivalAirportLocationCode}</h1><span className="city-name">{upCommingFlightDetail?.bookingDetails?.ArrivalAirportLocation}</span>
            </Box>
          </Box>
          <Box className="ticket-body">
            {/* <Box className="row flight_ticket_seat">
              <Box className="item col-lg-4">
                <h2 className="name">Flight</h2><span className="value">815</span>
              </Box>
              <Box className="item col-lg-4">
                <h2 className="name">Gate</h2><span className="value">22A</span>
              </Box>
              <Box className="item col-lg-4">
                <h2 className="name">Seat</h2><span className="value">10C</span>
              </Box>
            </Box> */}
            <Box className="row ticket_info">
              <Box className="col-lg-6">
                <Box className="item">
                  <h2 className="name">PNR No</h2><span className="value">{upCommingFlightDetail?.bookingDetails?.AirlinePNR}</span>
                </Box>
              </Box>
              <Box className="col-lg-6">
                <Box className="item">
                  <h2 className="name">Status</h2><span className="value">{upCommingFlightDetail?.bookingDetails?.status}</span>
                </Box>
              </Box>
              <Box className="col-lg-6">
                <Box className="item">
                  <h2 className="name">Payment Id</h2><span className="value">{upCommingFlightDetail?.bookingDetails?.paymentTransactionId}</span>
                </Box>
              </Box>
              <Box className="col-lg-6">
                <Box className="item">
                  <h2 className="name">Departure</h2><span className="value">{moment(upCommingFlightDetail?.bookingDetails?.DepartureDateTime).format('DD-MM-YYYY')}</span>
                </Box>
              </Box>
              {/* <Box className="col-lg-6">
                <Box className="item">
                  <h2 className="name">Class</h2><span className="value">Premium</span>
                </Box>
              </Box> */}
              <Box className="col-lg-6">
                <Box className="item">
                  <h2 className="name">Arrival</h2><span className="value">{moment(upCommingFlightDetail?.bookingDetails?.ArrivalDateTime).format('DD-MM-YYYY')}</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className='travel-tree'>
        <Box className='container'>
          <Box><Typography variant='h6' className='travel_head'>Flight Detail</Typography></Box>
          <Box className="wrapper">
            <Box className="center-line"></Box>
            {upCommingFlightDetail?.flightTripDetails.map((el, ind) =>
              <div key={'upCommingFlightDetail.flightTripDetails' + ind}>
                <Box className="row row-1">
                  <section>
                    <FlightTakeoffIcon className='icon' />
                    <Box className="details">
                      <span className="title">{el.DepartureAirportLocation} - {el.DepartureAirportLocationCode}</span>
                      <span className='date_span'>{moment(new Date(el.DepartureDateTime)).format("ll")}</span>
                    </Box>
                    <p>Baggage - <b>{el.Baggage}</b></p>
                    <p>Flight Number - <b>{el.FlightNumber}</b></p>
                    <p>Journey Duration - <b>{timeConvert(el.JourneyDuration)}</b></p>
                    <p>Marketing Airline Code - <b>{el.MarketingAirlineCodeName}</b></p>
                    <p>Operating Airline Code - <b>{el.OperatingAirlineCodeName}</b></p>
                    <Box className="bottom">
                      <a href="#">{moment(new Date(el.DepartureDateTime)).format("LT")}</a>
                      <i>{el.DepartureAirportCity}</i>
                    </Box>
                  </section>
                </Box>
                <Box className="row row-2">
                  <section>
                    <FlightLandIcon className='icon' />
                    <Box className="details">
                      <span className="title">{el.ArrivalAirportLocation} - {el.ArrivalAirportLocationCode}</span>
                      <span className='date_span'>{moment(new Date(el.DepartureDateTime)).format("ll")}</span>
                    </Box>
                    <p>Baggage - <b>{el.Baggage}</b></p>
                    <p>Flight Number - <b>{el.FlightNumber}</b></p>
                    <p>Journey Duration - <b>{timeConvert(el.JourneyDuration)}</b></p>
                    <p>Marketing Airline Code - <b>{el.MarketingAirlineCodeName}</b></p>
                    <p>Operating Airline Code - <b>{el.OperatingAirlineCodeName}</b></p>
                    <Box className="bottom">
                      <a href="#">{moment(new Date(el.ArrivalDateTime)).format("LT")}</a>
                      <i>{el.ArrivalAirportCity}</i>
                    </Box>
                  </section>
                </Box>
              </div>
            )}
          </Box>
        </Box>
      </Box>
      <Box className='traveller_list'>
        <Box className='container'>
          <Box className='row'>
            <Box className='col-lg-8'>
              <Box><Typography variant='h6' className='travel_head'>Passenger List</Typography></Box>
              {upCommingFlightDetail?.customerDetails.map((el, ind) => {
                return (
                  <Box className="wrapper-traveller" key={'wrapper-traveller' + ind}>
                    <Box className="right">
                      <Box className="info">
                        <h3> {el.PassengerTitle} {el.PassengerFirstName} {el.PassengerLastName}</h3>
                        <Box className="info_data">
                          <Box className="data">
                            <h4>Email</h4>
                            <p>{el.EmailAddress}</p>
                          </Box>
                          <Box className="data">
                            <h4>Phone</h4>
                            <p>{el.PhoneNumber}</p>
                          </Box>
                          <Box className="data">
                            <h4>Passport Number</h4>
                            <p>{el.PassportNumber}</p>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </Box>
            <Grid className='col-lg-4'>
              <Box><Typography variant='h6' className='travel_head'>Payment Details</Typography></Box>
              <div className='flight_pop_total'>
                <div className='pop_total_card'>
                  <div className='pop_total_card_single nd-head'>
                    <span className='pop_total_list pop_price_main'>fare details</span>
                  </div>
                  <div className='pop_total_card_single'>
                    <span className='pop_total_list'>Base Fare <br />
                      {/* <span className='sm_text'>
                        Adult {upCommingFlightDetail?.priceBreakDownDetails?.adultCount} x {upCommingFlightDetail?.priceBreakDownDetails?.adultEquifare}
                        {(upCommingFlightDetail?.priceBreakDownDetails?.childCount > 0) ? (`Child ${upCommingFlightDetail?.priceBreakDownDetails?.childCount} x ${upCommingFlightDetail?.priceBreakDownDetails?.childEquifare}`) : ''}
                        {(upCommingFlightDetail?.priceBreakDownDetails?.infantCount > 0) ? ( `Infant ${upCommingFlightDetail?.priceBreakDownDetails?.infantCount} x ${upCommingFlightDetail?.priceBreakDownDetails?.infantEquifare}`) : ''}
                      </span> */}
                    </span>
                    <span className='pop_total_price'>{upCommingFlightDetail?.bookingDetails?.currency} {upCommingFlightDetail?.bookingDetails?.total_amount_paid}</span>
                  </div>
                  <div className='pop_total_card_single'>
                    <span className='pop_total_list'>Taxes</span>
                    <span className='pop_total_price'>{upCommingFlightDetail?.bookingDetails?.currency}  {upCommingFlightDetail?.bookingDetails?.tax}</span>
                  </div>
                  <div className='pop_total_card_single'>
                    <span className='pop_total_list'>Discounts & Adjustments</span>
                    <span className='pop_total_price'>{upCommingFlightDetail?.bookingDetails?.currency} {upCommingFlightDetail?.bookingDetails?.coupon_applied_discount_amount}</span>
                  </div>
                  <div className='pop_total_card_single'>
                    <span className='pop_total_list'>Convenience Fee<br /><span className='sm_text'></span></span>
                    <span className='pop_total_price'>{upCommingFlightDetail?.bookingDetails?.currency} {upCommingFlightDetail?.bookingDetails?.convenience_fee}</span>
                  </div>
                  <div className='pop_total_card_single total_price'>
                    <span className='pop_total_list'>Total</span>
                    <span className='pop_total_price'>{upCommingFlightDetail?.bookingDetails?.currency} {upCommingFlightDetail?.bookingDetails?.order_amount}</span>
                  </div>
                </div>
              </div>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
