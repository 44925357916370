import flightReducer from "./flight/reducer";
import commonReducer from "./common/reducer";
import userReducer from './user/reducer'
import travellerReducer from './traveller/reducer'
import hotelReducer from "./hotel/reducer";
import homeReducer from './home/reducer';
import blogReducer from "./blog/reducer";

const reducers = { 
    flightReducer, 
    commonReducer,
    userReducer,
    travellerReducer,
    hotelReducer,
    homeReducer,
    blogReducer,
    
};


export default reducers;
