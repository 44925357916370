export default function ChildCom(props) {
    const { room_no, addRoom, row, deleteChild } = props
    const adultOption = [1, 2, 3, 4, 5, 6]
    const childtOption = [1, 2, 3, 4,]
    const childtAge = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const onAddChild = (val) => {
        let temp = [],
            i = 0;
        while (i < val) {
            temp.push(0);
            i++;
        }
        props.changeAddRoom(addRoom.map(el => {
            return el.room_no === room_no ? {
                ...el,
                child: val,
                child_age: temp
            } : { ...el }
        }));
    }
    const OnChildAge = (e, index) => {
        let childArr = row.child_age;
        childArr[index] = parseInt(e.target.value);
        props.changeAddRoom(addRoom.map(el => {
            return el.room_no === room_no ? {
                ...el,
                child_age: childArr
            } : { ...el }
        }));
    }

    const handleAdult = (val) => {
        props.changeAddRoom(addRoom.map(el => { return el.room_no === room_no ? { ...el, adult: val } : { ...el } }));
    }
    return (
        <div className="adul_child_select_box">
            <div className="adult_select">
                <span className='pas_td'>Adult ( 12+ yrs )</span>
                <ul>
                    {adultOption.map((val, ind) => (<li key={'adult' + ind}><a className={val === row.adult ? "active_badge" : ""} onClick={() => handleAdult(val)} >{val}</a></li>))}
                </ul>
            </div>
            <div className="child_select">
                <div className="child_pass">
                <span className='pas_td'>Child ( 0-12 yrs )</span>
                <button onClick={(event) => deleteChild(event,room_no)}>Remove</button>
                </div>
                <ul>
                    {childtOption.map((val, ind) => (<li key={'child' + ind}><a className={val === row.child ? "active_badge" : ""} onClick={() => onAddChild(val)} >{val}</a></li>))}
                </ul>

                <div className="row">
                    {Array(row.child).fill(null).map((value, index) => (

                        <div className="col-md-6 col-lg-6 col-12">
                            <div>
                                <span className="pas_td child_age_select">Child {index + 1} Age</span>

                            </div>

                            <select
                                onChange={(e) => OnChildAge(e, index)}
                            >
                                {childtAge.map((obj, ind) => {
                                    return (
                                        <option key={'childtAge' + ind} value={obj}>{obj}</option>
                                    )
                                })}
                            </select>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}