const travellerActions = {
  ADD_ADULT_TRAVELLER: 'ADD_ADULT_TRAVELLER',
  SET_ADULT_TRAVELLER:"SET_ADULT_TRAVELLER",
  EDIT_ADULT_TRAVELLER:"EDIT_ADULT_TRAVELLER",
  SET_TRAVELLER_EDIT:"SET_TRAVELLER_EDIT",
  GET_TRAVELLER_LIST:'GET_TRAVELLER_LIST',
  SET_TRAVELLER_LIST:'SET_TRAVELLER_LIST',
  DELETE_ADULT_TRAVELLER:'DELETE_ADULT_TRAVELLER',
  SET_PROFILE_EDIT:'SET_PROFILE_EDIT',
  GET_PROFILE_EDIT:'GET_PROFILE_EDIT',
  GET_PROFILE_DATA:'GET_PROFILE_DATA',
  SET_PROFILE_DATA:'SET_PROFILE_DATA',
};
  
  export default travellerActions;